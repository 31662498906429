import React, { createContext } from 'react'
import Cookie from 'js-cookie'
import { withRouter, SingletonRouter } from 'next/router'
import ApolloClient from 'apollo-client'
import { ME_QUERY } from '~/graphql/ready-to-generate/auth/meQuery.graphql'
import {
  MeQuery,
  useUpdateQuizDataMutation,
  UpdateQuizDataMutationVariables
} from '~/generated/graphql'
import cookie from 'cookie'
import { withApollo, WithApolloClient } from 'react-apollo'

export type PersonaSettings = {
  covid: boolean
  newGrad: boolean
  remote: boolean
  somethingElse: boolean
}

export type Cookies = {
  [key: string]: string | undefined
}

export type State = {
  reqCookies: Cookies
  settings: PersonaSettings
}

export type Actions = {
  fetchPersonaSettings: () => void
  addPersona: (name: string) => void
  removePersona: (name: string) => void
  updatePersona: (name: string, enabled: boolean) => void
  updatePersonas: (settings: PersonaSettings) => void
  onSignUp: () => void
  onSignIn: () => void
  onSignOut: () => void
}

interface PersonasProviderProps {
  state: State
  router: SingletonRouter
  client: ApolloClient<Record<string, unknown>>
}

type ExtendedProps = WithApolloClient<PersonasProviderProps>

const PersonaStateContext = createContext<State | undefined>(undefined)
const PersonaActionsContext = createContext<Actions | undefined>(undefined)

type Action = {
  [key: string]: boolean
}

function parseCookies(cookies?: any): { [key: string]: string | undefined } {
  return typeof document !== 'undefined' ? cookie.parse(document.cookie) : cookies
}

function personaReducer(state: State, action: Action): State {
  return {
    ...state,
    settings: {
      ...state.settings,
      ...action
    }
  }
}

let loaded: boolean = false

const PersonaProviderComponent: React.FC<ExtendedProps> = ({
  state: initialState,
  client,
  children
}) => {
  const { covidPersona, newGradPersona, remotePersona, somethingElsePersona } = parseCookies(
    initialState.reqCookies
  )

  const [state, dispatch] = React.useReducer(personaReducer, {
    reqCookies: initialState.reqCookies,
    settings: {
      covid: covidPersona === '1' ? true : false,
      newGrad: newGradPersona === '1' ? true : false,
      remote: remotePersona === '1' ? true : false,
      somethingElse: somethingElsePersona === '1' ? true : false
    }
  })

  const [updateQuizData] = useUpdateQuizDataMutation()

  const persistPersonas = async (settings: PersonaSettings) => {
    const { authToken } = parseCookies(state.reqCookies)

    // Later: try to verify they are authed, cant trust authToken
    if (authToken) {
      await updateQuizData({
        variables: settings as UpdateQuizDataMutationVariables,
        refetchQueries: () => [
          {
            query: ME_QUERY
          }
        ]
      })
    }

    // Lazy, improve later
    Cookie.set('covidPersona', settings.covid ? '1' : '0', { expires: 90 })
    Cookie.set('newGradPersona', settings.newGrad ? '1' : '0', { expires: 90 })
    Cookie.set('remotePersona', settings.remote ? '1' : '0', { expires: 90 })
    Cookie.set('somethingElsePersona', settings.somethingElse ? '1' : '0', { expires: 90 })
  }

  // Lazy, improve later
  const persistPersona = async (name: string, enabled: boolean) => {
    const settings = {
      ...state.settings,
      [name]: enabled
    }

    await persistPersonas(settings)
  }

  const addPersona = async (name: string) => {
    dispatch({ [name]: true })
    await persistPersona(name, true)
  }

  const removePersona = async (name: string) => {
    dispatch({ [name]: false })
    await persistPersona(name, false)
  }

  const updatePersona = async (name: string, enabled: boolean) => {
    dispatch({ [name]: enabled })
    await persistPersona(name, enabled)
  }

  const updatePersonas = async (settings: PersonaSettings) => {
    dispatch(settings)
    await persistPersonas(settings)
  }

  const fetchPersonaSettings = async () => {
    const cookies = parseCookies(state.reqCookies)
    const { authToken } = cookies
    if (!authToken) {
      return {
        covid: cookies.covidPersona === '1' ? true : false,
        newGrad: cookies.newGradPersona === '1' ? true : false,
        remote: cookies.remotePersona === '1' ? true : false,
        somethingElse: cookies.somethingElsePersona === '1' ? true : false
      }
    } else {
      const { data } = await client.query<MeQuery>({
        query: ME_QUERY
      })

      // @ts-ignore
      return data.quizData
    }
  }

  const onSignUp = async () => {
    const { covidPersona, newGradPersona, remotePersona, somethingElsePersona } = parseCookies(
      state.reqCookies
    )

    if (!covidPersona && !newGradPersona && !remotePersona && !somethingElsePersona) return

    await persistPersonas({
      covid: covidPersona === '1' ? true : false,
      newGrad: newGradPersona === '1' ? true : false,
      remote: remotePersona === '1' ? true : false,
      somethingElse: somethingElsePersona === '1' ? true : false
    })

    loaded = false
  }

  const onSignIn = async () => {
    const { covidPersona, newGradPersona, remotePersona, somethingElsePersona } = parseCookies(
      state.reqCookies
    )

    if (!covidPersona && !newGradPersona && !remotePersona && !somethingElsePersona) return

    await persistPersonas({
      covid: covidPersona === '1' ? true : false,
      newGrad: newGradPersona === '1' ? true : false,
      remote: remotePersona === '1' ? true : false,
      somethingElse: somethingElsePersona === '1' ? true : false
    })

    loaded = false
  }

  const onSignOut = async () => {
    console.log('sign out')
    loaded = false
  }

  const loadAccount = () => {
    client
      .query<MeQuery>({
        query: ME_QUERY
      })
      .then(data => {
        const quizData = data.data.me?.quizData
        if (quizData) {
          dispatch({
            covid: quizData.covid || false,
            newGrad: quizData.newGrad || false,
            remote: quizData.remote || false,
            somethingElse: quizData.somethingElse || false
          })

          // Lazy, improve later
          Cookie.set('covidPersona', quizData.covid ? '1' : '0', {
            expires: 90
          })
          Cookie.set('newGradPersona', quizData.newGrad ? '1' : '0', { expires: 90 })
          Cookie.set('remotePersona', quizData.remote ? '1' : '0', { expires: 90 })
          Cookie.set('somethingElsePersona', quizData.somethingElse ? '1' : '0', {
            expires: 90
          })
        }
      })
  }

  const actions = {
    addPersona,
    removePersona,
    updatePersona,
    updatePersonas,
    fetchPersonaSettings,
    onSignUp,
    onSignIn,
    onSignOut
  }

  const { authToken } = parseCookies(state.reqCookies)
  if (!loaded && authToken) {
    loadAccount()
    loaded = true
  }

  return (
    <PersonaStateContext.Provider value={state}>
      <PersonaActionsContext.Provider value={actions}>{children}</PersonaActionsContext.Provider>
    </PersonaStateContext.Provider>
  )
}

function usePersonaState() {
  const state = React.useContext(PersonaStateContext)

  if (state === undefined) {
    throw new Error('usePersonaState must be used within a PersonaProvider')
  }

  return state
}

function usePersonaActions() {
  const actions = React.useContext(PersonaActionsContext)

  if (actions === undefined) {
    throw new Error('usePersonaActions must be used within a PersonaProvider')
  }

  return actions
}

function usePersona(): [State, Actions] {
  return [usePersonaState(), usePersonaActions()]
}

interface ConsumerProps {
  children: (state: State | undefined, actions: Actions | undefined) => React.ReactNode
}
const PersonaConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <PersonaStateContext.Consumer>
      {state => {
        if (state === undefined) {
          throw new Error('PersonaConsumer must be used within a PersonaProvider')
        }
        return (
          <PersonaActionsContext.Consumer>
            {actions => {
              if (actions === undefined) {
                throw new Error('PersonaConsumer must be used within a PersonaProvider')
              }
              return children(state, actions)
            }}
          </PersonaActionsContext.Consumer>
        )
      }}
    </PersonaStateContext.Consumer>
  )
}

const PersonaProvider = withRouter(withApollo<PersonasProviderProps>(PersonaProviderComponent))

export { PersonaProvider, PersonaConsumer, usePersona, usePersonaState, usePersonaActions }
