import React from 'react'
import { NextPage } from 'next'
import Cookie from 'js-cookie'
import { ME_QUERY } from '~/graphql/ready-to-generate/auth/meQuery.graphql'
import { redirect } from '~/lib/redirect'
import { withApollo } from '~/lib/apollo'
import { MeQuery, JoblistUser } from '~/generated/graphql'
import { NextPageContextWithApollo, Flags } from '~/types/types'
import { parseCookies, onlyRequestCookies } from '~/lib/utils/cookies'
import { getFlags } from '~/lib/utils/appEnv'
import { FlagsProvider } from '~/context/FlagsContext'
import { CookieProvider } from '~/context/CookieContext'
import { ABProvider } from '~/context/ABContext'
import { getUserDevice } from '~/lib/utils/userEnv'
import { UserDevice, UserDeviceProvider } from '~/context/UserDeviceContext'
import { PersonaProvider, Cookies } from '~/context/PersonaContext'
import { getLanguage, LangProvider } from '~/context/LangContext'
import { PaidViewProvider } from '~/context/PaidViewContext'
import { GamifyProvider, GamifyData } from '~/context/GamifyContext'
import { ApolloQueryResult } from 'apollo-client'

interface PageProps {
  currentUser: JoblistUser
  flags: Flags
  cookies: { [key: string]: string | undefined }
  userDevice: UserDevice
  abTestOverwrites?: { [key: string]: string }
  reqCookies: Cookies
}

type withAppProvidersOptions = {
  redirectPath?: (ctx: NextPageContextWithApollo, currentUser?: MeQuery) => string | false
}

const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000)
}

const flagsCache: { flags: Flags | null; lastUpdate: number } = {
  flags: null,
  lastUpdate: getTimestampInSeconds()
}

const withAppProviders = <T extends { [key: string]: any }>(
  Component: NextPage<T>,
  options: withAppProvidersOptions = {}
) => {
  class AuthComponent extends React.Component<T & PageProps> {
    static async getInitialProps(context: NextPageContextWithApollo) {
      const currentTimeInSeconds = getTimestampInSeconds()
      const flagsCacheExpired = currentTimeInSeconds - flagsCache.lastUpdate > 60

      const req = context && context.req
      const lang = getLanguage(context.query, req)

      if (!flagsCache.flags || flagsCacheExpired) {
        flagsCache.flags = await getFlags(context.req)
        flagsCache.lastUpdate = currentTimeInSeconds
      }

      const userDevice = getUserDevice(context.req)
      const cookies = parseCookies(context.req)
      const { authToken } = cookies

      context['flags'] = flagsCache.flags
      context['userDevice'] = userDevice

      let meQueryResult: ApolloQueryResult<MeQuery> | undefined
      if (authToken) {
        meQueryResult = await context.apolloClient.query<MeQuery>({
          query: ME_QUERY,
          fetchPolicy: 'network-only'
        })
      }

      const redirectPath = options.redirectPath
        ? options.redirectPath(context, meQueryResult?.data)
        : null

      if (redirectPath) {
        redirect({ context, target: redirectPath })
        return
      }

      let pageProps = {}
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(context)
      }

      const reqCookies = onlyRequestCookies(context.req)

      return {
        ...pageProps,
        flags: flagsCache.flags,
        cookies,
        lang,
        userDevice,
        reqCookies,
        currentUser: meQueryResult?.data
      }
    }

    componentDidMount() {
      // TODO: we should update signin and signup mutations to return userId
      // so we can handle this cookie setting at that location once
      // rather than each page load
      if (this.props.lang) {
        Cookie.set('siteLang', this.props.lang)
      }

      const { currentUser } = this.props
      if (currentUser && currentUser.id != null) {
        Cookie.set('userId', currentUser.id)
      }
    }

    componentDidUpdate(prevProps: any) {
      if (this.props.lang && prevProps.lang !== this.props.lang) {
        Cookie.set('siteLang', this.props.lang)
      }
      const { currentUser } = this.props
      if (currentUser && currentUser.id != null) {
        Cookie.set('userId', currentUser.id)
      } else {
        Cookie.remove('userId')
      }
    }

    render() {
      const { lang, currentUser, flags, cookies, userDevice, reqCookies, ...props } = this.props

      const gamifyValues: GamifyData = {
        gamifyUiSettings: {
          showMainBanner:
            !cookies.hideGamifyBanner &&
            (!currentUser ||
              (!!currentUser?.showGamify && currentUser?.loggedInRealm !== 'employer'))
        },
        steps: {
          startYourJobSearch: true,
          emailAlert: !!cookies.emailAlertStep || !!currentUser?.progress?.emailAlert,
          signUp: !!currentUser?.progress?.signUp,
          inviteFriends: !!currentUser?.progress?.inviteFriends,
          saveJobs: !!currentUser?.progress?.saveJobs
        }
      }

      return (
        <GamifyProvider value={gamifyValues}>
          <PaidViewProvider value={{ isPaidView: !!cookies.isPaidView }}>
            <LangProvider value={{ lang: lang ? lang : 'en' }}>
              <UserDeviceProvider value={userDevice}>
                <CookieProvider value={cookies || {}}>
                  <FlagsProvider value={flags}>
                    <PersonaProvider
                      state={{
                        reqCookies,
                        settings: {
                          covid: false,
                          newGrad: false,
                          remote: false,
                          somethingElse: false
                        }
                      }}
                    >
                      <ABProvider
                        state={props.abTestOverwrites}
                        flags={flags.abTests}
                        firstVisitId={cookies?.firstVisitId}
                      >
                        <Component {...(props as T)} />
                      </ABProvider>
                    </PersonaProvider>
                  </FlagsProvider>
                </CookieProvider>
              </UserDeviceProvider>
            </LangProvider>
          </PaidViewProvider>
        </GamifyProvider>
      )
    }
  }
  return withApollo(AuthComponent)
}

export default withAppProviders
