/**
 * Escapes characters in the string that are not safe to use in a RegExp.
 * @param {string} str The string to escape.
 * @return {string} A RegExp safe, escaped copy of `s`.
 */
function regExpEscape(str: string): string {
  return str.replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1').replace(/\x08/g, '\\x08')
}

/**
 * Converts a string into TitleCase. First character of the string is always
 * capitalized in addition to the first letter of every subsequent word.
 * Words are delimited by one or more whitespaces by default. Custom delimiters
 * can optionally be specified to replace the default, which doesn't preserve
 * whitespace delimiters and instead must be explicitly included if needed.
 *
 * Default delimiter => " ":
 *    toTitleCase('oneTwoThree')    => 'OneTwoThree'
 *    toTitleCase('one two three')  => 'One Two Three'
 *    toTitleCase('  one   two   ') => '  One   Two   '
 *    toTitleCase('one_two_three')  => 'One_two_three'
 *    toTitleCase('one-two-three')  => 'One-two-three'
 *
 * Custom delimiter => "_-.":
 *    toTitleCase('oneTwoThree', '_-.')       => 'OneTwoThree'
 *    toTitleCase('one two three', '_-.')     => 'One two three'
 *    toTitleCase('  one   two   ', '_-.')    => '  one   two   '
 *    toTitleCase('one_two_three', '_-.')     => 'One_Two_Three'
 *    toTitleCase('one-two-three', '_-.')     => 'One-Two-Three'
 *    toTitleCase('one...two...three', '_-.') => 'One...Two...Three'
 *    toTitleCase('one. two. three', '_-.')   => 'One. two. three'
 *    toTitleCase('one-two.three', '_-.')     => 'One-Two.Three'
 *
 * @param {string} str String value in camelCase form.
 * @param {string=} optDelimiters Custom delimiter character set used to
 *      distinguish words in the string value. Each character represents a
 *      single delimiter. When provided, default whitespace delimiter is
 *      overridden and must be explicitly included if needed.
 * @return {string} String value in TitleCase form.
 */
export function toTitleCase(
  str: string,
  options?: { optDelimiters?: string; excludeSmallWords?: boolean }
): string {
  let delimiters =
    typeof options?.optDelimiters === 'string' ? regExpEscape(options.optDelimiters) : '\\s'
  // For IE8, we need to prevent using an empty character set. Otherwise,
  // incorrect matching will occur.
  delimiters = delimiters ? '|[' + delimiters + ']+' : ''
  const excludeSmallWordsRegex = options?.excludeSmallWords
    ? '(?!(an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\\.?|versus|via|when|with|without|yet)\\b)'
    : '()'
  const regexp = new RegExp(`(^${delimiters})${excludeSmallWordsRegex}([a-z])`, 'g')

  const words = str.replace(regexp, (all, p1, p2, p3) => {
    return p1 + p3.toUpperCase()
  })

  return words
}

/**
 * Character mappings used internally for escapeChar.
 */
const jsEscapeCache: Record<string, string> = {
  "'": "\\'"
}

/**
 * Special chars that need to be escaped for quoteText.
 */
const specialEscapeChars: Record<string, string> = {
  '\0': '\\0',
  '\b': '\\b',
  '\f': '\\f',
  '\n': '\\n',
  '\r': '\\r',
  '\t': '\\t',
  '\x0B': '\\x0B', // '\v' is not supported in JScript
  '"': '\\"',
  '\\': '\\\\',
  // To support the use case of embedding quoted strings inside of script
  // tags, we have to make sure HTML comments and opening/closing script tags do
  // not appear in the resulting string. The specific strings that must be
  // escaped are documented at:
  // https://html.spec.whatwg.org/multipage/scripting.html#restrictions-for-contents-of-script-elements
  '<': '\\u003C' // NOTE: JSON.parse crashes on '\\x3c'.
}

/**
 * Takes a character and returns the escaped string for that character. For
 * example escapeChar(String.fromCharCode(15)) -> "\\x0E".
 * @param {string} c The character to escape.
 * @return {string} An escaped string representing `c`.
 */
export function escapeChar(c: string): string {
  if (c in jsEscapeCache) {
    return jsEscapeCache[c]
  }

  if (c in specialEscapeChars) {
    return (jsEscapeCache[c] = specialEscapeChars[c])
  }

  let rv = c
  const cc = c.charCodeAt(0)
  if (cc > 31 && cc < 127) {
    rv = c
  } else {
    // tab is 9 but handled above
    if (cc < 256) {
      rv = '\\x'
      if (cc < 16 || cc > 256) {
        rv += '0'
      }
    } else {
      rv = '\\u'
      if (cc < 4096) {
        // \u1000
        rv += '0'
      }
    }
    rv += cc.toString(16).toUpperCase()
  }
  jsEscapeCache[c] = rv
  return rv
}

/**
 * Encloses a string in double quotes and escapes characters so that the
 * string is a valid JS string. The resulting string is safe to embed in
 * `<script>` tags as "<" is escaped.
 * @param {string} s The string to quote.
 * @return {string} A copy of `s` surrounded by double quotes.
 */
export function quoteText(s: string): string {
  const sb = ['"']
  for (let i = 0; i < s.length; i++) {
    const ch = s.charAt(i)
    const cc = ch.charCodeAt(0)
    sb[i + 1] = specialEscapeChars[ch] || (cc > 31 && cc < 127 ? ch : escapeChar(ch))
  }
  sb.push('"')
  return sb.join('')
}

/**
 * Quotes a given keyword if it contains a space.
 */
export function quoteIfSpaces(keyword: string) {
  return keyword.indexOf(' ') !== -1 ? quoteText(keyword) : keyword
}

export const capitalizeTitle = (title: string = ''): string => {
  const excludedWords = ['the', 'and', 'or', 'but', 'for', 'of', 'in', 'on', 'at', 'by']
  let words = title.split(' ')

  for (let i = 0, x = words.length; i < x; i++) {
    const word: string = words[i].trim()
    if (word && excludedWords.indexOf(word) === -1) {
      words[i] = word[0].toUpperCase() + word.substr(1)
    }
  }

  words = words.join(' ').split('-')

  for (let i = 0, x = words.length; i < x; i++) {
    const word: string = words[i].trim()
    if (word && excludedWords.indexOf(word) === -1) {
      words[i] = word[0].toUpperCase() + word.substr(1)
    }
  }

  return words.join('-')
}

export const upperCasebyLength = (title: string = '', maxLength: number): string => {
  const excludedWords = [
    'a',
    'of',
    'to',
    'in',
    'is',
    'be',
    'as',
    'at',
    'so',
    'we',
    'he',
    'by',
    'or',
    'on',
    'do',
    'if',
    'me',
    'my',
    'up',
    'an',
    'go',
    'no',
    'am',
    'he',
    'and',
    'for',
    'are',
    'but',
    'not',
    'you',
    'all',
    'any',
    'can',
    'had',
    'her',
    'was',
    'one',
    'our',
    'out',
    'day',
    'get',
    'has',
    'him',
    'his',
    'how',
    'man',
    'new',
    'now',
    'old',
    'see',
    'two',
    'way',
    'who',
    'boy',
    'did',
    'its',
    'let',
    'put',
    'say',
    'she',
    'too',
    'use',
    'will',
    'the',
    'van'
  ]
  const words = title.split(' ')

  for (let i = 0, x = words.length; i < x; i++) {
    const word: string = words[i].trim()
    const isExcludedWord = excludedWords.indexOf(word.toLowerCase()) !== -1
    words[i] = word && word.length <= maxLength && !isExcludedWord ? word.toUpperCase() : word
  }

  return words.join(' ')
}

export const numberWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

export const arrayWithCommas = (arr: string[]): string => {
  if (arr.length === 1) {
    return arr[0]
  }
  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]
  return firsts.join(', ') + ' and ' + last
}
