import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  Timestamp: any;
  Upload: any;
};

export type AddJobAlertOutput = JobAlert | JobAlertError;

export type AddJobAlertResult = {
  __typename?: 'AddJobAlertResult';
  id: Scalars['String'];
};

export enum AlreadyLoggedInError {
  AlreadyLoggedIn = 'ALREADY_LOGGED_IN'
}

export type Area1JobCount = {
  __typename?: 'Area1JobCount';
  area1?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export type ArticleBodyHeading = {
  __typename?: 'ArticleBodyHeading';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  heading?: Maybe<Scalars['String']>;
};

export type ArticleBodyHeadingInput = {
  heading?: Maybe<Scalars['String']>;
};

export type ArticleBodyImage = {
  __typename?: 'ArticleBodyImage';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Array<Maybe<VolumeInterface>>>;
  caption?: Maybe<RedactorFieldData>;
  alttext?: Maybe<Scalars['String']>;
  position?: Maybe<PositionEnum>;
  position_FieldData?: Maybe<OptionFieldData>;
};


export type ArticleBodyImageImageArgs = {
  filename?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export type ArticleBodyImageInput = {
  image?: Maybe<Array<Maybe<ImageAssetInput>>>;
  caption?: Maybe<Scalars['String']>;
  alttext?: Maybe<Scalars['String']>;
  position?: Maybe<PositionEnum>;
};

export type ArticleBodyInput = {
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  newsection?: Maybe<ArticleBodyNewsectionInput>;
  heading?: Maybe<ArticleBodyHeadingInput>;
  text?: Maybe<ArticleBodyTextInput>;
  pullquote?: Maybe<ArticleBodyPullquoteInput>;
  image?: Maybe<ArticleBodyImageInput>;
  quote?: Maybe<ArticleBodyQuoteInput>;
  searchcta?: Maybe<ArticleBodySearchctaInput>;
};

export type ArticleBodyNewsection = {
  __typename?: 'ArticleBodyNewsection';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  sectionheading?: Maybe<Scalars['String']>;
};

export type ArticleBodyNewsectionInput = {
  sectionheading?: Maybe<Scalars['String']>;
};

export type ArticleBodyPullquote = {
  __typename?: 'ArticleBodyPullquote';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  pullquote?: Maybe<Scalars['String']>;
  position?: Maybe<PositionEnum>;
  position_FieldData?: Maybe<OptionFieldData>;
};

export type ArticleBodyPullquoteInput = {
  pullquote?: Maybe<Scalars['String']>;
  position?: Maybe<PositionEnum>;
};

export type ArticleBodyQuote = {
  __typename?: 'ArticleBodyQuote';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  quote?: Maybe<Scalars['String']>;
  attribution?: Maybe<Scalars['String']>;
  position?: Maybe<PositionEnum>;
  position_FieldData?: Maybe<OptionFieldData>;
};

export type ArticleBodyQuoteInput = {
  quote?: Maybe<Scalars['String']>;
  attribution?: Maybe<Scalars['String']>;
  position?: Maybe<PositionEnum>;
};

export type ArticleBodySearchcta = {
  __typename?: 'ArticleBodySearchcta';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  headline?: Maybe<Scalars['String']>;
  searchctaurl?: Maybe<Scalars['String']>;
  searchctatext?: Maybe<Scalars['String']>;
};

export type ArticleBodySearchctaInput = {
  headline?: Maybe<Scalars['String']>;
  searchctaurl?: Maybe<Scalars['String']>;
  searchctatext?: Maybe<Scalars['String']>;
};

export type ArticleBodyText = {
  __typename?: 'ArticleBodyText';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  text?: Maybe<RedactorFieldData>;
  position?: Maybe<PositionEnum>;
  position_FieldData?: Maybe<OptionFieldData>;
};

export type ArticleBodyTextInput = {
  text?: Maybe<Scalars['String']>;
  position?: Maybe<PositionEnum>;
};

/** A union of possible blocks types */
export type ArticleBodyUnion = ArticleBodyNewsection | ArticleBodyHeading | ArticleBodyText | ArticleBodyPullquote | ArticleBodyImage | ArticleBodyQuote | ArticleBodySearchcta;

export type AssetFocalPoint = {
  __typename?: 'AssetFocalPoint';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export enum AuthenticationRequiredError {
  AuthenticationRequired = 'AUTHENTICATION_REQUIRED'
}

export type AuthorAvatarAssetInput = {
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Blog = EntryInterface & ElementInterface & {
  __typename?: 'Blog';
  id: Scalars['Int'];
  author: User;
  title: Scalars['String'];
  seoTitleTag?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  dateCreated: Scalars['Timestamp'];
  dateUpdated: Scalars['Timestamp'];
  expiryDate?: Maybe<Scalars['Timestamp']>;
  postDate?: Maybe<Scalars['Timestamp']>;
  enabled: Scalars['Boolean'];
  status: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  section?: Maybe<Section>;
  type?: Maybe<EntryType>;
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  hasDescendants: Scalars['Boolean'];
  level?: Maybe<Scalars['Int']>;
  parent?: Maybe<EntryInterface>;
  siblings?: Maybe<Array<Maybe<EntryInterface>>>;
  elementType?: Maybe<Scalars['String']>;
  heroImage?: Maybe<Array<Maybe<VolumeInterface>>>;
  featured?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  authorAvatar?: Maybe<Array<Maybe<VolumeInterface>>>;
  subheading?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<BlogCategory>>>;
  categoriesConnection?: Maybe<CategoryConnection>;
  tags?: Maybe<Scalars['String']>;
  articleBody?: Maybe<Array<Maybe<ArticleBodyUnion>>>;
  description?: Maybe<Scalars['String']>;
};


export type BlogChildrenArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type BlogHeroImageArgs = {
  filename?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type BlogAuthorAvatarArgs = {
  filename?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type BlogArticleBodyArgs = {
  type?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type BlogCategory = CategoryInterface & {
  __typename?: 'BlogCategory';
  id: Scalars['Int'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  group?: Maybe<CategoryGroup>;
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  childrenConnection?: Maybe<CategoryConnection>;
  parent?: Maybe<CategoryInterface>;
  next?: Maybe<CategoryInterface>;
  nextSibling?: Maybe<CategoryInterface>;
  prev?: Maybe<CategoryInterface>;
  prevSibling?: Maybe<CategoryInterface>;
  heroImage?: Maybe<Array<Maybe<VolumeInterface>>>;
  featured?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  authorAvatar?: Maybe<Array<Maybe<VolumeInterface>>>;
  subheading?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<BlogCategory>>>;
  categoriesConnection?: Maybe<CategoryConnection>;
  tags?: Maybe<Scalars['String']>;
  articleBody?: Maybe<Array<Maybe<ArticleBodyUnion>>>;
  description?: Maybe<Scalars['String']>;
};


export type BlogCategoryChildrenArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type BlogCategoryChildrenConnectionArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type BlogCategoryHeroImageArgs = {
  filename?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type BlogCategoryAuthorAvatarArgs = {
  filename?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type BlogCategoryArticleBodyArgs = {
  type?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type BrowseCity = {
  __typename?: 'BrowseCity';
  city: Scalars['String'];
  state: Scalars['String'];
  stateCode?: Maybe<Scalars['String']>;
};

export type BrowseCityContent = {
  __typename?: 'BrowseCityContent';
  location: Scalars['String'];
  description: Scalars['String'];
  facts?: Maybe<Scalars['String']>;
  medianHouseholdIncome?: Maybe<Scalars['String']>;
  medianMonthlyRent?: Maybe<Scalars['String']>;
};

export type BrowseCityInput = {
  city: Scalars['String'];
  state: Scalars['String'];
};

export type BrowseListing = {
  __typename?: 'BrowseListing';
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type BrowseResult = {
  __typename?: 'BrowseResult';
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Array<BrowseState>>;
  city?: Maybe<Array<BrowseCity>>;
  industry?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<Array<BrowseRole>>;
  roleContent?: Maybe<BrowseRoleContent>;
  cityContent?: Maybe<BrowseCityContent>;
  relatedRole?: Maybe<Array<BrowseRole>>;
};


export type BrowseResultStateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type BrowseResultCityArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type BrowseResultIndustryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type BrowseResultRoleArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type BrowseResultRelatedRoleArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};

export type BrowseRole = {
  __typename?: 'BrowseRole';
  role: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
};

export type BrowseRoleContent = {
  __typename?: 'BrowseRoleContent';
  keyword: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  payinfo?: Maybe<Scalars['String']>;
  requirements?: Maybe<Scalars['String']>;
  skills?: Maybe<Scalars['String']>;
  icons?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
};

export type BrowseRoleInput = {
  role: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
};

export type BrowseState = {
  __typename?: 'BrowseState';
  state: Scalars['String'];
  stateCode?: Maybe<Scalars['String']>;
};

export type BrowseStats = {
  __typename?: 'BrowseStats';
  companies?: Maybe<Array<Scalars['String']>>;
  jobCount: Scalars['Int'];
  companyCount: Scalars['Int'];
  partTimeJobCount: Scalars['Int'];
};

export type CategoriesCategoryInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  totalCount: Scalars['Int'];
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
};

export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CategoryInterface>;
  children?: Maybe<CategoryConnection>;
  relatedEntries?: Maybe<EntryConnection>;
};


export type CategoryEdgeChildrenArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type CategoryEdgeRelatedEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export type CategoryGroup = {
  __typename?: 'CategoryGroup';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
};

export enum CategoryGroupsEnum {
  Blog = 'blog'
}

export type CategoryInterface = {
  id: Scalars['Int'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  group?: Maybe<CategoryGroup>;
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  childrenConnection?: Maybe<CategoryConnection>;
  parent?: Maybe<CategoryInterface>;
  next?: Maybe<CategoryInterface>;
  nextSibling?: Maybe<CategoryInterface>;
  prev?: Maybe<CategoryInterface>;
  prevSibling?: Maybe<CategoryInterface>;
};


export type CategoryInterfaceChildrenArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type CategoryInterfaceChildrenConnectionArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export type CategoryJobCount = {
  __typename?: 'CategoryJobCount';
  category: Scalars['String'];
  count: Scalars['Int'];
};

export enum ChargeEntity {
  Customer = 'customer',
  System = 'system'
}

export enum CheckboxError {
  MustBeBoolean = 'MUST_BE_BOOLEAN'
}

export enum CheckboxRequiredError {
  MustBeBoolean = 'MUST_BE_BOOLEAN',
  MustBeChecked = 'MUST_BE_CHECKED'
}

export type CityData = {
  __typename?: 'CityData';
  description?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
  coli?: Maybe<Scalars['Float']>;
  minimum_wage?: Maybe<Scalars['Float']>;
  tipped_wage?: Maybe<Scalars['Float']>;
  state_uses_federal_wages?: Maybe<Scalars['Boolean']>;
  state_has_district_wages?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type CollaboratorInviteError = {
  __typename?: 'CollaboratorInviteError';
  inputErrors?: Maybe<CollaboratorInviteInputErrors>;
  otherError?: Maybe<CollaboratorInviteOtherError>;
};

export type CollaboratorInviteInput = {
  jobCollectionId: Scalars['String'];
  emailAddress: Scalars['String'];
};

export type CollaboratorInviteInputErrors = {
  __typename?: 'CollaboratorInviteInputErrors';
  jobCollectionId?: Maybe<RequiredInputError>;
  emailAddress?: Maybe<EmailInputError>;
};

export enum CollaboratorInviteOtherError {
  RequestFailed = 'REQUEST_FAILED',
  ExistingCollaborator = 'EXISTING_COLLABORATOR',
  InvitationPending = 'INVITATION_PENDING',
  CollaboratorLimitReached = 'COLLABORATOR_LIMIT_REACHED'
}

export type CollaboratorInviteResult = UserAuthenticationRequiredError | UserUnauthorizedError | CollaboratorInviteSuccess | CollaboratorInviteError;

/** COLLABORATOR INVITE */
export type CollaboratorInviteSuccess = {
  __typename?: 'CollaboratorInviteSuccess';
  success: Scalars['Boolean'];
  invitedUser: InvitedUser;
};

export type CollaboratorRemoveError = {
  __typename?: 'CollaboratorRemoveError';
  inputErrors?: Maybe<CollaboratorRemoveInputErrors>;
  otherError?: Maybe<CollaboratorRemoveOtherError>;
};

export type CollaboratorRemoveInput = {
  id: Scalars['ID'];
};

export type CollaboratorRemoveInputErrors = {
  __typename?: 'CollaboratorRemoveInputErrors';
  id?: Maybe<RequiredInputError>;
};

export enum CollaboratorRemoveOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type CollaboratorRemoveResult = UserAuthenticationRequiredError | CollaboratorRemoveSuccess | CollaboratorRemoveError;

/** COLLABORATOR REMOVE */
export type CollaboratorRemoveSuccess = {
  __typename?: 'CollaboratorRemoveSuccess';
  success: Scalars['Boolean'];
  jobCollection: JobCollection;
};

export type CompanyJobCount = {
  __typename?: 'CompanyJobCount';
  company: Scalars['String'];
  count: Scalars['Int'];
};

export type CompanyLogo = {
  __typename?: 'CompanyLogo';
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  logoData?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
};

export enum CompanyLogoProvider {
  Clearbit = 'CLEARBIT',
  Text = 'TEXT',
  Custom = 'CUSTOM'
}

export enum CompensationType {
  Salary = 'SALARY',
  Hourly = 'HOURLY',
  Contract = 'CONTRACT',
  Other = 'OTHER'
}

export type ConnectionParams = {
  connectionDownlink?: Maybe<Scalars['Float']>;
  connectionEffectiveType?: Maybe<Scalars['String']>;
  connectionRtt?: Maybe<Scalars['Float']>;
};

export type CountryBoost = {
  us?: Maybe<Scalars['Float']>;
  ca?: Maybe<Scalars['Float']>;
  uk?: Maybe<Scalars['Float']>;
  au?: Maybe<Scalars['Float']>;
};

export enum CropFormatInputEnum {
  Jpg = 'jpg',
  Gif = 'gif',
  Png = 'png',
  Auto = 'Auto'
}

export type CropInputObject = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  quality?: Maybe<Scalars['Int']>;
  position?: Maybe<PositionInputEnum>;
  format?: Maybe<CropFormatInputEnum>;
};

export enum DateFormatTypes {
  /** Atom feeds */
  Atom = 'atom',
  /** HTTP cookies */
  Cookie = 'cookie',
  /** ISO-8601 spec */
  Iso8601 = 'iso8601',
  /** RFC-822 spec */
  Rfc822 = 'rfc822',
  /** RFC-850 spec */
  Rfc850 = 'rfc850',
  /** RFC-1036 spec */
  Rfc1036 = 'rfc1036',
  /** RFC-1123 spec */
  Rfc1123 = 'rfc1123',
  /** RFC-2822 spec */
  Rfc2822 = 'rfc2822',
  /** RFC-3339 spec */
  Rfc3339 = 'rfc3339',
  /** RSS feed */
  Rss = 'rss',
  /** W3C spec */
  W3c = 'w3c'
}


export type DecayConfig = {
  func: DecayFunction;
  weight: Scalars['Int'];
  origin: Scalars['String'];
  scale: Scalars['String'];
  offset: Scalars['String'];
  decay: Scalars['Float'];
};

export enum DecayFunction {
  Gauss = 'GAUSS',
  Exp = 'EXP',
  Linear = 'LINEAR'
}

export type DeleteAllJobAlertsOutput = DeleteAllJobAlertsResult | JobAlertError;

export type DeleteAllJobAlertsResult = {
  __typename?: 'DeleteAllJobAlertsResult';
  alerts: Array<JobAlert>;
};

export type DeleteJobAlertOutput = DeleteJobAlertResult | JobAlertError;

export type DeleteJobAlertResult = {
  __typename?: 'DeleteJobAlertResult';
  status: Scalars['Boolean'];
};

export type DescriptionImpressionJobObject = {
  jobId?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  jobProvider?: Maybe<Scalars['String']>;
  jobCPC?: Maybe<Scalars['Float']>;
  jobCPCUSD?: Maybe<Scalars['Float']>;
  jobPostalCode?: Maybe<Scalars['String']>;
  jobArea1?: Maybe<Scalars['String']>;
  jobUrl?: Maybe<Scalars['String']>;
  jobCountry?: Maybe<Scalars['String']>;
  jobLocality?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['String']>;
  companyLogoProvider?: Maybe<CompanyLogoProvider>;
  jobLocation?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  featured?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ElementInterface = {
  elementType?: Maybe<Scalars['String']>;
};

export enum EmailAddressConfirmationInputError {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  DoesNotMatchEmailAddress = 'DOES_NOT_MATCH_EMAIL_ADDRESS'
}

export enum EmailInputError {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  TooLong = 'TOO_LONG'
}

export enum EmailInputErrorWithExists {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  TooLong = 'TOO_LONG',
  AlreadyExists = 'ALREADY_EXISTS'
}

export enum EmailInputErrorWithExistsAndSelfCompare {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  TooLong = 'TOO_LONG',
  AlreadyExists = 'ALREADY_EXISTS',
  NotDifferentFromCurrent = 'NOT_DIFFERENT_FROM_CURRENT'
}

export type EntryConnection = {
  __typename?: 'EntryConnection';
  totalCount: Scalars['Int'];
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<EntryEdge>>>;
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
};

export type EntryDraftConnection = {
  __typename?: 'EntryDraftConnection';
  totalCount: Scalars['Int'];
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<EntryDraftEdge>>>;
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
};

export type EntryDraftEdge = {
  __typename?: 'EntryDraftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<EntryInterface>;
  draftInfo?: Maybe<EntryDraftInfo>;
};

export type EntryDraftInfo = {
  __typename?: 'EntryDraftInfo';
  draftId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type EntryEdge = {
  __typename?: 'EntryEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<EntryInterface>;
  drafts?: Maybe<EntryDraftConnection>;
  relatedEntries?: Maybe<EntryConnection>;
  relatedCategories?: Maybe<CategoryConnection>;
};


export type EntryEdgeRelatedEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type EntryEdgeRelatedCategoriesArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export type EntryInterface = {
  id: Scalars['Int'];
  author: User;
  title: Scalars['String'];
  seoTitleTag?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  dateCreated: Scalars['Timestamp'];
  dateUpdated: Scalars['Timestamp'];
  expiryDate?: Maybe<Scalars['Timestamp']>;
  postDate?: Maybe<Scalars['Timestamp']>;
  enabled: Scalars['Boolean'];
  status: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  section?: Maybe<Section>;
  type?: Maybe<EntryType>;
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  hasDescendants: Scalars['Boolean'];
  level?: Maybe<Scalars['Int']>;
  parent?: Maybe<EntryInterface>;
  siblings?: Maybe<Array<Maybe<EntryInterface>>>;
};


export type EntryInterfaceChildrenArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export enum EntryLevel {
  EntryLevel = 'ENTRY_LEVEL',
  Other = 'OTHER'
}

export type EntryType = {
  __typename?: 'EntryType';
  id: Scalars['Int'];
  name: Scalars['String'];
  handle: Scalars['String'];
  graphQlTypeName: Scalars['String'];
  fields: Array<Maybe<Field>>;
};

export enum EntryTypesEnum {
  Blog = 'Blog'
}

export enum Environment {
  Retail = 'RETAIL',
  FoodServices = 'FOOD_SERVICES',
  Warehouse = 'WAREHOUSE',
  Delivery = 'DELIVERY',
  Driver = 'DRIVER',
  Hospitality = 'HOSPITALITY',
  Other = 'OTHER'
}

export type ExclusionClause = {
  fpt?: Maybe<Array<Maybe<Scalars['String']>>>;
  company?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum FeaturedEnum {
  Yes = 'yes',
  No = 'no'
}

export type Field = {
  __typename?: 'Field';
  name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
};

export type FieldBoost = {
  title?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['Float']>;
  categories?: Maybe<Scalars['Float']>;
};

export enum FirstNameInputError {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  TooShort = 'TOO_SHORT',
  TooLong = 'TOO_LONG'
}

export type FreeTrial = {
  __typename?: 'FreeTrial';
  isFreeTrialJob: Scalars['Boolean'];
  isFreeTrialActive: Scalars['Boolean'];
  freeTrialStart: Scalars['String'];
  freeTrialEnd: Scalars['String'];
  validityDays: Scalars['Int'];
  ruleMetric?: Maybe<Scalars['String']>;
  ruleLimit?: Maybe<Scalars['Int']>;
};

export type GetJobAlertsOutput = GetJobAlertsResult | JobAlertError;

export type GetJobAlertsResult = {
  __typename?: 'GetJobAlertsResult';
  alerts: Array<JobAlert>;
};

export enum HealthBenefits {
  HealthBenefits = 'HEALTH_BENEFITS',
  Other = 'OTHER'
}

export type HeroImageAssetInput = {
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ImageAssetInput = {
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ImpressionJobObject = {
  jobId?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  jobProvider?: Maybe<Scalars['String']>;
  jobCPC?: Maybe<Scalars['Float']>;
  jobCPCUSD?: Maybe<Scalars['Float']>;
  jobPostalCode?: Maybe<Scalars['String']>;
  jobArea1?: Maybe<Scalars['String']>;
  jobCountry?: Maybe<Scalars['String']>;
  jobLocality?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['String']>;
  companyLogoProvider?: Maybe<CompanyLogoProvider>;
  jobLocation?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Boolean']>;
  featured?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InviteAcceptError = {
  __typename?: 'InviteAcceptError';
  inputErrors?: Maybe<InviteAcceptInputErrors>;
  otherError?: Maybe<InviteAcceptOtherError>;
};

export type InviteAcceptInput = {
  id: Scalars['ID'];
};

export type InviteAcceptInputErrors = {
  __typename?: 'InviteAcceptInputErrors';
  id?: Maybe<RequiredInputError>;
};

export enum InviteAcceptOtherError {
  RequestFailed = 'REQUEST_FAILED',
  AlreadyAccepted = 'ALREADY_ACCEPTED'
}

export type InviteAcceptResult = UserAuthenticationRequiredError | InviteAcceptSuccess | InviteAcceptError;

/** INVITE ACCEPT */
export type InviteAcceptSuccess = {
  __typename?: 'InviteAcceptSuccess';
  success: Scalars['Boolean'];
  invitedUser: InvitedUser;
  jobCollectionCollaborator: JobCollectionCollaborator;
};

export type InvitedBy = {
  __typename?: 'InvitedBy';
  name?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  me: Scalars['Boolean'];
};

export type InvitedUser = {
  __typename?: 'InvitedUser';
  id: Scalars['ID'];
  emailAddress?: Maybe<Scalars['String']>;
  invitedByUser?: Maybe<JoblistUser>;
  jobCollection: JobCollection;
};

/** INPUT TYPES */
export type InviteFetchInput = {
  token: Scalars['String'];
};

export type InviteFetchResult = InviteFetchSuccess;

/** INVITE FETCH */
export type InviteFetchSuccess = {
  __typename?: 'InviteFetchSuccess';
  invite?: Maybe<InvitedUser>;
};

export type InviteIgnoreError = {
  __typename?: 'InviteIgnoreError';
  inputErrors?: Maybe<InviteIgnoreInputErrors>;
  otherError?: Maybe<InviteIgnoreOtherError>;
};

export type InviteIgnoreInput = {
  id: Scalars['ID'];
};

export type InviteIgnoreInputErrors = {
  __typename?: 'InviteIgnoreInputErrors';
  id?: Maybe<RequiredInputError>;
};

export enum InviteIgnoreOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type InviteIgnoreResult = UserAuthenticationRequiredError | InviteIgnoreSuccess | InviteIgnoreError;

/** INVITE IGNORE */
export type InviteIgnoreSuccess = {
  __typename?: 'InviteIgnoreSuccess';
  success: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type InviteRemoveError = {
  __typename?: 'InviteRemoveError';
  inputErrors?: Maybe<InviteRemoveInputErrors>;
  otherError?: Maybe<InviteRemoveOtherError>;
};

export type InviteRemoveInput = {
  id: Scalars['ID'];
};

export type InviteRemoveInputErrors = {
  __typename?: 'InviteRemoveInputErrors';
  id?: Maybe<RequiredInputError>;
};

export enum InviteRemoveOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type InviteRemoveResult = UserAuthenticationRequiredError | InviteRemoveSuccess | InviteRemoveError;

/** INVITE REMOVE - DONE BY THE INVITER */
export type InviteRemoveSuccess = {
  __typename?: 'InviteRemoveSuccess';
  success: Scalars['Boolean'];
  jobCollection: JobCollection;
};

export type Job = {
  __typename?: 'Job';
  internalId?: Maybe<Scalars['ID']>;
  id: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  fingerprintJd?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  area1?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  company: Scalars['String'];
  companyLogoSlug?: Maybe<Scalars['String']>;
  applyByEmail?: Maybe<Scalars['Boolean']>;
  companyLogo?: Maybe<CompanyLogo>;
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  internalUrl?: Maybe<Scalars['String']>;
  internalApplyUrl?: Maybe<Scalars['String']>;
  mobile_friendly?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['String']>>;
  provider: Scalars['String'];
  provider_id: Scalars['String'];
  cpc?: Maybe<Scalars['Float']>;
  cpc_usd?: Maybe<Scalars['Float']>;
  earlyApplicant?: Maybe<Scalars['Boolean']>;
  easyApply?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagSources>;
  source?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  explanation?: Maybe<Scalars['String']>;
  posted_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  expires_at?: Maybe<Scalars['DateTime']>;
  documentId?: Maybe<Scalars['String']>;
  isInLocalInventory?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  syndFingerprint1?: Maybe<Scalars['String']>;
  syndFingerprint2?: Maybe<Scalars['String']>;
};

export type JobAlert = {
  __typename?: 'JobAlert';
  id: Scalars['String'];
  status: JobAlertStatus;
  searchQuery: JobAlertSearchQuery;
};

export enum JobAlertCountry {
  Us = 'US',
  Uk = 'UK',
  Ca = 'CA',
  Au = 'AU'
}

export type JobAlertError = {
  __typename?: 'JobAlertError';
  error: JobAlertErrorCode;
};

export enum JobAlertErrorCode {
  AuthRequired = 'AUTH_REQUIRED',
  InvalidId = 'INVALID_ID',
  InvalidEmail = 'INVALID_EMAIL',
  SubscriberMissing = 'SUBSCRIBER_MISSING',
  EmailMismatch = 'EMAIL_MISMATCH',
  AlertAlreadyExists = 'ALERT_ALREADY_EXISTS',
  MaxAlertsReached = 'MAX_ALERTS_REACHED',
  TokenNotFound = 'TOKEN_NOT_FOUND',
  Unknown = 'UNKNOWN',
  SubscriptionNotFound = 'SUBSCRIPTION_NOT_FOUND',
  StaleToken = 'STALE_TOKEN'
}

export type JobAlertSearchQuery = {
  __typename?: 'JobAlertSearchQuery';
  keyword?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  country: JobAlertCountry;
};

export type JobAlertSearchQueryInput = {
  keyword?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  country: Scalars['String'];
};

export enum JobAlertStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  PausedByUser = 'PAUSED_BY_USER',
  PausedBySystem = 'PAUSED_BY_SYSTEM',
  Stopped = 'STOPPED'
}

export type JobCollection = {
  __typename?: 'JobCollection';
  id: Scalars['String'];
  name: Scalars['String'];
  createdByUser?: Maybe<JoblistUser>;
  jobsCount: Scalars['Int'];
  locationsCount: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  permissions?: Maybe<JobCollectionPerms>;
  jobs: Array<JobCollectionItem>;
  collaborators?: Maybe<Array<JobCollectionCollaborator>>;
  invitedUsers?: Maybe<Array<InvitedUser>>;
  myInvite?: Maybe<InvitedUser>;
  impressionId?: Maybe<Scalars['String']>;
  isOwner: Scalars['Boolean'];
  inviter?: Maybe<JoblistUser>;
};


export type JobCollectionJobsArgs = {
  optimized?: Maybe<Scalars['Boolean']>;
};

export type JobCollectionChangePermsError = {
  __typename?: 'JobCollectionChangePermsError';
  inputErrors?: Maybe<JobCollectionChangePermsInputErrors>;
  otherError?: Maybe<JobCollectionChangePermsOtherError>;
};

export type JobCollectionChangePermsInput = {
  id: Scalars['ID'];
  editTitle: Scalars['Boolean'];
  save: Scalars['Boolean'];
  unsave: Scalars['Boolean'];
  share: Scalars['Boolean'];
  invite: Scalars['Boolean'];
};

/** JOB COLLECTION CHANGE PERMS */
export type JobCollectionChangePermsInputErrors = {
  __typename?: 'JobCollectionChangePermsInputErrors';
  id?: Maybe<RequiredInputError>;
  editTitle?: Maybe<RequiredInputError>;
  save?: Maybe<RequiredInputError>;
  unsave?: Maybe<RequiredInputError>;
  share?: Maybe<RequiredInputError>;
  invite?: Maybe<RequiredInputError>;
};

export enum JobCollectionChangePermsOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type JobCollectionChangePermsResult = UserAuthenticationRequiredError | JobCollectionChangePermsSuccess | JobCollectionChangePermsError;

export type JobCollectionChangePermsSuccess = {
  __typename?: 'JobCollectionChangePermsSuccess';
  success: Scalars['Boolean'];
  jobCollection: JobCollection;
};

export type JobCollectionChangeTitleError = {
  __typename?: 'JobCollectionChangeTitleError';
  inputErrors?: Maybe<JobCollectionChangeTitleInputErrors>;
  otherError?: Maybe<JobCollectionChangeTitleOtherError>;
};

export type JobCollectionChangeTitleInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** JOB COLLECTION CHANGE TITLE */
export type JobCollectionChangeTitleInputErrors = {
  __typename?: 'JobCollectionChangeTitleInputErrors';
  id?: Maybe<RequiredInputError>;
  name?: Maybe<RequiredInputError>;
};

export enum JobCollectionChangeTitleOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type JobCollectionChangeTitleResult = UserAuthenticationRequiredError | JobCollectionChangeTitleSuccess | JobCollectionChangeTitleError;

export type JobCollectionChangeTitleSuccess = {
  __typename?: 'JobCollectionChangeTitleSuccess';
  success: Scalars['Boolean'];
  jobCollection: JobCollection;
};

export type JobCollectionCollaborator = {
  __typename?: 'JobCollectionCollaborator';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  me: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  invitedBy?: Maybe<InvitedBy>;
  jobCount: Scalars['Int'];
};

export type JobCollectionCreateError = {
  __typename?: 'JobCollectionCreateError';
  inputErrors?: Maybe<JobCollectionCreateInputErrors>;
  otherError?: Maybe<JobCollectionCreateOtherError>;
};

export type JobCollectionCreateInput = {
  name: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  routing?: Maybe<Scalars['String']>;
};

/** JOB COLLECTION CREATE */
export type JobCollectionCreateInputErrors = {
  __typename?: 'JobCollectionCreateInputErrors';
  name?: Maybe<NameError>;
};

export enum JobCollectionCreateOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type JobCollectionCreateResult = UserAuthenticationRequiredError | JobCollectionCreateSuccess | JobCollectionCreateError;

export type JobCollectionCreateSuccess = {
  __typename?: 'JobCollectionCreateSuccess';
  success: Scalars['Boolean'];
  jobCollection: JobCollection;
};

export type JobCollectionDeleteError = {
  __typename?: 'JobCollectionDeleteError';
  inputErrors?: Maybe<JobCollectionDeleteInputErrors>;
  otherError?: Maybe<JobCollectionDeleteOtherError>;
};

export type JobCollectionDeleteInput = {
  id: Scalars['ID'];
};

/** DELETE JOB COLLECTION TYPE DEFINITIONS */
export type JobCollectionDeleteInputErrors = {
  __typename?: 'JobCollectionDeleteInputErrors';
  id?: Maybe<RequiredInputError>;
};

export enum JobCollectionDeleteOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type JobCollectionDeleteResult = UserAuthenticationRequiredError | JobCollectionDeleteSuccess | JobCollectionDeleteError;

export type JobCollectionDeleteSuccess = {
  __typename?: 'JobCollectionDeleteSuccess';
  success: Scalars['Boolean'];
  id: Scalars['String'];
};

export type JobCollectionError = {
  __typename?: 'JobCollectionError';
  inputErrors?: Maybe<JobCollectionInputErrors>;
  otherError?: Maybe<JobCollectionOtherError>;
};

export type JobCollectionGetNewUrlInput = {
  shareId: Scalars['String'];
};

export type JobCollectionGetNewUrlResult = JobCollectionGetNewUrlSuccess;

/** JOB COLLECTION GET NEW URL */
export type JobCollectionGetNewUrlSuccess = {
  __typename?: 'JobCollectionGetNewURLSuccess';
  id?: Maybe<Scalars['String']>;
};

export type JobCollectionInput = {
  id: Scalars['ID'];
};

export type JobCollectionInputErrors = {
  __typename?: 'JobCollectionInputErrors';
  id?: Maybe<RequiredInputError>;
};

export type JobCollectionItem = {
  __typename?: 'JobCollectionItem';
  id: Scalars['ID'];
  addedBy?: Maybe<Scalars['String']>;
  job: Job;
};

export type JobCollectionListError = {
  __typename?: 'JobCollectionListError';
  inputErrors?: Maybe<JobCollectionListInputErrors>;
  otherError?: Maybe<JobCollectionListOtherError>;
};

/** ALL JOB COLLECTIONS TYPE DEFINITIONS */
export type JobCollectionListInput = {
  ownedBy: JobCollectionListOwnedByEnum;
  saveable: JobCollectionListSaveableEnum;
  inviteStatus: JobCollectionListInviteStatusEnum;
};

export type JobCollectionListInputErrors = {
  __typename?: 'JobCollectionListInputErrors';
  ownedBy?: Maybe<RequiredInputError>;
  saveable?: Maybe<RequiredInputError>;
  inviteStatus?: Maybe<RequiredInputError>;
};

export enum JobCollectionListInviteStatusEnum {
  Accepted = 'ACCEPTED',
  All = 'ALL'
}

export enum JobCollectionListOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export enum JobCollectionListOwnedByEnum {
  Me = 'ME',
  SharingUser = 'SHARING_USER',
  All = 'ALL'
}

export type JobCollectionListResult = UserAuthenticationRequiredError | JobCollectionListSuccess | JobCollectionListError;

export enum JobCollectionListSaveableEnum {
  OnlySaveable = 'ONLY_SAVEABLE',
  OnlyUnsaveable = 'ONLY_UNSAVEABLE',
  All = 'ALL'
}

export type JobCollectionListSuccess = {
  __typename?: 'JobCollectionListSuccess';
  jobCollections: Array<JobCollection>;
};

export type JobCollectionMod = {
  __typename?: 'JobCollectionMod';
  modType: ModTypeEnum;
  jobCollection: JobCollection;
  jobCollectionItem: JobCollectionItem;
};

export enum JobCollectionOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type JobCollectionPerms = {
  __typename?: 'JobCollectionPerms';
  editTitle: Scalars['Boolean'];
  save: Scalars['Boolean'];
  unsave: Scalars['Boolean'];
  enablePublicLink: Scalars['Boolean'];
  invite: Scalars['Boolean'];
};

export type JobCollectionResult = UserAuthenticationRequiredError | UserUnauthorizedError | JobCollectionSuccess | JobCollectionError;

/** JOB COLLECTION TYPE DEFINITIONS */
export type JobCollectionSuccess = {
  __typename?: 'JobCollectionSuccess';
  jobCollection: JobCollection;
};

export type JobCollectionToggleSharingError = {
  __typename?: 'JobCollectionToggleSharingError';
  inputErrors?: Maybe<JobCollectionToggleSharingInputErrors>;
  otherError?: Maybe<JobCollectionToggleSharingOtherError>;
};

export type JobCollectionToggleSharingInput = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};

/** JOB COLLECTION CHANGE PUBLIC SHARING */
export type JobCollectionToggleSharingInputErrors = {
  __typename?: 'JobCollectionToggleSharingInputErrors';
  id?: Maybe<RequiredInputError>;
  value?: Maybe<RequiredInputError>;
};

export enum JobCollectionToggleSharingOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type JobCollectionToggleSharingResult = UserAuthenticationRequiredError | JobCollectionToggleSharingSuccess | JobCollectionToggleSharingError;

export type JobCollectionToggleSharingSuccess = {
  __typename?: 'JobCollectionToggleSharingSuccess';
  success: Scalars['Boolean'];
  jobCollection: JobCollection;
};

export type JobData = {
  __typename?: 'JobData';
  low_salary?: Maybe<Scalars['Int']>;
  average_salary?: Maybe<Scalars['Int']>;
  high_salary?: Maybe<Scalars['Int']>;
  hourly_salary?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
};

export type JobError = {
  __typename?: 'JobError';
  error: Scalars['String'];
};

export type JoblistcontentqaVolume = VolumeInterface & {
  __typename?: 'JoblistcontentqaVolume';
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  folder?: Maybe<VolumeFolder>;
  volumeId?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  dateCreatedTimestamp?: Maybe<Scalars['Timestamp']>;
  dateCreated?: Maybe<Scalars['Timestamp']>;
  dateUpdatedTimestamp?: Maybe<Scalars['Timestamp']>;
  dateUpdated?: Maybe<Scalars['Timestamp']>;
  focalPoint?: Maybe<AssetFocalPoint>;
};


export type JoblistcontentqaVolumeUrlArgs = {
  transform?: Maybe<NamedTransformsEnum>;
  crop?: Maybe<CropInputObject>;
  fit?: Maybe<CropInputObject>;
  stretch?: Maybe<CropInputObject>;
};


export type JoblistcontentqaVolumeWidthArgs = {
  transform?: Maybe<NamedTransformsEnum>;
  crop?: Maybe<CropInputObject>;
  fit?: Maybe<CropInputObject>;
  stretch?: Maybe<CropInputObject>;
};


export type JoblistcontentqaVolumeHeightArgs = {
  transform?: Maybe<NamedTransformsEnum>;
  crop?: Maybe<CropInputObject>;
  fit?: Maybe<CropInputObject>;
  stretch?: Maybe<CropInputObject>;
};

export type JoblistUser = {
  __typename?: 'JoblistUser';
  id?: Maybe<Scalars['ID']>;
  shareId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  isSavedJobsListPublic?: Maybe<Scalars['Boolean']>;
  savedJobsListName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lastSigninAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  quizData?: Maybe<QuizData>;
  loggedInRealm?: Maybe<UserLoggedInRealm>;
  progress?: Maybe<UserProgress>;
  showGamify?: Maybe<Scalars['Boolean']>;
  pendingEmailChange?: Maybe<Scalars['String']>;
};

export type JobPosting = {
  __typename?: 'JobPosting';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isSearchable: Scalars['Boolean'];
  content: JobPostingContent;
  hadProduct: Scalars['Boolean'];
  hasProduct: Scalars['Boolean'];
  hasProductFrom?: Maybe<Scalars['DateTime']>;
  hasProductUntil?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  subscriptionStart?: Maybe<Scalars['DateTime']>;
  subscriptionEnd?: Maybe<Scalars['DateTime']>;
  isFreeTrialJob: Scalars['Boolean'];
  isFreeTrialActive: Scalars['Boolean'];
  freeTrialStart?: Maybe<Scalars['DateTime']>;
  freeTrialEnd?: Maybe<Scalars['DateTime']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  impressions?: Maybe<Scalars['Int']>;
  applications?: Maybe<Scalars['Int']>;
};

export type JobPostingActiveProductInput = {
  productCode: Scalars['String'];
  productVersion: Scalars['Int'];
};

export type JobPostingActiveProductOutput = JobPostingActiveProductSuccess | JobPostingError;

export type JobPostingActiveProductSuccess = {
  __typename?: 'JobPostingActiveProductSuccess';
  productCode: Scalars['String'];
  hasFreeTrial: Scalars['Boolean'];
  freeTrialDuration?: Maybe<Scalars['Int']>;
  paidProductDuration: Scalars['Int'];
  postingPrice: Scalars['Float'];
  ruleMetric?: Maybe<Scalars['String']>;
  ruleLimit?: Maybe<Scalars['Int']>;
};

export type JobPostingApplyInput = {
  postingId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  resume: Scalars['Upload'];
  coverLetter?: Maybe<Scalars['Upload']>;
};

export type JobPostingApplyOutput = JobPostingApplySuccess | JobPostingError;

export type JobPostingApplySuccess = {
  __typename?: 'JobPostingApplySuccess';
  status?: Maybe<Scalars['Boolean']>;
};

export type JobPostingCharge = {
  __typename?: 'JobPostingCharge';
  grossAmount: Scalars['Int'];
  netAmount: Scalars['Int'];
  discount: Scalars['Int'];
  currency: JobPostingCurrency;
  validity?: Maybe<Scalars['Int']>;
};

export type JobPostingChargeInput = {
  country: JobPostingCountry;
  product: Scalars['String'];
  version: Scalars['Int'];
  coupon?: Maybe<Scalars['String']>;
};

export type JobPostingChargeOutput = JobPostingChargeSuccess | JobPostingError;

export type JobPostingChargeSuccess = {
  __typename?: 'JobPostingChargeSuccess';
  charge: JobPostingCharge;
  order: JobPostingOrder;
  freeTrial?: Maybe<FreeTrial>;
};

export type JobPostingCheckoutInput = {
  product: Scalars['String'];
  version: Scalars['Int'];
  coupon?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  deferralId?: Maybe<Scalars['String']>;
};

export type JobPostingCheckoutOutput = JobPostingCheckoutSuccess | JobPostingError;

export type JobPostingCheckoutSuccess = {
  __typename?: 'JobPostingCheckoutSuccess';
  charge: JobPostingCharge;
  billingEmailAddress: Scalars['String'];
  paymentIntentSecret?: Maybe<Scalars['String']>;
  setupIntentSecret?: Maybe<Scalars['String']>;
  freeTrial?: Maybe<FreeTrial>;
};

export enum JobPostingCompensation {
  Hourly = 'HOURLY',
  Salary = 'SALARY',
  Other = 'OTHER'
}

export type JobPostingConfig = {
  clickDecay: DecayConfig;
  impressionDecay: DecayConfig;
};

export type JobPostingContent = {
  __typename?: 'JobPostingContent';
  title: Scalars['String'];
  country: JobPostingCountry;
  description: Scalars['String'];
  zipCode: Scalars['String'];
  area1?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyLogo?: Maybe<Scalars['String']>;
  isRemote: Scalars['Boolean'];
  jobType?: Maybe<JobPostingType>;
  compensation?: Maybe<JobPostingCompensation>;
  isEntryLevel?: Maybe<Scalars['Boolean']>;
  requiresPhysicalLabor?: Maybe<Scalars['Boolean']>;
  applyByEmail?: Maybe<Scalars['String']>;
  applyAtUrl?: Maybe<Scalars['String']>;
};

export type JobPostingContentInput = {
  title: Scalars['String'];
  country: JobPostingCountry;
  description: Scalars['String'];
  zipCode: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  area1?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyLogo?: Maybe<Scalars['Upload']>;
  isRemote: Scalars['Boolean'];
  jobType: JobPostingType;
  compensation: JobPostingCompensation;
  isEntryLevel?: Maybe<Scalars['Boolean']>;
  requiresPhysicalLabor?: Maybe<Scalars['Boolean']>;
  applyByEmail?: Maybe<Scalars['String']>;
  applyAtUrl?: Maybe<Scalars['String']>;
};

export type JobPostingCountOutput = JobPostingCountSuccess | JobPostingError;

export enum JobPostingCountry {
  Us = 'US',
  Uk = 'UK',
  Ca = 'CA',
  Au = 'AU'
}

export type JobPostingCountSuccess = {
  __typename?: 'JobPostingCountSuccess';
  total: Scalars['Int'];
  open: Scalars['Int'];
  closed: Scalars['Int'];
  draft: Scalars['Int'];
  hasActiveProduct: Scalars['Int'];
};

export enum JobPostingCurrency {
  Aud = 'aud',
  Cad = 'cad',
  Gbp = 'gbp',
  Usd = 'usd'
}

export type JobPostingDecodeIdInput = {
  id: Scalars['ID'];
};

export type JobPostingDecodeIdOutput = JobPostingDecodeIdSuccess | JobPostingError;

export type JobPostingDecodeIdSuccess = {
  __typename?: 'JobPostingDecodeIdSuccess';
  id?: Maybe<Scalars['ID']>;
};

export type JobPostingError = {
  __typename?: 'JobPostingError';
  error?: Maybe<JobPostingErrorCode>;
  message?: Maybe<Scalars['String']>;
};

export enum JobPostingErrorCode {
  InvalidInput = 'INVALID_INPUT',
  InvalidZipCode = 'INVALID_ZIP_CODE',
  InvalidJobPosting = 'INVALID_JOB_POSTING',
  InvalidProduct = 'INVALID_PRODUCT',
  InvalidCouponCode = 'INVALID_COUPON_CODE',
  InvalidEmailAddress = 'INVALID_EMAIL_ADDRESS',
  InvalidDeferral = 'INVALID_DEFERRAL',
  BillingEmailMismatch = 'BILLING_EMAIL_MISMATCH',
  CompanyLogoRequired = 'COMPANY_LOGO_REQUIRED',
  CouponQuotaExhausted = 'COUPON_QUOTA_EXHAUSTED',
  AuthRequired = 'AUTH_REQUIRED',
  JobPostingNotFound = 'JOB_POSTING_NOT_FOUND',
  Forbidden = 'FORBIDDEN',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  JobPostingProfanityError = 'JOB_POSTING_PROFANITY_ERROR',
  MaxiumumGuestPostingsExceeded = 'MAXIUMUM_GUEST_POSTINGS_EXCEEDED'
}

export type JobPostingInput = {
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isSearchable?: Maybe<Scalars['Boolean']>;
  content?: Maybe<JobPostingContentInput>;
};

export enum JobPostingListFilter {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  HasActiveProduct = 'HAS_ACTIVE_PRODUCT'
}

export type JobPostingListOutput = JobPostingListSuccess | JobPostingError;

export type JobPostingListSuccess = {
  __typename?: 'JobPostingListSuccess';
  jobPostingList: Array<JobPosting>;
};

export type JobPostingLogViewInput = {
  viewId: Scalars['String'];
  loggedAt: Scalars['String'];
  clickId?: Maybe<Scalars['String']>;
  userParams?: Maybe<UserParams>;
  pageParams?: Maybe<PageParams>;
  jobPosting: JobPostingInput;
};

export type JobPostingOrder = {
  __typename?: 'JobPostingOrder';
  id: Scalars['String'];
  status: JobPostingOrderStatus;
  coupon?: Maybe<VersionedEntity>;
  grossAmount: Scalars['Int'];
  netAmount: Scalars['Int'];
  discount: Scalars['Int'];
  items: Array<JobPostingOrderItem>;
  productConsumption?: Maybe<JobPostingProductConsumption>;
  paidOn?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  isPaymentSuccessful?: Maybe<Scalars['Boolean']>;
  freeTrial?: Maybe<FreeTrial>;
  paymentDeferral?: Maybe<JobPostingPaymentDeferral>;
  validityDays?: Maybe<Scalars['Int']>;
  postingPrice?: Maybe<Scalars['Float']>;
  chargeEntity?: Maybe<ChargeEntity>;
};

export type JobPostingOrderItem = {
  __typename?: 'JobPostingOrderItem';
  product: VersionedEntity;
  unitPrice: Scalars['Int'];
  unitsRequested: Scalars['Int'];
};

export type JobPostingOrderOutput = JobPostingOrderSuccess | JobPostingError;

export enum JobPostingOrderStatus {
  PaymentDue = 'PAYMENT_DUE',
  Cancelled = 'CANCELLED',
  Fulfilled = 'FULFILLED',
  Unfulfilled = 'UNFULFILLED',
  PaymentDeferred = 'PAYMENT_DEFERRED'
}

export type JobPostingOrderSuccess = {
  __typename?: 'JobPostingOrderSuccess';
  billingEmailAddress: Scalars['String'];
  order: JobPostingOrder;
};

export type JobPostingOutput = JobPostingSuccess | JobPostingError;

export type JobPostingPaymentDeferral = {
  __typename?: 'JobPostingPaymentDeferral';
  type?: Maybe<PaymentDeferralType>;
  chargeEarly: Scalars['Boolean'];
  ruleMetric?: Maybe<PaymentDeferralRuleMetric>;
  ruleGrouping?: Maybe<PaymentDeferralRuleGrouping>;
  ruleLimit: Scalars['Int'];
};

export type JobPostingProductConsumption = {
  __typename?: 'JobPostingProductConsumption';
  activeFrom: Scalars['String'];
  activeUntil: Scalars['String'];
};

export type JobPostingReceipt = {
  __typename?: 'JobPostingReceipt';
  id: Scalars['String'];
  amount: Scalars['String'];
  discount: Scalars['String'];
  productConsumption?: Maybe<JobPostingProductConsumption>;
  paymentCharged: Scalars['String'];
  paymentReceived?: Maybe<Scalars['String']>;
  isFreeTrialJob: Scalars['Boolean'];
  isFreeTrialActive: Scalars['Boolean'];
  freeTrialStart?: Maybe<Scalars['String']>;
  freeTrialEnd?: Maybe<Scalars['String']>;
  freeTrialValidity?: Maybe<Scalars['Int']>;
  validity: Scalars['Int'];
};

export type JobPostingReceiptListInput = {
  postingId: Scalars['ID'];
};

export type JobPostingReceiptListOutput = JobPostingReceiptListSuccess | JobPostingError;

export type JobPostingReceiptListSuccess = {
  __typename?: 'JobPostingReceiptListSuccess';
  receipts?: Maybe<Array<JobPostingReceipt>>;
};

export type JobPostingSuccess = {
  __typename?: 'JobPostingSuccess';
  jobPosting: JobPosting;
};

export enum JobPostingType {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Other = 'OTHER'
}

export enum JobProvider {
  AppcastCpa = 'APPCAST_CPA',
  AppcastCpc = 'APPCAST_CPC',
  AdzunaCpc_2 = 'ADZUNA_CPC_2',
  AdzunaCpc = 'ADZUNA_CPC',
  AppcastGd = 'APPCAST_GD',
  AppcastLi = 'APPCAST_LI',
  AppcastExchangeCpaDesktop = 'APPCAST_EXCHANGE_CPA_DESKTOP',
  AppcastExchangeCpaMobile = 'APPCAST_EXCHANGE_CPA_MOBILE',
  Indeed = 'INDEED',
  Jobg8 = 'JOBG8',
  Jobget = 'JOBGET',
  Joblift = 'JOBLIFT',
  JoblistSponsored = 'JOBLIST_SPONSORED',
  Joblookup = 'JOBLOOKUP',
  Joveo = 'JOVEO',
  LinkupOrganic = 'LINKUP_ORGANIC',
  LinkupSponsored = 'LINKUP_SPONSORED',
  NeuvooOrganic = 'NEUVOO_ORGANIC',
  NeuvooSponsored = 'NEUVOO_SPONSORED',
  Pandologic_1 = 'PANDOLOGIC_1',
  Pandologic_2 = 'PANDOLOGIC_2',
  Talroo_1Xml = 'TALROO_1_XML',
  TalrooDirect = 'TALROO_DIRECT',
  TalrooTier1 = 'TALROO_TIER1',
  TalrooTier2 = 'TALROO_TIER2',
  Totaljobs = 'TOTALJOBS',
  Upward = 'UPWARD',
  ZiprecruiterPriority = 'ZIPRECRUITER_PRIORITY',
  ZiprecruiterStandard = 'ZIPRECRUITER_STANDARD',
  ZiprecruiterTest10 = 'ZIPRECRUITER_TEST10',
  ZiprecruiterTest30 = 'ZIPRECRUITER_TEST30',
  Ziprecruiter_10P = 'ZIPRECRUITER_10P',
  Ziprecruiter_10S = 'ZIPRECRUITER_10S',
  ZiprecruiterTest15 = 'ZIPRECRUITER_TEST15'
}

export type JobResult = Job | JobError;

export type JobSaveError = {
  __typename?: 'JobSaveError';
  inputErrors?: Maybe<JobSaveInputErrors>;
  otherError?: Maybe<JobSaveOtherError>;
};

export type JobSaveInput = {
  provider: Scalars['String'];
  providerId: Scalars['String'];
  routing?: Maybe<Scalars['String']>;
  jobCollectionIdsToSave: Array<Scalars['String']>;
  jobCollectionIdsToRemove: Array<Scalars['String']>;
};

export enum JobSaveInputError {
  Required = 'REQUIRED',
  TooShort = 'TOO_SHORT',
  AtLeastOneFieldRequired = 'AT_LEAST_ONE_FIELD_REQUIRED'
}

export type JobSaveInputErrors = {
  __typename?: 'JobSaveInputErrors';
  provider?: Maybe<RequiredInputError>;
  providerId?: Maybe<RequiredInputError>;
  routing?: Maybe<RequiredInputError>;
  jobCollectionIdsToSave?: Maybe<JobSaveInputError>;
  jobCollectionIdsToRemove?: Maybe<JobSaveInputError>;
};

export enum JobSaveOtherError {
  RequestFailed = 'REQUEST_FAILED',
  NotFound = 'NOT_FOUND'
}

export type JobSaveResult = UserAuthenticationRequiredError | JobSaveSuccess | JobSaveError;

/** SAVE JOB TYPE DEFINITIONS */
export type JobSaveSuccess = {
  __typename?: 'JobSaveSuccess';
  success: Scalars['Boolean'];
  jobCollectionMods: Array<JobCollectionMod>;
};

export enum JobType {
  PartTime = 'PART_TIME',
  FullTime = 'FULL_TIME',
  Contractor = 'CONTRACTOR',
  Other = 'OTHER'
}

export type JobUnsaveError = {
  __typename?: 'JobUnsaveError';
  inputErrors?: Maybe<JobUnsaveInputErrors>;
  otherError?: Maybe<JobUnsaveOtherError>;
};

export type JobUnsaveInput = {
  provider: Scalars['String'];
  providerId: Scalars['String'];
  jobCollectionIdsToRemove: Array<Scalars['String']>;
};

export enum JobUnsaveInputError {
  Required = 'REQUIRED',
  TooShort = 'TOO_SHORT'
}

export type JobUnsaveInputErrors = {
  __typename?: 'JobUnsaveInputErrors';
  provider?: Maybe<RequiredInputError>;
  providerId?: Maybe<RequiredInputError>;
  jobCollectionIdsToRemove?: Maybe<JobUnsaveInputError>;
};

export enum JobUnsaveOtherError {
  RequestFailed = 'REQUEST_FAILED',
  NotFound = 'NOT_FOUND'
}

export type JobUnsaveResult = UserAuthenticationRequiredError | JobUnsaveSuccess | JobUnsaveError;

/** UNSAVE JOB TYPE DEFINITIONS */
export type JobUnsaveSuccess = {
  __typename?: 'JobUnsaveSuccess';
  success: Scalars['Boolean'];
  jobCollectionMods: Array<JobCollectionMod>;
};

export enum LastNameInputError {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  TooShort = 'TOO_SHORT',
  TooLong = 'TOO_LONG'
}

export type LocalityJobCount = {
  __typename?: 'LocalityJobCount';
  locality?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export type Location = {
  __typename?: 'Location';
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export enum LocationRadius {
  Within_50Miles = 'WITHIN_50_MILES',
  Within_25Miles = 'WITHIN_25_MILES',
  Within_10Miles = 'WITHIN_10_MILES',
  Within_5Miles = 'WITHIN_5_MILES',
  Within_100Miles = 'WITHIN_100_MILES',
  Within_50Kms = 'WITHIN_50_KMS',
  Within_25Kms = 'WITHIN_25_KMS',
  Within_10Kms = 'WITHIN_10_KMS',
  Within_5Kms = 'WITHIN_5_KMS',
  Within_100Kms = 'WITHIN_100_KMS',
  AnyLocation = 'ANY_LOCATION'
}

export type LogRanking = {
  scaling_factor: Scalars['Float'];
};

/** MASQUERADE */
export type MasqueradeInput = {
  emailAddress: Scalars['String'];
};

export enum ModTypeEnum {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type Mutation = {
  __typename?: 'Mutation';
  logUserInterest: Scalars['Boolean'];
  logUserContactMessage: Scalars['Boolean'];
  logImpression: Scalars['Boolean'];
  logDescriptionImpression: Scalars['Boolean'];
  /** A sample mutation. Doesn't actually save anything. */
  helloWorld?: Maybe<Scalars['String']>;
  /** Create or update existing Blog. */
  upsertBlog?: Maybe<Blog>;
  upsertUser?: Maybe<User>;
  signup: UserSignupResult;
  login: UserLoginResult;
  verifyEmail: UserVerifyEmailResult;
  resendEmailVerification: UserResendEmailVerificationResult;
  forgotPassword: UserForgotPasswordResult;
  resetPassword: UserResetPasswordResult;
  updateAccount: UserUpdateAccountResult;
  changeEmail: UserChangeEmailResult;
  changePassword: UserChangePasswordResult;
  deleteAccount: UserDeleteAccountResult;
  updateQuizData: UserUpdateQuizDataResult;
  saveStep: UserSaveStepResult;
  updateShowGamify: UserUpdateShowGamifyResult;
  userChangeLoggedInRealm: UserChangeLoggedInRealmResult;
  userCancelPendingEmailChange: UserCancelPendingEmailChangeResult;
  inviteAccept: InviteAcceptResult;
  inviteIgnore: InviteIgnoreResult;
  inviteRemove: InviteRemoveResult;
  collaboratorInvite: CollaboratorInviteResult;
  collaboratorRemove: CollaboratorRemoveResult;
  jobCollectionCreate: JobCollectionCreateResult;
  jobCollectionDelete: JobCollectionDeleteResult;
  jobCollectionChangeTitle: JobCollectionChangeTitleResult;
  jobCollectionChangePerms: JobCollectionChangePermsResult;
  jobCollectionToggleSharing: JobCollectionToggleSharingResult;
  jobSave: JobSaveResult;
  jobUnsave: JobUnsaveResult;
  addJobAlert: AddJobAlertOutput;
  verifyJobAlert: VerifyJobAlertOutput;
  pauseJobAlert: PauseJobAlertOutput;
  pauseAllJobAlerts: PauseAllJobAlertsOutput;
  deleteJobAlert: DeleteJobAlertOutput;
  deleteAllJobAlerts: DeleteAllJobAlertsOutput;
  jobPostingCreate: JobPostingOutput;
  jobPostingUpdate: JobPostingOutput;
  jobPostingApply: JobPostingApplyOutput;
  jobPostingCheckout: JobPostingCheckoutOutput;
  jobPostingLogView: Scalars['Boolean'];
};


export type MutationLogUserInterestArgs = {
  email: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  form?: Maybe<UserInterestForm>;
  userParams?: Maybe<UserParams>;
};


export type MutationLogUserContactMessageArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  message: Scalars['String'];
  department: Scalars['String'];
  userParams?: Maybe<UserParams>;
};


export type MutationLogImpressionArgs = {
  impression_id: Scalars['String'];
  placement_context: Scalars['String'];
  placement_path: Scalars['String'];
  placement_platform: Scalars['String'];
  location: Scalars['String'];
  keyword: Scalars['String'];
  filters: Scalars['String'];
  url: Scalars['String'];
  jobs: Array<Maybe<ImpressionJobObject>>;
  userParams?: Maybe<UserParams>;
};


export type MutationLogDescriptionImpressionArgs = {
  impression_id: Scalars['String'];
  placement_context: Scalars['String'];
  placement_path: Scalars['String'];
  placement_platform: Scalars['String'];
  location: Scalars['String'];
  keyword: Scalars['String'];
  filters: Scalars['String'];
  url: Scalars['String'];
  job: DescriptionImpressionJobObject;
  userParams?: Maybe<UserParams>;
  navigationParams?: Maybe<NavigationParams>;
  pageParams?: Maybe<PageParams>;
  screenParams?: Maybe<ScreenParams>;
  windowParams?: Maybe<WindowParams>;
  connectionParams?: Maybe<ConnectionParams>;
  page_context: Scalars['String'];
};


export type MutationUpsertBlogArgs = {
  id?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  postDate?: Maybe<Scalars['Timestamp']>;
  expiryDate?: Maybe<Scalars['Timestamp']>;
  parentId?: Maybe<Scalars['Int']>;
  query?: Maybe<MutationQuery>;
  heroImage?: Maybe<Array<Maybe<HeroImageAssetInput>>>;
  featured?: Maybe<FeaturedEnum>;
  authorName?: Maybe<Scalars['String']>;
  authorAvatar?: Maybe<Array<Maybe<AuthorAvatarAssetInput>>>;
  subheading?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<CategoriesCategoryInput>>>;
  tags?: Maybe<Scalars['String']>;
  articleBody?: Maybe<Array<Maybe<ArticleBodyInput>>>;
  description?: Maybe<Scalars['String']>;
};


export type MutationUpsertUserArgs = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationSignupArgs = {
  input: UserSignupInput;
};


export type MutationLoginArgs = {
  input: UserLoginInput;
};


export type MutationVerifyEmailArgs = {
  input: UserVerifyEmailInput;
};


export type MutationForgotPasswordArgs = {
  input: UserForgotPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: UserResetPasswordInput;
};


export type MutationUpdateAccountArgs = {
  input: UserUpdateAccountInput;
};


export type MutationChangeEmailArgs = {
  input: UserChangeEmailInput;
};


export type MutationChangePasswordArgs = {
  input: UserChangePasswordInput;
};


export type MutationDeleteAccountArgs = {
  input: UserDeleteAccountInput;
};


export type MutationUpdateQuizDataArgs = {
  input?: Maybe<UserUpdateQuizDataInput>;
};


export type MutationSaveStepArgs = {
  input?: Maybe<UserSaveStepInput>;
};


export type MutationUpdateShowGamifyArgs = {
  input?: Maybe<UserUpdateShowGamifyInput>;
};


export type MutationUserChangeLoggedInRealmArgs = {
  input?: Maybe<UserChangeLoggedInRealmInput>;
};


export type MutationInviteAcceptArgs = {
  input: InviteAcceptInput;
};


export type MutationInviteIgnoreArgs = {
  input: InviteIgnoreInput;
};


export type MutationInviteRemoveArgs = {
  input: InviteRemoveInput;
};


export type MutationCollaboratorInviteArgs = {
  input: CollaboratorInviteInput;
};


export type MutationCollaboratorRemoveArgs = {
  input: CollaboratorRemoveInput;
};


export type MutationJobCollectionCreateArgs = {
  input: JobCollectionCreateInput;
};


export type MutationJobCollectionDeleteArgs = {
  input: JobCollectionDeleteInput;
};


export type MutationJobCollectionChangeTitleArgs = {
  input: JobCollectionChangeTitleInput;
};


export type MutationJobCollectionChangePermsArgs = {
  input: JobCollectionChangePermsInput;
};


export type MutationJobCollectionToggleSharingArgs = {
  input: JobCollectionToggleSharingInput;
};


export type MutationJobSaveArgs = {
  input: JobSaveInput;
};


export type MutationJobUnsaveArgs = {
  input: JobUnsaveInput;
};


export type MutationAddJobAlertArgs = {
  searchQuery: JobAlertSearchQueryInput;
  email?: Maybe<Scalars['String']>;
};


export type MutationVerifyJobAlertArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type MutationPauseJobAlertArgs = {
  id: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationDeleteJobAlertArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAllJobAlertsArgs = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type MutationJobPostingCreateArgs = {
  input: JobPostingInput;
};


export type MutationJobPostingUpdateArgs = {
  id: Scalars['ID'];
  input: JobPostingInput;
};


export type MutationJobPostingApplyArgs = {
  input?: Maybe<JobPostingApplyInput>;
};


export type MutationJobPostingCheckoutArgs = {
  id: Scalars['ID'];
  input: JobPostingCheckoutInput;
};


export type MutationJobPostingLogViewArgs = {
  input: JobPostingLogViewInput;
};

export type MutationQuery = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export enum NamedTransformsEnum {
  /** Cropping */
  Cropping = 'cropping'
}

export enum NameError {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  TooLong = 'TOO_LONG'
}

export type NavigationParams = {
  navigationAppCodeName?: Maybe<Scalars['String']>;
  navigationAppName?: Maybe<Scalars['String']>;
  navigationAppVersion?: Maybe<Scalars['String']>;
  navigationCookieEnabled?: Maybe<Scalars['Boolean']>;
  navigationHardwareConcurrency?: Maybe<Scalars['Int']>;
  navigationLanguage?: Maybe<Scalars['String']>;
  navigationMaxTouchPoints?: Maybe<Scalars['Int']>;
  navigationPlatform?: Maybe<Scalars['String']>;
  navigationProduct?: Maybe<Scalars['String']>;
  navigationProductSub?: Maybe<Scalars['String']>;
  navigationVendor?: Maybe<Scalars['String']>;
  navigationVendorSub?: Maybe<Scalars['String']>;
};

export type NormalBrowseArgs = {
  __typename?: 'NormalBrowseArgs';
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export enum OneFieldRequiredError {
  AtLeastOneFieldRequired = 'AT_LEAST_ONE_FIELD_REQUIRED'
}

export type OptionFieldData = {
  __typename?: 'OptionFieldData';
  selected?: Maybe<OptionFieldDataOptions>;
  options?: Maybe<Array<Maybe<OptionFieldDataOptions>>>;
};

export type OptionFieldDataOptions = {
  __typename?: 'OptionFieldDataOptions';
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  currentPage?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PageParams = {
  pageHash?: Maybe<Scalars['String']>;
  pageHostname?: Maybe<Scalars['String']>;
  pageHref?: Maybe<Scalars['String']>;
  pagePathname?: Maybe<Scalars['String']>;
};

export enum PasswordConfirmationInputError {
  Required = 'REQUIRED',
  DoesNotMatchPassword = 'DOES_NOT_MATCH_PASSWORD'
}

export enum PasswordInputError {
  Required = 'REQUIRED',
  TooShort = 'TOO_SHORT',
  TooLong = 'TOO_LONG'
}

export enum PasswordInputErrorWithValidation {
  Required = 'REQUIRED',
  TooShort = 'TOO_SHORT',
  TooLong = 'TOO_LONG',
  Invalid = 'INVALID'
}

export type PauseAllJobAlertsOutput = PauseAllJobAlertsResult | JobAlertError;

export type PauseAllJobAlertsResult = {
  __typename?: 'PauseAllJobAlertsResult';
  status: Scalars['Boolean'];
};

export type PauseJobAlertOutput = PauseJobAlertResult | JobAlertError;

export type PauseJobAlertResult = {
  __typename?: 'PauseJobAlertResult';
  status: Scalars['Boolean'];
};

export enum PaymentDeferralRuleGrouping {
  Unique = 'unique',
  Total = 'total'
}

export enum PaymentDeferralRuleMetric {
  Impressions = 'impressions',
  Views = 'views',
  Clicks = 'clicks',
  Applications = 'applications'
}

export enum PaymentDeferralType {
  Immediate = 'immediate',
  Deferred = 'deferred'
}

export enum PhysicalLabor {
  PhysicalLabor = 'PHYSICAL_LABOR',
  Other = 'OTHER'
}

export enum PositionEnum {
  /** Left */
  Left = 'left',
  /** Center */
  Center = 'center',
  /** Right */
  Right = 'right',
  Empty = 'empty'
}

export enum PositionInputEnum {
  TopLeft = 'topLeft',
  TopCenter = 'topCenter',
  TopRight = 'topRight',
  CenterLeft = 'centerLeft',
  CenterCenter = 'centerCenter',
  CenterRight = 'centerRight',
  BottomLeft = 'bottomLeft',
  BottomCenter = 'bottomCenter',
  BottomRight = 'bottomRight'
}

export type PostalCodeJobCount = {
  __typename?: 'PostalCodeJobCount';
  postal_code?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export enum PostedDate {
  Today = 'TODAY',
  ThisWeek = 'THIS_WEEK',
  Last_2Weeks = 'LAST_2_WEEKS',
  Last_30Days = 'LAST_30_DAYS',
  AnyDate = 'ANY_DATE'
}

export type PressEntriesPressEntry = {
  __typename?: 'PressEntriesPressEntry';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  entryTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<VolumeInterface>>>;
  entryUrl?: Maybe<Scalars['String']>;
};


export type PressEntriesPressEntryImageArgs = {
  filename?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export type PressEntriesPressSection = {
  __typename?: 'PressEntriesPressSection';
  id?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  pressSectionTitle?: Maybe<Scalars['String']>;
};

export type PressEntriesUnion = PressEntriesPressSection | PressEntriesPressEntry;

export type Presspage = EntryInterface & ElementInterface & {
  __typename?: 'Presspage';
  id: Scalars['Int'];
  author: User;
  title: Scalars['String'];
  seoTitleTag?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  dateCreated: Scalars['Timestamp'];
  dateUpdated: Scalars['Timestamp'];
  expiryDate?: Maybe<Scalars['Timestamp']>;
  postDate?: Maybe<Scalars['Timestamp']>;
  enabled: Scalars['Boolean'];
  status: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  section?: Maybe<Section>;
  type?: Maybe<EntryType>;
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  hasDescendants: Scalars['Boolean'];
  level?: Maybe<Scalars['Int']>;
  parent?: Maybe<EntryInterface>;
  siblings?: Maybe<Array<Maybe<EntryInterface>>>;
  elementType?: Maybe<Scalars['String']>;
  pressEntries?: Maybe<Array<Maybe<PressEntriesUnion>>>;
  description?: Maybe<Scalars['String']>;
};


export type PresspageChildrenArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type PresspagePressEntriesArgs = {
  type?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ProviderConfig = {
  provider?: Maybe<JobProvider>;
  boost?: Maybe<Scalars['Float']>;
  countryBoost?: Maybe<CountryBoost>;
  cpcFloor?: Maybe<Scalars['Float']>;
};

export type ProvidersFilter = {
  local?: Maybe<Scalars['Boolean']>;
  localInventory?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  searchJobs: SearchResult;
  job: JobResult;
  eJob: JobResult;
  jitRoutingJob?: Maybe<Job>;
  topJob: JobResult;
  browseJobs: BrowseResult;
  normalizeBrowseArgs: NormalBrowseArgs;
  browseNearbyCities: Array<BrowseCity>;
  browseNearbyStates: Array<BrowseState>;
  browseJobCount: Scalars['Int'];
  browseListing: Array<BrowseListing>;
  browseStats: BrowseStats;
  browseResolveCityRoleOrIndustry?: Maybe<BrowseCity>;
  getSuggestionsForCompletion: Array<Scalars['String']>;
  getSuggestionsForTypeahead: Array<Scalars['String']>;
  browseSegmentCount: Scalars['Int'];
  fetchJobData: JobData;
  fetchCityData?: Maybe<CityData>;
  suggestJobTitle: Array<Scalars['String']>;
  hasJobs: Scalars['Boolean'];
  topJobTitles: Array<TitleJobCount>;
  topCompanies: Array<CompanyJobCount>;
  topGeoArea1: Array<Area1JobCount>;
  topGeoLocalities: Array<LocalityJobCount>;
  topGeoPostalCodes: Array<Maybe<PostalCodeJobCount>>;
  topCategories: Array<Maybe<CategoryJobCount>>;
  getCompanyLogo?: Maybe<CompanyLogo>;
  helloWorld?: Maybe<Scalars['String']>;
  ping?: Maybe<Scalars['String']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  pressPage?: Maybe<Presspage>;
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
  entriesConnection?: Maybe<EntryConnection>;
  entry?: Maybe<EntryInterface>;
  draft?: Maybe<EntryInterface>;
  assets?: Maybe<Array<Maybe<VolumeInterface>>>;
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category?: Maybe<CategoryInterface>;
  categoriesConnection?: Maybe<CategoryConnection>;
  users?: Maybe<Array<Maybe<User>>>;
  user?: Maybe<User>;
  sections?: Maybe<Array<Maybe<Section>>>;
  entryCount: Scalars['Int'];
  me?: Maybe<JoblistUser>;
  userProgress?: Maybe<UserProgress>;
  verifyToken: Scalars['Boolean'];
  masquerade?: Maybe<Scalars['String']>;
  inviteFetch: InviteFetchResult;
  jobCollection: JobCollectionResult;
  jobCollectionList: JobCollectionListResult;
  jobCollectionGetNewURL: JobCollectionGetNewUrlResult;
  getJobAlerts: GetJobAlertsOutput;
  jobPostingDraft: JobPostingOutput;
  jobPostingFinalized: JobPostingOutput;
  jobPostingCount: JobPostingCountOutput;
  jobPostingList: JobPostingListOutput;
  jobPostingCharge: JobPostingChargeOutput;
  jobPostingOrder: JobPostingOrderOutput;
  jobPostingDecodeId: JobPostingDecodeIdOutput;
  jobPostingReceiptList: JobPostingReceiptListOutput;
  jobPostingActiveProduct?: Maybe<JobPostingActiveProductOutput>;
};


export type QuerySearchJobsArgs = {
  query: SearchQuery;
  providers?: Maybe<ProvidersFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryJobArgs = {
  provider: Scalars['String'];
  providerId: Scalars['String'];
  routing?: Maybe<Scalars['String']>;
  impressionId?: Maybe<Scalars['String']>;
};


export type QueryEJobArgs = {
  id: Scalars['String'];
};


export type QueryJitRoutingJobArgs = {
  id: Scalars['ID'];
  country: Scalars['String'];
  providers?: Maybe<Array<Scalars['String']>>;
  strategy: Scalars['String'];
};


export type QueryTopJobArgs = {
  query: SearchQuery;
  providers?: Maybe<ProvidersFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryBrowseJobsArgs = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
};


export type QueryNormalizeBrowseArgsArgs = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
};


export type QueryBrowseNearbyCitiesArgs = {
  country?: Maybe<Scalars['String']>;
  city: BrowseCityInput;
  radius: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type QueryBrowseNearbyStatesArgs = {
  country?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  radius: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type QueryBrowseJobCountArgs = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type QueryBrowseListingArgs = {
  country?: Maybe<Scalars['String']>;
  segment: Scalars['Int'];
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
};


export type QueryBrowseStatsArgs = {
  city: BrowseCityInput;
  numCompanies: Scalars['Int'];
  jobCountPrecision: Scalars['Int'];
};


export type QueryBrowseResolveCityRoleOrIndustryArgs = {
  cityRoleOrIndustry: Scalars['String'];
  state: Scalars['String'];
};


export type QueryGetSuggestionsForCompletionArgs = {
  prefix: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<SuggestionContext>>;
  fuzzy?: Maybe<SuggestionFuzziness>;
};


export type QueryGetSuggestionsForTypeaheadArgs = {
  text: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  fuzziness?: Maybe<Scalars['String']>;
  prefixLength?: Maybe<Scalars['Int']>;
  fuzzyTranspositions?: Maybe<Scalars['Boolean']>;
};


export type QueryBrowseSegmentCountArgs = {
  segment: Scalars['Int'];
  country?: Maybe<Scalars['String']>;
};


export type QueryFetchJobDataArgs = {
  role: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};


export type QueryFetchCityDataArgs = {
  city: Scalars['String'];
  state: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};


export type QuerySuggestJobTitleArgs = {
  prefix: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryHasJobsArgs = {
  query: SearchQuery;
};


export type QueryTopJobTitlesArgs = {
  query: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTopCompaniesArgs = {
  query: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTopGeoArea1Args = {
  query: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTopGeoLocalitiesArgs = {
  query: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTopGeoPostalCodesArgs = {
  query: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTopCategoriesArgs = {
  query: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetCompanyLogoArgs = {
  companyName: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
};


export type QuerySitesArgs = {
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
};


export type QueryPressPageArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryEntriesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryEntryArgs = {
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryDraftArgs = {
  draftId: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  authorGroup?: Maybe<Scalars['String']>;
  authorGroupId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  before?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  localeEnabled?: Maybe<Scalars['Boolean']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idNot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  relatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  orRelatedTo?: Maybe<Array<Maybe<RelatedToInputType>>>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<SectionsEnum>>>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<EntryTypesEnum>>>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryAssetsArgs = {
  filename?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryCategoriesArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryCategoriesConnectionArgs = {
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<CategoryGroupsEnum>>>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  indexBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  siblingOf?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  FeaturedEnum?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  admin?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLoginDate?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  admin?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLoginDate?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<FeaturedEnum>;
  subheading?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type QueryEntryCountArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryVerifyTokenArgs = {
  input: VerifyTokenInput;
};


export type QueryMasqueradeArgs = {
  input: MasqueradeInput;
};


export type QueryInviteFetchArgs = {
  input: InviteFetchInput;
};


export type QueryJobCollectionArgs = {
  input: JobCollectionInput;
};


export type QueryJobCollectionListArgs = {
  input: JobCollectionListInput;
};


export type QueryJobCollectionGetNewUrlArgs = {
  input: JobCollectionGetNewUrlInput;
};


export type QueryJobPostingDraftArgs = {
  id: Scalars['ID'];
};


export type QueryJobPostingFinalizedArgs = {
  id: Scalars['ID'];
};


export type QueryJobPostingCountArgs = {
  filter?: Maybe<JobPostingListFilter>;
};


export type QueryJobPostingListArgs = {
  filter?: Maybe<JobPostingListFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryJobPostingChargeArgs = {
  input: JobPostingChargeInput;
};


export type QueryJobPostingOrderArgs = {
  orderId?: Maybe<Scalars['ID']>;
  postingId?: Maybe<Scalars['ID']>;
};


export type QueryJobPostingDecodeIdArgs = {
  input: JobPostingDecodeIdInput;
};


export type QueryJobPostingReceiptListArgs = {
  input: JobPostingReceiptListInput;
};


export type QueryJobPostingActiveProductArgs = {
  input: JobPostingActiveProductInput;
};

export type QuizData = {
  __typename?: 'QuizData';
  covid?: Maybe<Scalars['Boolean']>;
  newGrad?: Maybe<Scalars['Boolean']>;
  remote?: Maybe<Scalars['Boolean']>;
  somethingElse?: Maybe<Scalars['Boolean']>;
};

export type RankFeature = {
  field: Scalars['String'];
  boost?: Maybe<Scalars['Float']>;
  saturation?: Maybe<SaturationRanking>;
  log?: Maybe<LogRanking>;
  sigmoid?: Maybe<SigmoidRanking>;
};

export type RedactorFieldData = {
  __typename?: 'RedactorFieldData';
  totalPages?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
};


export type RedactorFieldDataContentArgs = {
  page?: Maybe<Scalars['Int']>;
};

export type RelatedToInputType = {
  element?: Maybe<Array<Maybe<Scalars['Int']>>>;
  elementIsEdge?: Maybe<Scalars['Boolean']>;
  sourceElement?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sourceElementIsEdge?: Maybe<Scalars['Boolean']>;
  targetElement?: Maybe<Array<Maybe<Scalars['Int']>>>;
  targetElementIsEdge?: Maybe<Scalars['Boolean']>;
  field?: Maybe<Scalars['String']>;
  sourceLocale?: Maybe<Scalars['String']>;
};

export enum RequiredAndInvalidInputError {
  Required = 'REQUIRED',
  Invalid = 'INVALID'
}

export enum RequiredInputError {
  Required = 'REQUIRED'
}

export type SaturationRanking = {
  pivot: Scalars['Float'];
};

export type ScreenParams = {
  screenAvailHeight?: Maybe<Scalars['Int']>;
  screenAvailWidth?: Maybe<Scalars['Int']>;
  screenColorDepth?: Maybe<Scalars['Int']>;
  screenHeight?: Maybe<Scalars['Int']>;
  screenPixelDepth?: Maybe<Scalars['Int']>;
  screenWidth?: Maybe<Scalars['Int']>;
};

export type SearchQuery = {
  keyword?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationRadius?: Maybe<LocationRadius>;
  providers?: Maybe<Array<Scalars['String']>>;
  titles?: Maybe<Array<Scalars['String']>>;
  companys?: Maybe<Array<Scalars['String']>>;
  categories?: Maybe<Array<Scalars['String']>>;
  includeTags?: Maybe<TagFilters>;
  excludeTags?: Maybe<TagFilters>;
  tagSources?: Maybe<TagFlags>;
  easyApply?: Maybe<Scalars['Boolean']>;
  posted?: Maybe<PostedDate>;
  placeid?: Maybe<Scalars['String']>;
  firstVisitId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  adChannel?: Maybe<Scalars['String']>;
  settings?: Maybe<SearchSettings>;
  country?: Maybe<Scalars['String']>;
  excludeBy?: Maybe<ExclusionClause>;
  useDfsSearch?: Maybe<Scalars['Boolean']>;
  usePhraseSearch?: Maybe<Scalars['Boolean']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  jobs: Array<Job>;
  impression_id: Scalars['String'];
  total: Scalars['Int'];
  totalAppcast: Scalars['Int'];
  totalLocalInventory: Scalars['Int'];
};

export type SearchSettings = {
  explain?: Maybe<Scalars['Boolean']>;
  rankFeatures?: Maybe<Array<RankFeature>>;
  providerConfig?: Maybe<Array<ProviderConfig>>;
  postingConfig?: Maybe<JobPostingConfig>;
  fieldBoost?: Maybe<FieldBoost>;
  cpcFloor?: Maybe<Scalars['Int']>;
  includeDescInSearch?: Maybe<Scalars['Boolean']>;
  useQueryExpansion?: Maybe<Scalars['Boolean']>;
  radiusOverwrite?: Maybe<Scalars['Int']>;
  radiusBoost?: Maybe<Scalars['Int']>;
  rankAllRadiusSearches?: Maybe<Scalars['Boolean']>;
  cpcBoost?: Maybe<Scalars['Int']>;
  rankByPosted?: Maybe<Scalars['Boolean']>;
  calcCpcPivot?: Maybe<Scalars['Boolean']>;
  useCustomCpcPivot?: Maybe<Scalars['Boolean']>;
  customCpcPivot?: Maybe<Scalars['Float']>;
  slop?: Maybe<Scalars['Float']>;
  alwaysBoostCompanies?: Maybe<Scalars['Boolean']>;
  dedupeResultsByCompany?: Maybe<Scalars['Boolean']>;
};

export type Section = {
  __typename?: 'Section';
  id: Scalars['Int'];
  structureId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  handle: Scalars['String'];
  type: Scalars['String'];
  siteSettings?: Maybe<Array<Maybe<SectionSiteSettings>>>;
  maxLevels?: Maybe<Scalars['Int']>;
  hasUrls?: Maybe<Scalars['Boolean']>;
  enableVersioning?: Maybe<Scalars['Boolean']>;
  entryTypes?: Maybe<Array<Maybe<EntryType>>>;
};

export enum SectionsEnum {
  Blog = 'blog',
  Presspage = 'presspage'
}

export type SectionSiteSettings = {
  __typename?: 'SectionSiteSettings';
  id: Scalars['Int'];
  siteId: Scalars['Int'];
  enabledByDefault: Scalars['Boolean'];
  hasUrls: Scalars['Boolean'];
  uriFormat?: Maybe<Scalars['String']>;
  template: Scalars['String'];
};

export type SigmoidRanking = {
  pivot: Scalars['Float'];
  exponent: Scalars['Float'];
};

export type Site = {
  __typename?: 'Site';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  baseUrl?: Maybe<Scalars['String']>;
  hasUrls?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  originalBaseUrl?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export enum SiteLocation {
  OnSite = 'ON_SITE',
  Remote = 'REMOTE',
  Other = 'OTHER'
}

export type SuggestionContext = {
  context: Scalars['String'];
  boost?: Maybe<Scalars['Float']>;
};

export type SuggestionFuzziness = {
  fuzziness: Scalars['String'];
  transpositions?: Maybe<Scalars['Boolean']>;
  min_length?: Maybe<Scalars['Int']>;
  prefix_length?: Maybe<Scalars['Int']>;
};

export type TagFilters = {
  jobType?: Maybe<Array<JobType>>;
  siteLocation?: Maybe<Array<SiteLocation>>;
  physicalLabor?: Maybe<Array<PhysicalLabor>>;
  healthBenefits?: Maybe<Array<HealthBenefits>>;
  compensationType?: Maybe<Array<CompensationType>>;
  environment?: Maybe<Array<Environment>>;
  entryLevel?: Maybe<Array<EntryLevel>>;
};

export type TagFlag = {
  source?: Maybe<Scalars['String']>;
};

export type TagFlags = {
  jobType?: Maybe<TagFlag>;
  siteLocation?: Maybe<TagFlag>;
  requiresPhysicalLabor?: Maybe<TagFlag>;
  providesHealthBenefits?: Maybe<TagFlag>;
  compensationType?: Maybe<TagFlag>;
  environment?: Maybe<TagFlag>;
  entryLevel?: Maybe<TagFlag>;
  bCorp?: Maybe<TagFlag>;
  diverse?: Maybe<TagFlag>;
  ecoFriendly?: Maybe<TagFlag>;
};

export type Tags = {
  __typename?: 'Tags';
  jobType?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteLocation?: Maybe<Array<Maybe<Scalars['String']>>>;
  requiresPhysicalLabor?: Maybe<Array<Maybe<Scalars['String']>>>;
  providesHealthBenefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  compensationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  environment?: Maybe<Array<Maybe<Scalars['String']>>>;
  entryLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
  bCorp?: Maybe<Array<Maybe<Scalars['String']>>>;
  diverse?: Maybe<Array<Maybe<Scalars['String']>>>;
  ecoFriendly?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagSources = {
  __typename?: 'TagSources';
  regex?: Maybe<Tags>;
  script?: Maybe<Tags>;
  manual?: Maybe<Tags>;
};


export type TitleJobCount = {
  __typename?: 'TitleJobCount';
  title: Scalars['String'];
  count: Scalars['Int'];
};

export enum TokenError {
  Required = 'REQUIRED',
  Invalid = 'INVALID',
  Expired = 'EXPIRED',
  TooLong = 'TOO_LONG'
}

export enum UnauthorizedError {
  Unauthorized = 'UNAUTHORIZED'
}


export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  name: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  friendlyName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email: Scalars['String'];
  admin: Scalars['Boolean'];
  isCurrent: Scalars['Boolean'];
  preferredLocale?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Timestamp'];
  dateUpdated: Scalars['Timestamp'];
  lastLoginDate: Scalars['Timestamp'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserAlreadyLoggedInError = {
  __typename?: 'UserAlreadyLoggedInError';
  error: AlreadyLoggedInError;
};

export type UserAuthenticationRequiredError = {
  __typename?: 'UserAuthenticationRequiredError';
  error: AuthenticationRequiredError;
};

export type UserCancelPendingEmailChangeError = {
  __typename?: 'UserCancelPendingEmailChangeError';
  otherError: UserCancelPendingEmailChangeOtherError;
};

/** USER CANCEL PENDING EMAIL CHANGE */
export enum UserCancelPendingEmailChangeOtherError {
  RequestFailed = 'REQUEST_FAILED'
}

export type UserCancelPendingEmailChangeResult = UserAuthenticationRequiredError | UserCancelPendingEmailChangeSuccess | UserCancelPendingEmailChangeError;

export type UserCancelPendingEmailChangeSuccess = {
  __typename?: 'UserCancelPendingEmailChangeSuccess';
  success: Scalars['Boolean'];
};

export type UserChangeEmailError = {
  __typename?: 'UserChangeEmailError';
  inputErrors?: Maybe<UserChangeEmailInputErrors>;
  otherError?: Maybe<UserChangeEmailOtherError>;
};

/** CHANGE EMAIL TYPE DEFINITIONS */
export type UserChangeEmailInput = {
  newEmailAddress: Scalars['String'];
  newEmailAddressConfirmation: Scalars['String'];
};

export type UserChangeEmailInputErrors = {
  __typename?: 'UserChangeEmailInputErrors';
  newEmailAddress?: Maybe<EmailInputErrorWithExistsAndSelfCompare>;
  newEmailAddressConfirmation?: Maybe<EmailAddressConfirmationInputError>;
};

export enum UserChangeEmailOtherError {
  Failed = 'FAILED',
  PendingChange = 'PENDING_CHANGE'
}

export type UserChangeEmailResult = UserAuthenticationRequiredError | UserChangeEmailSuccess | UserChangeEmailError;

export type UserChangeEmailSuccess = {
  __typename?: 'UserChangeEmailSuccess';
  success: Scalars['Boolean'];
};

export type UserChangeLoggedInRealmError = {
  __typename?: 'UserChangeLoggedInRealmError';
  inputErrors?: Maybe<UserChangeLoggedInRealmInputError>;
  otherError?: Maybe<UserChangeLoggedInRealmOtherError>;
};

export type UserChangeLoggedInRealmInput = {
  loggedInRealm: UserLoggedInRealm;
};

export type UserChangeLoggedInRealmInputError = {
  __typename?: 'UserChangeLoggedInRealmInputError';
  loggedInRealm?: Maybe<RequiredInputError>;
};

export enum UserChangeLoggedInRealmOtherError {
  Failed = 'FAILED'
}

export type UserChangeLoggedInRealmResult = UserAuthenticationRequiredError | UserChangeLoggedInRealmSuccess | UserChangeLoggedInRealmError;

export type UserChangeLoggedInRealmSuccess = {
  __typename?: 'UserChangeLoggedInRealmSuccess';
  success: Scalars['Boolean'];
};

export type UserChangePasswordError = {
  __typename?: 'UserChangePasswordError';
  inputErrors?: Maybe<UserChangePasswordInputErrors>;
  otherError?: Maybe<UserChangePasswordOtherError>;
};

/** CHANGE PASSWORD TYPE DEFINITIONS */
export type UserChangePasswordInput = {
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
  currentPassword: Scalars['String'];
};

export type UserChangePasswordInputErrors = {
  __typename?: 'UserChangePasswordInputErrors';
  newPassword?: Maybe<PasswordInputError>;
  newPasswordConfirmation?: Maybe<PasswordConfirmationInputError>;
  currentPassword?: Maybe<PasswordInputErrorWithValidation>;
};

export enum UserChangePasswordOtherError {
  Failed = 'FAILED'
}

export type UserChangePasswordResult = UserAuthenticationRequiredError | UserChangePasswordSuccess | UserChangePasswordError;

export type UserChangePasswordSuccess = {
  __typename?: 'UserChangePasswordSuccess';
  success: Scalars['Boolean'];
};

export type UserDeleteAccountError = {
  __typename?: 'UserDeleteAccountError';
  inputErrors?: Maybe<UserDeleteAccountInputErrors>;
  otherError?: Maybe<UserDeleteAccountOtherError>;
};

/** DELETE ACCOUNT TYPE DEFINITIONS */
export type UserDeleteAccountInput = {
  emailAddress: Scalars['String'];
  password: Scalars['String'];
};

export type UserDeleteAccountInputErrors = {
  __typename?: 'UserDeleteAccountInputErrors';
  emailAddress?: Maybe<EmailInputError>;
  password?: Maybe<PasswordInputError>;
};

export enum UserDeleteAccountOtherError {
  IncorrectEmailOrPassword = 'INCORRECT_EMAIL_OR_PASSWORD'
}

export type UserDeleteAccountResult = UserAuthenticationRequiredError | UserDeleteAccountSuccess | UserDeleteAccountError;

export type UserDeleteAccountSuccess = {
  __typename?: 'UserDeleteAccountSuccess';
  success: Scalars['Boolean'];
};

export type UserForgotPasswordError = {
  __typename?: 'UserForgotPasswordError';
  inputErrors?: Maybe<UserForgotPasswordInputError>;
};

/** FORGOT PASSWORD TYPE DEFINITIONS */
export type UserForgotPasswordInput = {
  emailAddress: Scalars['String'];
};

export type UserForgotPasswordInputError = {
  __typename?: 'UserForgotPasswordInputError';
  emailAddress?: Maybe<EmailInputError>;
};

export type UserForgotPasswordResult = UserForgotPasswordSuccess | UserForgotPasswordError;

export type UserForgotPasswordSuccess = {
  __typename?: 'UserForgotPasswordSuccess';
  success: Scalars['Boolean'];
};

export enum UserInterestForm {
  Sidebar = 'SIDEBAR',
  BottomModal = 'BOTTOM_MODAL'
}

/** USER CHANGE LOGGED IN REALM */
export enum UserLoggedInRealm {
  Jobseeker = 'jobseeker',
  Employer = 'employer'
}

export type UserLoginError = {
  __typename?: 'UserLoginError';
  inputErrors?: Maybe<UserLoginInputErrors>;
  otherError?: Maybe<UserLoginOtherError>;
  message?: Maybe<Scalars['String']>;
};

/** LOGIN TYPE DEFINITIONS */
export type UserLoginInput = {
  emailAddress: Scalars['String'];
  password: Scalars['String'];
};

export type UserLoginInputErrors = {
  __typename?: 'UserLoginInputErrors';
  emailAddress?: Maybe<EmailInputError>;
  password?: Maybe<PasswordInputError>;
};

export enum UserLoginOtherError {
  IncorrectEmailOrPassword = 'INCORRECT_EMAIL_OR_PASSWORD'
}

export type UserLoginResult = UserAlreadyLoggedInError | UserLoginSuccess | UserLoginError;

export type UserLoginSuccess = {
  __typename?: 'UserLoginSuccess';
  token: Scalars['String'];
};

export type UserParams = {
  adChannel?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  msclkid?: Maybe<Scalars['String']>;
  firstVisitId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};

export type UserProgress = {
  __typename?: 'UserProgress';
  emailAlert: Scalars['Boolean'];
  signUp: Scalars['Boolean'];
  saveJobs: Scalars['Boolean'];
  inviteFriends: Scalars['Boolean'];
};

/** USER PROGRESS */
export enum UserProgressStep {
  EmailAlert = 'emailAlert',
  SignUp = 'signUp',
  SaveJobs = 'saveJobs',
  InviteFriends = 'inviteFriends'
}

export type UserResendEmailVerificationError = {
  __typename?: 'UserResendEmailVerificationError';
  otherError: UserResendEmailVerificationOtherError;
};

export enum UserResendEmailVerificationOtherError {
  RequestFailed = 'REQUEST_FAILED',
  AlreadyVerified = 'ALREADY_VERIFIED'
}

export type UserResendEmailVerificationResult = UserAuthenticationRequiredError | UserResendEmailVerificationSuccess | UserResendEmailVerificationError;

/** RESEND EMAIL VERIFICATION TYPE DEFINITIONS */
export type UserResendEmailVerificationSuccess = {
  __typename?: 'UserResendEmailVerificationSuccess';
  success: Scalars['Boolean'];
};

export type UserResetPasswordError = {
  __typename?: 'UserResetPasswordError';
  inputErrors?: Maybe<UserResetPasswordInputError>;
  otherError?: Maybe<UserResetPasswordOtherError>;
};

/** RESET PASSWORD TYPE DEFINITIONS */
export type UserResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type UserResetPasswordInputError = {
  __typename?: 'UserResetPasswordInputError';
  token?: Maybe<TokenError>;
  password?: Maybe<PasswordInputError>;
  passwordConfirmation?: Maybe<PasswordConfirmationInputError>;
};

export enum UserResetPasswordOtherError {
  RequestFailed = 'REQUEST_FAILED',
  TokenExpired = 'TOKEN_EXPIRED'
}

export type UserResetPasswordResult = UserResetPasswordSuccess | UserResetPasswordError;

export type UserResetPasswordSuccess = {
  __typename?: 'UserResetPasswordSuccess';
  success: Scalars['Boolean'];
};

export type UserRole = {
  __typename?: 'UserRole';
  name: Scalars['String'];
};

export type UserSaveStepError = {
  __typename?: 'UserSaveStepError';
  otherError?: Maybe<UserSaveStepOtherError>;
};

export type UserSaveStepInput = {
  step: Array<UserProgressStep>;
};

export enum UserSaveStepOtherError {
  Failed = 'FAILED'
}

export type UserSaveStepResult = UserAuthenticationRequiredError | UserSaveStepSuccess | UserSaveStepError;

export type UserSaveStepSuccess = {
  __typename?: 'UserSaveStepSuccess';
  user?: Maybe<JoblistUser>;
};

export type UserSignupError = {
  __typename?: 'UserSignupError';
  inputErrors?: Maybe<UserSignupInputErrors>;
  otherError?: Maybe<UserSignupOtherError>;
};

/** SIGNUP TYPE DEFINITIONS */
export type UserSignupInput = {
  emailAddress: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  tosAccepted?: Maybe<Scalars['Boolean']>;
  emailAccepted?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
};

export type UserSignupInputErrors = {
  __typename?: 'UserSignupInputErrors';
  emailAddress?: Maybe<EmailInputErrorWithExists>;
  password?: Maybe<PasswordInputError>;
  passwordConfirmation?: Maybe<PasswordConfirmationInputError>;
  tosAccepted?: Maybe<CheckboxRequiredError>;
  emailAccepted?: Maybe<CheckboxError>;
};

export enum UserSignupOtherError {
  SignupFailed = 'SIGNUP_FAILED'
}

export type UserSignupResult = UserAlreadyLoggedInError | UserSignupSuccess | UserSignupError;

export type UserSignupSuccess = {
  __typename?: 'UserSignupSuccess';
  token: Scalars['String'];
};

export type UserUnauthorizedError = {
  __typename?: 'UserUnauthorizedError';
  unauthorizedError: UnauthorizedError;
};

export type UserUpdateAccountError = {
  __typename?: 'UserUpdateAccountError';
  inputErrors?: Maybe<UserUpdateAccountInputErrors>;
  otherError?: Maybe<UserUpdateAccountOtherError>;
};

/** UPDATE ACCOUNT TYPE DEFINITIONS */
export type UserUpdateAccountInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserUpdateAccountInputErrors = {
  __typename?: 'UserUpdateAccountInputErrors';
  firstName?: Maybe<FirstNameInputError>;
  lastName?: Maybe<LastNameInputError>;
};

export enum UserUpdateAccountOtherError {
  Failed = 'FAILED'
}

export type UserUpdateAccountResult = UserAuthenticationRequiredError | UserUpdateAccountSuccess | UserUpdateAccountError;

export type UserUpdateAccountSuccess = {
  __typename?: 'UserUpdateAccountSuccess';
  success: Scalars['Boolean'];
};

export type UserUpdateQuizDataError = {
  __typename?: 'UserUpdateQuizDataError';
  inputErrors?: Maybe<UserUpdateQuizDataInputErrors>;
  otherError?: Maybe<UserUpdateQuizDataOtherError>;
};

/** UPDATE QUIZ DATA */
export type UserUpdateQuizDataInput = {
  covid?: Maybe<Scalars['Boolean']>;
  newGrad?: Maybe<Scalars['Boolean']>;
  remote?: Maybe<Scalars['Boolean']>;
  somethingElse?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateQuizDataInputErrors = {
  __typename?: 'UserUpdateQuizDataInputErrors';
  covid?: Maybe<CheckboxError>;
  newGrad?: Maybe<CheckboxError>;
  remote?: Maybe<CheckboxError>;
  somethingElse?: Maybe<CheckboxError>;
};

export enum UserUpdateQuizDataOtherError {
  Failed = 'FAILED'
}

export type UserUpdateQuizDataResult = UserAuthenticationRequiredError | UserUpdateQuizDataSuccess | UserUpdateQuizDataError;

export type UserUpdateQuizDataSuccess = {
  __typename?: 'UserUpdateQuizDataSuccess';
  success: Scalars['Boolean'];
};

export type UserUpdateShowGamifyError = {
  __typename?: 'UserUpdateShowGamifyError';
  otherError?: Maybe<UserUpdateShowGamifyOtherError>;
};

export type UserUpdateShowGamifyInput = {
  showGamify: Scalars['Boolean'];
};

export enum UserUpdateShowGamifyOtherError {
  Failed = 'FAILED'
}

export type UserUpdateShowGamifyResult = UserAuthenticationRequiredError | UserUpdateShowGamifySuccess | UserUpdateShowGamifyError;

export type UserUpdateShowGamifySuccess = {
  __typename?: 'UserUpdateShowGamifySuccess';
  success: Scalars['Boolean'];
  user?: Maybe<JoblistUser>;
};

export type UserVerifyEmailError = {
  __typename?: 'UserVerifyEmailError';
  inputErrors?: Maybe<UserVerifyEmailInputError>;
  otherError?: Maybe<UserVerifyEmailOtherError>;
};

/** VERIFY EMAIL TYPE DEFINITIONS */
export type UserVerifyEmailInput = {
  token?: Maybe<Scalars['String']>;
};

export type UserVerifyEmailInputError = {
  __typename?: 'UserVerifyEmailInputError';
  token: TokenError;
};

export enum UserVerifyEmailOtherError {
  Invalid = 'INVALID',
  Expired = 'EXPIRED',
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  InvalidPendingVerificationRequest = 'INVALID_PENDING_VERIFICATION_REQUEST'
}

export type UserVerifyEmailResult = UserVerifyEmailSuccess | UserVerifyEmailError;

export type UserVerifyEmailSuccess = {
  __typename?: 'UserVerifyEmailSuccess';
  success: Scalars['Boolean'];
};

export type VerifyJobAlertOutput = VerifyJobAlertResult | JobAlertError;

export type VerifyJobAlertResult = {
  __typename?: 'VerifyJobAlertResult';
  alert?: Maybe<JobAlert>;
};

/** VERIFY TOKEN */
export type VerifyTokenInput = {
  token: Scalars['String'];
  args: Array<Maybe<Scalars['String']>>;
};

export type VersionedEntity = {
  __typename?: 'VersionedEntity';
  code: Scalars['String'];
  version: Scalars['Int'];
};

export type VolumeFolder = {
  __typename?: 'VolumeFolder';
  id?: Maybe<Scalars['Int']>;
  volumeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type VolumeInterface = {
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  folder?: Maybe<VolumeFolder>;
  volumeId?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  dateCreatedTimestamp?: Maybe<Scalars['Timestamp']>;
  dateCreated?: Maybe<Scalars['Timestamp']>;
  dateUpdatedTimestamp?: Maybe<Scalars['Timestamp']>;
  dateUpdated?: Maybe<Scalars['Timestamp']>;
  focalPoint?: Maybe<AssetFocalPoint>;
};


export type VolumeInterfaceUrlArgs = {
  transform?: Maybe<NamedTransformsEnum>;
  crop?: Maybe<CropInputObject>;
  fit?: Maybe<CropInputObject>;
  stretch?: Maybe<CropInputObject>;
};


export type VolumeInterfaceWidthArgs = {
  transform?: Maybe<NamedTransformsEnum>;
  crop?: Maybe<CropInputObject>;
  fit?: Maybe<CropInputObject>;
  stretch?: Maybe<CropInputObject>;
};


export type VolumeInterfaceHeightArgs = {
  transform?: Maybe<NamedTransformsEnum>;
  crop?: Maybe<CropInputObject>;
  fit?: Maybe<CropInputObject>;
  stretch?: Maybe<CropInputObject>;
};

export type WindowParams = {
  windowDevicePixelRatio?: Maybe<Scalars['Int']>;
  windowInnerHeight?: Maybe<Scalars['Int']>;
  windowInnerWidth?: Maybe<Scalars['Int']>;
  windowOuterHeight?: Maybe<Scalars['Int']>;
  windowOuterWidth?: Maybe<Scalars['Int']>;
  windowPageXOffset?: Maybe<Scalars['Int']>;
  windowPageYOffset?: Maybe<Scalars['Int']>;
  windowScreenLeft?: Maybe<Scalars['Int']>;
  windowScreenTop?: Maybe<Scalars['Int']>;
  windowScreenX?: Maybe<Scalars['Int']>;
  windowScreenY?: Maybe<Scalars['Int']>;
  windowScrollX?: Maybe<Scalars['Int']>;
  windowScrollY?: Maybe<Scalars['Int']>;
};

export type AddJobAlertMutationVariables = {
  email?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  country: Scalars['String'];
};


export type AddJobAlertMutation = (
  { __typename?: 'Mutation' }
  & { addJobAlert: (
    { __typename: 'JobAlert' }
    & Pick<JobAlert, 'id' | 'status'>
    & { searchQuery: (
      { __typename?: 'JobAlertSearchQuery' }
      & Pick<JobAlertSearchQuery, 'keyword' | 'location' | 'country'>
    ) }
  ) | (
    { __typename: 'JobAlertError' }
    & Pick<JobAlertError, 'error'>
  ) }
);

export type DeleteAllJobAlertsMutationVariables = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type DeleteAllJobAlertsMutation = (
  { __typename?: 'Mutation' }
  & { deleteAllJobAlerts: (
    { __typename: 'DeleteAllJobAlertsResult' }
    & { alerts: Array<(
      { __typename?: 'JobAlert' }
      & Pick<JobAlert, 'id' | 'status'>
      & { searchQuery: (
        { __typename?: 'JobAlertSearchQuery' }
        & Pick<JobAlertSearchQuery, 'keyword' | 'location' | 'country'>
      ) }
    )> }
  ) | (
    { __typename: 'JobAlertError' }
    & Pick<JobAlertError, 'error'>
  ) }
);

export type DeleteJobAlertMutationVariables = {
  id: Scalars['String'];
};


export type DeleteJobAlertMutation = (
  { __typename?: 'Mutation' }
  & { deleteJobAlert: (
    { __typename: 'DeleteJobAlertResult' }
    & Pick<DeleteJobAlertResult, 'status'>
  ) | (
    { __typename: 'JobAlertError' }
    & Pick<JobAlertError, 'error'>
  ) }
);

export type GetJobAlertsQueryVariables = {};


export type GetJobAlertsQuery = (
  { __typename?: 'Query' }
  & { getJobAlerts: (
    { __typename: 'GetJobAlertsResult' }
    & { alerts: Array<(
      { __typename?: 'JobAlert' }
      & Pick<JobAlert, 'id' | 'status'>
      & { searchQuery: (
        { __typename?: 'JobAlertSearchQuery' }
        & Pick<JobAlertSearchQuery, 'keyword' | 'location' | 'country'>
      ) }
    )> }
  ) | (
    { __typename: 'JobAlertError' }
    & Pick<JobAlertError, 'error'>
  ) }
);

export type PauseJobAlertMutationVariables = {
  id: Scalars['String'];
  value: Scalars['Boolean'];
};


export type PauseJobAlertMutation = (
  { __typename?: 'Mutation' }
  & { pauseJobAlert: (
    { __typename: 'PauseJobAlertResult' }
    & Pick<PauseJobAlertResult, 'status'>
  ) | (
    { __typename: 'JobAlertError' }
    & Pick<JobAlertError, 'error'>
  ) }
);

export type VerifyJobAlertMutationVariables = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type VerifyJobAlertMutation = (
  { __typename?: 'Mutation' }
  & { verifyJobAlert: (
    { __typename: 'VerifyJobAlertResult' }
    & { alert?: Maybe<(
      { __typename?: 'JobAlert' }
      & Pick<JobAlert, 'id' | 'status'>
      & { searchQuery: (
        { __typename?: 'JobAlertSearchQuery' }
        & Pick<JobAlertSearchQuery, 'keyword' | 'location' | 'country'>
      ) }
    )> }
  ) | (
    { __typename: 'JobAlertError' }
    & Pick<JobAlertError, 'error'>
  ) }
);

export type CreateJobPostingPageJobPostingFragment = (
  { __typename?: 'JobPosting' }
  & Pick<JobPosting, 'id' | 'isSearchable' | 'isActive' | 'createdAt' | 'hadProduct' | 'hasProduct' | 'hasProductFrom' | 'hasProductUntil' | 'isFreeTrialJob' | 'isFreeTrialActive' | 'freeTrialStart' | 'freeTrialEnd' | 'isPaid'>
  & { content: (
    { __typename?: 'JobPostingContent' }
    & JobPostingContentFragment
  ) }
);

export type JobPostingContentFragment = (
  { __typename?: 'JobPostingContent' }
  & Pick<JobPostingContent, 'title' | 'description' | 'zipCode' | 'companyName' | 'isRemote' | 'country' | 'jobType' | 'compensation' | 'isEntryLevel' | 'requiresPhysicalLabor' | 'companyLogo' | 'area1' | 'locality' | 'applyByEmail' | 'applyAtUrl'>
);

export type JobPostingCheckoutMutationVariables = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  coupon?: Maybe<Scalars['String']>;
  product: Scalars['String'];
  version: Scalars['Int'];
  deferralId?: Maybe<Scalars['String']>;
};


export type JobPostingCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { jobPostingCheckout: (
    { __typename: 'JobPostingCheckoutSuccess' }
    & Pick<JobPostingCheckoutSuccess, 'paymentIntentSecret' | 'setupIntentSecret' | 'billingEmailAddress'>
    & { charge: (
      { __typename?: 'JobPostingCharge' }
      & Pick<JobPostingCharge, 'netAmount' | 'grossAmount' | 'discount' | 'currency' | 'validity'>
    ), freeTrial?: Maybe<(
      { __typename?: 'FreeTrial' }
      & Pick<FreeTrial, 'isFreeTrialJob' | 'isFreeTrialActive' | 'freeTrialStart' | 'freeTrialEnd' | 'validityDays' | 'ruleMetric' | 'ruleLimit'>
    )> }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingCreateMutationVariables = {
  title: Scalars['String'];
  companyName: Scalars['String'];
  zipCode: Scalars['String'];
  locality: Scalars['String'];
  area1: Scalars['String'];
  country: JobPostingCountry;
  isRemote: Scalars['Boolean'];
  jobType: JobPostingType;
  compensation: JobPostingCompensation;
  isEntryLevel?: Maybe<Scalars['Boolean']>;
  requiresPhysicalLabor?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  companyLogo?: Maybe<Scalars['Upload']>;
  isActive: Scalars['Boolean'];
  isSearchable: Scalars['Boolean'];
  applyByEmail?: Maybe<Scalars['String']>;
  applyAtUrl?: Maybe<Scalars['String']>;
};


export type JobPostingCreateMutation = (
  { __typename?: 'Mutation' }
  & { jobPostingCreate: (
    { __typename: 'JobPostingSuccess' }
    & { jobPosting: (
      { __typename?: 'JobPosting' }
      & CreateJobPostingPageJobPostingFragment
    ) }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error' | 'message'>
  ) }
);

export type JobPostingUpdateMutationVariables = {
  id: Scalars['ID'];
  content?: Maybe<JobPostingContentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isSearchable?: Maybe<Scalars['Boolean']>;
};


export type JobPostingUpdateMutation = (
  { __typename?: 'Mutation' }
  & { jobPostingUpdate: (
    { __typename: 'JobPostingSuccess' }
    & { jobPosting: (
      { __typename?: 'JobPosting' }
      & CreateJobPostingPageJobPostingFragment
    ) }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error' | 'message'>
  ) }
);

export type JobPostingDraftQueryVariables = {
  id: Scalars['ID'];
};


export type JobPostingDraftQuery = (
  { __typename?: 'Query' }
  & { jobPostingDraft: (
    { __typename: 'JobPostingSuccess' }
    & { jobPosting: (
      { __typename?: 'JobPosting' }
      & Pick<JobPosting, 'id' | 'isSearchable' | 'isActive' | 'hadProduct' | 'hasProduct' | 'hasProductFrom' | 'hasProductUntil' | 'createdAt' | 'subscriptionStart' | 'subscriptionEnd' | 'isFreeTrialJob' | 'isFreeTrialActive' | 'freeTrialStart' | 'freeTrialEnd'>
      & { content: (
        { __typename?: 'JobPostingContent' }
        & JobPostingContentFragment
      ) }
    ) }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingFinalizedQueryVariables = {
  id: Scalars['ID'];
};


export type JobPostingFinalizedQuery = (
  { __typename?: 'Query' }
  & { jobPostingFinalized: (
    { __typename: 'JobPostingSuccess' }
    & { jobPosting: (
      { __typename?: 'JobPosting' }
      & Pick<JobPosting, 'id' | 'isSearchable' | 'isActive' | 'hadProduct' | 'hasProduct' | 'hasProductFrom' | 'hasProductUntil' | 'isFreeTrialJob' | 'isFreeTrialActive' | 'freeTrialStart' | 'freeTrialEnd' | 'isPaid' | 'createdAt'>
      & { content: (
        { __typename?: 'JobPostingContent' }
        & JobPostingContentFragment
      ) }
    ) }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingActiveProductQueryVariables = {
  productCode: Scalars['String'];
  productVersion: Scalars['Int'];
};


export type JobPostingActiveProductQuery = (
  { __typename?: 'Query' }
  & { jobPostingActiveProduct?: Maybe<(
    { __typename: 'JobPostingActiveProductSuccess' }
    & Pick<JobPostingActiveProductSuccess, 'hasFreeTrial' | 'postingPrice' | 'freeTrialDuration' | 'paidProductDuration' | 'productCode' | 'ruleMetric' | 'ruleLimit'>
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error' | 'message'>
  )> }
);

export type JobPostingApplyMutationVariables = {
  postingId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  resume: Scalars['Upload'];
  coverLetter?: Maybe<Scalars['Upload']>;
};


export type JobPostingApplyMutation = (
  { __typename?: 'Mutation' }
  & { jobPostingApply: (
    { __typename: 'JobPostingApplySuccess' }
    & Pick<JobPostingApplySuccess, 'status'>
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type MyJobPostingsPageJobPostingFragment = (
  { __typename?: 'JobPosting' }
  & Pick<JobPosting, 'id' | 'isSearchable' | 'isActive' | 'createdAt' | 'hadProduct' | 'hasProduct' | 'hasProductFrom' | 'hasProductUntil' | 'isFreeTrialJob' | 'isFreeTrialActive' | 'freeTrialStart' | 'freeTrialEnd' | 'isPaid' | 'impressions' | 'applications'>
  & { content: (
    { __typename?: 'JobPostingContent' }
    & JobPostingContentFragment
  ) }
);

export type JobPostingCountQueryVariables = {
  filter?: Maybe<JobPostingListFilter>;
};


export type JobPostingCountQuery = (
  { __typename?: 'Query' }
  & { jobPostingCount: (
    { __typename: 'JobPostingCountSuccess' }
    & Pick<JobPostingCountSuccess, 'total' | 'open' | 'closed' | 'draft' | 'hasActiveProduct'>
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingListQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<JobPostingListFilter>;
};


export type JobPostingListQuery = (
  { __typename?: 'Query' }
  & { jobPostingList: (
    { __typename: 'JobPostingListSuccess' }
    & { jobPostingList: Array<(
      { __typename?: 'JobPosting' }
      & MyJobPostingsPageJobPostingFragment
    )> }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingStatusUpdateMutationVariables = {
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isSearchable?: Maybe<Scalars['Boolean']>;
};


export type JobPostingStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & { jobPostingUpdate: (
    { __typename: 'JobPostingSuccess' }
    & { jobPosting: (
      { __typename?: 'JobPosting' }
      & Pick<JobPosting, 'id'>
    ) }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingChargeQueryVariables = {
  country: JobPostingCountry;
  product: Scalars['String'];
  version: Scalars['Int'];
  coupon?: Maybe<Scalars['String']>;
};


export type JobPostingChargeQuery = (
  { __typename?: 'Query' }
  & { jobPostingCharge: (
    { __typename: 'JobPostingChargeSuccess' }
    & { charge: (
      { __typename?: 'JobPostingCharge' }
      & Pick<JobPostingCharge, 'netAmount' | 'grossAmount' | 'discount' | 'currency' | 'validity'>
    ), freeTrial?: Maybe<(
      { __typename?: 'FreeTrial' }
      & Pick<FreeTrial, 'freeTrialEnd' | 'validityDays' | 'ruleMetric' | 'ruleLimit'>
    )> }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingOrderQueryVariables = {
  orderId?: Maybe<Scalars['ID']>;
  postingId?: Maybe<Scalars['ID']>;
};


export type JobPostingOrderQuery = (
  { __typename?: 'Query' }
  & { jobPostingOrder: (
    { __typename: 'JobPostingOrderSuccess' }
    & Pick<JobPostingOrderSuccess, 'billingEmailAddress'>
    & { order: (
      { __typename?: 'JobPostingOrder' }
      & Pick<JobPostingOrder, 'id' | 'status' | 'grossAmount' | 'netAmount' | 'discount' | 'paidOn' | 'paidAt' | 'isPaymentSuccessful' | 'validityDays' | 'postingPrice' | 'chargeEntity'>
      & { coupon?: Maybe<(
        { __typename?: 'VersionedEntity' }
        & Pick<VersionedEntity, 'code'>
      )>, items: Array<(
        { __typename?: 'JobPostingOrderItem' }
        & Pick<JobPostingOrderItem, 'unitPrice' | 'unitsRequested'>
        & { product: (
          { __typename?: 'VersionedEntity' }
          & Pick<VersionedEntity, 'code'>
        ) }
      )>, productConsumption?: Maybe<(
        { __typename?: 'JobPostingProductConsumption' }
        & Pick<JobPostingProductConsumption, 'activeFrom' | 'activeUntil'>
      )>, freeTrial?: Maybe<(
        { __typename?: 'FreeTrial' }
        & Pick<FreeTrial, 'isFreeTrialJob' | 'isFreeTrialActive' | 'freeTrialStart' | 'freeTrialEnd' | 'validityDays'>
      )>, paymentDeferral?: Maybe<(
        { __typename?: 'JobPostingPaymentDeferral' }
        & Pick<JobPostingPaymentDeferral, 'type' | 'chargeEarly' | 'ruleMetric' | 'ruleGrouping' | 'ruleLimit'>
      )> }
    ) }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type JobPostingReceiptListQueryVariables = {
  postingId: Scalars['ID'];
};


export type JobPostingReceiptListQuery = (
  { __typename?: 'Query' }
  & { jobPostingReceiptList: (
    { __typename: 'JobPostingReceiptListSuccess' }
    & { receipts?: Maybe<Array<(
      { __typename?: 'JobPostingReceipt' }
      & Pick<JobPostingReceipt, 'id' | 'discount' | 'amount' | 'paymentCharged' | 'paymentReceived' | 'isFreeTrialJob' | 'isFreeTrialActive' | 'freeTrialStart' | 'freeTrialEnd' | 'freeTrialValidity' | 'validity'>
      & { productConsumption?: Maybe<(
        { __typename?: 'JobPostingProductConsumption' }
        & Pick<JobPostingProductConsumption, 'activeFrom' | 'activeUntil'>
      )> }
    )>> }
  ) | (
    { __typename: 'JobPostingError' }
    & Pick<JobPostingError, 'error'>
  ) }
);

export type ChangeEmailMutationVariables = {
  newEmailAddress: Scalars['String'];
  newEmailAddressConfirmation: Scalars['String'];
};


export type ChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & { changeEmail: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserChangeEmailSuccess' }
    & Pick<UserChangeEmailSuccess, 'success'>
  ) | (
    { __typename: 'UserChangeEmailError' }
    & Pick<UserChangeEmailError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserChangeEmailInputErrors' }
      & Pick<UserChangeEmailInputErrors, 'newEmailAddress' | 'newEmailAddressConfirmation'>
    )> }
  ) }
);

export type ChangePasswordMutationVariables = {
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserChangePasswordSuccess' }
    & Pick<UserChangePasswordSuccess, 'success'>
  ) | (
    { __typename: 'UserChangePasswordError' }
    & Pick<UserChangePasswordError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserChangePasswordInputErrors' }
      & Pick<UserChangePasswordInputErrors, 'newPassword' | 'newPasswordConfirmation' | 'currentPassword'>
    )> }
  ) }
);

export type DeleteAccountMutationVariables = {
  emailAddress: Scalars['String'];
  password: Scalars['String'];
};


export type DeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccount: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserDeleteAccountSuccess' }
    & Pick<UserDeleteAccountSuccess, 'success'>
  ) | (
    { __typename: 'UserDeleteAccountError' }
    & Pick<UserDeleteAccountError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserDeleteAccountInputErrors' }
      & Pick<UserDeleteAccountInputErrors, 'emailAddress' | 'password'>
    )> }
  ) }
);

export type ForgotPasswordMutationVariables = {
  emailAddress: Scalars['String'];
};


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword: (
    { __typename: 'UserForgotPasswordSuccess' }
    & Pick<UserForgotPasswordSuccess, 'success'>
  ) | (
    { __typename: 'UserForgotPasswordError' }
    & { inputErrors?: Maybe<(
      { __typename?: 'UserForgotPasswordInputError' }
      & Pick<UserForgotPasswordInputError, 'emailAddress'>
    )> }
  ) }
);

export type LoginMutationVariables = {
  emailAddress: Scalars['String'];
  password: Scalars['String'];
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename: 'UserAlreadyLoggedInError' }
    & Pick<UserAlreadyLoggedInError, 'error'>
  ) | (
    { __typename: 'UserLoginSuccess' }
    & Pick<UserLoginSuccess, 'token'>
  ) | (
    { __typename: 'UserLoginError' }
    & Pick<UserLoginError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserLoginInputErrors' }
      & Pick<UserLoginInputErrors, 'emailAddress' | 'password'>
    )> }
  ) }
);

export type MasqueradeQueryVariables = {
  emailAddress: Scalars['String'];
};


export type MasqueradeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'masquerade'>
);

export type MeQueryVariables = {};


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'JoblistUser' }
    & Pick<JoblistUser, 'id' | 'shareId' | 'firstName' | 'lastName' | 'fullName' | 'emailAddress' | 'isVerified' | 'isSavedJobsListPublic' | 'savedJobsListName' | 'lastSigninAt' | 'createdAt' | 'showGamify' | 'loggedInRealm' | 'pendingEmailChange'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'name'>
    )>>>, quizData?: Maybe<(
      { __typename?: 'QuizData' }
      & Pick<QuizData, 'covid' | 'newGrad' | 'remote' | 'somethingElse'>
    )>, progress?: Maybe<(
      { __typename?: 'UserProgress' }
      & Pick<UserProgress, 'emailAlert' | 'signUp' | 'saveJobs' | 'inviteFriends'>
    )> }
  )> }
);

export type ResendEmailVerificationMutationVariables = {};


export type ResendEmailVerificationMutation = (
  { __typename?: 'Mutation' }
  & { resendEmailVerification: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserResendEmailVerificationSuccess' }
    & Pick<UserResendEmailVerificationSuccess, 'success'>
  ) | (
    { __typename: 'UserResendEmailVerificationError' }
    & Pick<UserResendEmailVerificationError, 'otherError'>
  ) }
);

export type ResetPasswordMutationVariables = {
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename: 'UserResetPasswordSuccess' }
    & Pick<UserResetPasswordSuccess, 'success'>
  ) | (
    { __typename: 'UserResetPasswordError' }
    & Pick<UserResetPasswordError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserResetPasswordInputError' }
      & Pick<UserResetPasswordInputError, 'token' | 'password' | 'passwordConfirmation'>
    )> }
  ) }
);

export type SignupMutationVariables = {
  emailAddress: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  tosAccepted?: Maybe<Scalars['Boolean']>;
  emailAccepted?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
};


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename: 'UserAlreadyLoggedInError' }
    & Pick<UserAlreadyLoggedInError, 'error'>
  ) | (
    { __typename: 'UserSignupSuccess' }
    & Pick<UserSignupSuccess, 'token'>
  ) | (
    { __typename: 'UserSignupError' }
    & Pick<UserSignupError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserSignupInputErrors' }
      & Pick<UserSignupInputErrors, 'emailAddress' | 'password' | 'passwordConfirmation' | 'tosAccepted' | 'emailAccepted'>
    )> }
  ) }
);

export type UpdateAccountMutationVariables = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserUpdateAccountSuccess' }
    & Pick<UserUpdateAccountSuccess, 'success'>
  ) | (
    { __typename: 'UserUpdateAccountError' }
    & { inputErrors?: Maybe<(
      { __typename?: 'UserUpdateAccountInputErrors' }
      & Pick<UserUpdateAccountInputErrors, 'firstName' | 'lastName'>
    )> }
  ) }
);

export type UpdateQuizDataMutationVariables = {
  covid: Scalars['Boolean'];
  newGrad: Scalars['Boolean'];
  remote: Scalars['Boolean'];
  somethingElse: Scalars['Boolean'];
};


export type UpdateQuizDataMutation = (
  { __typename?: 'Mutation' }
  & { updateQuizData: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserUpdateQuizDataSuccess' }
    & Pick<UserUpdateQuizDataSuccess, 'success'>
  ) | (
    { __typename: 'UserUpdateQuizDataError' }
    & Pick<UserUpdateQuizDataError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserUpdateQuizDataInputErrors' }
      & Pick<UserUpdateQuizDataInputErrors, 'covid' | 'newGrad' | 'remote' | 'somethingElse'>
    )> }
  ) }
);

export type UserCancelPendingEmailChangeMutationVariables = {};


export type UserCancelPendingEmailChangeMutation = (
  { __typename?: 'Mutation' }
  & { userCancelPendingEmailChange: (
    { __typename?: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename?: 'UserCancelPendingEmailChangeSuccess' }
    & Pick<UserCancelPendingEmailChangeSuccess, 'success'>
  ) | (
    { __typename?: 'UserCancelPendingEmailChangeError' }
    & Pick<UserCancelPendingEmailChangeError, 'otherError'>
  ) }
);

export type UserChangeLoggedInRealmMutationVariables = {
  loggedInRealm: UserLoggedInRealm;
};


export type UserChangeLoggedInRealmMutation = (
  { __typename?: 'Mutation' }
  & { userChangeLoggedInRealm: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserChangeLoggedInRealmSuccess' }
    & Pick<UserChangeLoggedInRealmSuccess, 'success'>
  ) | (
    { __typename: 'UserChangeLoggedInRealmError' }
    & Pick<UserChangeLoggedInRealmError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserChangeLoggedInRealmInputError' }
      & Pick<UserChangeLoggedInRealmInputError, 'loggedInRealm'>
    )> }
  ) }
);

export type VerifyEmailMutationVariables = {
  token: Scalars['String'];
};


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail: (
    { __typename: 'UserVerifyEmailSuccess' }
    & Pick<UserVerifyEmailSuccess, 'success'>
  ) | (
    { __typename: 'UserVerifyEmailError' }
    & Pick<UserVerifyEmailError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'UserVerifyEmailInputError' }
      & Pick<UserVerifyEmailInputError, 'token'>
    )> }
  ) }
);

export type ArticleQueryVariables = {
  slug: Scalars['String'];
};


export type ArticleQuery = (
  { __typename?: 'Query' }
  & { entry?: Maybe<{ __typename?: 'Presspage' } | (
    { __typename?: 'Blog' }
    & Pick<Blog, 'title' | 'seoTitleTag' | 'dateCreated' | 'postDate' | 'id' | 'uri' | 'description' | 'tags'>
    & { heroImage?: Maybe<Array<Maybe<(
      { __typename?: 'JoblistcontentqaVolume' }
      & Pick<JoblistcontentqaVolume, 'url'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'BlogCategory' }
      & Pick<BlogCategory, 'title' | 'uri'>
    )>>>, articleBody?: Maybe<Array<Maybe<(
      { __typename: 'ArticleBodyNewsection' }
      & Pick<ArticleBodyNewsection, 'sectionheading'>
    ) | (
      { __typename: 'ArticleBodyHeading' }
      & Pick<ArticleBodyHeading, 'heading'>
    ) | (
      { __typename: 'ArticleBodyText' }
      & Pick<ArticleBodyText, 'position'>
      & { text?: Maybe<(
        { __typename: 'RedactorFieldData' }
        & Pick<RedactorFieldData, 'totalPages' | 'content'>
      )> }
    ) | (
      { __typename: 'ArticleBodyPullquote' }
      & Pick<ArticleBodyPullquote, 'pullquote' | 'position'>
    ) | (
      { __typename: 'ArticleBodyImage' }
      & Pick<ArticleBodyImage, 'alttext' | 'position'>
      & { image?: Maybe<Array<Maybe<(
        { __typename?: 'JoblistcontentqaVolume' }
        & Pick<JoblistcontentqaVolume, 'url'>
      )>>>, caption?: Maybe<(
        { __typename: 'RedactorFieldData' }
        & Pick<RedactorFieldData, 'totalPages' | 'content'>
      )> }
    ) | (
      { __typename: 'ArticleBodyQuote' }
      & Pick<ArticleBodyQuote, 'quote' | 'attribution' | 'position'>
    ) | (
      { __typename: 'ArticleBodySearchcta' }
      & Pick<ArticleBodySearchcta, 'headline' | 'searchctaurl' | 'searchctatext'>
    )>>> }
  )> }
);

export type CategoriesConnectionQueryVariables = {
  slug?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
};


export type CategoriesConnectionQuery = (
  { __typename?: 'Query' }
  & { categoriesConnection?: Maybe<(
    { __typename?: 'CategoryConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEdge' }
      & { relatedEntries?: Maybe<(
        { __typename?: 'EntryConnection' }
        & Pick<EntryConnection, 'totalCount'>
        & { entries?: Maybe<Array<Maybe<{ __typename: 'Presspage' } | (
          { __typename: 'Blog' }
          & Pick<Blog, 'id' | 'uri' | 'title' | 'description'>
          & { heroImage?: Maybe<Array<Maybe<(
            { __typename?: 'JoblistcontentqaVolume' }
            & Pick<JoblistcontentqaVolume, 'url'>
          )>>>, categories?: Maybe<Array<Maybe<(
            { __typename?: 'BlogCategory' }
            & Pick<BlogCategory, 'title' | 'uri'>
          )>>> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type DraftArticleQueryVariables = {
  draftId: Scalars['Int'];
  slug: Scalars['String'];
};


export type DraftArticleQuery = (
  { __typename?: 'Query' }
  & { draft?: Maybe<{ __typename?: 'Presspage' } | (
    { __typename?: 'Blog' }
    & Pick<Blog, 'title' | 'dateCreated' | 'postDate' | 'id' | 'uri' | 'description' | 'tags'>
    & { heroImage?: Maybe<Array<Maybe<(
      { __typename?: 'JoblistcontentqaVolume' }
      & Pick<JoblistcontentqaVolume, 'url'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'BlogCategory' }
      & Pick<BlogCategory, 'title' | 'uri'>
    )>>>, articleBody?: Maybe<Array<Maybe<(
      { __typename: 'ArticleBodyNewsection' }
      & Pick<ArticleBodyNewsection, 'sectionheading'>
    ) | (
      { __typename: 'ArticleBodyHeading' }
      & Pick<ArticleBodyHeading, 'heading'>
    ) | (
      { __typename: 'ArticleBodyText' }
      & Pick<ArticleBodyText, 'position'>
      & { text?: Maybe<(
        { __typename: 'RedactorFieldData' }
        & Pick<RedactorFieldData, 'totalPages' | 'content'>
      )> }
    ) | (
      { __typename: 'ArticleBodyPullquote' }
      & Pick<ArticleBodyPullquote, 'pullquote' | 'position'>
    ) | (
      { __typename: 'ArticleBodyImage' }
      & Pick<ArticleBodyImage, 'alttext' | 'position'>
      & { image?: Maybe<Array<Maybe<(
        { __typename?: 'JoblistcontentqaVolume' }
        & Pick<JoblistcontentqaVolume, 'url'>
      )>>>, caption?: Maybe<(
        { __typename: 'RedactorFieldData' }
        & Pick<RedactorFieldData, 'totalPages' | 'content'>
      )> }
    ) | (
      { __typename: 'ArticleBodyQuote' }
      & Pick<ArticleBodyQuote, 'quote' | 'attribution' | 'position'>
    ) | { __typename?: 'ArticleBodySearchcta' }>>> }
  )> }
);

export type EntriesQueryVariables = {
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type EntriesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'entryCount'>
  & { entries?: Maybe<Array<Maybe<{ __typename: 'Presspage' } | (
    { __typename: 'Blog' }
    & Pick<Blog, 'id' | 'uri' | 'title' | 'description'>
    & { heroImage?: Maybe<Array<Maybe<(
      { __typename?: 'JoblistcontentqaVolume' }
      & Pick<JoblistcontentqaVolume, 'url'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'BlogCategory' }
      & Pick<BlogCategory, 'title' | 'uri'>
    )>>> }
  )>>> }
);

export type RelatedArticlesQueryVariables = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  idNot?: Maybe<Scalars['Int']>;
};


export type RelatedArticlesQuery = (
  { __typename?: 'Query' }
  & { entries?: Maybe<Array<Maybe<{ __typename?: 'Presspage' } | (
    { __typename?: 'Blog' }
    & Pick<Blog, 'id' | 'title' | 'uri' | 'tags'>
    & { heroImage?: Maybe<Array<Maybe<(
      { __typename?: 'JoblistcontentqaVolume' }
      & Pick<JoblistcontentqaVolume, 'url'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'BlogCategory' }
      & Pick<BlogCategory, 'title' | 'uri'>
    )>>> }
  )>>> }
);

export type BrowseJobsQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateLimit?: Maybe<Scalars['Int']>;
  stateOffset?: Maybe<Scalars['Int']>;
  stateThreshold?: Maybe<Scalars['Int']>;
  city?: Maybe<BrowseCityInput>;
  cityLimit?: Maybe<Scalars['Int']>;
  cityOffset?: Maybe<Scalars['Int']>;
  cityThreshold?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  industryLimit?: Maybe<Scalars['Int']>;
  industryOffset?: Maybe<Scalars['Int']>;
  industryThreshold?: Maybe<Scalars['Int']>;
  role?: Maybe<BrowseRoleInput>;
  roleLimit?: Maybe<Scalars['Int']>;
  roleOffset?: Maybe<Scalars['Int']>;
  roleThreshold?: Maybe<Scalars['Int']>;
};


export type BrowseJobsQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & Pick<BrowseResult, 'country' | 'industry'>
    & { state?: Maybe<Array<(
      { __typename?: 'BrowseState' }
      & Pick<BrowseState, 'state' | 'stateCode'>
    )>>, city?: Maybe<Array<(
      { __typename?: 'BrowseCity' }
      & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
    )>>, role?: Maybe<Array<(
      { __typename?: 'BrowseRole' }
      & Pick<BrowseRole, 'role' | 'industry'>
    )>>, roleContent?: Maybe<(
      { __typename?: 'BrowseRoleContent' }
      & Pick<BrowseRoleContent, 'keyword' | 'description' | 'responsibilities' | 'payinfo' | 'requirements' | 'skills' | 'icons' | 'team'>
    )>, cityContent?: Maybe<(
      { __typename?: 'BrowseCityContent' }
      & Pick<BrowseCityContent, 'location' | 'description' | 'facts'>
    )> }
  ) }
);

export type BrowseNearbyCitiesQueryVariables = {
  country?: Maybe<Scalars['String']>;
  city: BrowseCityInput;
  radius: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type BrowseNearbyCitiesQuery = (
  { __typename?: 'Query' }
  & { browseNearbyCities: Array<(
    { __typename?: 'BrowseCity' }
    & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
  )> }
);

export type BrowseStatsQueryQueryVariables = {
  city: BrowseCityInput;
  numCompanies: Scalars['Int'];
  jobCountPrecision: Scalars['Int'];
};


export type BrowseStatsQueryQuery = (
  { __typename?: 'Query' }
  & { browseStats: (
    { __typename?: 'BrowseStats' }
    & Pick<BrowseStats, 'companies' | 'jobCount' | 'companyCount'>
  ) }
);

export type CityIndustryRoleQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  cityLimit?: Maybe<Scalars['Int']>;
  cityOffset?: Maybe<Scalars['Int']>;
  cityThreshold?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  industryLimit?: Maybe<Scalars['Int']>;
  industryOffset?: Maybe<Scalars['Int']>;
  industryThreshold?: Maybe<Scalars['Int']>;
  role?: Maybe<BrowseRoleInput>;
  roleLimit?: Maybe<Scalars['Int']>;
  roleOffset?: Maybe<Scalars['Int']>;
  roleThreshold?: Maybe<Scalars['Int']>;
};


export type CityIndustryRoleQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & Pick<BrowseResult, 'country' | 'industry'>
    & { city?: Maybe<Array<(
      { __typename?: 'BrowseCity' }
      & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
    )>>, role?: Maybe<Array<(
      { __typename?: 'BrowseRole' }
      & Pick<BrowseRole, 'role' | 'industry'>
    )>> }
  ) }
);

export type CityIndustryStateQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  cityLimit?: Maybe<Scalars['Int']>;
  cityOffset?: Maybe<Scalars['Int']>;
  cityThreshold?: Maybe<Scalars['Int']>;
  industryLimit?: Maybe<Scalars['Int']>;
  industryOffset?: Maybe<Scalars['Int']>;
  industryThreshold?: Maybe<Scalars['Int']>;
  stateLimit?: Maybe<Scalars['Int']>;
  stateOffset?: Maybe<Scalars['Int']>;
  stateThreshold?: Maybe<Scalars['Int']>;
};


export type CityIndustryStateQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & Pick<BrowseResult, 'country' | 'industry'>
    & { city?: Maybe<Array<(
      { __typename?: 'BrowseCity' }
      & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
    )>>, state?: Maybe<Array<(
      { __typename?: 'BrowseState' }
      & Pick<BrowseState, 'state' | 'stateCode'>
    )>>, roleContent?: Maybe<(
      { __typename?: 'BrowseRoleContent' }
      & Pick<BrowseRoleContent, 'keyword' | 'description' | 'responsibilities' | 'payinfo' | 'requirements' | 'skills' | 'icons' | 'team'>
    )>, cityContent?: Maybe<(
      { __typename?: 'BrowseCityContent' }
      & Pick<BrowseCityContent, 'location' | 'description' | 'facts'>
    )> }
  ) }
);

export type CityQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  cityLimit?: Maybe<Scalars['Int']>;
  cityOffset?: Maybe<Scalars['Int']>;
  cityThreshold?: Maybe<Scalars['Int']>;
};


export type CityQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & { city?: Maybe<Array<(
      { __typename?: 'BrowseCity' }
      & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
    )>> }
  ) }
);

export type CityRoleQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  cityLimit?: Maybe<Scalars['Int']>;
  cityOffset?: Maybe<Scalars['Int']>;
  cityThreshold?: Maybe<Scalars['Int']>;
  roleLimit?: Maybe<Scalars['Int']>;
  roleOffset?: Maybe<Scalars['Int']>;
  roleThreshold?: Maybe<Scalars['Int']>;
};


export type CityRoleQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & { city?: Maybe<Array<(
      { __typename?: 'BrowseCity' }
      & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
    )>>, role?: Maybe<Array<(
      { __typename?: 'BrowseRole' }
      & Pick<BrowseRole, 'role' | 'industry'>
    )>> }
  ) }
);

export type CityRoleStateQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  cityLimit?: Maybe<Scalars['Int']>;
  cityOffset?: Maybe<Scalars['Int']>;
  cityThreshold?: Maybe<Scalars['Int']>;
  stateLimit?: Maybe<Scalars['Int']>;
  stateOffset?: Maybe<Scalars['Int']>;
  stateThreshold?: Maybe<Scalars['Int']>;
  roleLimit?: Maybe<Scalars['Int']>;
  roleOffset?: Maybe<Scalars['Int']>;
  roleThreshold?: Maybe<Scalars['Int']>;
};


export type CityRoleStateQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & Pick<BrowseResult, 'country'>
    & { city?: Maybe<Array<(
      { __typename?: 'BrowseCity' }
      & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
    )>>, role?: Maybe<Array<(
      { __typename?: 'BrowseRole' }
      & Pick<BrowseRole, 'role' | 'industry'>
    )>>, state?: Maybe<Array<(
      { __typename?: 'BrowseState' }
      & Pick<BrowseState, 'state' | 'stateCode'>
    )>> }
  ) }
);

export type FetchCityDataQueryVariables = {
  city: Scalars['String'];
  state: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};


export type FetchCityDataQuery = (
  { __typename?: 'Query' }
  & { fetchCityData?: Maybe<(
    { __typename: 'CityData' }
    & Pick<CityData, 'description' | 'population' | 'coli' | 'minimum_wage' | 'tipped_wage' | 'state_uses_federal_wages' | 'state_has_district_wages' | 'city' | 'state' | 'state_code' | 'country'>
  )> }
);

export type FetchJobDataQueryVariables = {
  city: Scalars['String'];
  state: Scalars['String'];
  role: Scalars['String'];
};


export type FetchJobDataQuery = (
  { __typename?: 'Query' }
  & { fetchJobData: (
    { __typename: 'JobData' }
    & Pick<JobData, 'low_salary' | 'average_salary' | 'high_salary' | 'hourly_salary' | 'content'>
  ) }
);

export type IndustryQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  industryLimit?: Maybe<Scalars['Int']>;
  industryOffset?: Maybe<Scalars['Int']>;
  industryThreshold?: Maybe<Scalars['Int']>;
};


export type IndustryQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & Pick<BrowseResult, 'industry'>
  ) }
);

export type JobCountQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type JobCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'browseJobCount'>
);

export type NearbyCitiesQueryVariables = {
  country?: Maybe<Scalars['String']>;
  city: BrowseCityInput;
  radius: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type NearbyCitiesQuery = (
  { __typename?: 'Query' }
  & { browseNearbyCities: Array<(
    { __typename?: 'BrowseCity' }
    & Pick<BrowseCity, 'city' | 'state' | 'stateCode'>
  )> }
);

export type NearByStatesQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  radius: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  threshold?: Maybe<Scalars['Int']>;
};


export type NearByStatesQuery = (
  { __typename?: 'Query' }
  & { browseNearbyStates: Array<(
    { __typename?: 'BrowseState' }
    & Pick<BrowseState, 'state' | 'stateCode'>
  )> }
);

export type NormalizeBrowseArgsQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
};


export type NormalizeBrowseArgsQuery = (
  { __typename?: 'Query' }
  & { normalizeBrowseArgs: (
    { __typename?: 'NormalBrowseArgs' }
    & Pick<NormalBrowseArgs, 'country' | 'state' | 'city' | 'industry' | 'role'>
  ) }
);

export type RelatedRoleQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  industryLimit?: Maybe<Scalars['Int']>;
  industryOffset?: Maybe<Scalars['Int']>;
  industryThreshold?: Maybe<Scalars['Int']>;
  relatedRoleLimit?: Maybe<Scalars['Int']>;
  relatedRoleOffset?: Maybe<Scalars['Int']>;
  relatedRoleThreshold?: Maybe<Scalars['Int']>;
};


export type RelatedRoleQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename: 'BrowseResult' }
    & Pick<BrowseResult, 'industry'>
    & { relatedRole?: Maybe<Array<(
      { __typename?: 'BrowseRole' }
      & Pick<BrowseRole, 'role'>
    )>> }
  ) }
);

export type RoleQueryVariables = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<BrowseCityInput>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<BrowseRoleInput>;
  roleLimit?: Maybe<Scalars['Int']>;
  roleOffset?: Maybe<Scalars['Int']>;
  roleThreshold?: Maybe<Scalars['Int']>;
};


export type RoleQuery = (
  { __typename?: 'Query' }
  & { browseJobs: (
    { __typename?: 'BrowseResult' }
    & { role?: Maybe<Array<(
      { __typename?: 'BrowseRole' }
      & Pick<BrowseRole, 'role' | 'industry'>
    )>> }
  ) }
);

export type TopCompaniesQueryVariables = {
  companyQuery: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type TopCompaniesQuery = (
  { __typename?: 'Query' }
  & { topCompanies: Array<(
    { __typename?: 'CompanyJobCount' }
    & Pick<CompanyJobCount, 'company' | 'count'>
  )> }
);

export type TopJobTitlesQueryVariables = {
  jobTitlesQuery: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
};


export type TopJobTitlesQuery = (
  { __typename?: 'Query' }
  & { topJobTitles: Array<(
    { __typename?: 'TitleJobCount' }
    & Pick<TitleJobCount, 'title' | 'count'>
  )> }
);

export type CollaboratorInviteMutationVariables = {
  jobCollectionId: Scalars['String'];
  emailAddress: Scalars['String'];
};


export type CollaboratorInviteMutation = (
  { __typename?: 'Mutation' }
  & { collaboratorInvite: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'UserUnauthorizedError' }
    & Pick<UserUnauthorizedError, 'unauthorizedError'>
  ) | (
    { __typename: 'CollaboratorInviteSuccess' }
    & Pick<CollaboratorInviteSuccess, 'success'>
    & { invitedUser: (
      { __typename?: 'InvitedUser' }
      & JobCollectionPageInvitedUserFragment
    ) }
  ) | (
    { __typename: 'CollaboratorInviteError' }
    & Pick<CollaboratorInviteError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'CollaboratorInviteInputErrors' }
      & Pick<CollaboratorInviteInputErrors, 'jobCollectionId' | 'emailAddress'>
    )> }
  ) }
);

export type CollaboratorRemoveMutationVariables = {
  id: Scalars['ID'];
};


export type CollaboratorRemoveMutation = (
  { __typename?: 'Mutation' }
  & { collaboratorRemove: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'CollaboratorRemoveSuccess' }
    & Pick<CollaboratorRemoveSuccess, 'success'>
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & JobCollectionPageJobCollectionFragment
    ) }
  ) | (
    { __typename: 'CollaboratorRemoveError' }
    & Pick<CollaboratorRemoveError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'CollaboratorRemoveInputErrors' }
      & Pick<CollaboratorRemoveInputErrors, 'id'>
    )> }
  ) }
);

export type InviteAcceptMutationVariables = {
  id: Scalars['ID'];
};


export type InviteAcceptMutation = (
  { __typename?: 'Mutation' }
  & { inviteAccept: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'InviteAcceptSuccess' }
    & Pick<InviteAcceptSuccess, 'success'>
  ) | (
    { __typename: 'InviteAcceptError' }
    & Pick<InviteAcceptError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'InviteAcceptInputErrors' }
      & Pick<InviteAcceptInputErrors, 'id'>
    )> }
  ) }
);

export type InviteFetchQueryVariables = {
  token: Scalars['String'];
};


export type InviteFetchQuery = (
  { __typename?: 'Query' }
  & { inviteFetch: (
    { __typename: 'InviteFetchSuccess' }
    & { invite?: Maybe<(
      { __typename?: 'InvitedUser' }
      & Pick<InvitedUser, 'emailAddress' | 'id'>
      & { invitedByUser?: Maybe<(
        { __typename?: 'JoblistUser' }
        & Pick<JoblistUser, 'emailAddress' | 'firstName' | 'lastName'>
      )>, jobCollection: (
        { __typename?: 'JobCollection' }
        & Pick<JobCollection, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type InviteIgnoreMutationVariables = {
  id: Scalars['ID'];
};


export type InviteIgnoreMutation = (
  { __typename?: 'Mutation' }
  & { inviteIgnore: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'InviteIgnoreSuccess' }
    & Pick<InviteIgnoreSuccess, 'success'>
  ) | (
    { __typename: 'InviteIgnoreError' }
    & Pick<InviteIgnoreError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'InviteIgnoreInputErrors' }
      & Pick<InviteIgnoreInputErrors, 'id'>
    )> }
  ) }
);

export type InviteRemoveMutationVariables = {
  id: Scalars['ID'];
};


export type InviteRemoveMutation = (
  { __typename?: 'Mutation' }
  & { inviteRemove: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'InviteRemoveSuccess' }
    & Pick<InviteRemoveSuccess, 'success'>
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & Pick<JobCollection, 'id'>
      & { invitedUsers?: Maybe<Array<(
        { __typename?: 'InvitedUser' }
        & JobCollectionPageInvitedUserFragment
      )>> }
    ) }
  ) | (
    { __typename: 'InviteRemoveError' }
    & Pick<InviteRemoveError, 'otherError'>
  ) }
);

export type JobCollectionDeleteMutationVariables = {
  id: Scalars['ID'];
};


export type JobCollectionDeleteMutation = (
  { __typename?: 'Mutation' }
  & { jobCollectionDelete: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionDeleteSuccess' }
    & Pick<JobCollectionDeleteSuccess, 'success'>
  ) | (
    { __typename: 'JobCollectionDeleteError' }
    & Pick<JobCollectionDeleteError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionDeleteInputErrors' }
      & Pick<JobCollectionDeleteInputErrors, 'id'>
    )> }
  ) }
);

export type JobCollectionChangePermsMutationVariables = {
  id: Scalars['ID'];
  editTitle: Scalars['Boolean'];
  save: Scalars['Boolean'];
  unsave: Scalars['Boolean'];
  share: Scalars['Boolean'];
  invite: Scalars['Boolean'];
};


export type JobCollectionChangePermsMutation = (
  { __typename?: 'Mutation' }
  & { jobCollectionChangePerms: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionChangePermsSuccess' }
    & Pick<JobCollectionChangePermsSuccess, 'success'>
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & Pick<JobCollection, 'id'>
      & { permissions?: Maybe<(
        { __typename?: 'JobCollectionPerms' }
        & Pick<JobCollectionPerms, 'editTitle' | 'save' | 'unsave' | 'enablePublicLink' | 'invite'>
      )> }
    ) }
  ) | (
    { __typename: 'JobCollectionChangePermsError' }
    & Pick<JobCollectionChangePermsError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionChangePermsInputErrors' }
      & Pick<JobCollectionChangePermsInputErrors, 'id' | 'editTitle' | 'save' | 'unsave' | 'share' | 'invite'>
    )> }
  ) }
);

export type JobCollectionChangeTitleMutationVariables = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type JobCollectionChangeTitleMutation = (
  { __typename?: 'Mutation' }
  & { jobCollectionChangeTitle: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionChangeTitleSuccess' }
    & Pick<JobCollectionChangeTitleSuccess, 'success'>
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & Pick<JobCollection, 'id' | 'name'>
    ) }
  ) | (
    { __typename: 'JobCollectionChangeTitleError' }
    & Pick<JobCollectionChangeTitleError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionChangeTitleInputErrors' }
      & Pick<JobCollectionChangeTitleInputErrors, 'id' | 'name'>
    )> }
  ) }
);

export type JobCollectionToggleSharingMutationVariables = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type JobCollectionToggleSharingMutation = (
  { __typename?: 'Mutation' }
  & { jobCollectionToggleSharing: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionToggleSharingSuccess' }
    & Pick<JobCollectionToggleSharingSuccess, 'success'>
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & Pick<JobCollection, 'id' | 'isPublic'>
    ) }
  ) | (
    { __typename: 'JobCollectionToggleSharingError' }
    & Pick<JobCollectionToggleSharingError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionToggleSharingInputErrors' }
      & Pick<JobCollectionToggleSharingInputErrors, 'id' | 'value'>
    )> }
  ) }
);

export type JobUnsaveMutationVariables = {
  provider: Scalars['String'];
  providerId: Scalars['String'];
  jobCollectionIdsToRemove: Array<Scalars['String']>;
};


export type JobUnsaveMutation = (
  { __typename?: 'Mutation' }
  & { jobUnsave: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobUnsaveSuccess' }
    & Pick<JobUnsaveSuccess, 'success'>
    & { jobCollectionMods: Array<(
      { __typename?: 'JobCollectionMod' }
      & Pick<JobCollectionMod, 'modType'>
      & { jobCollection: (
        { __typename?: 'JobCollection' }
        & Pick<JobCollection, 'id'>
      ), jobCollectionItem: (
        { __typename?: 'JobCollectionItem' }
        & Pick<JobCollectionItem, 'id'>
      ) }
    )> }
  ) | (
    { __typename: 'JobUnsaveError' }
    & Pick<JobUnsaveError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobUnsaveInputErrors' }
      & Pick<JobUnsaveInputErrors, 'provider' | 'providerId' | 'jobCollectionIdsToRemove'>
    )> }
  ) }
);

export type JobCollectionPageCollaboratorFragment = (
  { __typename?: 'JobCollectionCollaborator' }
  & Pick<JobCollectionCollaborator, 'id' | 'name' | 'emailAddress' | 'me' | 'isOwner' | 'jobCount'>
  & { invitedBy?: Maybe<(
    { __typename?: 'InvitedBy' }
    & Pick<InvitedBy, 'name' | 'emailAddress' | 'me'>
  )> }
);

export type JobCollectionPageInvitedUserFragment = (
  { __typename?: 'InvitedUser' }
  & Pick<InvitedUser, 'id' | 'emailAddress'>
  & { invitedByUser?: Maybe<(
    { __typename?: 'JoblistUser' }
    & Pick<JoblistUser, 'id' | 'fullName' | 'firstName' | 'lastName' | 'emailAddress'>
  )> }
);

export type JobCollectionPageJobCollectionFragment = (
  { __typename?: 'JobCollection' }
  & Pick<JobCollection, 'id' | 'name' | 'jobsCount' | 'locationsCount' | 'isPublic' | 'isOwner' | 'impressionId'>
  & { createdByUser?: Maybe<(
    { __typename?: 'JoblistUser' }
    & Pick<JoblistUser, 'id' | 'shareId' | 'fullName' | 'firstName' | 'lastName' | 'emailAddress' | 'isVerified' | 'isSavedJobsListPublic' | 'savedJobsListName' | 'createdAt' | 'lastSigninAt'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'name'>
    )>>>, quizData?: Maybe<(
      { __typename?: 'QuizData' }
      & Pick<QuizData, 'covid' | 'newGrad' | 'remote' | 'somethingElse'>
    )> }
  )>, permissions?: Maybe<(
    { __typename?: 'JobCollectionPerms' }
    & Pick<JobCollectionPerms, 'editTitle' | 'save' | 'unsave' | 'enablePublicLink' | 'invite'>
  )>, jobs: Array<(
    { __typename?: 'JobCollectionItem' }
    & Pick<JobCollectionItem, 'addedBy' | 'id'>
    & { job: (
      { __typename?: 'Job' }
      & Pick<Job, 'url'>
      & JobFieldsFragmentFragment
    ) }
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'JobCollectionCollaborator' }
    & JobCollectionPageCollaboratorFragment
  )>>, invitedUsers?: Maybe<Array<(
    { __typename?: 'InvitedUser' }
    & JobCollectionPageInvitedUserFragment
  )>> }
);

export type JobCollectionPageJobCollectionQueryVariables = {
  id: Scalars['ID'];
};


export type JobCollectionPageJobCollectionQuery = (
  { __typename?: 'Query' }
  & { jobCollection: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | { __typename: 'UserUnauthorizedError' } | (
    { __typename: 'JobCollectionSuccess' }
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & JobCollectionPageJobCollectionFragment
    ) }
  ) | (
    { __typename: 'JobCollectionError' }
    & Pick<JobCollectionError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionInputErrors' }
      & Pick<JobCollectionInputErrors, 'id'>
    )> }
  ) }
);

export type JobCollectionGetNewUrlQueryVariables = {
  shareId: Scalars['String'];
};


export type JobCollectionGetNewUrlQuery = (
  { __typename?: 'Query' }
  & { jobCollectionGetNewURL: (
    { __typename: 'JobCollectionGetNewURLSuccess' }
    & Pick<JobCollectionGetNewUrlSuccess, 'id'>
  ) }
);

export type MyJoblistsPageJobCollectionFragment = (
  { __typename?: 'JobCollection' }
  & Pick<JobCollection, 'id' | 'name' | 'jobsCount' | 'isOwner' | 'isPublic'>
  & { inviter?: Maybe<(
    { __typename?: 'JoblistUser' }
    & Pick<JoblistUser, 'id' | 'fullName' | 'emailAddress'>
  )>, myInvite?: Maybe<(
    { __typename?: 'InvitedUser' }
    & Pick<InvitedUser, 'id'>
  )> }
);

export type JobCollectionCreatedResponseFragment = (
  { __typename?: 'JobCollection' }
  & Pick<JobCollection, 'id' | 'name' | 'jobsCount' | 'locationsCount' | 'isOwner' | 'isPublic'>
  & { inviter?: Maybe<(
    { __typename?: 'JoblistUser' }
    & Pick<JoblistUser, 'id' | 'fullName' | 'emailAddress'>
  )>, createdByUser?: Maybe<(
    { __typename?: 'JoblistUser' }
    & Pick<JoblistUser, 'id' | 'fullName' | 'firstName' | 'emailAddress'>
  )>, jobs: Array<(
    { __typename?: 'JobCollectionItem' }
    & SearchPageJobCollectionItemFragment
  )>, permissions?: Maybe<(
    { __typename?: 'JobCollectionPerms' }
    & Pick<JobCollectionPerms, 'editTitle' | 'save' | 'unsave' | 'enablePublicLink' | 'invite'>
  )>, myInvite?: Maybe<(
    { __typename?: 'InvitedUser' }
    & Pick<InvitedUser, 'id' | 'emailAddress'>
    & { invitedByUser?: Maybe<(
      { __typename?: 'JoblistUser' }
      & Pick<JoblistUser, 'id' | 'firstName' | 'emailAddress'>
    )> }
  )> }
);

export type MyJoblistsPageJobCollectionCreateMutationVariables = {
  name: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  routing?: Maybe<Scalars['String']>;
};


export type MyJoblistsPageJobCollectionCreateMutation = (
  { __typename?: 'Mutation' }
  & { jobCollectionCreate: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionCreateSuccess' }
    & Pick<JobCollectionCreateSuccess, 'success'>
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & JobCollectionCreatedResponseFragment
    ) }
  ) | (
    { __typename: 'JobCollectionCreateError' }
    & Pick<JobCollectionCreateError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionCreateInputErrors' }
      & Pick<JobCollectionCreateInputErrors, 'name'>
    )> }
  ) }
);

export type MyJoblistsPageJobCollectionListQueryVariables = {
  ownedBy: JobCollectionListOwnedByEnum;
  saveable: JobCollectionListSaveableEnum;
  status: JobCollectionListInviteStatusEnum;
};


export type MyJoblistsPageJobCollectionListQuery = (
  { __typename?: 'Query' }
  & { jobCollectionList: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionListSuccess' }
    & { jobCollections: Array<(
      { __typename?: 'JobCollection' }
      & MyJoblistsPageJobCollectionFragment
    )> }
  ) | (
    { __typename: 'JobCollectionListError' }
    & Pick<JobCollectionListError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionListInputErrors' }
      & Pick<JobCollectionListInputErrors, 'ownedBy' | 'saveable'>
    )> }
  ) }
);

export type SearchPageJobCollectionFragment = (
  { __typename?: 'JobCollection' }
  & Pick<JobCollection, 'id' | 'name' | 'jobsCount' | 'locationsCount' | 'isOwner' | 'isPublic'>
  & { createdByUser?: Maybe<(
    { __typename?: 'JoblistUser' }
    & Pick<JoblistUser, 'id' | 'fullName' | 'firstName' | 'emailAddress'>
  )>, permissions?: Maybe<(
    { __typename?: 'JobCollectionPerms' }
    & Pick<JobCollectionPerms, 'editTitle' | 'save' | 'unsave' | 'enablePublicLink' | 'invite'>
  )>, jobs: Array<(
    { __typename?: 'JobCollectionItem' }
    & SearchPageJobCollectionItemFragment
  )> }
);

export type SearchPageJobCollectionItemFragment = (
  { __typename?: 'JobCollectionItem' }
  & Pick<JobCollectionItem, 'addedBy' | 'id'>
  & { job: (
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  ) }
);

export type SearchPageJobCollectionCreateMutationVariables = {
  name: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  routing?: Maybe<Scalars['String']>;
};


export type SearchPageJobCollectionCreateMutation = (
  { __typename?: 'Mutation' }
  & { jobCollectionCreate: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionCreateSuccess' }
    & Pick<JobCollectionCreateSuccess, 'success'>
    & { jobCollection: (
      { __typename?: 'JobCollection' }
      & SearchPageJobCollectionFragment
    ) }
  ) | (
    { __typename: 'JobCollectionCreateError' }
    & Pick<JobCollectionCreateError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionCreateInputErrors' }
      & Pick<JobCollectionCreateInputErrors, 'name'>
    )> }
  ) }
);

export type SearchPageJobCollectionListQueryVariables = {
  ownedBy: JobCollectionListOwnedByEnum;
  saveable: JobCollectionListSaveableEnum;
  status: JobCollectionListInviteStatusEnum;
};


export type SearchPageJobCollectionListQuery = (
  { __typename?: 'Query' }
  & { jobCollectionList: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobCollectionListSuccess' }
    & { jobCollections: Array<(
      { __typename?: 'JobCollection' }
      & SearchPageJobCollectionFragment
    )> }
  ) | (
    { __typename: 'JobCollectionListError' }
    & Pick<JobCollectionListError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobCollectionListInputErrors' }
      & Pick<JobCollectionListInputErrors, 'ownedBy' | 'saveable'>
    )> }
  ) }
);

export type SearchPageJobSaveMutationVariables = {
  provider: Scalars['String'];
  providerId: Scalars['String'];
  routing?: Maybe<Scalars['String']>;
  jobCollectionIdsToSave: Array<Scalars['String']>;
  jobCollectionIdsToRemove: Array<Scalars['String']>;
};


export type SearchPageJobSaveMutation = (
  { __typename?: 'Mutation' }
  & { jobSave: (
    { __typename: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename: 'JobSaveSuccess' }
    & Pick<JobSaveSuccess, 'success'>
    & { jobCollectionMods: Array<(
      { __typename?: 'JobCollectionMod' }
      & Pick<JobCollectionMod, 'modType'>
      & { jobCollection: (
        { __typename?: 'JobCollection' }
        & Pick<JobCollection, 'id' | 'jobsCount' | 'locationsCount'>
      ), jobCollectionItem: (
        { __typename?: 'JobCollectionItem' }
        & SearchPageJobCollectionItemFragment
      ) }
    )> }
  ) | (
    { __typename: 'JobSaveError' }
    & Pick<JobSaveError, 'otherError'>
    & { inputErrors?: Maybe<(
      { __typename?: 'JobSaveInputErrors' }
      & Pick<JobSaveInputErrors, 'provider' | 'providerId' | 'routing'>
    )> }
  ) }
);

export type DynamicFiltersQueryVariables = {
  titleQuery: SearchQuery;
  companyQuery: SearchQuery;
  limit?: Maybe<Scalars['Int']>;
  validateTitle?: Maybe<Array<Scalars['String']>>;
  validateCompany?: Maybe<Array<Scalars['String']>>;
  providers?: Maybe<Array<Scalars['String']>>;
  country?: Maybe<Scalars['String']>;
};


export type DynamicFiltersQuery = (
  { __typename?: 'Query' }
  & { isActiveTitleValid: Query['hasJobs'], isActiveCompanyValid: Query['hasJobs'] }
  & { topJobTitles: Array<(
    { __typename?: 'TitleJobCount' }
    & Pick<TitleJobCount, 'title'>
  )>, topCompanies: Array<(
    { __typename?: 'CompanyJobCount' }
    & Pick<CompanyJobCount, 'company' | 'count'>
  )> }
);

export type SaveStepMutationVariables = {
  input?: Maybe<UserSaveStepInput>;
};


export type SaveStepMutation = (
  { __typename?: 'Mutation' }
  & { saveStep: (
    { __typename?: 'UserAuthenticationRequiredError' }
    & Pick<UserAuthenticationRequiredError, 'error'>
  ) | (
    { __typename?: 'UserSaveStepSuccess' }
    & { user?: Maybe<(
      { __typename?: 'JoblistUser' }
      & Pick<JoblistUser, 'id'>
      & { progress?: Maybe<(
        { __typename?: 'UserProgress' }
        & Pick<UserProgress, 'emailAlert' | 'signUp' | 'saveJobs' | 'inviteFriends'>
      )> }
    )> }
  ) | (
    { __typename?: 'UserSaveStepError' }
    & Pick<UserSaveStepError, 'otherError'>
  ) }
);

export type UpdateShowGamifyMutationVariables = {
  showGamify: Scalars['Boolean'];
};


export type UpdateShowGamifyMutation = (
  { __typename?: 'Mutation' }
  & { updateShowGamify: { __typename?: 'UserAuthenticationRequiredError' } | (
    { __typename?: 'UserUpdateShowGamifySuccess' }
    & { user?: Maybe<(
      { __typename?: 'JoblistUser' }
      & Pick<JoblistUser, 'id' | 'showGamify'>
    )> }
  ) | { __typename?: 'UserUpdateShowGamifyError' } }
);

export type EJobQueryVariables = {
  id: Scalars['String'];
};


export type EJobQuery = (
  { __typename?: 'Query' }
  & { eJob: (
    { __typename: 'Job' }
    & Pick<Job, 'content' | 'url'>
    & JobFieldsFragmentFragment
  ) | (
    { __typename: 'JobError' }
    & Pick<JobError, 'error'>
  ) }
);

export type JobQueryVariables = {
  provider: Scalars['String'];
  providerId: Scalars['String'];
  routing?: Maybe<Scalars['String']>;
  impressionId?: Maybe<Scalars['String']>;
};


export type JobQuery = (
  { __typename?: 'Query' }
  & { job: (
    { __typename: 'Job' }
    & Pick<Job, 'content' | 'url'>
    & JobFieldsFragmentFragment
  ) | (
    { __typename: 'JobError' }
    & Pick<JobError, 'error'>
  ) }
);

export type TopJobQueryVariables = {
  query: SearchQuery;
  providers?: Maybe<ProvidersFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type TopJobQuery = (
  { __typename?: 'Query' }
  & { topJob: (
    { __typename: 'Job' }
    & Pick<Job, 'content' | 'url'>
    & JobFieldsFragmentFragment
  ) | (
    { __typename: 'JobError' }
    & Pick<JobError, 'error'>
  ) }
);

export type PressPageQueryVariables = {};


export type PressPageQuery = (
  { __typename?: 'Query' }
  & { pressPage?: Maybe<(
    { __typename?: 'Presspage' }
    & { pressEntries?: Maybe<Array<Maybe<(
      { __typename: 'PressEntriesPressSection' }
      & Pick<PressEntriesPressSection, 'id' | 'enabled' | 'pressSectionTitle'>
    ) | (
      { __typename: 'PressEntriesPressEntry' }
      & Pick<PressEntriesPressEntry, 'id' | 'entryTitle' | 'description' | 'entryUrl'>
      & { image?: Maybe<Array<Maybe<(
        { __typename?: 'JoblistcontentqaVolume' }
        & Pick<JoblistcontentqaVolume, 'id' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type JobFieldsFragmentFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'internalId' | 'id' | 'fingerprint' | 'fingerprintJd' | 'service' | 'country' | 'area1' | 'locality' | 'postal_code' | 'categories' | 'internalUrl' | 'internalApplyUrl' | 'company' | 'companyLogoSlug' | 'applyByEmail' | 'title' | 'mobile_friendly' | 'provider' | 'provider_id' | 'cpc' | 'cpc_usd' | 'earlyApplicant' | 'easyApply' | 'roles' | 'source' | 'score' | 'explanation' | 'posted_at' | 'updated_at' | 'expires_at' | 'documentId' | 'isInLocalInventory'>
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'lat' | 'lon'>
  )>, companyLogo?: Maybe<(
    { __typename?: 'CompanyLogo' }
    & Pick<CompanyLogo, 'name' | 'domain'>
  )>, tags?: Maybe<(
    { __typename?: 'TagSources' }
    & { regex?: Maybe<(
      { __typename?: 'Tags' }
      & Pick<Tags, 'jobType' | 'siteLocation' | 'requiresPhysicalLabor' | 'providesHealthBenefits' | 'compensationType' | 'environment' | 'entryLevel' | 'bCorp' | 'diverse' | 'ecoFriendly'>
    )>, script?: Maybe<(
      { __typename?: 'Tags' }
      & Pick<Tags, 'jobType' | 'siteLocation' | 'requiresPhysicalLabor' | 'providesHealthBenefits' | 'compensationType' | 'environment' | 'entryLevel' | 'bCorp' | 'diverse' | 'ecoFriendly'>
    )>, manual?: Maybe<(
      { __typename?: 'Tags' }
      & Pick<Tags, 'jobType' | 'siteLocation' | 'requiresPhysicalLabor' | 'providesHealthBenefits' | 'compensationType' | 'environment' | 'entryLevel' | 'bCorp' | 'diverse' | 'ecoFriendly'>
    )> }
  )> }
);

export type SearchJobsQueryQueryVariables = {
  keyword?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationRadius?: Maybe<LocationRadius>;
  providers?: Maybe<Array<Scalars['String']>>;
  titles?: Maybe<Array<Scalars['String']>>;
  companys?: Maybe<Array<Scalars['String']>>;
  includeTags?: Maybe<TagFilters>;
  excludeTags?: Maybe<TagFilters>;
  tagSources?: Maybe<TagFlags>;
  easyApply?: Maybe<Scalars['Boolean']>;
  posted?: Maybe<PostedDate>;
  placeid?: Maybe<Scalars['String']>;
  firstVisitId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  adChannel?: Maybe<Scalars['String']>;
  settings?: Maybe<SearchSettings>;
  country?: Maybe<Scalars['String']>;
  excludeBy?: Maybe<ExclusionClause>;
  searchProviders?: Maybe<ProvidersFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  isPaidView: Scalars['Boolean'];
  useDfsSearch?: Maybe<Scalars['Boolean']>;
  usePhraseSearch?: Maybe<Scalars['Boolean']>;
};


export type SearchJobsQueryQuery = (
  { __typename?: 'Query' }
  & { searchJobs: (
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'impression_id' | 'total' | 'totalAppcast' | 'totalLocalInventory'>
    & { jobs: Array<(
      { __typename?: 'Job' }
      & Pick<Job, 'url'>
      & JobFieldsFragmentFragment
    )> }
  ) }
);

export type VerifyTokenQueryVariables = {
  token: Scalars['String'];
  args: Array<Maybe<Scalars['String']>>;
};


export type VerifyTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyToken'>
);

export const JobPostingContentFragmentDoc = gql`
    fragment JobPostingContent on JobPostingContent {
  title
  description
  zipCode
  companyName
  isRemote
  country
  jobType
  compensation
  isEntryLevel
  requiresPhysicalLabor
  companyLogo
  area1
  locality
  applyByEmail
  applyAtUrl
}
    `;
export const CreateJobPostingPageJobPostingFragmentDoc = gql`
    fragment CreateJobPostingPageJobPosting on JobPosting {
  id
  isSearchable
  isActive
  createdAt
  content {
    ...JobPostingContent
  }
  hadProduct
  hasProduct
  hasProductFrom
  hasProductUntil
  isFreeTrialJob
  isFreeTrialActive
  freeTrialStart
  freeTrialEnd
  isPaid
}
    ${JobPostingContentFragmentDoc}`;
export const MyJobPostingsPageJobPostingFragmentDoc = gql`
    fragment MyJobPostingsPageJobPosting on JobPosting {
  id
  isSearchable
  isActive
  createdAt
  content {
    ...JobPostingContent
  }
  hadProduct
  hasProduct
  hasProductFrom
  hasProductUntil
  isFreeTrialJob
  isFreeTrialActive
  freeTrialStart
  freeTrialEnd
  isPaid
  impressions
  applications
}
    ${JobPostingContentFragmentDoc}`;
export const JobFieldsFragmentFragmentDoc = gql`
    fragment JobFieldsFragment on Job {
  internalId
  id
  fingerprint
  fingerprintJd
  service
  country
  area1
  locality
  postal_code
  categories
  internalUrl
  internalApplyUrl
  location {
    lat
    lon
  }
  company
  companyLogo {
    name
    domain
  }
  companyLogoSlug
  applyByEmail
  title
  mobile_friendly
  provider
  provider_id
  cpc
  cpc_usd
  earlyApplicant
  easyApply
  roles
  tags {
    regex {
      jobType
      siteLocation
      requiresPhysicalLabor
      providesHealthBenefits
      compensationType
      environment
      entryLevel
      bCorp
      diverse
      ecoFriendly
    }
    script {
      jobType
      siteLocation
      requiresPhysicalLabor
      providesHealthBenefits
      compensationType
      environment
      entryLevel
      bCorp
      diverse
      ecoFriendly
    }
    manual {
      jobType
      siteLocation
      requiresPhysicalLabor
      providesHealthBenefits
      compensationType
      environment
      entryLevel
      bCorp
      diverse
      ecoFriendly
    }
  }
  source
  score
  explanation
  posted_at
  updated_at
  expires_at
  documentId
  isInLocalInventory
  documentId
  isInLocalInventory
}
    `;
export const JobCollectionPageCollaboratorFragmentDoc = gql`
    fragment JobCollectionPageCollaborator on JobCollectionCollaborator {
  id
  name
  emailAddress
  me
  isOwner
  invitedBy {
    name
    emailAddress
    me
  }
  jobCount
}
    `;
export const JobCollectionPageInvitedUserFragmentDoc = gql`
    fragment JobCollectionPageInvitedUser on InvitedUser {
  id
  emailAddress
  invitedByUser {
    id
    fullName
    firstName
    lastName
    emailAddress
  }
}
    `;
export const JobCollectionPageJobCollectionFragmentDoc = gql`
    fragment JobCollectionPageJobCollection on JobCollection {
  id
  name
  jobsCount
  locationsCount
  isPublic
  isOwner
  createdByUser {
    id
    shareId
    fullName
    firstName
    lastName
    emailAddress
    isVerified
    isSavedJobsListPublic
    savedJobsListName
    createdAt
    lastSigninAt
    roles {
      name
    }
    quizData {
      covid
      newGrad
      remote
      somethingElse
    }
  }
  permissions {
    editTitle
    save
    unsave
    enablePublicLink
    invite
  }
  jobs(optimized: true) {
    addedBy
    id
    job {
      url
      ...JobFieldsFragment
    }
  }
  collaborators {
    ...JobCollectionPageCollaborator
  }
  invitedUsers {
    ...JobCollectionPageInvitedUser
  }
  impressionId
}
    ${JobFieldsFragmentFragmentDoc}
${JobCollectionPageCollaboratorFragmentDoc}
${JobCollectionPageInvitedUserFragmentDoc}`;
export const MyJoblistsPageJobCollectionFragmentDoc = gql`
    fragment MyJoblistsPageJobCollection on JobCollection {
  id
  name
  jobsCount
  isOwner
  isPublic
  inviter {
    id
    fullName
    emailAddress
  }
  myInvite {
    id
  }
}
    `;
export const SearchPageJobCollectionItemFragmentDoc = gql`
    fragment SearchPageJobCollectionItem on JobCollectionItem {
  addedBy
  id
  job {
    id
  }
}
    `;
export const JobCollectionCreatedResponseFragmentDoc = gql`
    fragment JobCollectionCreatedResponse on JobCollection {
  id
  name
  jobsCount
  locationsCount
  isOwner
  isPublic
  inviter {
    id
    fullName
    emailAddress
  }
  createdByUser {
    id
    fullName
    firstName
    emailAddress
  }
  jobs(optimized: true) {
    ...SearchPageJobCollectionItem
  }
  permissions {
    editTitle
    save
    unsave
    enablePublicLink
    invite
  }
  myInvite {
    id
    emailAddress
    invitedByUser {
      id
      firstName
      emailAddress
    }
  }
}
    ${SearchPageJobCollectionItemFragmentDoc}`;
export const SearchPageJobCollectionFragmentDoc = gql`
    fragment SearchPageJobCollection on JobCollection {
  id
  name
  jobsCount
  locationsCount
  isOwner
  isPublic
  createdByUser {
    id
    fullName
    firstName
    emailAddress
  }
  permissions {
    editTitle
    save
    unsave
    enablePublicLink
    invite
  }
  jobs(optimized: true) {
    ...SearchPageJobCollectionItem
  }
}
    ${SearchPageJobCollectionItemFragmentDoc}`;
export const AddJobAlertDocument = gql`
    mutation AddJobAlert($email: String, $keyword: String, $location: String!, $country: String!) {
  addJobAlert(searchQuery: {keyword: $keyword, location: $location, country: $country}, email: $email) {
    __typename
    ... on JobAlert {
      id
      status
      searchQuery {
        keyword
        location
        country
      }
    }
    ... on JobAlertError {
      error
    }
  }
}
    `;
export type AddJobAlertMutationFn = ApolloReactCommon.MutationFunction<AddJobAlertMutation, AddJobAlertMutationVariables>;

/**
 * __useAddJobAlertMutation__
 *
 * To run a mutation, you first call `useAddJobAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobAlertMutation, { data, loading, error }] = useAddJobAlertMutation({
 *   variables: {
 *      email: // value for 'email'
 *      keyword: // value for 'keyword'
 *      location: // value for 'location'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useAddJobAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddJobAlertMutation, AddJobAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<AddJobAlertMutation, AddJobAlertMutationVariables>(AddJobAlertDocument, baseOptions);
      }
export type AddJobAlertMutationHookResult = ReturnType<typeof useAddJobAlertMutation>;
export type AddJobAlertMutationResult = ApolloReactCommon.MutationResult<AddJobAlertMutation>;
export type AddJobAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<AddJobAlertMutation, AddJobAlertMutationVariables>;
export const DeleteAllJobAlertsDocument = gql`
    mutation DeleteAllJobAlerts($id: String, $token: String) {
  deleteAllJobAlerts(id: $id, token: $token) {
    __typename
    ... on DeleteAllJobAlertsResult {
      alerts {
        id
        status
        searchQuery {
          keyword
          location
          country
        }
      }
    }
    ... on JobAlertError {
      error
    }
  }
}
    `;
export type DeleteAllJobAlertsMutationFn = ApolloReactCommon.MutationFunction<DeleteAllJobAlertsMutation, DeleteAllJobAlertsMutationVariables>;

/**
 * __useDeleteAllJobAlertsMutation__
 *
 * To run a mutation, you first call `useDeleteAllJobAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllJobAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllJobAlertsMutation, { data, loading, error }] = useDeleteAllJobAlertsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDeleteAllJobAlertsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAllJobAlertsMutation, DeleteAllJobAlertsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAllJobAlertsMutation, DeleteAllJobAlertsMutationVariables>(DeleteAllJobAlertsDocument, baseOptions);
      }
export type DeleteAllJobAlertsMutationHookResult = ReturnType<typeof useDeleteAllJobAlertsMutation>;
export type DeleteAllJobAlertsMutationResult = ApolloReactCommon.MutationResult<DeleteAllJobAlertsMutation>;
export type DeleteAllJobAlertsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAllJobAlertsMutation, DeleteAllJobAlertsMutationVariables>;
export const DeleteJobAlertDocument = gql`
    mutation DeleteJobAlert($id: String!) {
  deleteJobAlert(id: $id) {
    __typename
    ... on DeleteJobAlertResult {
      status
    }
    ... on JobAlertError {
      error
    }
  }
}
    `;
export type DeleteJobAlertMutationFn = ApolloReactCommon.MutationFunction<DeleteJobAlertMutation, DeleteJobAlertMutationVariables>;

/**
 * __useDeleteJobAlertMutation__
 *
 * To run a mutation, you first call `useDeleteJobAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobAlertMutation, { data, loading, error }] = useDeleteJobAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteJobAlertMutation, DeleteJobAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteJobAlertMutation, DeleteJobAlertMutationVariables>(DeleteJobAlertDocument, baseOptions);
      }
export type DeleteJobAlertMutationHookResult = ReturnType<typeof useDeleteJobAlertMutation>;
export type DeleteJobAlertMutationResult = ApolloReactCommon.MutationResult<DeleteJobAlertMutation>;
export type DeleteJobAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteJobAlertMutation, DeleteJobAlertMutationVariables>;
export const GetJobAlertsDocument = gql`
    query GetJobAlerts {
  getJobAlerts {
    __typename
    ... on GetJobAlertsResult {
      alerts {
        id
        status
        searchQuery {
          keyword
          location
          country
        }
      }
    }
    ... on JobAlertError {
      error
    }
  }
}
    `;

/**
 * __useGetJobAlertsQuery__
 *
 * To run a query within a React component, call `useGetJobAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobAlertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJobAlertsQuery, GetJobAlertsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJobAlertsQuery, GetJobAlertsQueryVariables>(GetJobAlertsDocument, baseOptions);
      }
export function useGetJobAlertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJobAlertsQuery, GetJobAlertsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJobAlertsQuery, GetJobAlertsQueryVariables>(GetJobAlertsDocument, baseOptions);
        }
export type GetJobAlertsQueryHookResult = ReturnType<typeof useGetJobAlertsQuery>;
export type GetJobAlertsLazyQueryHookResult = ReturnType<typeof useGetJobAlertsLazyQuery>;
export type GetJobAlertsQueryResult = ApolloReactCommon.QueryResult<GetJobAlertsQuery, GetJobAlertsQueryVariables>;
export const PauseJobAlertDocument = gql`
    mutation PauseJobAlert($id: String!, $value: Boolean!) {
  pauseJobAlert(id: $id, value: $value) {
    __typename
    ... on PauseJobAlertResult {
      status
    }
    ... on JobAlertError {
      error
    }
  }
}
    `;
export type PauseJobAlertMutationFn = ApolloReactCommon.MutationFunction<PauseJobAlertMutation, PauseJobAlertMutationVariables>;

/**
 * __usePauseJobAlertMutation__
 *
 * To run a mutation, you first call `usePauseJobAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseJobAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseJobAlertMutation, { data, loading, error }] = usePauseJobAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePauseJobAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PauseJobAlertMutation, PauseJobAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<PauseJobAlertMutation, PauseJobAlertMutationVariables>(PauseJobAlertDocument, baseOptions);
      }
export type PauseJobAlertMutationHookResult = ReturnType<typeof usePauseJobAlertMutation>;
export type PauseJobAlertMutationResult = ApolloReactCommon.MutationResult<PauseJobAlertMutation>;
export type PauseJobAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<PauseJobAlertMutation, PauseJobAlertMutationVariables>;
export const VerifyJobAlertDocument = gql`
    mutation VerifyJobAlert($id: String!, $token: String!) {
  verifyJobAlert(id: $id, token: $token) {
    __typename
    ... on VerifyJobAlertResult {
      alert {
        id
        status
        searchQuery {
          keyword
          location
          country
        }
      }
    }
    ... on JobAlertError {
      error
    }
  }
}
    `;
export type VerifyJobAlertMutationFn = ApolloReactCommon.MutationFunction<VerifyJobAlertMutation, VerifyJobAlertMutationVariables>;

/**
 * __useVerifyJobAlertMutation__
 *
 * To run a mutation, you first call `useVerifyJobAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyJobAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyJobAlertMutation, { data, loading, error }] = useVerifyJobAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyJobAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyJobAlertMutation, VerifyJobAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyJobAlertMutation, VerifyJobAlertMutationVariables>(VerifyJobAlertDocument, baseOptions);
      }
export type VerifyJobAlertMutationHookResult = ReturnType<typeof useVerifyJobAlertMutation>;
export type VerifyJobAlertMutationResult = ApolloReactCommon.MutationResult<VerifyJobAlertMutation>;
export type VerifyJobAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyJobAlertMutation, VerifyJobAlertMutationVariables>;
export const JobPostingCheckoutDocument = gql`
    mutation jobPostingCheckout($id: ID!, $emailAddress: String!, $coupon: String, $product: String!, $version: Int!, $deferralId: String) {
  jobPostingCheckout(id: $id, input: {emailAddress: $emailAddress, coupon: $coupon, product: $product, version: $version, deferralId: $deferralId}) {
    __typename
    ... on JobPostingCheckoutSuccess {
      paymentIntentSecret
      setupIntentSecret
      billingEmailAddress
      charge {
        netAmount
        grossAmount
        discount
        currency
        validity
      }
      freeTrial {
        isFreeTrialJob
        isFreeTrialActive
        freeTrialStart
        freeTrialEnd
        validityDays
        ruleMetric
        ruleLimit
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    `;
export type JobPostingCheckoutMutationFn = ApolloReactCommon.MutationFunction<JobPostingCheckoutMutation, JobPostingCheckoutMutationVariables>;

/**
 * __useJobPostingCheckoutMutation__
 *
 * To run a mutation, you first call `useJobPostingCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingCheckoutMutation, { data, loading, error }] = useJobPostingCheckoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailAddress: // value for 'emailAddress'
 *      coupon: // value for 'coupon'
 *      product: // value for 'product'
 *      version: // value for 'version'
 *      deferralId: // value for 'deferralId'
 *   },
 * });
 */
export function useJobPostingCheckoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingCheckoutMutation, JobPostingCheckoutMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingCheckoutMutation, JobPostingCheckoutMutationVariables>(JobPostingCheckoutDocument, baseOptions);
      }
export type JobPostingCheckoutMutationHookResult = ReturnType<typeof useJobPostingCheckoutMutation>;
export type JobPostingCheckoutMutationResult = ApolloReactCommon.MutationResult<JobPostingCheckoutMutation>;
export type JobPostingCheckoutMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingCheckoutMutation, JobPostingCheckoutMutationVariables>;
export const JobPostingCreateDocument = gql`
    mutation jobPostingCreate($title: String!, $companyName: String!, $zipCode: String!, $locality: String!, $area1: String!, $country: JobPostingCountry!, $isRemote: Boolean!, $jobType: JobPostingType!, $compensation: JobPostingCompensation!, $isEntryLevel: Boolean, $requiresPhysicalLabor: Boolean, $description: String!, $companyLogo: Upload, $isActive: Boolean!, $isSearchable: Boolean!, $applyByEmail: String, $applyAtUrl: String) {
  jobPostingCreate(input: {isActive: $isActive, isSearchable: $isSearchable, content: {title: $title, companyName: $companyName, zipCode: $zipCode, locality: $locality, area1: $area1, country: $country, isRemote: $isRemote, jobType: $jobType, compensation: $compensation, isEntryLevel: $isEntryLevel, requiresPhysicalLabor: $requiresPhysicalLabor, description: $description, companyLogo: $companyLogo, applyByEmail: $applyByEmail, applyAtUrl: $applyAtUrl}}) {
    __typename
    ... on JobPostingSuccess {
      jobPosting {
        ...CreateJobPostingPageJobPosting
      }
    }
    ... on JobPostingError {
      error
      message
    }
  }
}
    ${CreateJobPostingPageJobPostingFragmentDoc}`;
export type JobPostingCreateMutationFn = ApolloReactCommon.MutationFunction<JobPostingCreateMutation, JobPostingCreateMutationVariables>;

/**
 * __useJobPostingCreateMutation__
 *
 * To run a mutation, you first call `useJobPostingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingCreateMutation, { data, loading, error }] = useJobPostingCreateMutation({
 *   variables: {
 *      title: // value for 'title'
 *      companyName: // value for 'companyName'
 *      zipCode: // value for 'zipCode'
 *      locality: // value for 'locality'
 *      area1: // value for 'area1'
 *      country: // value for 'country'
 *      isRemote: // value for 'isRemote'
 *      jobType: // value for 'jobType'
 *      compensation: // value for 'compensation'
 *      isEntryLevel: // value for 'isEntryLevel'
 *      requiresPhysicalLabor: // value for 'requiresPhysicalLabor'
 *      description: // value for 'description'
 *      companyLogo: // value for 'companyLogo'
 *      isActive: // value for 'isActive'
 *      isSearchable: // value for 'isSearchable'
 *      applyByEmail: // value for 'applyByEmail'
 *      applyAtUrl: // value for 'applyAtUrl'
 *   },
 * });
 */
export function useJobPostingCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingCreateMutation, JobPostingCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingCreateMutation, JobPostingCreateMutationVariables>(JobPostingCreateDocument, baseOptions);
      }
export type JobPostingCreateMutationHookResult = ReturnType<typeof useJobPostingCreateMutation>;
export type JobPostingCreateMutationResult = ApolloReactCommon.MutationResult<JobPostingCreateMutation>;
export type JobPostingCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingCreateMutation, JobPostingCreateMutationVariables>;
export const JobPostingUpdateDocument = gql`
    mutation jobPostingUpdate($id: ID!, $content: JobPostingContentInput, $isActive: Boolean, $isSearchable: Boolean) {
  jobPostingUpdate(id: $id, input: {isActive: $isActive, isSearchable: $isSearchable, content: $content}) {
    __typename
    ... on JobPostingSuccess {
      jobPosting {
        ...CreateJobPostingPageJobPosting
      }
    }
    ... on JobPostingError {
      error
      message
    }
  }
}
    ${CreateJobPostingPageJobPostingFragmentDoc}`;
export type JobPostingUpdateMutationFn = ApolloReactCommon.MutationFunction<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>;

/**
 * __useJobPostingUpdateMutation__
 *
 * To run a mutation, you first call `useJobPostingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingUpdateMutation, { data, loading, error }] = useJobPostingUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      isActive: // value for 'isActive'
 *      isSearchable: // value for 'isSearchable'
 *   },
 * });
 */
export function useJobPostingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>(JobPostingUpdateDocument, baseOptions);
      }
export type JobPostingUpdateMutationHookResult = ReturnType<typeof useJobPostingUpdateMutation>;
export type JobPostingUpdateMutationResult = ApolloReactCommon.MutationResult<JobPostingUpdateMutation>;
export type JobPostingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>;
export const JobPostingDraftDocument = gql`
    query JobPostingDraft($id: ID!) {
  jobPostingDraft(id: $id) {
    __typename
    ... on JobPostingSuccess {
      jobPosting {
        id
        isSearchable
        isActive
        content {
          ...JobPostingContent
        }
        hadProduct
        hasProduct
        hasProductFrom
        hasProductUntil
        createdAt
        subscriptionStart
        subscriptionEnd
        isFreeTrialJob
        isFreeTrialActive
        freeTrialStart
        freeTrialEnd
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    ${JobPostingContentFragmentDoc}`;

/**
 * __useJobPostingDraftQuery__
 *
 * To run a query within a React component, call `useJobPostingDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobPostingDraftQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingDraftQuery, JobPostingDraftQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingDraftQuery, JobPostingDraftQueryVariables>(JobPostingDraftDocument, baseOptions);
      }
export function useJobPostingDraftLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingDraftQuery, JobPostingDraftQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingDraftQuery, JobPostingDraftQueryVariables>(JobPostingDraftDocument, baseOptions);
        }
export type JobPostingDraftQueryHookResult = ReturnType<typeof useJobPostingDraftQuery>;
export type JobPostingDraftLazyQueryHookResult = ReturnType<typeof useJobPostingDraftLazyQuery>;
export type JobPostingDraftQueryResult = ApolloReactCommon.QueryResult<JobPostingDraftQuery, JobPostingDraftQueryVariables>;
export const JobPostingFinalizedDocument = gql`
    query JobPostingFinalized($id: ID!) {
  jobPostingFinalized(id: $id) {
    __typename
    ... on JobPostingSuccess {
      jobPosting {
        id
        isSearchable
        isActive
        content {
          ...JobPostingContent
        }
        hadProduct
        hasProduct
        hasProductFrom
        hasProductUntil
        isFreeTrialJob
        isFreeTrialActive
        freeTrialStart
        freeTrialEnd
        isPaid
        createdAt
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    ${JobPostingContentFragmentDoc}`;

/**
 * __useJobPostingFinalizedQuery__
 *
 * To run a query within a React component, call `useJobPostingFinalizedQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingFinalizedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingFinalizedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobPostingFinalizedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingFinalizedQuery, JobPostingFinalizedQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingFinalizedQuery, JobPostingFinalizedQueryVariables>(JobPostingFinalizedDocument, baseOptions);
      }
export function useJobPostingFinalizedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingFinalizedQuery, JobPostingFinalizedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingFinalizedQuery, JobPostingFinalizedQueryVariables>(JobPostingFinalizedDocument, baseOptions);
        }
export type JobPostingFinalizedQueryHookResult = ReturnType<typeof useJobPostingFinalizedQuery>;
export type JobPostingFinalizedLazyQueryHookResult = ReturnType<typeof useJobPostingFinalizedLazyQuery>;
export type JobPostingFinalizedQueryResult = ApolloReactCommon.QueryResult<JobPostingFinalizedQuery, JobPostingFinalizedQueryVariables>;
export const JobPostingActiveProductDocument = gql`
    query JobPostingActiveProduct($productCode: String!, $productVersion: Int!) {
  jobPostingActiveProduct(input: {productCode: $productCode, productVersion: $productVersion}) {
    __typename
    ... on JobPostingActiveProductSuccess {
      hasFreeTrial
      postingPrice
      freeTrialDuration
      paidProductDuration
      productCode
      ruleMetric
      ruleLimit
    }
    ... on JobPostingError {
      error
      message
    }
  }
}
    `;

/**
 * __useJobPostingActiveProductQuery__
 *
 * To run a query within a React component, call `useJobPostingActiveProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingActiveProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingActiveProductQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *      productVersion: // value for 'productVersion'
 *   },
 * });
 */
export function useJobPostingActiveProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingActiveProductQuery, JobPostingActiveProductQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingActiveProductQuery, JobPostingActiveProductQueryVariables>(JobPostingActiveProductDocument, baseOptions);
      }
export function useJobPostingActiveProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingActiveProductQuery, JobPostingActiveProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingActiveProductQuery, JobPostingActiveProductQueryVariables>(JobPostingActiveProductDocument, baseOptions);
        }
export type JobPostingActiveProductQueryHookResult = ReturnType<typeof useJobPostingActiveProductQuery>;
export type JobPostingActiveProductLazyQueryHookResult = ReturnType<typeof useJobPostingActiveProductLazyQuery>;
export type JobPostingActiveProductQueryResult = ApolloReactCommon.QueryResult<JobPostingActiveProductQuery, JobPostingActiveProductQueryVariables>;
export const JobPostingApplyDocument = gql`
    mutation jobPostingApply($postingId: ID!, $firstName: String!, $lastName: String!, $emailAddress: String!, $phoneNumber: String, $resume: Upload!, $coverLetter: Upload) {
  jobPostingApply(input: {postingId: $postingId, firstName: $firstName, lastName: $lastName, emailAddress: $emailAddress, phoneNumber: $phoneNumber, resume: $resume, coverLetter: $coverLetter}) {
    __typename
    ... on JobPostingApplySuccess {
      status
    }
    ... on JobPostingError {
      error
    }
  }
}
    `;
export type JobPostingApplyMutationFn = ApolloReactCommon.MutationFunction<JobPostingApplyMutation, JobPostingApplyMutationVariables>;

/**
 * __useJobPostingApplyMutation__
 *
 * To run a mutation, you first call `useJobPostingApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingApplyMutation, { data, loading, error }] = useJobPostingApplyMutation({
 *   variables: {
 *      postingId: // value for 'postingId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      emailAddress: // value for 'emailAddress'
 *      phoneNumber: // value for 'phoneNumber'
 *      resume: // value for 'resume'
 *      coverLetter: // value for 'coverLetter'
 *   },
 * });
 */
export function useJobPostingApplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingApplyMutation, JobPostingApplyMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingApplyMutation, JobPostingApplyMutationVariables>(JobPostingApplyDocument, baseOptions);
      }
export type JobPostingApplyMutationHookResult = ReturnType<typeof useJobPostingApplyMutation>;
export type JobPostingApplyMutationResult = ApolloReactCommon.MutationResult<JobPostingApplyMutation>;
export type JobPostingApplyMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingApplyMutation, JobPostingApplyMutationVariables>;
export const JobPostingCountDocument = gql`
    query jobPostingCount($filter: JobPostingListFilter) {
  jobPostingCount(filter: $filter) {
    __typename
    ... on JobPostingCountSuccess {
      total
      open
      closed
      draft
      hasActiveProduct
    }
    ... on JobPostingError {
      error
    }
  }
}
    `;

/**
 * __useJobPostingCountQuery__
 *
 * To run a query within a React component, call `useJobPostingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJobPostingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingCountQuery, JobPostingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingCountQuery, JobPostingCountQueryVariables>(JobPostingCountDocument, baseOptions);
      }
export function useJobPostingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingCountQuery, JobPostingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingCountQuery, JobPostingCountQueryVariables>(JobPostingCountDocument, baseOptions);
        }
export type JobPostingCountQueryHookResult = ReturnType<typeof useJobPostingCountQuery>;
export type JobPostingCountLazyQueryHookResult = ReturnType<typeof useJobPostingCountLazyQuery>;
export type JobPostingCountQueryResult = ApolloReactCommon.QueryResult<JobPostingCountQuery, JobPostingCountQueryVariables>;
export const JobPostingListDocument = gql`
    query jobPostingList($limit: Int, $offset: Int, $filter: JobPostingListFilter) {
  jobPostingList(limit: $limit, offset: $offset, filter: $filter) {
    __typename
    ... on JobPostingListSuccess {
      jobPostingList {
        ...MyJobPostingsPageJobPosting
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    ${MyJobPostingsPageJobPostingFragmentDoc}`;

/**
 * __useJobPostingListQuery__
 *
 * To run a query within a React component, call `useJobPostingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJobPostingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingListQuery, JobPostingListQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingListQuery, JobPostingListQueryVariables>(JobPostingListDocument, baseOptions);
      }
export function useJobPostingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingListQuery, JobPostingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingListQuery, JobPostingListQueryVariables>(JobPostingListDocument, baseOptions);
        }
export type JobPostingListQueryHookResult = ReturnType<typeof useJobPostingListQuery>;
export type JobPostingListLazyQueryHookResult = ReturnType<typeof useJobPostingListLazyQuery>;
export type JobPostingListQueryResult = ApolloReactCommon.QueryResult<JobPostingListQuery, JobPostingListQueryVariables>;
export const JobPostingStatusUpdateDocument = gql`
    mutation jobPostingStatusUpdate($id: ID!, $isActive: Boolean, $isSearchable: Boolean) {
  jobPostingUpdate(id: $id, input: {isActive: $isActive, isSearchable: $isSearchable}) {
    __typename
    ... on JobPostingSuccess {
      jobPosting {
        id
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    `;
export type JobPostingStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>;

/**
 * __useJobPostingStatusUpdateMutation__
 *
 * To run a mutation, you first call `useJobPostingStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingStatusUpdateMutation, { data, loading, error }] = useJobPostingStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *      isSearchable: // value for 'isSearchable'
 *   },
 * });
 */
export function useJobPostingStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>(JobPostingStatusUpdateDocument, baseOptions);
      }
export type JobPostingStatusUpdateMutationHookResult = ReturnType<typeof useJobPostingStatusUpdateMutation>;
export type JobPostingStatusUpdateMutationResult = ApolloReactCommon.MutationResult<JobPostingStatusUpdateMutation>;
export type JobPostingStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>;
export const JobPostingChargeDocument = gql`
    query jobPostingCharge($country: JobPostingCountry!, $product: String!, $version: Int!, $coupon: String) {
  jobPostingCharge(input: {country: $country, product: $product, version: $version, coupon: $coupon}) {
    __typename
    ... on JobPostingChargeSuccess {
      charge {
        netAmount
        grossAmount
        discount
        currency
        validity
      }
      freeTrial {
        freeTrialEnd
        validityDays
        ruleMetric
        ruleLimit
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    `;

/**
 * __useJobPostingChargeQuery__
 *
 * To run a query within a React component, call `useJobPostingChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingChargeQuery({
 *   variables: {
 *      country: // value for 'country'
 *      product: // value for 'product'
 *      version: // value for 'version'
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useJobPostingChargeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingChargeQuery, JobPostingChargeQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingChargeQuery, JobPostingChargeQueryVariables>(JobPostingChargeDocument, baseOptions);
      }
export function useJobPostingChargeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingChargeQuery, JobPostingChargeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingChargeQuery, JobPostingChargeQueryVariables>(JobPostingChargeDocument, baseOptions);
        }
export type JobPostingChargeQueryHookResult = ReturnType<typeof useJobPostingChargeQuery>;
export type JobPostingChargeLazyQueryHookResult = ReturnType<typeof useJobPostingChargeLazyQuery>;
export type JobPostingChargeQueryResult = ApolloReactCommon.QueryResult<JobPostingChargeQuery, JobPostingChargeQueryVariables>;
export const JobPostingOrderDocument = gql`
    query JobPostingOrder($orderId: ID, $postingId: ID) {
  jobPostingOrder(orderId: $orderId, postingId: $postingId) {
    __typename
    ... on JobPostingOrderSuccess {
      billingEmailAddress
      order {
        id
        status
        grossAmount
        netAmount
        discount
        coupon {
          code
        }
        items {
          product {
            code
          }
          unitPrice
          unitsRequested
        }
        productConsumption {
          activeFrom
          activeUntil
        }
        paidOn
        paidAt
        isPaymentSuccessful
        freeTrial {
          isFreeTrialJob
          isFreeTrialActive
          freeTrialStart
          freeTrialEnd
          validityDays
        }
        paymentDeferral {
          type
          chargeEarly
          ruleMetric
          ruleGrouping
          ruleLimit
        }
        validityDays
        postingPrice
        chargeEntity
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    `;

/**
 * __useJobPostingOrderQuery__
 *
 * To run a query within a React component, call `useJobPostingOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      postingId: // value for 'postingId'
 *   },
 * });
 */
export function useJobPostingOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingOrderQuery, JobPostingOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingOrderQuery, JobPostingOrderQueryVariables>(JobPostingOrderDocument, baseOptions);
      }
export function useJobPostingOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingOrderQuery, JobPostingOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingOrderQuery, JobPostingOrderQueryVariables>(JobPostingOrderDocument, baseOptions);
        }
export type JobPostingOrderQueryHookResult = ReturnType<typeof useJobPostingOrderQuery>;
export type JobPostingOrderLazyQueryHookResult = ReturnType<typeof useJobPostingOrderLazyQuery>;
export type JobPostingOrderQueryResult = ApolloReactCommon.QueryResult<JobPostingOrderQuery, JobPostingOrderQueryVariables>;
export const JobPostingReceiptListDocument = gql`
    query JobPostingReceiptList($postingId: ID!) {
  jobPostingReceiptList(input: {postingId: $postingId}) {
    __typename
    ... on JobPostingReceiptListSuccess {
      receipts {
        id
        discount
        amount
        productConsumption {
          activeFrom
          activeUntil
        }
        paymentCharged
        paymentReceived
        isFreeTrialJob
        isFreeTrialActive
        freeTrialStart
        freeTrialEnd
        freeTrialValidity
        validity
      }
    }
    ... on JobPostingError {
      error
    }
  }
}
    `;

/**
 * __useJobPostingReceiptListQuery__
 *
 * To run a query within a React component, call `useJobPostingReceiptListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingReceiptListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingReceiptListQuery({
 *   variables: {
 *      postingId: // value for 'postingId'
 *   },
 * });
 */
export function useJobPostingReceiptListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingReceiptListQuery, JobPostingReceiptListQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingReceiptListQuery, JobPostingReceiptListQueryVariables>(JobPostingReceiptListDocument, baseOptions);
      }
export function useJobPostingReceiptListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingReceiptListQuery, JobPostingReceiptListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingReceiptListQuery, JobPostingReceiptListQueryVariables>(JobPostingReceiptListDocument, baseOptions);
        }
export type JobPostingReceiptListQueryHookResult = ReturnType<typeof useJobPostingReceiptListQuery>;
export type JobPostingReceiptListLazyQueryHookResult = ReturnType<typeof useJobPostingReceiptListLazyQuery>;
export type JobPostingReceiptListQueryResult = ApolloReactCommon.QueryResult<JobPostingReceiptListQuery, JobPostingReceiptListQueryVariables>;
export const ChangeEmailDocument = gql`
    mutation ChangeEmail($newEmailAddress: String!, $newEmailAddressConfirmation: String!) {
  changeEmail(input: {newEmailAddress: $newEmailAddress, newEmailAddressConfirmation: $newEmailAddressConfirmation}) {
    __typename
    ... on UserChangeEmailSuccess {
      success
    }
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserChangeEmailError {
      inputErrors {
        newEmailAddress
        newEmailAddressConfirmation
      }
      otherError
    }
  }
}
    `;
export type ChangeEmailMutationFn = ApolloReactCommon.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      newEmailAddress: // value for 'newEmailAddress'
 *      newEmailAddressConfirmation: // value for 'newEmailAddressConfirmation'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, baseOptions);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = ApolloReactCommon.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($newPassword: String!, $newPasswordConfirmation: String!, $currentPassword: String!) {
  changePassword(input: {newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation, currentPassword: $currentPassword}) {
    __typename
    ... on UserChangePasswordSuccess {
      success
    }
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserChangePasswordError {
      inputErrors {
        newPassword
        newPasswordConfirmation
        currentPassword
      }
      otherError
    }
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmation: // value for 'newPasswordConfirmation'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($emailAddress: String!, $password: String!) {
  deleteAccount(input: {emailAddress: $emailAddress, password: $password}) {
    __typename
    ... on UserDeleteAccountSuccess {
      success
    }
    ... on UserDeleteAccountError {
      inputErrors {
        emailAddress
        password
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type DeleteAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = ApolloReactCommon.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($emailAddress: String!) {
  forgotPassword(input: {emailAddress: $emailAddress}) {
    __typename
    ... on UserForgotPasswordSuccess {
      success
    }
    ... on UserForgotPasswordError {
      inputErrors {
        emailAddress
      }
    }
  }
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($emailAddress: String!, $password: String!) {
  login(input: {emailAddress: $emailAddress, password: $password}) {
    __typename
    ... on UserLoginSuccess {
      token
    }
    ... on UserAlreadyLoggedInError {
      error
    }
    ... on UserLoginError {
      inputErrors {
        emailAddress
        password
      }
      otherError
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MasqueradeDocument = gql`
    query Masquerade($emailAddress: String!) {
  masquerade(input: {emailAddress: $emailAddress})
}
    `;

/**
 * __useMasqueradeQuery__
 *
 * To run a query within a React component, call `useMasqueradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasqueradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasqueradeQuery({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useMasqueradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MasqueradeQuery, MasqueradeQueryVariables>) {
        return ApolloReactHooks.useQuery<MasqueradeQuery, MasqueradeQueryVariables>(MasqueradeDocument, baseOptions);
      }
export function useMasqueradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MasqueradeQuery, MasqueradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MasqueradeQuery, MasqueradeQueryVariables>(MasqueradeDocument, baseOptions);
        }
export type MasqueradeQueryHookResult = ReturnType<typeof useMasqueradeQuery>;
export type MasqueradeLazyQueryHookResult = ReturnType<typeof useMasqueradeLazyQuery>;
export type MasqueradeQueryResult = ApolloReactCommon.QueryResult<MasqueradeQuery, MasqueradeQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    shareId
    firstName
    lastName
    fullName
    emailAddress
    isVerified
    roles {
      name
    }
    isSavedJobsListPublic
    savedJobsListName
    lastSigninAt
    createdAt
    quizData {
      covid
      newGrad
      remote
      somethingElse
    }
    showGamify
    progress {
      emailAlert
      signUp
      saveJobs
      inviteFriends
    }
    loggedInRealm
    pendingEmailChange
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const ResendEmailVerificationDocument = gql`
    mutation ResendEmailVerification {
  resendEmailVerification {
    __typename
    ... on UserResendEmailVerificationSuccess {
      success
    }
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserResendEmailVerificationError {
      otherError
    }
  }
}
    `;
export type ResendEmailVerificationMutationFn = ApolloReactCommon.MutationFunction<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailVerificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>(ResendEmailVerificationDocument, baseOptions);
      }
export type ResendEmailVerificationMutationHookResult = ReturnType<typeof useResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationResult = ApolloReactCommon.MutationResult<ResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(input: {token: $token, password: $password, passwordConfirmation: $passwordConfirmation}) {
    __typename
    ... on UserResetPasswordSuccess {
      success
    }
    ... on UserResetPasswordError {
      inputErrors {
        token
        password
        passwordConfirmation
      }
      otherError
    }
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($emailAddress: String!, $password: String!, $passwordConfirmation: String!, $tosAccepted: Boolean, $emailAccepted: Boolean, $country: String) {
  signup(input: {emailAddress: $emailAddress, password: $password, passwordConfirmation: $passwordConfirmation, tosAccepted: $tosAccepted, emailAccepted: $emailAccepted, country: $country}) {
    __typename
    ... on UserSignupSuccess {
      token
    }
    ... on UserAlreadyLoggedInError {
      error
    }
    ... on UserSignupError {
      inputErrors {
        emailAddress
        password
        passwordConfirmation
        tosAccepted
        emailAccepted
      }
      otherError
    }
  }
}
    `;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      tosAccepted: // value for 'tosAccepted'
 *      emailAccepted: // value for 'emailAccepted'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<SignupMutation>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($firstName: String!, $lastName: String!) {
  updateAccount(input: {firstName: $firstName, lastName: $lastName}) {
    __typename
    ... on UserUpdateAccountSuccess {
      success
    }
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserUpdateAccountError {
      inputErrors {
        firstName
        lastName
      }
    }
  }
}
    `;
export type UpdateAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = ApolloReactCommon.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UpdateQuizDataDocument = gql`
    mutation UpdateQuizData($covid: Boolean!, $newGrad: Boolean!, $remote: Boolean!, $somethingElse: Boolean!) {
  updateQuizData(input: {covid: $covid, newGrad: $newGrad, remote: $remote, somethingElse: $somethingElse}) {
    ... on UserAuthenticationRequiredError {
      error
    }
    __typename
    ... on UserUpdateQuizDataSuccess {
      success
    }
    ... on UserUpdateQuizDataError {
      inputErrors {
        covid
        newGrad
        remote
        somethingElse
      }
      otherError
    }
  }
}
    `;
export type UpdateQuizDataMutationFn = ApolloReactCommon.MutationFunction<UpdateQuizDataMutation, UpdateQuizDataMutationVariables>;

/**
 * __useUpdateQuizDataMutation__
 *
 * To run a mutation, you first call `useUpdateQuizDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuizDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuizDataMutation, { data, loading, error }] = useUpdateQuizDataMutation({
 *   variables: {
 *      covid: // value for 'covid'
 *      newGrad: // value for 'newGrad'
 *      remote: // value for 'remote'
 *      somethingElse: // value for 'somethingElse'
 *   },
 * });
 */
export function useUpdateQuizDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuizDataMutation, UpdateQuizDataMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuizDataMutation, UpdateQuizDataMutationVariables>(UpdateQuizDataDocument, baseOptions);
      }
export type UpdateQuizDataMutationHookResult = ReturnType<typeof useUpdateQuizDataMutation>;
export type UpdateQuizDataMutationResult = ApolloReactCommon.MutationResult<UpdateQuizDataMutation>;
export type UpdateQuizDataMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuizDataMutation, UpdateQuizDataMutationVariables>;
export const UserCancelPendingEmailChangeDocument = gql`
    mutation UserCancelPendingEmailChange {
  userCancelPendingEmailChange {
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserCancelPendingEmailChangeSuccess {
      success
    }
    ... on UserCancelPendingEmailChangeError {
      otherError
    }
  }
}
    `;
export type UserCancelPendingEmailChangeMutationFn = ApolloReactCommon.MutationFunction<UserCancelPendingEmailChangeMutation, UserCancelPendingEmailChangeMutationVariables>;

/**
 * __useUserCancelPendingEmailChangeMutation__
 *
 * To run a mutation, you first call `useUserCancelPendingEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCancelPendingEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCancelPendingEmailChangeMutation, { data, loading, error }] = useUserCancelPendingEmailChangeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserCancelPendingEmailChangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserCancelPendingEmailChangeMutation, UserCancelPendingEmailChangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UserCancelPendingEmailChangeMutation, UserCancelPendingEmailChangeMutationVariables>(UserCancelPendingEmailChangeDocument, baseOptions);
      }
export type UserCancelPendingEmailChangeMutationHookResult = ReturnType<typeof useUserCancelPendingEmailChangeMutation>;
export type UserCancelPendingEmailChangeMutationResult = ApolloReactCommon.MutationResult<UserCancelPendingEmailChangeMutation>;
export type UserCancelPendingEmailChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UserCancelPendingEmailChangeMutation, UserCancelPendingEmailChangeMutationVariables>;
export const UserChangeLoggedInRealmDocument = gql`
    mutation UserChangeLoggedInRealm($loggedInRealm: UserLoggedInRealm!) {
  userChangeLoggedInRealm(input: {loggedInRealm: $loggedInRealm}) {
    __typename
    ... on UserChangeLoggedInRealmSuccess {
      success
    }
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserChangeLoggedInRealmError {
      inputErrors {
        loggedInRealm
      }
      otherError
    }
  }
}
    `;
export type UserChangeLoggedInRealmMutationFn = ApolloReactCommon.MutationFunction<UserChangeLoggedInRealmMutation, UserChangeLoggedInRealmMutationVariables>;

/**
 * __useUserChangeLoggedInRealmMutation__
 *
 * To run a mutation, you first call `useUserChangeLoggedInRealmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangeLoggedInRealmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangeLoggedInRealmMutation, { data, loading, error }] = useUserChangeLoggedInRealmMutation({
 *   variables: {
 *      loggedInRealm: // value for 'loggedInRealm'
 *   },
 * });
 */
export function useUserChangeLoggedInRealmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserChangeLoggedInRealmMutation, UserChangeLoggedInRealmMutationVariables>) {
        return ApolloReactHooks.useMutation<UserChangeLoggedInRealmMutation, UserChangeLoggedInRealmMutationVariables>(UserChangeLoggedInRealmDocument, baseOptions);
      }
export type UserChangeLoggedInRealmMutationHookResult = ReturnType<typeof useUserChangeLoggedInRealmMutation>;
export type UserChangeLoggedInRealmMutationResult = ApolloReactCommon.MutationResult<UserChangeLoggedInRealmMutation>;
export type UserChangeLoggedInRealmMutationOptions = ApolloReactCommon.BaseMutationOptions<UserChangeLoggedInRealmMutation, UserChangeLoggedInRealmMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($token: String!) {
  verifyEmail(input: {token: $token}) {
    __typename
    ... on UserVerifyEmailSuccess {
      success
    }
    ... on UserVerifyEmailError {
      inputErrors {
        token
      }
      otherError
    }
  }
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ArticleDocument = gql`
    query Article($slug: String!) {
  entry(slug: $slug) {
    ... on Blog {
      title
      seoTitleTag
      dateCreated
      postDate
      id
      uri
      description
      heroImage {
        url
      }
      categories {
        title
        uri
      }
      tags
      articleBody {
        ... on ArticleBodyText {
          __typename
          text {
            __typename
            totalPages
            content
          }
          position
        }
        ... on ArticleBodyHeading {
          __typename
          heading
        }
        ... on ArticleBodyQuote {
          __typename
          quote
          attribution
          position
        }
        ... on ArticleBodyNewsection {
          __typename
          sectionheading
        }
        ... on ArticleBodyPullquote {
          __typename
          pullquote
          position
        }
        ... on ArticleBodyImage {
          __typename
          image {
            url
          }
          caption {
            __typename
            totalPages
            content
          }
          alttext
          position
        }
        ... on ArticleBodySearchcta {
          __typename
          headline
          searchctaurl
          searchctatext
        }
      }
    }
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        return ApolloReactHooks.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
      }
export function useArticleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = ApolloReactCommon.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const CategoriesConnectionDocument = gql`
    query CategoriesConnection($slug: String, $limit: Int!, $offset: Int) {
  categoriesConnection(slug: $slug, limit: 1) {
    edges {
      relatedEntries(limit: $limit, offset: $offset) {
        totalCount
        entries {
          __typename
          ... on Blog {
            id
            uri
            title
            description
            heroImage {
              url
            }
            categories {
              title
              uri
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCategoriesConnectionQuery__
 *
 * To run a query within a React component, call `useCategoriesConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesConnectionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCategoriesConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesConnectionQuery, CategoriesConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoriesConnectionQuery, CategoriesConnectionQueryVariables>(CategoriesConnectionDocument, baseOptions);
      }
export function useCategoriesConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesConnectionQuery, CategoriesConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoriesConnectionQuery, CategoriesConnectionQueryVariables>(CategoriesConnectionDocument, baseOptions);
        }
export type CategoriesConnectionQueryHookResult = ReturnType<typeof useCategoriesConnectionQuery>;
export type CategoriesConnectionLazyQueryHookResult = ReturnType<typeof useCategoriesConnectionLazyQuery>;
export type CategoriesConnectionQueryResult = ApolloReactCommon.QueryResult<CategoriesConnectionQuery, CategoriesConnectionQueryVariables>;
export const DraftArticleDocument = gql`
    query DraftArticle($draftId: Int!, $slug: String!) {
  draft(draftId: $draftId, slug: $slug) {
    ... on Blog {
      title
      dateCreated
      postDate
      id
      uri
      description
      heroImage {
        url
      }
      categories {
        title
        uri
      }
      tags
      articleBody {
        ... on ArticleBodyText {
          __typename
          text {
            __typename
            totalPages
            content
          }
          position
        }
        ... on ArticleBodyHeading {
          __typename
          heading
        }
        ... on ArticleBodyQuote {
          __typename
          quote
          attribution
          position
        }
        ... on ArticleBodyNewsection {
          __typename
          sectionheading
        }
        ... on ArticleBodyPullquote {
          __typename
          pullquote
          position
        }
        ... on ArticleBodyImage {
          __typename
          image {
            url
          }
          caption {
            __typename
            totalPages
            content
          }
          alttext
          position
        }
      }
    }
  }
}
    `;

/**
 * __useDraftArticleQuery__
 *
 * To run a query within a React component, call `useDraftArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftArticleQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDraftArticleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DraftArticleQuery, DraftArticleQueryVariables>) {
        return ApolloReactHooks.useQuery<DraftArticleQuery, DraftArticleQueryVariables>(DraftArticleDocument, baseOptions);
      }
export function useDraftArticleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DraftArticleQuery, DraftArticleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DraftArticleQuery, DraftArticleQueryVariables>(DraftArticleDocument, baseOptions);
        }
export type DraftArticleQueryHookResult = ReturnType<typeof useDraftArticleQuery>;
export type DraftArticleLazyQueryHookResult = ReturnType<typeof useDraftArticleLazyQuery>;
export type DraftArticleQueryResult = ApolloReactCommon.QueryResult<DraftArticleQuery, DraftArticleQueryVariables>;
export const EntriesDocument = gql`
    query Entries($limit: Int!, $offset: Int, $slug: String, $search: String) {
  entries(section: [blog], limit: $limit, offset: $offset, search: $search) {
    __typename
    ... on Blog {
      id
      uri
      title
      description
      heroImage {
        url
      }
      categories {
        title
        uri
      }
    }
  }
  entryCount(slug: $slug)
}
    `;

/**
 * __useEntriesQuery__
 *
 * To run a query within a React component, call `useEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      slug: // value for 'slug'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntriesQuery, EntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntriesQuery, EntriesQueryVariables>(EntriesDocument, baseOptions);
      }
export function useEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntriesQuery, EntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntriesQuery, EntriesQueryVariables>(EntriesDocument, baseOptions);
        }
export type EntriesQueryHookResult = ReturnType<typeof useEntriesQuery>;
export type EntriesLazyQueryHookResult = ReturnType<typeof useEntriesLazyQuery>;
export type EntriesQueryResult = ApolloReactCommon.QueryResult<EntriesQuery, EntriesQueryVariables>;
export const RelatedArticlesDocument = gql`
    query RelatedArticles($search: String, $limit: Int, $orderBy: String, $idNot: Int) {
  entries(search: $search, limit: $limit, orderBy: $orderBy, idNot: [$idNot]) {
    ... on Blog {
      id
      title
      uri
      tags
      heroImage {
        url
      }
      categories {
        title
        uri
      }
    }
  }
}
    `;

/**
 * __useRelatedArticlesQuery__
 *
 * To run a query within a React component, call `useRelatedArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedArticlesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      idNot: // value for 'idNot'
 *   },
 * });
 */
export function useRelatedArticlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RelatedArticlesQuery, RelatedArticlesQueryVariables>) {
        return ApolloReactHooks.useQuery<RelatedArticlesQuery, RelatedArticlesQueryVariables>(RelatedArticlesDocument, baseOptions);
      }
export function useRelatedArticlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RelatedArticlesQuery, RelatedArticlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RelatedArticlesQuery, RelatedArticlesQueryVariables>(RelatedArticlesDocument, baseOptions);
        }
export type RelatedArticlesQueryHookResult = ReturnType<typeof useRelatedArticlesQuery>;
export type RelatedArticlesLazyQueryHookResult = ReturnType<typeof useRelatedArticlesLazyQuery>;
export type RelatedArticlesQueryResult = ApolloReactCommon.QueryResult<RelatedArticlesQuery, RelatedArticlesQueryVariables>;
export const BrowseJobsDocument = gql`
    query BrowseJobs($country: String, $state: String, $stateLimit: Int, $stateOffset: Int, $stateThreshold: Int, $city: BrowseCityInput, $cityLimit: Int, $cityOffset: Int, $cityThreshold: Int, $industry: String, $industryLimit: Int, $industryOffset: Int, $industryThreshold: Int, $role: BrowseRoleInput, $roleLimit: Int, $roleOffset: Int, $roleThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    country
    state(limit: $stateLimit, offset: $stateOffset, threshold: $stateThreshold) {
      state
      stateCode
    }
    industry(limit: $industryLimit, offset: $industryOffset, threshold: $industryThreshold)
    city(limit: $cityLimit, offset: $cityOffset, threshold: $cityThreshold) {
      city
      state
      stateCode
    }
    role(limit: $roleLimit, offset: $roleOffset, threshold: $roleThreshold) {
      role
      industry
    }
    roleContent {
      keyword
      description
      responsibilities
      payinfo
      requirements
      skills
      icons
      team
    }
    cityContent {
      location
      description
      facts
    }
  }
}
    `;

/**
 * __useBrowseJobsQuery__
 *
 * To run a query within a React component, call `useBrowseJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowseJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowseJobsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      stateLimit: // value for 'stateLimit'
 *      stateOffset: // value for 'stateOffset'
 *      stateThreshold: // value for 'stateThreshold'
 *      city: // value for 'city'
 *      cityLimit: // value for 'cityLimit'
 *      cityOffset: // value for 'cityOffset'
 *      cityThreshold: // value for 'cityThreshold'
 *      industry: // value for 'industry'
 *      industryLimit: // value for 'industryLimit'
 *      industryOffset: // value for 'industryOffset'
 *      industryThreshold: // value for 'industryThreshold'
 *      role: // value for 'role'
 *      roleLimit: // value for 'roleLimit'
 *      roleOffset: // value for 'roleOffset'
 *      roleThreshold: // value for 'roleThreshold'
 *   },
 * });
 */
export function useBrowseJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrowseJobsQuery, BrowseJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<BrowseJobsQuery, BrowseJobsQueryVariables>(BrowseJobsDocument, baseOptions);
      }
export function useBrowseJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrowseJobsQuery, BrowseJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrowseJobsQuery, BrowseJobsQueryVariables>(BrowseJobsDocument, baseOptions);
        }
export type BrowseJobsQueryHookResult = ReturnType<typeof useBrowseJobsQuery>;
export type BrowseJobsLazyQueryHookResult = ReturnType<typeof useBrowseJobsLazyQuery>;
export type BrowseJobsQueryResult = ApolloReactCommon.QueryResult<BrowseJobsQuery, BrowseJobsQueryVariables>;
export const BrowseNearbyCitiesDocument = gql`
    query BrowseNearbyCities($country: String, $city: BrowseCityInput!, $radius: Int!, $industry: String, $role: BrowseRoleInput, $limit: Int, $offset: Int, $threshold: Int) {
  browseNearbyCities(country: $country, city: $city, radius: $radius, industry: $industry, role: $role, limit: $limit, offset: $offset, threshold: $threshold) {
    city
    state
    stateCode
  }
}
    `;

/**
 * __useBrowseNearbyCitiesQuery__
 *
 * To run a query within a React component, call `useBrowseNearbyCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowseNearbyCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowseNearbyCitiesQuery({
 *   variables: {
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      radius: // value for 'radius'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      threshold: // value for 'threshold'
 *   },
 * });
 */
export function useBrowseNearbyCitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrowseNearbyCitiesQuery, BrowseNearbyCitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<BrowseNearbyCitiesQuery, BrowseNearbyCitiesQueryVariables>(BrowseNearbyCitiesDocument, baseOptions);
      }
export function useBrowseNearbyCitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrowseNearbyCitiesQuery, BrowseNearbyCitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrowseNearbyCitiesQuery, BrowseNearbyCitiesQueryVariables>(BrowseNearbyCitiesDocument, baseOptions);
        }
export type BrowseNearbyCitiesQueryHookResult = ReturnType<typeof useBrowseNearbyCitiesQuery>;
export type BrowseNearbyCitiesLazyQueryHookResult = ReturnType<typeof useBrowseNearbyCitiesLazyQuery>;
export type BrowseNearbyCitiesQueryResult = ApolloReactCommon.QueryResult<BrowseNearbyCitiesQuery, BrowseNearbyCitiesQueryVariables>;
export const BrowseStatsQueryDocument = gql`
    query BrowseStatsQuery($city: BrowseCityInput!, $numCompanies: Int!, $jobCountPrecision: Int!) {
  browseStats(city: $city, numCompanies: $numCompanies, jobCountPrecision: $jobCountPrecision) {
    companies
    jobCount
    companyCount
  }
}
    `;

/**
 * __useBrowseStatsQueryQuery__
 *
 * To run a query within a React component, call `useBrowseStatsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowseStatsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowseStatsQueryQuery({
 *   variables: {
 *      city: // value for 'city'
 *      numCompanies: // value for 'numCompanies'
 *      jobCountPrecision: // value for 'jobCountPrecision'
 *   },
 * });
 */
export function useBrowseStatsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrowseStatsQueryQuery, BrowseStatsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<BrowseStatsQueryQuery, BrowseStatsQueryQueryVariables>(BrowseStatsQueryDocument, baseOptions);
      }
export function useBrowseStatsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrowseStatsQueryQuery, BrowseStatsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrowseStatsQueryQuery, BrowseStatsQueryQueryVariables>(BrowseStatsQueryDocument, baseOptions);
        }
export type BrowseStatsQueryQueryHookResult = ReturnType<typeof useBrowseStatsQueryQuery>;
export type BrowseStatsQueryLazyQueryHookResult = ReturnType<typeof useBrowseStatsQueryLazyQuery>;
export type BrowseStatsQueryQueryResult = ApolloReactCommon.QueryResult<BrowseStatsQueryQuery, BrowseStatsQueryQueryVariables>;
export const CityIndustryRoleDocument = gql`
    query CityIndustryRole($country: String, $state: String, $city: BrowseCityInput, $cityLimit: Int, $cityOffset: Int, $cityThreshold: Int, $industry: String, $industryLimit: Int, $industryOffset: Int, $industryThreshold: Int, $role: BrowseRoleInput, $roleLimit: Int, $roleOffset: Int, $roleThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    country
    industry(limit: $industryLimit, offset: $industryOffset, threshold: $industryThreshold)
    city(limit: $cityLimit, offset: $cityOffset, threshold: $cityThreshold) {
      city
      state
      stateCode
    }
    role(limit: $roleLimit, offset: $roleOffset, threshold: $roleThreshold) {
      role
      industry
    }
  }
}
    `;

/**
 * __useCityIndustryRoleQuery__
 *
 * To run a query within a React component, call `useCityIndustryRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityIndustryRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityIndustryRoleQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      cityLimit: // value for 'cityLimit'
 *      cityOffset: // value for 'cityOffset'
 *      cityThreshold: // value for 'cityThreshold'
 *      industry: // value for 'industry'
 *      industryLimit: // value for 'industryLimit'
 *      industryOffset: // value for 'industryOffset'
 *      industryThreshold: // value for 'industryThreshold'
 *      role: // value for 'role'
 *      roleLimit: // value for 'roleLimit'
 *      roleOffset: // value for 'roleOffset'
 *      roleThreshold: // value for 'roleThreshold'
 *   },
 * });
 */
export function useCityIndustryRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityIndustryRoleQuery, CityIndustryRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<CityIndustryRoleQuery, CityIndustryRoleQueryVariables>(CityIndustryRoleDocument, baseOptions);
      }
export function useCityIndustryRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityIndustryRoleQuery, CityIndustryRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityIndustryRoleQuery, CityIndustryRoleQueryVariables>(CityIndustryRoleDocument, baseOptions);
        }
export type CityIndustryRoleQueryHookResult = ReturnType<typeof useCityIndustryRoleQuery>;
export type CityIndustryRoleLazyQueryHookResult = ReturnType<typeof useCityIndustryRoleLazyQuery>;
export type CityIndustryRoleQueryResult = ApolloReactCommon.QueryResult<CityIndustryRoleQuery, CityIndustryRoleQueryVariables>;
export const CityIndustryStateDocument = gql`
    query CityIndustryState($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput, $cityLimit: Int, $cityOffset: Int, $cityThreshold: Int, $industryLimit: Int, $industryOffset: Int, $industryThreshold: Int, $stateLimit: Int, $stateOffset: Int, $stateThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    country
    industry(limit: $industryLimit, offset: $industryOffset, threshold: $industryThreshold)
    city(limit: $cityLimit, offset: $cityOffset, threshold: $cityThreshold) {
      city
      state
      stateCode
    }
    state(limit: $stateLimit, offset: $stateOffset, threshold: $stateThreshold) {
      state
      stateCode
    }
    roleContent {
      keyword
      description
      responsibilities
      payinfo
      requirements
      skills
      icons
      team
    }
    cityContent {
      location
      description
      facts
    }
  }
}
    `;

/**
 * __useCityIndustryStateQuery__
 *
 * To run a query within a React component, call `useCityIndustryStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityIndustryStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityIndustryStateQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      cityLimit: // value for 'cityLimit'
 *      cityOffset: // value for 'cityOffset'
 *      cityThreshold: // value for 'cityThreshold'
 *      industryLimit: // value for 'industryLimit'
 *      industryOffset: // value for 'industryOffset'
 *      industryThreshold: // value for 'industryThreshold'
 *      stateLimit: // value for 'stateLimit'
 *      stateOffset: // value for 'stateOffset'
 *      stateThreshold: // value for 'stateThreshold'
 *   },
 * });
 */
export function useCityIndustryStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityIndustryStateQuery, CityIndustryStateQueryVariables>) {
        return ApolloReactHooks.useQuery<CityIndustryStateQuery, CityIndustryStateQueryVariables>(CityIndustryStateDocument, baseOptions);
      }
export function useCityIndustryStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityIndustryStateQuery, CityIndustryStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityIndustryStateQuery, CityIndustryStateQueryVariables>(CityIndustryStateDocument, baseOptions);
        }
export type CityIndustryStateQueryHookResult = ReturnType<typeof useCityIndustryStateQuery>;
export type CityIndustryStateLazyQueryHookResult = ReturnType<typeof useCityIndustryStateLazyQuery>;
export type CityIndustryStateQueryResult = ApolloReactCommon.QueryResult<CityIndustryStateQuery, CityIndustryStateQueryVariables>;
export const CityDocument = gql`
    query City($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput, $cityLimit: Int, $cityOffset: Int, $cityThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    city(limit: $cityLimit, offset: $cityOffset, threshold: $cityThreshold) {
      city
      state
      stateCode
    }
  }
}
    `;

/**
 * __useCityQuery__
 *
 * To run a query within a React component, call `useCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      cityLimit: // value for 'cityLimit'
 *      cityOffset: // value for 'cityOffset'
 *      cityThreshold: // value for 'cityThreshold'
 *   },
 * });
 */
export function useCityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityQuery, CityQueryVariables>) {
        return ApolloReactHooks.useQuery<CityQuery, CityQueryVariables>(CityDocument, baseOptions);
      }
export function useCityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityQuery, CityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityQuery, CityQueryVariables>(CityDocument, baseOptions);
        }
export type CityQueryHookResult = ReturnType<typeof useCityQuery>;
export type CityLazyQueryHookResult = ReturnType<typeof useCityLazyQuery>;
export type CityQueryResult = ApolloReactCommon.QueryResult<CityQuery, CityQueryVariables>;
export const CityRoleDocument = gql`
    query CityRole($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput, $cityLimit: Int, $cityOffset: Int, $cityThreshold: Int, $roleLimit: Int, $roleOffset: Int, $roleThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    city(limit: $cityLimit, offset: $cityOffset, threshold: $cityThreshold) {
      city
      state
      stateCode
    }
    role(limit: $roleLimit, offset: $roleOffset, threshold: $roleThreshold) {
      role
      industry
    }
  }
}
    `;

/**
 * __useCityRoleQuery__
 *
 * To run a query within a React component, call `useCityRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityRoleQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      cityLimit: // value for 'cityLimit'
 *      cityOffset: // value for 'cityOffset'
 *      cityThreshold: // value for 'cityThreshold'
 *      roleLimit: // value for 'roleLimit'
 *      roleOffset: // value for 'roleOffset'
 *      roleThreshold: // value for 'roleThreshold'
 *   },
 * });
 */
export function useCityRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityRoleQuery, CityRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<CityRoleQuery, CityRoleQueryVariables>(CityRoleDocument, baseOptions);
      }
export function useCityRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityRoleQuery, CityRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityRoleQuery, CityRoleQueryVariables>(CityRoleDocument, baseOptions);
        }
export type CityRoleQueryHookResult = ReturnType<typeof useCityRoleQuery>;
export type CityRoleLazyQueryHookResult = ReturnType<typeof useCityRoleLazyQuery>;
export type CityRoleQueryResult = ApolloReactCommon.QueryResult<CityRoleQuery, CityRoleQueryVariables>;
export const CityRoleStateDocument = gql`
    query CityRoleState($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput, $cityLimit: Int, $cityOffset: Int, $cityThreshold: Int, $stateLimit: Int, $stateOffset: Int, $stateThreshold: Int, $roleLimit: Int, $roleOffset: Int, $roleThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    country
    city(limit: $cityLimit, offset: $cityOffset, threshold: $cityThreshold) {
      city
      state
      stateCode
    }
    role(limit: $roleLimit, offset: $roleOffset, threshold: $roleThreshold) {
      role
      industry
    }
    state(limit: $stateLimit, offset: $stateOffset, threshold: $stateThreshold) {
      state
      stateCode
    }
  }
}
    `;

/**
 * __useCityRoleStateQuery__
 *
 * To run a query within a React component, call `useCityRoleStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityRoleStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityRoleStateQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      cityLimit: // value for 'cityLimit'
 *      cityOffset: // value for 'cityOffset'
 *      cityThreshold: // value for 'cityThreshold'
 *      stateLimit: // value for 'stateLimit'
 *      stateOffset: // value for 'stateOffset'
 *      stateThreshold: // value for 'stateThreshold'
 *      roleLimit: // value for 'roleLimit'
 *      roleOffset: // value for 'roleOffset'
 *      roleThreshold: // value for 'roleThreshold'
 *   },
 * });
 */
export function useCityRoleStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityRoleStateQuery, CityRoleStateQueryVariables>) {
        return ApolloReactHooks.useQuery<CityRoleStateQuery, CityRoleStateQueryVariables>(CityRoleStateDocument, baseOptions);
      }
export function useCityRoleStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityRoleStateQuery, CityRoleStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityRoleStateQuery, CityRoleStateQueryVariables>(CityRoleStateDocument, baseOptions);
        }
export type CityRoleStateQueryHookResult = ReturnType<typeof useCityRoleStateQuery>;
export type CityRoleStateLazyQueryHookResult = ReturnType<typeof useCityRoleStateLazyQuery>;
export type CityRoleStateQueryResult = ApolloReactCommon.QueryResult<CityRoleStateQuery, CityRoleStateQueryVariables>;
export const FetchCityDataDocument = gql`
    query FetchCityData($city: String!, $state: String!, $country: String) {
  fetchCityData(city: $city, state: $state, country: $country) {
    __typename
    ... on CityData {
      description
      population
      coli
      minimum_wage
      tipped_wage
      state_uses_federal_wages
      state_has_district_wages
      city
      state
      state_code
      country
    }
  }
}
    `;

/**
 * __useFetchCityDataQuery__
 *
 * To run a query within a React component, call `useFetchCityDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCityDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCityDataQuery({
 *   variables: {
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useFetchCityDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchCityDataQuery, FetchCityDataQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchCityDataQuery, FetchCityDataQueryVariables>(FetchCityDataDocument, baseOptions);
      }
export function useFetchCityDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCityDataQuery, FetchCityDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchCityDataQuery, FetchCityDataQueryVariables>(FetchCityDataDocument, baseOptions);
        }
export type FetchCityDataQueryHookResult = ReturnType<typeof useFetchCityDataQuery>;
export type FetchCityDataLazyQueryHookResult = ReturnType<typeof useFetchCityDataLazyQuery>;
export type FetchCityDataQueryResult = ApolloReactCommon.QueryResult<FetchCityDataQuery, FetchCityDataQueryVariables>;
export const FetchJobDataDocument = gql`
    query FetchJobData($city: String!, $state: String!, $role: String!) {
  fetchJobData(city: $city, state: $state, role: $role) {
    __typename
    ... on JobData {
      low_salary
      average_salary
      high_salary
      hourly_salary
      content
    }
  }
}
    `;

/**
 * __useFetchJobDataQuery__
 *
 * To run a query within a React component, call `useFetchJobDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchJobDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchJobDataQuery({
 *   variables: {
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useFetchJobDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchJobDataQuery, FetchJobDataQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchJobDataQuery, FetchJobDataQueryVariables>(FetchJobDataDocument, baseOptions);
      }
export function useFetchJobDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchJobDataQuery, FetchJobDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchJobDataQuery, FetchJobDataQueryVariables>(FetchJobDataDocument, baseOptions);
        }
export type FetchJobDataQueryHookResult = ReturnType<typeof useFetchJobDataQuery>;
export type FetchJobDataLazyQueryHookResult = ReturnType<typeof useFetchJobDataLazyQuery>;
export type FetchJobDataQueryResult = ApolloReactCommon.QueryResult<FetchJobDataQuery, FetchJobDataQueryVariables>;
export const IndustryDocument = gql`
    query Industry($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput, $industryLimit: Int, $industryOffset: Int, $industryThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    industry(limit: $industryLimit, offset: $industryOffset, threshold: $industryThreshold)
  }
}
    `;

/**
 * __useIndustryQuery__
 *
 * To run a query within a React component, call `useIndustryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      industryLimit: // value for 'industryLimit'
 *      industryOffset: // value for 'industryOffset'
 *      industryThreshold: // value for 'industryThreshold'
 *   },
 * });
 */
export function useIndustryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IndustryQuery, IndustryQueryVariables>) {
        return ApolloReactHooks.useQuery<IndustryQuery, IndustryQueryVariables>(IndustryDocument, baseOptions);
      }
export function useIndustryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IndustryQuery, IndustryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IndustryQuery, IndustryQueryVariables>(IndustryDocument, baseOptions);
        }
export type IndustryQueryHookResult = ReturnType<typeof useIndustryQuery>;
export type IndustryLazyQueryHookResult = ReturnType<typeof useIndustryLazyQuery>;
export type IndustryQueryResult = ApolloReactCommon.QueryResult<IndustryQuery, IndustryQueryVariables>;
export const JobCountDocument = gql`
    query JobCount($country: String, $state: String, $city: String, $industry: String, $role: String) {
  browseJobCount(country: $country, state: $state, city: $city, industry: $industry, role: $role)
}
    `;

/**
 * __useJobCountQuery__
 *
 * To run a query within a React component, call `useJobCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCountQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useJobCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobCountQuery, JobCountQueryVariables>) {
        return ApolloReactHooks.useQuery<JobCountQuery, JobCountQueryVariables>(JobCountDocument, baseOptions);
      }
export function useJobCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobCountQuery, JobCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobCountQuery, JobCountQueryVariables>(JobCountDocument, baseOptions);
        }
export type JobCountQueryHookResult = ReturnType<typeof useJobCountQuery>;
export type JobCountLazyQueryHookResult = ReturnType<typeof useJobCountLazyQuery>;
export type JobCountQueryResult = ApolloReactCommon.QueryResult<JobCountQuery, JobCountQueryVariables>;
export const NearbyCitiesDocument = gql`
    query NearbyCities($country: String, $city: BrowseCityInput!, $radius: Int!, $industry: String, $role: BrowseRoleInput, $limit: Int, $offset: Int, $threshold: Int) {
  browseNearbyCities(country: $country, city: $city, radius: $radius, industry: $industry, role: $role, limit: $limit, offset: $offset, threshold: $threshold) {
    city
    state
    stateCode
  }
}
    `;

/**
 * __useNearbyCitiesQuery__
 *
 * To run a query within a React component, call `useNearbyCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNearbyCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNearbyCitiesQuery({
 *   variables: {
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      radius: // value for 'radius'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      threshold: // value for 'threshold'
 *   },
 * });
 */
export function useNearbyCitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NearbyCitiesQuery, NearbyCitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<NearbyCitiesQuery, NearbyCitiesQueryVariables>(NearbyCitiesDocument, baseOptions);
      }
export function useNearbyCitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NearbyCitiesQuery, NearbyCitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NearbyCitiesQuery, NearbyCitiesQueryVariables>(NearbyCitiesDocument, baseOptions);
        }
export type NearbyCitiesQueryHookResult = ReturnType<typeof useNearbyCitiesQuery>;
export type NearbyCitiesLazyQueryHookResult = ReturnType<typeof useNearbyCitiesLazyQuery>;
export type NearbyCitiesQueryResult = ApolloReactCommon.QueryResult<NearbyCitiesQuery, NearbyCitiesQueryVariables>;
export const NearByStatesDocument = gql`
    query NearByStates($country: String, $state: String!, $radius: Int!, $industry: String, $role: BrowseRoleInput, $limit: Int, $offset: Int, $threshold: Int) {
  browseNearbyStates(country: $country, state: $state, radius: $radius, industry: $industry, role: $role, limit: $limit, offset: $offset, threshold: $threshold) {
    state
    stateCode
  }
}
    `;

/**
 * __useNearByStatesQuery__
 *
 * To run a query within a React component, call `useNearByStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNearByStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNearByStatesQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      radius: // value for 'radius'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      threshold: // value for 'threshold'
 *   },
 * });
 */
export function useNearByStatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NearByStatesQuery, NearByStatesQueryVariables>) {
        return ApolloReactHooks.useQuery<NearByStatesQuery, NearByStatesQueryVariables>(NearByStatesDocument, baseOptions);
      }
export function useNearByStatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NearByStatesQuery, NearByStatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NearByStatesQuery, NearByStatesQueryVariables>(NearByStatesDocument, baseOptions);
        }
export type NearByStatesQueryHookResult = ReturnType<typeof useNearByStatesQuery>;
export type NearByStatesLazyQueryHookResult = ReturnType<typeof useNearByStatesLazyQuery>;
export type NearByStatesQueryResult = ApolloReactCommon.QueryResult<NearByStatesQuery, NearByStatesQueryVariables>;
export const NormalizeBrowseArgsDocument = gql`
    query NormalizeBrowseArgs($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput) {
  normalizeBrowseArgs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    country
    state
    city
    industry
    role
  }
}
    `;

/**
 * __useNormalizeBrowseArgsQuery__
 *
 * To run a query within a React component, call `useNormalizeBrowseArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNormalizeBrowseArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNormalizeBrowseArgsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useNormalizeBrowseArgsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NormalizeBrowseArgsQuery, NormalizeBrowseArgsQueryVariables>) {
        return ApolloReactHooks.useQuery<NormalizeBrowseArgsQuery, NormalizeBrowseArgsQueryVariables>(NormalizeBrowseArgsDocument, baseOptions);
      }
export function useNormalizeBrowseArgsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NormalizeBrowseArgsQuery, NormalizeBrowseArgsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NormalizeBrowseArgsQuery, NormalizeBrowseArgsQueryVariables>(NormalizeBrowseArgsDocument, baseOptions);
        }
export type NormalizeBrowseArgsQueryHookResult = ReturnType<typeof useNormalizeBrowseArgsQuery>;
export type NormalizeBrowseArgsLazyQueryHookResult = ReturnType<typeof useNormalizeBrowseArgsLazyQuery>;
export type NormalizeBrowseArgsQueryResult = ApolloReactCommon.QueryResult<NormalizeBrowseArgsQuery, NormalizeBrowseArgsQueryVariables>;
export const RelatedRoleDocument = gql`
    query RelatedRole($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput, $industryLimit: Int, $industryOffset: Int, $industryThreshold: Int, $relatedRoleLimit: Int, $relatedRoleOffset: Int, $relatedRoleThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    industry(limit: $industryLimit, offset: $industryOffset, threshold: $industryThreshold)
    relatedRole(limit: $relatedRoleLimit, offset: $relatedRoleOffset, threshold: $relatedRoleThreshold) {
      role
    }
    __typename
  }
}
    `;

/**
 * __useRelatedRoleQuery__
 *
 * To run a query within a React component, call `useRelatedRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedRoleQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      industryLimit: // value for 'industryLimit'
 *      industryOffset: // value for 'industryOffset'
 *      industryThreshold: // value for 'industryThreshold'
 *      relatedRoleLimit: // value for 'relatedRoleLimit'
 *      relatedRoleOffset: // value for 'relatedRoleOffset'
 *      relatedRoleThreshold: // value for 'relatedRoleThreshold'
 *   },
 * });
 */
export function useRelatedRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RelatedRoleQuery, RelatedRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<RelatedRoleQuery, RelatedRoleQueryVariables>(RelatedRoleDocument, baseOptions);
      }
export function useRelatedRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RelatedRoleQuery, RelatedRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RelatedRoleQuery, RelatedRoleQueryVariables>(RelatedRoleDocument, baseOptions);
        }
export type RelatedRoleQueryHookResult = ReturnType<typeof useRelatedRoleQuery>;
export type RelatedRoleLazyQueryHookResult = ReturnType<typeof useRelatedRoleLazyQuery>;
export type RelatedRoleQueryResult = ApolloReactCommon.QueryResult<RelatedRoleQuery, RelatedRoleQueryVariables>;
export const RoleDocument = gql`
    query Role($country: String, $state: String, $city: BrowseCityInput, $industry: String, $role: BrowseRoleInput, $roleLimit: Int, $roleOffset: Int, $roleThreshold: Int) {
  browseJobs(country: $country, state: $state, city: $city, industry: $industry, role: $role) {
    role(limit: $roleLimit, offset: $roleOffset, threshold: $roleThreshold) {
      role
      industry
    }
  }
}
    `;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      industry: // value for 'industry'
 *      role: // value for 'role'
 *      roleLimit: // value for 'roleLimit'
 *      roleOffset: // value for 'roleOffset'
 *      roleThreshold: // value for 'roleThreshold'
 *   },
 * });
 */
export function useRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
        return ApolloReactHooks.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, baseOptions);
      }
export function useRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, baseOptions);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = ApolloReactCommon.QueryResult<RoleQuery, RoleQueryVariables>;
export const TopCompaniesDocument = gql`
    query TopCompanies($companyQuery: SearchQuery!, $limit: Int) {
  topCompanies(query: $companyQuery, limit: $limit) {
    company
    count
  }
}
    `;

/**
 * __useTopCompaniesQuery__
 *
 * To run a query within a React component, call `useTopCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopCompaniesQuery({
 *   variables: {
 *      companyQuery: // value for 'companyQuery'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTopCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TopCompaniesQuery, TopCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<TopCompaniesQuery, TopCompaniesQueryVariables>(TopCompaniesDocument, baseOptions);
      }
export function useTopCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TopCompaniesQuery, TopCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TopCompaniesQuery, TopCompaniesQueryVariables>(TopCompaniesDocument, baseOptions);
        }
export type TopCompaniesQueryHookResult = ReturnType<typeof useTopCompaniesQuery>;
export type TopCompaniesLazyQueryHookResult = ReturnType<typeof useTopCompaniesLazyQuery>;
export type TopCompaniesQueryResult = ApolloReactCommon.QueryResult<TopCompaniesQuery, TopCompaniesQueryVariables>;
export const TopJobTitlesDocument = gql`
    query TopJobTitles($jobTitlesQuery: SearchQuery!, $limit: Int) {
  topJobTitles(query: $jobTitlesQuery, limit: $limit) {
    title
    count
  }
}
    `;

/**
 * __useTopJobTitlesQuery__
 *
 * To run a query within a React component, call `useTopJobTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopJobTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopJobTitlesQuery({
 *   variables: {
 *      jobTitlesQuery: // value for 'jobTitlesQuery'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTopJobTitlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TopJobTitlesQuery, TopJobTitlesQueryVariables>) {
        return ApolloReactHooks.useQuery<TopJobTitlesQuery, TopJobTitlesQueryVariables>(TopJobTitlesDocument, baseOptions);
      }
export function useTopJobTitlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TopJobTitlesQuery, TopJobTitlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TopJobTitlesQuery, TopJobTitlesQueryVariables>(TopJobTitlesDocument, baseOptions);
        }
export type TopJobTitlesQueryHookResult = ReturnType<typeof useTopJobTitlesQuery>;
export type TopJobTitlesLazyQueryHookResult = ReturnType<typeof useTopJobTitlesLazyQuery>;
export type TopJobTitlesQueryResult = ApolloReactCommon.QueryResult<TopJobTitlesQuery, TopJobTitlesQueryVariables>;
export const CollaboratorInviteDocument = gql`
    mutation CollaboratorInvite($jobCollectionId: String!, $emailAddress: String!) {
  collaboratorInvite(input: {jobCollectionId: $jobCollectionId, emailAddress: $emailAddress}) {
    __typename
    ... on CollaboratorInviteSuccess {
      success
      invitedUser {
        ...JobCollectionPageInvitedUser
      }
    }
    ... on CollaboratorInviteError {
      inputErrors {
        jobCollectionId
        emailAddress
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserUnauthorizedError {
      unauthorizedError
    }
  }
}
    ${JobCollectionPageInvitedUserFragmentDoc}`;
export type CollaboratorInviteMutationFn = ApolloReactCommon.MutationFunction<CollaboratorInviteMutation, CollaboratorInviteMutationVariables>;

/**
 * __useCollaboratorInviteMutation__
 *
 * To run a mutation, you first call `useCollaboratorInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollaboratorInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collaboratorInviteMutation, { data, loading, error }] = useCollaboratorInviteMutation({
 *   variables: {
 *      jobCollectionId: // value for 'jobCollectionId'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useCollaboratorInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CollaboratorInviteMutation, CollaboratorInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<CollaboratorInviteMutation, CollaboratorInviteMutationVariables>(CollaboratorInviteDocument, baseOptions);
      }
export type CollaboratorInviteMutationHookResult = ReturnType<typeof useCollaboratorInviteMutation>;
export type CollaboratorInviteMutationResult = ApolloReactCommon.MutationResult<CollaboratorInviteMutation>;
export type CollaboratorInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<CollaboratorInviteMutation, CollaboratorInviteMutationVariables>;
export const CollaboratorRemoveDocument = gql`
    mutation CollaboratorRemove($id: ID!) {
  collaboratorRemove(input: {id: $id}) {
    __typename
    ... on CollaboratorRemoveSuccess {
      success
      jobCollection {
        ...JobCollectionPageJobCollection
      }
    }
    ... on CollaboratorRemoveError {
      inputErrors {
        id
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${JobCollectionPageJobCollectionFragmentDoc}`;
export type CollaboratorRemoveMutationFn = ApolloReactCommon.MutationFunction<CollaboratorRemoveMutation, CollaboratorRemoveMutationVariables>;

/**
 * __useCollaboratorRemoveMutation__
 *
 * To run a mutation, you first call `useCollaboratorRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollaboratorRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collaboratorRemoveMutation, { data, loading, error }] = useCollaboratorRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCollaboratorRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CollaboratorRemoveMutation, CollaboratorRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<CollaboratorRemoveMutation, CollaboratorRemoveMutationVariables>(CollaboratorRemoveDocument, baseOptions);
      }
export type CollaboratorRemoveMutationHookResult = ReturnType<typeof useCollaboratorRemoveMutation>;
export type CollaboratorRemoveMutationResult = ApolloReactCommon.MutationResult<CollaboratorRemoveMutation>;
export type CollaboratorRemoveMutationOptions = ApolloReactCommon.BaseMutationOptions<CollaboratorRemoveMutation, CollaboratorRemoveMutationVariables>;
export const InviteAcceptDocument = gql`
    mutation InviteAccept($id: ID!) {
  inviteAccept(input: {id: $id}) {
    __typename
    ... on InviteAcceptSuccess {
      success
    }
    ... on InviteAcceptError {
      inputErrors {
        id
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type InviteAcceptMutationFn = ApolloReactCommon.MutationFunction<InviteAcceptMutation, InviteAcceptMutationVariables>;

/**
 * __useInviteAcceptMutation__
 *
 * To run a mutation, you first call `useInviteAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteAcceptMutation, { data, loading, error }] = useInviteAcceptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteAcceptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteAcceptMutation, InviteAcceptMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteAcceptMutation, InviteAcceptMutationVariables>(InviteAcceptDocument, baseOptions);
      }
export type InviteAcceptMutationHookResult = ReturnType<typeof useInviteAcceptMutation>;
export type InviteAcceptMutationResult = ApolloReactCommon.MutationResult<InviteAcceptMutation>;
export type InviteAcceptMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteAcceptMutation, InviteAcceptMutationVariables>;
export const InviteFetchDocument = gql`
    query InviteFetch($token: String!) {
  inviteFetch(input: {token: $token}) {
    __typename
    ... on InviteFetchSuccess {
      invite {
        emailAddress
        id
        invitedByUser {
          emailAddress
          firstName
          lastName
        }
        jobCollection {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useInviteFetchQuery__
 *
 * To run a query within a React component, call `useInviteFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteFetchQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInviteFetchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InviteFetchQuery, InviteFetchQueryVariables>) {
        return ApolloReactHooks.useQuery<InviteFetchQuery, InviteFetchQueryVariables>(InviteFetchDocument, baseOptions);
      }
export function useInviteFetchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InviteFetchQuery, InviteFetchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InviteFetchQuery, InviteFetchQueryVariables>(InviteFetchDocument, baseOptions);
        }
export type InviteFetchQueryHookResult = ReturnType<typeof useInviteFetchQuery>;
export type InviteFetchLazyQueryHookResult = ReturnType<typeof useInviteFetchLazyQuery>;
export type InviteFetchQueryResult = ApolloReactCommon.QueryResult<InviteFetchQuery, InviteFetchQueryVariables>;
export const InviteIgnoreDocument = gql`
    mutation InviteIgnore($id: ID!) {
  inviteIgnore(input: {id: $id}) {
    __typename
    ... on InviteIgnoreSuccess {
      success
    }
    ... on InviteIgnoreError {
      inputErrors {
        id
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type InviteIgnoreMutationFn = ApolloReactCommon.MutationFunction<InviteIgnoreMutation, InviteIgnoreMutationVariables>;

/**
 * __useInviteIgnoreMutation__
 *
 * To run a mutation, you first call `useInviteIgnoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteIgnoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteIgnoreMutation, { data, loading, error }] = useInviteIgnoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteIgnoreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteIgnoreMutation, InviteIgnoreMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteIgnoreMutation, InviteIgnoreMutationVariables>(InviteIgnoreDocument, baseOptions);
      }
export type InviteIgnoreMutationHookResult = ReturnType<typeof useInviteIgnoreMutation>;
export type InviteIgnoreMutationResult = ApolloReactCommon.MutationResult<InviteIgnoreMutation>;
export type InviteIgnoreMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteIgnoreMutation, InviteIgnoreMutationVariables>;
export const InviteRemoveDocument = gql`
    mutation InviteRemove($id: ID!) {
  inviteRemove(input: {id: $id}) {
    __typename
    ... on InviteRemoveSuccess {
      success
      jobCollection {
        id
        invitedUsers {
          ...JobCollectionPageInvitedUser
        }
      }
    }
    ... on InviteRemoveError {
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${JobCollectionPageInvitedUserFragmentDoc}`;
export type InviteRemoveMutationFn = ApolloReactCommon.MutationFunction<InviteRemoveMutation, InviteRemoveMutationVariables>;

/**
 * __useInviteRemoveMutation__
 *
 * To run a mutation, you first call `useInviteRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteRemoveMutation, { data, loading, error }] = useInviteRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteRemoveMutation, InviteRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteRemoveMutation, InviteRemoveMutationVariables>(InviteRemoveDocument, baseOptions);
      }
export type InviteRemoveMutationHookResult = ReturnType<typeof useInviteRemoveMutation>;
export type InviteRemoveMutationResult = ApolloReactCommon.MutationResult<InviteRemoveMutation>;
export type InviteRemoveMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteRemoveMutation, InviteRemoveMutationVariables>;
export const JobCollectionDeleteDocument = gql`
    mutation JobCollectionDelete($id: ID!) {
  jobCollectionDelete(input: {id: $id}) {
    __typename
    ... on JobCollectionDeleteSuccess {
      success
    }
    ... on JobCollectionDeleteError {
      inputErrors {
        id
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type JobCollectionDeleteMutationFn = ApolloReactCommon.MutationFunction<JobCollectionDeleteMutation, JobCollectionDeleteMutationVariables>;

/**
 * __useJobCollectionDeleteMutation__
 *
 * To run a mutation, you first call `useJobCollectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobCollectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobCollectionDeleteMutation, { data, loading, error }] = useJobCollectionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobCollectionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobCollectionDeleteMutation, JobCollectionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<JobCollectionDeleteMutation, JobCollectionDeleteMutationVariables>(JobCollectionDeleteDocument, baseOptions);
      }
export type JobCollectionDeleteMutationHookResult = ReturnType<typeof useJobCollectionDeleteMutation>;
export type JobCollectionDeleteMutationResult = ApolloReactCommon.MutationResult<JobCollectionDeleteMutation>;
export type JobCollectionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<JobCollectionDeleteMutation, JobCollectionDeleteMutationVariables>;
export const JobCollectionChangePermsDocument = gql`
    mutation JobCollectionChangePerms($id: ID!, $editTitle: Boolean!, $save: Boolean!, $unsave: Boolean!, $share: Boolean!, $invite: Boolean!) {
  jobCollectionChangePerms(input: {id: $id, editTitle: $editTitle, save: $save, unsave: $unsave, share: $share, invite: $invite}) {
    __typename
    ... on JobCollectionChangePermsSuccess {
      success
      jobCollection {
        id
        permissions {
          editTitle
          save
          unsave
          enablePublicLink
          invite
        }
      }
    }
    ... on JobCollectionChangePermsError {
      inputErrors {
        id
        editTitle
        save
        unsave
        share
        invite
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type JobCollectionChangePermsMutationFn = ApolloReactCommon.MutationFunction<JobCollectionChangePermsMutation, JobCollectionChangePermsMutationVariables>;

/**
 * __useJobCollectionChangePermsMutation__
 *
 * To run a mutation, you first call `useJobCollectionChangePermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobCollectionChangePermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobCollectionChangePermsMutation, { data, loading, error }] = useJobCollectionChangePermsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      editTitle: // value for 'editTitle'
 *      save: // value for 'save'
 *      unsave: // value for 'unsave'
 *      share: // value for 'share'
 *      invite: // value for 'invite'
 *   },
 * });
 */
export function useJobCollectionChangePermsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobCollectionChangePermsMutation, JobCollectionChangePermsMutationVariables>) {
        return ApolloReactHooks.useMutation<JobCollectionChangePermsMutation, JobCollectionChangePermsMutationVariables>(JobCollectionChangePermsDocument, baseOptions);
      }
export type JobCollectionChangePermsMutationHookResult = ReturnType<typeof useJobCollectionChangePermsMutation>;
export type JobCollectionChangePermsMutationResult = ApolloReactCommon.MutationResult<JobCollectionChangePermsMutation>;
export type JobCollectionChangePermsMutationOptions = ApolloReactCommon.BaseMutationOptions<JobCollectionChangePermsMutation, JobCollectionChangePermsMutationVariables>;
export const JobCollectionChangeTitleDocument = gql`
    mutation JobCollectionChangeTitle($id: ID!, $name: String!) {
  jobCollectionChangeTitle(input: {id: $id, name: $name}) {
    __typename
    ... on JobCollectionChangeTitleSuccess {
      success
      jobCollection {
        id
        name
      }
    }
    ... on JobCollectionChangeTitleError {
      inputErrors {
        id
        name
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type JobCollectionChangeTitleMutationFn = ApolloReactCommon.MutationFunction<JobCollectionChangeTitleMutation, JobCollectionChangeTitleMutationVariables>;

/**
 * __useJobCollectionChangeTitleMutation__
 *
 * To run a mutation, you first call `useJobCollectionChangeTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobCollectionChangeTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobCollectionChangeTitleMutation, { data, loading, error }] = useJobCollectionChangeTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useJobCollectionChangeTitleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobCollectionChangeTitleMutation, JobCollectionChangeTitleMutationVariables>) {
        return ApolloReactHooks.useMutation<JobCollectionChangeTitleMutation, JobCollectionChangeTitleMutationVariables>(JobCollectionChangeTitleDocument, baseOptions);
      }
export type JobCollectionChangeTitleMutationHookResult = ReturnType<typeof useJobCollectionChangeTitleMutation>;
export type JobCollectionChangeTitleMutationResult = ApolloReactCommon.MutationResult<JobCollectionChangeTitleMutation>;
export type JobCollectionChangeTitleMutationOptions = ApolloReactCommon.BaseMutationOptions<JobCollectionChangeTitleMutation, JobCollectionChangeTitleMutationVariables>;
export const JobCollectionToggleSharingDocument = gql`
    mutation JobCollectionToggleSharing($id: ID!, $value: Boolean!) {
  jobCollectionToggleSharing(input: {id: $id, value: $value}) {
    __typename
    ... on JobCollectionToggleSharingSuccess {
      success
      jobCollection {
        id
        isPublic
      }
    }
    ... on JobCollectionToggleSharingError {
      inputErrors {
        id
        value
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type JobCollectionToggleSharingMutationFn = ApolloReactCommon.MutationFunction<JobCollectionToggleSharingMutation, JobCollectionToggleSharingMutationVariables>;

/**
 * __useJobCollectionToggleSharingMutation__
 *
 * To run a mutation, you first call `useJobCollectionToggleSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobCollectionToggleSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobCollectionToggleSharingMutation, { data, loading, error }] = useJobCollectionToggleSharingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useJobCollectionToggleSharingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobCollectionToggleSharingMutation, JobCollectionToggleSharingMutationVariables>) {
        return ApolloReactHooks.useMutation<JobCollectionToggleSharingMutation, JobCollectionToggleSharingMutationVariables>(JobCollectionToggleSharingDocument, baseOptions);
      }
export type JobCollectionToggleSharingMutationHookResult = ReturnType<typeof useJobCollectionToggleSharingMutation>;
export type JobCollectionToggleSharingMutationResult = ApolloReactCommon.MutationResult<JobCollectionToggleSharingMutation>;
export type JobCollectionToggleSharingMutationOptions = ApolloReactCommon.BaseMutationOptions<JobCollectionToggleSharingMutation, JobCollectionToggleSharingMutationVariables>;
export const JobUnsaveDocument = gql`
    mutation JobUnsave($provider: String!, $providerId: String!, $jobCollectionIdsToRemove: [String!]!) {
  jobUnsave(input: {provider: $provider, providerId: $providerId, jobCollectionIdsToRemove: $jobCollectionIdsToRemove}) {
    __typename
    ... on JobUnsaveSuccess {
      success
      jobCollectionMods {
        modType
        jobCollection {
          id
        }
        jobCollectionItem {
          id
        }
      }
    }
    ... on JobUnsaveError {
      inputErrors {
        provider
        providerId
        jobCollectionIdsToRemove
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    `;
export type JobUnsaveMutationFn = ApolloReactCommon.MutationFunction<JobUnsaveMutation, JobUnsaveMutationVariables>;

/**
 * __useJobUnsaveMutation__
 *
 * To run a mutation, you first call `useJobUnsaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobUnsaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobUnsaveMutation, { data, loading, error }] = useJobUnsaveMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      providerId: // value for 'providerId'
 *      jobCollectionIdsToRemove: // value for 'jobCollectionIdsToRemove'
 *   },
 * });
 */
export function useJobUnsaveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobUnsaveMutation, JobUnsaveMutationVariables>) {
        return ApolloReactHooks.useMutation<JobUnsaveMutation, JobUnsaveMutationVariables>(JobUnsaveDocument, baseOptions);
      }
export type JobUnsaveMutationHookResult = ReturnType<typeof useJobUnsaveMutation>;
export type JobUnsaveMutationResult = ApolloReactCommon.MutationResult<JobUnsaveMutation>;
export type JobUnsaveMutationOptions = ApolloReactCommon.BaseMutationOptions<JobUnsaveMutation, JobUnsaveMutationVariables>;
export const JobCollectionPageJobCollectionDocument = gql`
    query JobCollectionPageJobCollection($id: ID!) {
  jobCollection(input: {id: $id}) {
    __typename
    ... on JobCollectionSuccess {
      jobCollection {
        ...JobCollectionPageJobCollection
      }
    }
    ... on JobCollectionError {
      inputErrors {
        id
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${JobCollectionPageJobCollectionFragmentDoc}`;

/**
 * __useJobCollectionPageJobCollectionQuery__
 *
 * To run a query within a React component, call `useJobCollectionPageJobCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCollectionPageJobCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCollectionPageJobCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobCollectionPageJobCollectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobCollectionPageJobCollectionQuery, JobCollectionPageJobCollectionQueryVariables>) {
        return ApolloReactHooks.useQuery<JobCollectionPageJobCollectionQuery, JobCollectionPageJobCollectionQueryVariables>(JobCollectionPageJobCollectionDocument, baseOptions);
      }
export function useJobCollectionPageJobCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobCollectionPageJobCollectionQuery, JobCollectionPageJobCollectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobCollectionPageJobCollectionQuery, JobCollectionPageJobCollectionQueryVariables>(JobCollectionPageJobCollectionDocument, baseOptions);
        }
export type JobCollectionPageJobCollectionQueryHookResult = ReturnType<typeof useJobCollectionPageJobCollectionQuery>;
export type JobCollectionPageJobCollectionLazyQueryHookResult = ReturnType<typeof useJobCollectionPageJobCollectionLazyQuery>;
export type JobCollectionPageJobCollectionQueryResult = ApolloReactCommon.QueryResult<JobCollectionPageJobCollectionQuery, JobCollectionPageJobCollectionQueryVariables>;
export const JobCollectionGetNewUrlDocument = gql`
    query JobCollectionGetNewURL($shareId: String!) {
  jobCollectionGetNewURL(input: {shareId: $shareId}) {
    __typename
    ... on JobCollectionGetNewURLSuccess {
      id
    }
  }
}
    `;

/**
 * __useJobCollectionGetNewUrlQuery__
 *
 * To run a query within a React component, call `useJobCollectionGetNewUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCollectionGetNewUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCollectionGetNewUrlQuery({
 *   variables: {
 *      shareId: // value for 'shareId'
 *   },
 * });
 */
export function useJobCollectionGetNewUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobCollectionGetNewUrlQuery, JobCollectionGetNewUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<JobCollectionGetNewUrlQuery, JobCollectionGetNewUrlQueryVariables>(JobCollectionGetNewUrlDocument, baseOptions);
      }
export function useJobCollectionGetNewUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobCollectionGetNewUrlQuery, JobCollectionGetNewUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobCollectionGetNewUrlQuery, JobCollectionGetNewUrlQueryVariables>(JobCollectionGetNewUrlDocument, baseOptions);
        }
export type JobCollectionGetNewUrlQueryHookResult = ReturnType<typeof useJobCollectionGetNewUrlQuery>;
export type JobCollectionGetNewUrlLazyQueryHookResult = ReturnType<typeof useJobCollectionGetNewUrlLazyQuery>;
export type JobCollectionGetNewUrlQueryResult = ApolloReactCommon.QueryResult<JobCollectionGetNewUrlQuery, JobCollectionGetNewUrlQueryVariables>;
export const MyJoblistsPageJobCollectionCreateDocument = gql`
    mutation MyJoblistsPageJobCollectionCreate($name: String!, $provider: String, $providerId: String, $routing: String) {
  jobCollectionCreate(input: {name: $name, provider: $provider, providerId: $providerId, routing: $routing}) {
    __typename
    ... on JobCollectionCreateSuccess {
      success
      jobCollection {
        ...JobCollectionCreatedResponse
      }
    }
    ... on JobCollectionCreateError {
      inputErrors {
        name
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${JobCollectionCreatedResponseFragmentDoc}`;
export type MyJoblistsPageJobCollectionCreateMutationFn = ApolloReactCommon.MutationFunction<MyJoblistsPageJobCollectionCreateMutation, MyJoblistsPageJobCollectionCreateMutationVariables>;

/**
 * __useMyJoblistsPageJobCollectionCreateMutation__
 *
 * To run a mutation, you first call `useMyJoblistsPageJobCollectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyJoblistsPageJobCollectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myJoblistsPageJobCollectionCreateMutation, { data, loading, error }] = useMyJoblistsPageJobCollectionCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      provider: // value for 'provider'
 *      providerId: // value for 'providerId'
 *      routing: // value for 'routing'
 *   },
 * });
 */
export function useMyJoblistsPageJobCollectionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MyJoblistsPageJobCollectionCreateMutation, MyJoblistsPageJobCollectionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<MyJoblistsPageJobCollectionCreateMutation, MyJoblistsPageJobCollectionCreateMutationVariables>(MyJoblistsPageJobCollectionCreateDocument, baseOptions);
      }
export type MyJoblistsPageJobCollectionCreateMutationHookResult = ReturnType<typeof useMyJoblistsPageJobCollectionCreateMutation>;
export type MyJoblistsPageJobCollectionCreateMutationResult = ApolloReactCommon.MutationResult<MyJoblistsPageJobCollectionCreateMutation>;
export type MyJoblistsPageJobCollectionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<MyJoblistsPageJobCollectionCreateMutation, MyJoblistsPageJobCollectionCreateMutationVariables>;
export const MyJoblistsPageJobCollectionListDocument = gql`
    query MyJoblistsPageJobCollectionList($ownedBy: JobCollectionListOwnedByEnum!, $saveable: JobCollectionListSaveableEnum!, $status: JobCollectionListInviteStatusEnum!) {
  jobCollectionList(input: {ownedBy: $ownedBy, saveable: $saveable, inviteStatus: $status}) {
    __typename
    ... on JobCollectionListSuccess {
      jobCollections {
        ...MyJoblistsPageJobCollection
      }
    }
    ... on JobCollectionListError {
      inputErrors {
        ownedBy
        saveable
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${MyJoblistsPageJobCollectionFragmentDoc}`;

/**
 * __useMyJoblistsPageJobCollectionListQuery__
 *
 * To run a query within a React component, call `useMyJoblistsPageJobCollectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyJoblistsPageJobCollectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyJoblistsPageJobCollectionListQuery({
 *   variables: {
 *      ownedBy: // value for 'ownedBy'
 *      saveable: // value for 'saveable'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMyJoblistsPageJobCollectionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyJoblistsPageJobCollectionListQuery, MyJoblistsPageJobCollectionListQueryVariables>) {
        return ApolloReactHooks.useQuery<MyJoblistsPageJobCollectionListQuery, MyJoblistsPageJobCollectionListQueryVariables>(MyJoblistsPageJobCollectionListDocument, baseOptions);
      }
export function useMyJoblistsPageJobCollectionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyJoblistsPageJobCollectionListQuery, MyJoblistsPageJobCollectionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyJoblistsPageJobCollectionListQuery, MyJoblistsPageJobCollectionListQueryVariables>(MyJoblistsPageJobCollectionListDocument, baseOptions);
        }
export type MyJoblistsPageJobCollectionListQueryHookResult = ReturnType<typeof useMyJoblistsPageJobCollectionListQuery>;
export type MyJoblistsPageJobCollectionListLazyQueryHookResult = ReturnType<typeof useMyJoblistsPageJobCollectionListLazyQuery>;
export type MyJoblistsPageJobCollectionListQueryResult = ApolloReactCommon.QueryResult<MyJoblistsPageJobCollectionListQuery, MyJoblistsPageJobCollectionListQueryVariables>;
export const SearchPageJobCollectionCreateDocument = gql`
    mutation SearchPageJobCollectionCreate($name: String!, $provider: String, $providerId: String, $routing: String) {
  jobCollectionCreate(input: {name: $name, provider: $provider, providerId: $providerId, routing: $routing}) {
    __typename
    ... on JobCollectionCreateSuccess {
      success
      jobCollection {
        ...SearchPageJobCollection
      }
    }
    ... on JobCollectionCreateError {
      inputErrors {
        name
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${SearchPageJobCollectionFragmentDoc}`;
export type SearchPageJobCollectionCreateMutationFn = ApolloReactCommon.MutationFunction<SearchPageJobCollectionCreateMutation, SearchPageJobCollectionCreateMutationVariables>;

/**
 * __useSearchPageJobCollectionCreateMutation__
 *
 * To run a mutation, you first call `useSearchPageJobCollectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchPageJobCollectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchPageJobCollectionCreateMutation, { data, loading, error }] = useSearchPageJobCollectionCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      provider: // value for 'provider'
 *      providerId: // value for 'providerId'
 *      routing: // value for 'routing'
 *   },
 * });
 */
export function useSearchPageJobCollectionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SearchPageJobCollectionCreateMutation, SearchPageJobCollectionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<SearchPageJobCollectionCreateMutation, SearchPageJobCollectionCreateMutationVariables>(SearchPageJobCollectionCreateDocument, baseOptions);
      }
export type SearchPageJobCollectionCreateMutationHookResult = ReturnType<typeof useSearchPageJobCollectionCreateMutation>;
export type SearchPageJobCollectionCreateMutationResult = ApolloReactCommon.MutationResult<SearchPageJobCollectionCreateMutation>;
export type SearchPageJobCollectionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<SearchPageJobCollectionCreateMutation, SearchPageJobCollectionCreateMutationVariables>;
export const SearchPageJobCollectionListDocument = gql`
    query SearchPageJobCollectionList($ownedBy: JobCollectionListOwnedByEnum!, $saveable: JobCollectionListSaveableEnum!, $status: JobCollectionListInviteStatusEnum!) {
  jobCollectionList(input: {ownedBy: $ownedBy, saveable: $saveable, inviteStatus: $status}) {
    __typename
    ... on JobCollectionListSuccess {
      jobCollections {
        ...SearchPageJobCollection
      }
    }
    ... on JobCollectionListError {
      inputErrors {
        ownedBy
        saveable
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${SearchPageJobCollectionFragmentDoc}`;

/**
 * __useSearchPageJobCollectionListQuery__
 *
 * To run a query within a React component, call `useSearchPageJobCollectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPageJobCollectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPageJobCollectionListQuery({
 *   variables: {
 *      ownedBy: // value for 'ownedBy'
 *      saveable: // value for 'saveable'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSearchPageJobCollectionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPageJobCollectionListQuery, SearchPageJobCollectionListQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPageJobCollectionListQuery, SearchPageJobCollectionListQueryVariables>(SearchPageJobCollectionListDocument, baseOptions);
      }
export function useSearchPageJobCollectionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPageJobCollectionListQuery, SearchPageJobCollectionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPageJobCollectionListQuery, SearchPageJobCollectionListQueryVariables>(SearchPageJobCollectionListDocument, baseOptions);
        }
export type SearchPageJobCollectionListQueryHookResult = ReturnType<typeof useSearchPageJobCollectionListQuery>;
export type SearchPageJobCollectionListLazyQueryHookResult = ReturnType<typeof useSearchPageJobCollectionListLazyQuery>;
export type SearchPageJobCollectionListQueryResult = ApolloReactCommon.QueryResult<SearchPageJobCollectionListQuery, SearchPageJobCollectionListQueryVariables>;
export const SearchPageJobSaveDocument = gql`
    mutation SearchPageJobSave($provider: String!, $providerId: String!, $routing: String, $jobCollectionIdsToSave: [String!]!, $jobCollectionIdsToRemove: [String!]!) {
  jobSave(input: {provider: $provider, providerId: $providerId, routing: $routing, jobCollectionIdsToSave: $jobCollectionIdsToSave, jobCollectionIdsToRemove: $jobCollectionIdsToRemove}) {
    __typename
    ... on JobSaveSuccess {
      success
      jobCollectionMods {
        modType
        jobCollection {
          id
          jobsCount
          locationsCount
        }
        jobCollectionItem {
          ...SearchPageJobCollectionItem
        }
      }
    }
    ... on JobSaveError {
      inputErrors {
        provider
        providerId
        routing
      }
      otherError
    }
    ... on UserAuthenticationRequiredError {
      error
    }
  }
}
    ${SearchPageJobCollectionItemFragmentDoc}`;
export type SearchPageJobSaveMutationFn = ApolloReactCommon.MutationFunction<SearchPageJobSaveMutation, SearchPageJobSaveMutationVariables>;

/**
 * __useSearchPageJobSaveMutation__
 *
 * To run a mutation, you first call `useSearchPageJobSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchPageJobSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchPageJobSaveMutation, { data, loading, error }] = useSearchPageJobSaveMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      providerId: // value for 'providerId'
 *      routing: // value for 'routing'
 *      jobCollectionIdsToSave: // value for 'jobCollectionIdsToSave'
 *      jobCollectionIdsToRemove: // value for 'jobCollectionIdsToRemove'
 *   },
 * });
 */
export function useSearchPageJobSaveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SearchPageJobSaveMutation, SearchPageJobSaveMutationVariables>) {
        return ApolloReactHooks.useMutation<SearchPageJobSaveMutation, SearchPageJobSaveMutationVariables>(SearchPageJobSaveDocument, baseOptions);
      }
export type SearchPageJobSaveMutationHookResult = ReturnType<typeof useSearchPageJobSaveMutation>;
export type SearchPageJobSaveMutationResult = ApolloReactCommon.MutationResult<SearchPageJobSaveMutation>;
export type SearchPageJobSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<SearchPageJobSaveMutation, SearchPageJobSaveMutationVariables>;
export const DynamicFiltersDocument = gql`
    query DynamicFilters($titleQuery: SearchQuery!, $companyQuery: SearchQuery!, $limit: Int, $validateTitle: [String!], $validateCompany: [String!], $providers: [String!], $country: String) {
  topJobTitles(query: $titleQuery, limit: $limit) {
    title
  }
  topCompanies(query: $companyQuery, limit: $limit) {
    company
    count
  }
  isActiveTitleValid: hasJobs(query: {titles: $validateTitle, providers: $providers, country: $country})
  isActiveCompanyValid: hasJobs(query: {companys: $validateCompany, providers: $providers, country: $country})
}
    `;

/**
 * __useDynamicFiltersQuery__
 *
 * To run a query within a React component, call `useDynamicFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicFiltersQuery({
 *   variables: {
 *      titleQuery: // value for 'titleQuery'
 *      companyQuery: // value for 'companyQuery'
 *      limit: // value for 'limit'
 *      validateTitle: // value for 'validateTitle'
 *      validateCompany: // value for 'validateCompany'
 *      providers: // value for 'providers'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useDynamicFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DynamicFiltersQuery, DynamicFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<DynamicFiltersQuery, DynamicFiltersQueryVariables>(DynamicFiltersDocument, baseOptions);
      }
export function useDynamicFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DynamicFiltersQuery, DynamicFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DynamicFiltersQuery, DynamicFiltersQueryVariables>(DynamicFiltersDocument, baseOptions);
        }
export type DynamicFiltersQueryHookResult = ReturnType<typeof useDynamicFiltersQuery>;
export type DynamicFiltersLazyQueryHookResult = ReturnType<typeof useDynamicFiltersLazyQuery>;
export type DynamicFiltersQueryResult = ApolloReactCommon.QueryResult<DynamicFiltersQuery, DynamicFiltersQueryVariables>;
export const SaveStepDocument = gql`
    mutation SaveStep($input: UserSaveStepInput) {
  saveStep(input: $input) {
    ... on UserSaveStepSuccess {
      user {
        id
        progress {
          emailAlert
          signUp
          saveJobs
          inviteFriends
        }
      }
    }
    ... on UserAuthenticationRequiredError {
      error
    }
    ... on UserSaveStepError {
      otherError
    }
  }
}
    `;
export type SaveStepMutationFn = ApolloReactCommon.MutationFunction<SaveStepMutation, SaveStepMutationVariables>;

/**
 * __useSaveStepMutation__
 *
 * To run a mutation, you first call `useSaveStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStepMutation, { data, loading, error }] = useSaveStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveStepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveStepMutation, SaveStepMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveStepMutation, SaveStepMutationVariables>(SaveStepDocument, baseOptions);
      }
export type SaveStepMutationHookResult = ReturnType<typeof useSaveStepMutation>;
export type SaveStepMutationResult = ApolloReactCommon.MutationResult<SaveStepMutation>;
export type SaveStepMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveStepMutation, SaveStepMutationVariables>;
export const UpdateShowGamifyDocument = gql`
    mutation UpdateShowGamify($showGamify: Boolean!) {
  updateShowGamify(input: {showGamify: $showGamify}) {
    ... on UserUpdateShowGamifySuccess {
      user {
        id
        showGamify
      }
    }
  }
}
    `;
export type UpdateShowGamifyMutationFn = ApolloReactCommon.MutationFunction<UpdateShowGamifyMutation, UpdateShowGamifyMutationVariables>;

/**
 * __useUpdateShowGamifyMutation__
 *
 * To run a mutation, you first call `useUpdateShowGamifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowGamifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowGamifyMutation, { data, loading, error }] = useUpdateShowGamifyMutation({
 *   variables: {
 *      showGamify: // value for 'showGamify'
 *   },
 * });
 */
export function useUpdateShowGamifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShowGamifyMutation, UpdateShowGamifyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShowGamifyMutation, UpdateShowGamifyMutationVariables>(UpdateShowGamifyDocument, baseOptions);
      }
export type UpdateShowGamifyMutationHookResult = ReturnType<typeof useUpdateShowGamifyMutation>;
export type UpdateShowGamifyMutationResult = ApolloReactCommon.MutationResult<UpdateShowGamifyMutation>;
export type UpdateShowGamifyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShowGamifyMutation, UpdateShowGamifyMutationVariables>;
export const EJobDocument = gql`
    query EJob($id: String!) {
  eJob(id: $id) {
    __typename
    ... on Job {
      content
      url
      ...JobFieldsFragment
    }
    ... on JobError {
      error
    }
  }
}
    ${JobFieldsFragmentFragmentDoc}`;

/**
 * __useEJobQuery__
 *
 * To run a query within a React component, call `useEJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useEJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EJobQuery, EJobQueryVariables>) {
        return ApolloReactHooks.useQuery<EJobQuery, EJobQueryVariables>(EJobDocument, baseOptions);
      }
export function useEJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EJobQuery, EJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EJobQuery, EJobQueryVariables>(EJobDocument, baseOptions);
        }
export type EJobQueryHookResult = ReturnType<typeof useEJobQuery>;
export type EJobLazyQueryHookResult = ReturnType<typeof useEJobLazyQuery>;
export type EJobQueryResult = ApolloReactCommon.QueryResult<EJobQuery, EJobQueryVariables>;
export const JobDocument = gql`
    query Job($provider: String!, $providerId: String!, $routing: String, $impressionId: String) {
  job(provider: $provider, providerId: $providerId, routing: $routing, impressionId: $impressionId) {
    __typename
    ... on Job {
      content
      url
      ...JobFieldsFragment
    }
    ... on JobError {
      error
    }
  }
}
    ${JobFieldsFragmentFragmentDoc}`;

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *      providerId: // value for 'providerId'
 *      routing: // value for 'routing'
 *      impressionId: // value for 'impressionId'
 *   },
 * });
 */
export function useJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobQuery, JobQueryVariables>) {
        return ApolloReactHooks.useQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
      }
export function useJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobQuery, JobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
        }
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = ApolloReactCommon.QueryResult<JobQuery, JobQueryVariables>;
export const TopJobDocument = gql`
    query TopJob($query: SearchQuery!, $providers: ProvidersFilter, $limit: Int, $offset: Int) {
  topJob(query: $query, providers: $providers, limit: $limit, offset: $offset) {
    __typename
    ... on Job {
      content
      url
      ...JobFieldsFragment
    }
    ... on JobError {
      error
    }
  }
}
    ${JobFieldsFragmentFragmentDoc}`;

/**
 * __useTopJobQuery__
 *
 * To run a query within a React component, call `useTopJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopJobQuery({
 *   variables: {
 *      query: // value for 'query'
 *      providers: // value for 'providers'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTopJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TopJobQuery, TopJobQueryVariables>) {
        return ApolloReactHooks.useQuery<TopJobQuery, TopJobQueryVariables>(TopJobDocument, baseOptions);
      }
export function useTopJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TopJobQuery, TopJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TopJobQuery, TopJobQueryVariables>(TopJobDocument, baseOptions);
        }
export type TopJobQueryHookResult = ReturnType<typeof useTopJobQuery>;
export type TopJobLazyQueryHookResult = ReturnType<typeof useTopJobLazyQuery>;
export type TopJobQueryResult = ApolloReactCommon.QueryResult<TopJobQuery, TopJobQueryVariables>;
export const PressPageDocument = gql`
    query PressPage {
  pressPage(slug: "presspage") {
    ... on Presspage {
      pressEntries {
        __typename
        ... on PressEntriesPressSection {
          __typename
          id
          enabled
          pressSectionTitle
        }
        ... on PressEntriesPressEntry {
          __typename
          id
          image {
            id
            url
          }
          entryTitle
          description
          entryUrl
        }
      }
    }
  }
}
    `;

/**
 * __usePressPageQuery__
 *
 * To run a query within a React component, call `usePressPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePressPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePressPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePressPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PressPageQuery, PressPageQueryVariables>) {
        return ApolloReactHooks.useQuery<PressPageQuery, PressPageQueryVariables>(PressPageDocument, baseOptions);
      }
export function usePressPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PressPageQuery, PressPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PressPageQuery, PressPageQueryVariables>(PressPageDocument, baseOptions);
        }
export type PressPageQueryHookResult = ReturnType<typeof usePressPageQuery>;
export type PressPageLazyQueryHookResult = ReturnType<typeof usePressPageLazyQuery>;
export type PressPageQueryResult = ApolloReactCommon.QueryResult<PressPageQuery, PressPageQueryVariables>;
export const SearchJobsQueryDocument = gql`
    query searchJobsQuery($keyword: String, $location: String, $locationRadius: LocationRadius, $providers: [String!], $titles: [String!], $companys: [String!], $includeTags: TagFilters, $excludeTags: TagFilters, $tagSources: TagFlags, $easyApply: Boolean, $posted: PostedDate, $placeid: String, $firstVisitId: String, $sessionId: String, $fbclid: String, $adChannel: String, $settings: SearchSettings, $country: String, $excludeBy: ExclusionClause, $searchProviders: ProvidersFilter, $limit: Int, $offset: Int, $isPaidView: Boolean!, $useDfsSearch: Boolean, $usePhraseSearch: Boolean) {
  searchJobs(query: {keyword: $keyword, location: $location, locationRadius: $locationRadius, providers: $providers, titles: $titles, companys: $companys, includeTags: $includeTags, excludeTags: $excludeTags, tagSources: $tagSources, easyApply: $easyApply, posted: $posted, placeid: $placeid, firstVisitId: $firstVisitId, sessionId: $sessionId, fbclid: $fbclid, adChannel: $adChannel, settings: $settings, country: $country, excludeBy: $excludeBy, useDfsSearch: $useDfsSearch, usePhraseSearch: $usePhraseSearch}, providers: $searchProviders, limit: $limit, offset: $offset) {
    jobs {
      url @include(if: $isPaidView)
      ...JobFieldsFragment
    }
    impression_id
    total
    totalAppcast
    totalLocalInventory
  }
}
    ${JobFieldsFragmentFragmentDoc}`;

/**
 * __useSearchJobsQueryQuery__
 *
 * To run a query within a React component, call `useSearchJobsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchJobsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchJobsQueryQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      location: // value for 'location'
 *      locationRadius: // value for 'locationRadius'
 *      providers: // value for 'providers'
 *      titles: // value for 'titles'
 *      companys: // value for 'companys'
 *      includeTags: // value for 'includeTags'
 *      excludeTags: // value for 'excludeTags'
 *      tagSources: // value for 'tagSources'
 *      easyApply: // value for 'easyApply'
 *      posted: // value for 'posted'
 *      placeid: // value for 'placeid'
 *      firstVisitId: // value for 'firstVisitId'
 *      sessionId: // value for 'sessionId'
 *      fbclid: // value for 'fbclid'
 *      adChannel: // value for 'adChannel'
 *      settings: // value for 'settings'
 *      country: // value for 'country'
 *      excludeBy: // value for 'excludeBy'
 *      searchProviders: // value for 'searchProviders'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      isPaidView: // value for 'isPaidView'
 *      useDfsSearch: // value for 'useDfsSearch'
 *      usePhraseSearch: // value for 'usePhraseSearch'
 *   },
 * });
 */
export function useSearchJobsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchJobsQueryQuery, SearchJobsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchJobsQueryQuery, SearchJobsQueryQueryVariables>(SearchJobsQueryDocument, baseOptions);
      }
export function useSearchJobsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchJobsQueryQuery, SearchJobsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchJobsQueryQuery, SearchJobsQueryQueryVariables>(SearchJobsQueryDocument, baseOptions);
        }
export type SearchJobsQueryQueryHookResult = ReturnType<typeof useSearchJobsQueryQuery>;
export type SearchJobsQueryLazyQueryHookResult = ReturnType<typeof useSearchJobsQueryLazyQuery>;
export type SearchJobsQueryQueryResult = ApolloReactCommon.QueryResult<SearchJobsQueryQuery, SearchJobsQueryQueryVariables>;
export const VerifyTokenDocument = gql`
    query VerifyToken($token: String!, $args: [String]!) {
  verifyToken(input: {token: $token, args: $args})
}
    `;

/**
 * __useVerifyTokenQuery__
 *
 * To run a query within a React component, call `useVerifyTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useVerifyTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyTokenQuery, VerifyTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyTokenQuery, VerifyTokenQueryVariables>(VerifyTokenDocument, baseOptions);
      }
export function useVerifyTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyTokenQuery, VerifyTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyTokenQuery, VerifyTokenQueryVariables>(VerifyTokenDocument, baseOptions);
        }
export type VerifyTokenQueryHookResult = ReturnType<typeof useVerifyTokenQuery>;
export type VerifyTokenLazyQueryHookResult = ReturnType<typeof useVerifyTokenLazyQuery>;
export type VerifyTokenQueryResult = ApolloReactCommon.QueryResult<VerifyTokenQuery, VerifyTokenQueryVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (obj: T, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  SearchQuery: SearchQuery;
  String: ResolverTypeWrapper<Scalars['String']>;
  LocationRadius: LocationRadius;
  TagFilters: TagFilters;
  JobType: JobType;
  SiteLocation: SiteLocation;
  PhysicalLabor: PhysicalLabor;
  HealthBenefits: HealthBenefits;
  CompensationType: CompensationType;
  Environment: Environment;
  EntryLevel: EntryLevel;
  TagFlags: TagFlags;
  TagFlag: TagFlag;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  PostedDate: PostedDate;
  SearchSettings: SearchSettings;
  RankFeature: RankFeature;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  SaturationRanking: SaturationRanking;
  LogRanking: LogRanking;
  SigmoidRanking: SigmoidRanking;
  ProviderConfig: ProviderConfig;
  JobProvider: JobProvider;
  CountryBoost: CountryBoost;
  JobPostingConfig: JobPostingConfig;
  DecayConfig: DecayConfig;
  DecayFunction: DecayFunction;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  FieldBoost: FieldBoost;
  ExclusionClause: ExclusionClause;
  ProvidersFilter: ProvidersFilter;
  SearchResult: ResolverTypeWrapper<SearchResult>;
  Job: ResolverTypeWrapper<Job>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Location: ResolverTypeWrapper<Location>;
  CompanyLogo: ResolverTypeWrapper<CompanyLogo>;
  TagSources: ResolverTypeWrapper<TagSources>;
  Tags: ResolverTypeWrapper<Tags>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  JobResult: ResolversTypes['Job'] | ResolversTypes['JobError'];
  JobError: ResolverTypeWrapper<JobError>;
  BrowseCityInput: BrowseCityInput;
  BrowseRoleInput: BrowseRoleInput;
  BrowseResult: ResolverTypeWrapper<BrowseResult>;
  BrowseState: ResolverTypeWrapper<BrowseState>;
  BrowseCity: ResolverTypeWrapper<BrowseCity>;
  BrowseRole: ResolverTypeWrapper<BrowseRole>;
  BrowseRoleContent: ResolverTypeWrapper<BrowseRoleContent>;
  BrowseCityContent: ResolverTypeWrapper<BrowseCityContent>;
  NormalBrowseArgs: ResolverTypeWrapper<NormalBrowseArgs>;
  BrowseListing: ResolverTypeWrapper<BrowseListing>;
  BrowseStats: ResolverTypeWrapper<BrowseStats>;
  SuggestionContext: SuggestionContext;
  SuggestionFuzziness: SuggestionFuzziness;
  JobData: ResolverTypeWrapper<JobData>;
  CityData: ResolverTypeWrapper<CityData>;
  TitleJobCount: ResolverTypeWrapper<TitleJobCount>;
  CompanyJobCount: ResolverTypeWrapper<CompanyJobCount>;
  Area1JobCount: ResolverTypeWrapper<Area1JobCount>;
  LocalityJobCount: ResolverTypeWrapper<LocalityJobCount>;
  PostalCodeJobCount: ResolverTypeWrapper<PostalCodeJobCount>;
  CategoryJobCount: ResolverTypeWrapper<CategoryJobCount>;
  Site: ResolverTypeWrapper<Site>;
  Presspage: ResolverTypeWrapper<Omit<Presspage, 'pressEntries'> & { pressEntries?: Maybe<Array<Maybe<ResolversTypes['PressEntriesUnion']>>> }>;
  EntryInterface: ResolversTypes['Presspage'] | ResolversTypes['Blog'];
  User: ResolverTypeWrapper<User>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  Section: ResolverTypeWrapper<Section>;
  SectionSiteSettings: ResolverTypeWrapper<SectionSiteSettings>;
  EntryType: ResolverTypeWrapper<EntryType>;
  Field: ResolverTypeWrapper<Field>;
  RelatedToInputType: RelatedToInputType;
  SectionsEnum: SectionsEnum;
  EntryTypesEnum: EntryTypesEnum;
  ElementInterface: ResolversTypes['Presspage'] | ResolversTypes['Blog'];
  PressEntriesUnion: ResolversTypes['PressEntriesPressSection'] | ResolversTypes['PressEntriesPressEntry'];
  PressEntriesPressSection: ResolverTypeWrapper<PressEntriesPressSection>;
  PressEntriesPressEntry: ResolverTypeWrapper<PressEntriesPressEntry>;
  VolumeInterface: ResolversTypes['JoblistcontentqaVolume'];
  NamedTransformsEnum: NamedTransformsEnum;
  CropInputObject: CropInputObject;
  PositionInputEnum: PositionInputEnum;
  CropFormatInputEnum: CropFormatInputEnum;
  VolumeFolder: ResolverTypeWrapper<VolumeFolder>;
  AssetFocalPoint: ResolverTypeWrapper<AssetFocalPoint>;
  FeaturedEnum: FeaturedEnum;
  EntryConnection: ResolverTypeWrapper<EntryConnection>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  EntryEdge: ResolverTypeWrapper<EntryEdge>;
  EntryDraftConnection: ResolverTypeWrapper<EntryDraftConnection>;
  EntryDraftEdge: ResolverTypeWrapper<EntryDraftEdge>;
  EntryDraftInfo: ResolverTypeWrapper<EntryDraftInfo>;
  CategoryGroupsEnum: CategoryGroupsEnum;
  CategoryConnection: ResolverTypeWrapper<CategoryConnection>;
  CategoryEdge: ResolverTypeWrapper<CategoryEdge>;
  CategoryInterface: ResolversTypes['BlogCategory'];
  CategoryGroup: ResolverTypeWrapper<CategoryGroup>;
  JoblistUser: ResolverTypeWrapper<JoblistUser>;
  UserRole: ResolverTypeWrapper<UserRole>;
  QuizData: ResolverTypeWrapper<QuizData>;
  UserLoggedInRealm: UserLoggedInRealm;
  UserProgress: ResolverTypeWrapper<UserProgress>;
  VerifyTokenInput: VerifyTokenInput;
  MasqueradeInput: MasqueradeInput;
  InviteFetchInput: InviteFetchInput;
  InviteFetchResult: ResolversTypes['InviteFetchSuccess'];
  InviteFetchSuccess: ResolverTypeWrapper<InviteFetchSuccess>;
  InvitedUser: ResolverTypeWrapper<InvitedUser>;
  JobCollection: ResolverTypeWrapper<JobCollection>;
  JobCollectionPerms: ResolverTypeWrapper<JobCollectionPerms>;
  JobCollectionItem: ResolverTypeWrapper<JobCollectionItem>;
  JobCollectionCollaborator: ResolverTypeWrapper<JobCollectionCollaborator>;
  InvitedBy: ResolverTypeWrapper<InvitedBy>;
  JobCollectionInput: JobCollectionInput;
  JobCollectionResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserUnauthorizedError'] | ResolversTypes['JobCollectionSuccess'] | ResolversTypes['JobCollectionError'];
  UserAuthenticationRequiredError: ResolverTypeWrapper<UserAuthenticationRequiredError>;
  AuthenticationRequiredError: AuthenticationRequiredError;
  UserUnauthorizedError: ResolverTypeWrapper<UserUnauthorizedError>;
  UnauthorizedError: UnauthorizedError;
  JobCollectionSuccess: ResolverTypeWrapper<JobCollectionSuccess>;
  JobCollectionError: ResolverTypeWrapper<JobCollectionError>;
  JobCollectionInputErrors: ResolverTypeWrapper<JobCollectionInputErrors>;
  RequiredInputError: RequiredInputError;
  JobCollectionOtherError: JobCollectionOtherError;
  JobCollectionListInput: JobCollectionListInput;
  JobCollectionListOwnedByEnum: JobCollectionListOwnedByEnum;
  JobCollectionListSaveableEnum: JobCollectionListSaveableEnum;
  JobCollectionListInviteStatusEnum: JobCollectionListInviteStatusEnum;
  JobCollectionListResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobCollectionListSuccess'] | ResolversTypes['JobCollectionListError'];
  JobCollectionListSuccess: ResolverTypeWrapper<JobCollectionListSuccess>;
  JobCollectionListError: ResolverTypeWrapper<JobCollectionListError>;
  JobCollectionListInputErrors: ResolverTypeWrapper<JobCollectionListInputErrors>;
  JobCollectionListOtherError: JobCollectionListOtherError;
  JobCollectionGetNewURLInput: JobCollectionGetNewUrlInput;
  JobCollectionGetNewURLResult: ResolversTypes['JobCollectionGetNewURLSuccess'];
  JobCollectionGetNewURLSuccess: ResolverTypeWrapper<JobCollectionGetNewUrlSuccess>;
  GetJobAlertsOutput: ResolversTypes['GetJobAlertsResult'] | ResolversTypes['JobAlertError'];
  GetJobAlertsResult: ResolverTypeWrapper<GetJobAlertsResult>;
  JobAlert: ResolverTypeWrapper<JobAlert>;
  JobAlertStatus: JobAlertStatus;
  JobAlertSearchQuery: ResolverTypeWrapper<JobAlertSearchQuery>;
  JobAlertCountry: JobAlertCountry;
  JobAlertError: ResolverTypeWrapper<JobAlertError>;
  JobAlertErrorCode: JobAlertErrorCode;
  JobPostingOutput: ResolversTypes['JobPostingSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingSuccess: ResolverTypeWrapper<JobPostingSuccess>;
  JobPosting: ResolverTypeWrapper<JobPosting>;
  JobPostingContent: ResolverTypeWrapper<JobPostingContent>;
  JobPostingCountry: JobPostingCountry;
  JobPostingType: JobPostingType;
  JobPostingCompensation: JobPostingCompensation;
  JobPostingError: ResolverTypeWrapper<JobPostingError>;
  JobPostingErrorCode: JobPostingErrorCode;
  JobPostingListFilter: JobPostingListFilter;
  JobPostingCountOutput: ResolversTypes['JobPostingCountSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingCountSuccess: ResolverTypeWrapper<JobPostingCountSuccess>;
  JobPostingListOutput: ResolversTypes['JobPostingListSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingListSuccess: ResolverTypeWrapper<JobPostingListSuccess>;
  JobPostingChargeInput: JobPostingChargeInput;
  JobPostingChargeOutput: ResolversTypes['JobPostingChargeSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingChargeSuccess: ResolverTypeWrapper<JobPostingChargeSuccess>;
  JobPostingCharge: ResolverTypeWrapper<JobPostingCharge>;
  JobPostingCurrency: JobPostingCurrency;
  JobPostingOrder: ResolverTypeWrapper<JobPostingOrder>;
  JobPostingOrderStatus: JobPostingOrderStatus;
  VersionedEntity: ResolverTypeWrapper<VersionedEntity>;
  JobPostingOrderItem: ResolverTypeWrapper<JobPostingOrderItem>;
  JobPostingProductConsumption: ResolverTypeWrapper<JobPostingProductConsumption>;
  FreeTrial: ResolverTypeWrapper<FreeTrial>;
  JobPostingPaymentDeferral: ResolverTypeWrapper<JobPostingPaymentDeferral>;
  PaymentDeferralType: PaymentDeferralType;
  PaymentDeferralRuleMetric: PaymentDeferralRuleMetric;
  PaymentDeferralRuleGrouping: PaymentDeferralRuleGrouping;
  ChargeEntity: ChargeEntity;
  JobPostingOrderOutput: ResolversTypes['JobPostingOrderSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingOrderSuccess: ResolverTypeWrapper<JobPostingOrderSuccess>;
  JobPostingDecodeIdInput: JobPostingDecodeIdInput;
  JobPostingDecodeIdOutput: ResolversTypes['JobPostingDecodeIdSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingDecodeIdSuccess: ResolverTypeWrapper<JobPostingDecodeIdSuccess>;
  JobPostingReceiptListInput: JobPostingReceiptListInput;
  JobPostingReceiptListOutput: ResolversTypes['JobPostingReceiptListSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingReceiptListSuccess: ResolverTypeWrapper<JobPostingReceiptListSuccess>;
  JobPostingReceipt: ResolverTypeWrapper<JobPostingReceipt>;
  JobPostingActiveProductInput: JobPostingActiveProductInput;
  JobPostingActiveProductOutput: ResolversTypes['JobPostingActiveProductSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingActiveProductSuccess: ResolverTypeWrapper<JobPostingActiveProductSuccess>;
  Mutation: ResolverTypeWrapper<{}>;
  UserInterestForm: UserInterestForm;
  UserParams: UserParams;
  ImpressionJobObject: ImpressionJobObject;
  CompanyLogoProvider: CompanyLogoProvider;
  DescriptionImpressionJobObject: DescriptionImpressionJobObject;
  NavigationParams: NavigationParams;
  PageParams: PageParams;
  ScreenParams: ScreenParams;
  WindowParams: WindowParams;
  ConnectionParams: ConnectionParams;
  MutationQuery: MutationQuery;
  HeroImageAssetInput: HeroImageAssetInput;
  AuthorAvatarAssetInput: AuthorAvatarAssetInput;
  CategoriesCategoryInput: CategoriesCategoryInput;
  ArticleBodyInput: ArticleBodyInput;
  ArticleBodyNewsectionInput: ArticleBodyNewsectionInput;
  ArticleBodyHeadingInput: ArticleBodyHeadingInput;
  ArticleBodyTextInput: ArticleBodyTextInput;
  PositionEnum: PositionEnum;
  ArticleBodyPullquoteInput: ArticleBodyPullquoteInput;
  ArticleBodyImageInput: ArticleBodyImageInput;
  ImageAssetInput: ImageAssetInput;
  ArticleBodyQuoteInput: ArticleBodyQuoteInput;
  ArticleBodySearchctaInput: ArticleBodySearchctaInput;
  Blog: ResolverTypeWrapper<Omit<Blog, 'articleBody'> & { articleBody?: Maybe<Array<Maybe<ResolversTypes['ArticleBodyUnion']>>> }>;
  BlogCategory: ResolverTypeWrapper<Omit<BlogCategory, 'articleBody'> & { articleBody?: Maybe<Array<Maybe<ResolversTypes['ArticleBodyUnion']>>> }>;
  ArticleBodyUnion: ResolversTypes['ArticleBodyNewsection'] | ResolversTypes['ArticleBodyHeading'] | ResolversTypes['ArticleBodyText'] | ResolversTypes['ArticleBodyPullquote'] | ResolversTypes['ArticleBodyImage'] | ResolversTypes['ArticleBodyQuote'] | ResolversTypes['ArticleBodySearchcta'];
  ArticleBodyNewsection: ResolverTypeWrapper<ArticleBodyNewsection>;
  ArticleBodyHeading: ResolverTypeWrapper<ArticleBodyHeading>;
  ArticleBodyText: ResolverTypeWrapper<ArticleBodyText>;
  RedactorFieldData: ResolverTypeWrapper<RedactorFieldData>;
  OptionFieldData: ResolverTypeWrapper<OptionFieldData>;
  OptionFieldDataOptions: ResolverTypeWrapper<OptionFieldDataOptions>;
  ArticleBodyPullquote: ResolverTypeWrapper<ArticleBodyPullquote>;
  ArticleBodyImage: ResolverTypeWrapper<ArticleBodyImage>;
  ArticleBodyQuote: ResolverTypeWrapper<ArticleBodyQuote>;
  ArticleBodySearchcta: ResolverTypeWrapper<ArticleBodySearchcta>;
  UserSignupInput: UserSignupInput;
  UserSignupResult: ResolversTypes['UserAlreadyLoggedInError'] | ResolversTypes['UserSignupSuccess'] | ResolversTypes['UserSignupError'];
  UserAlreadyLoggedInError: ResolverTypeWrapper<UserAlreadyLoggedInError>;
  AlreadyLoggedInError: AlreadyLoggedInError;
  UserSignupSuccess: ResolverTypeWrapper<UserSignupSuccess>;
  UserSignupError: ResolverTypeWrapper<UserSignupError>;
  UserSignupInputErrors: ResolverTypeWrapper<UserSignupInputErrors>;
  EmailInputErrorWithExists: EmailInputErrorWithExists;
  PasswordInputError: PasswordInputError;
  PasswordConfirmationInputError: PasswordConfirmationInputError;
  CheckboxRequiredError: CheckboxRequiredError;
  CheckboxError: CheckboxError;
  UserSignupOtherError: UserSignupOtherError;
  UserLoginInput: UserLoginInput;
  UserLoginResult: ResolversTypes['UserAlreadyLoggedInError'] | ResolversTypes['UserLoginSuccess'] | ResolversTypes['UserLoginError'];
  UserLoginSuccess: ResolverTypeWrapper<UserLoginSuccess>;
  UserLoginError: ResolverTypeWrapper<UserLoginError>;
  UserLoginInputErrors: ResolverTypeWrapper<UserLoginInputErrors>;
  EmailInputError: EmailInputError;
  UserLoginOtherError: UserLoginOtherError;
  UserVerifyEmailInput: UserVerifyEmailInput;
  UserVerifyEmailResult: ResolversTypes['UserVerifyEmailSuccess'] | ResolversTypes['UserVerifyEmailError'];
  UserVerifyEmailSuccess: ResolverTypeWrapper<UserVerifyEmailSuccess>;
  UserVerifyEmailError: ResolverTypeWrapper<UserVerifyEmailError>;
  UserVerifyEmailInputError: ResolverTypeWrapper<UserVerifyEmailInputError>;
  TokenError: TokenError;
  UserVerifyEmailOtherError: UserVerifyEmailOtherError;
  UserResendEmailVerificationResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserResendEmailVerificationSuccess'] | ResolversTypes['UserResendEmailVerificationError'];
  UserResendEmailVerificationSuccess: ResolverTypeWrapper<UserResendEmailVerificationSuccess>;
  UserResendEmailVerificationError: ResolverTypeWrapper<UserResendEmailVerificationError>;
  UserResendEmailVerificationOtherError: UserResendEmailVerificationOtherError;
  UserForgotPasswordInput: UserForgotPasswordInput;
  UserForgotPasswordResult: ResolversTypes['UserForgotPasswordSuccess'] | ResolversTypes['UserForgotPasswordError'];
  UserForgotPasswordSuccess: ResolverTypeWrapper<UserForgotPasswordSuccess>;
  UserForgotPasswordError: ResolverTypeWrapper<UserForgotPasswordError>;
  UserForgotPasswordInputError: ResolverTypeWrapper<UserForgotPasswordInputError>;
  UserResetPasswordInput: UserResetPasswordInput;
  UserResetPasswordResult: ResolversTypes['UserResetPasswordSuccess'] | ResolversTypes['UserResetPasswordError'];
  UserResetPasswordSuccess: ResolverTypeWrapper<UserResetPasswordSuccess>;
  UserResetPasswordError: ResolverTypeWrapper<UserResetPasswordError>;
  UserResetPasswordInputError: ResolverTypeWrapper<UserResetPasswordInputError>;
  UserResetPasswordOtherError: UserResetPasswordOtherError;
  UserUpdateAccountInput: UserUpdateAccountInput;
  UserUpdateAccountResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserUpdateAccountSuccess'] | ResolversTypes['UserUpdateAccountError'];
  UserUpdateAccountSuccess: ResolverTypeWrapper<UserUpdateAccountSuccess>;
  UserUpdateAccountError: ResolverTypeWrapper<UserUpdateAccountError>;
  UserUpdateAccountInputErrors: ResolverTypeWrapper<UserUpdateAccountInputErrors>;
  FirstNameInputError: FirstNameInputError;
  LastNameInputError: LastNameInputError;
  UserUpdateAccountOtherError: UserUpdateAccountOtherError;
  UserChangeEmailInput: UserChangeEmailInput;
  UserChangeEmailResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserChangeEmailSuccess'] | ResolversTypes['UserChangeEmailError'];
  UserChangeEmailSuccess: ResolverTypeWrapper<UserChangeEmailSuccess>;
  UserChangeEmailError: ResolverTypeWrapper<UserChangeEmailError>;
  UserChangeEmailInputErrors: ResolverTypeWrapper<UserChangeEmailInputErrors>;
  EmailInputErrorWithExistsAndSelfCompare: EmailInputErrorWithExistsAndSelfCompare;
  EmailAddressConfirmationInputError: EmailAddressConfirmationInputError;
  UserChangeEmailOtherError: UserChangeEmailOtherError;
  UserChangePasswordInput: UserChangePasswordInput;
  UserChangePasswordResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserChangePasswordSuccess'] | ResolversTypes['UserChangePasswordError'];
  UserChangePasswordSuccess: ResolverTypeWrapper<UserChangePasswordSuccess>;
  UserChangePasswordError: ResolverTypeWrapper<UserChangePasswordError>;
  UserChangePasswordInputErrors: ResolverTypeWrapper<UserChangePasswordInputErrors>;
  PasswordInputErrorWithValidation: PasswordInputErrorWithValidation;
  UserChangePasswordOtherError: UserChangePasswordOtherError;
  UserDeleteAccountInput: UserDeleteAccountInput;
  UserDeleteAccountResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserDeleteAccountSuccess'] | ResolversTypes['UserDeleteAccountError'];
  UserDeleteAccountSuccess: ResolverTypeWrapper<UserDeleteAccountSuccess>;
  UserDeleteAccountError: ResolverTypeWrapper<UserDeleteAccountError>;
  UserDeleteAccountInputErrors: ResolverTypeWrapper<UserDeleteAccountInputErrors>;
  UserDeleteAccountOtherError: UserDeleteAccountOtherError;
  UserUpdateQuizDataInput: UserUpdateQuizDataInput;
  UserUpdateQuizDataResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserUpdateQuizDataSuccess'] | ResolversTypes['UserUpdateQuizDataError'];
  UserUpdateQuizDataSuccess: ResolverTypeWrapper<UserUpdateQuizDataSuccess>;
  UserUpdateQuizDataError: ResolverTypeWrapper<UserUpdateQuizDataError>;
  UserUpdateQuizDataInputErrors: ResolverTypeWrapper<UserUpdateQuizDataInputErrors>;
  UserUpdateQuizDataOtherError: UserUpdateQuizDataOtherError;
  UserSaveStepInput: UserSaveStepInput;
  UserProgressStep: UserProgressStep;
  UserSaveStepResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserSaveStepSuccess'] | ResolversTypes['UserSaveStepError'];
  UserSaveStepSuccess: ResolverTypeWrapper<UserSaveStepSuccess>;
  UserSaveStepError: ResolverTypeWrapper<UserSaveStepError>;
  UserSaveStepOtherError: UserSaveStepOtherError;
  UserUpdateShowGamifyInput: UserUpdateShowGamifyInput;
  UserUpdateShowGamifyResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserUpdateShowGamifySuccess'] | ResolversTypes['UserUpdateShowGamifyError'];
  UserUpdateShowGamifySuccess: ResolverTypeWrapper<UserUpdateShowGamifySuccess>;
  UserUpdateShowGamifyError: ResolverTypeWrapper<UserUpdateShowGamifyError>;
  UserUpdateShowGamifyOtherError: UserUpdateShowGamifyOtherError;
  UserChangeLoggedInRealmInput: UserChangeLoggedInRealmInput;
  UserChangeLoggedInRealmResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserChangeLoggedInRealmSuccess'] | ResolversTypes['UserChangeLoggedInRealmError'];
  UserChangeLoggedInRealmSuccess: ResolverTypeWrapper<UserChangeLoggedInRealmSuccess>;
  UserChangeLoggedInRealmError: ResolverTypeWrapper<UserChangeLoggedInRealmError>;
  UserChangeLoggedInRealmInputError: ResolverTypeWrapper<UserChangeLoggedInRealmInputError>;
  UserChangeLoggedInRealmOtherError: UserChangeLoggedInRealmOtherError;
  UserCancelPendingEmailChangeResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserCancelPendingEmailChangeSuccess'] | ResolversTypes['UserCancelPendingEmailChangeError'];
  UserCancelPendingEmailChangeSuccess: ResolverTypeWrapper<UserCancelPendingEmailChangeSuccess>;
  UserCancelPendingEmailChangeError: ResolverTypeWrapper<UserCancelPendingEmailChangeError>;
  UserCancelPendingEmailChangeOtherError: UserCancelPendingEmailChangeOtherError;
  InviteAcceptInput: InviteAcceptInput;
  InviteAcceptResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['InviteAcceptSuccess'] | ResolversTypes['InviteAcceptError'];
  InviteAcceptSuccess: ResolverTypeWrapper<InviteAcceptSuccess>;
  InviteAcceptError: ResolverTypeWrapper<InviteAcceptError>;
  InviteAcceptInputErrors: ResolverTypeWrapper<InviteAcceptInputErrors>;
  InviteAcceptOtherError: InviteAcceptOtherError;
  InviteIgnoreInput: InviteIgnoreInput;
  InviteIgnoreResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['InviteIgnoreSuccess'] | ResolversTypes['InviteIgnoreError'];
  InviteIgnoreSuccess: ResolverTypeWrapper<InviteIgnoreSuccess>;
  InviteIgnoreError: ResolverTypeWrapper<InviteIgnoreError>;
  InviteIgnoreInputErrors: ResolverTypeWrapper<InviteIgnoreInputErrors>;
  InviteIgnoreOtherError: InviteIgnoreOtherError;
  InviteRemoveInput: InviteRemoveInput;
  InviteRemoveResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['InviteRemoveSuccess'] | ResolversTypes['InviteRemoveError'];
  InviteRemoveSuccess: ResolverTypeWrapper<InviteRemoveSuccess>;
  InviteRemoveError: ResolverTypeWrapper<InviteRemoveError>;
  InviteRemoveInputErrors: ResolverTypeWrapper<InviteRemoveInputErrors>;
  InviteRemoveOtherError: InviteRemoveOtherError;
  CollaboratorInviteInput: CollaboratorInviteInput;
  CollaboratorInviteResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['UserUnauthorizedError'] | ResolversTypes['CollaboratorInviteSuccess'] | ResolversTypes['CollaboratorInviteError'];
  CollaboratorInviteSuccess: ResolverTypeWrapper<CollaboratorInviteSuccess>;
  CollaboratorInviteError: ResolverTypeWrapper<CollaboratorInviteError>;
  CollaboratorInviteInputErrors: ResolverTypeWrapper<CollaboratorInviteInputErrors>;
  CollaboratorInviteOtherError: CollaboratorInviteOtherError;
  CollaboratorRemoveInput: CollaboratorRemoveInput;
  CollaboratorRemoveResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['CollaboratorRemoveSuccess'] | ResolversTypes['CollaboratorRemoveError'];
  CollaboratorRemoveSuccess: ResolverTypeWrapper<CollaboratorRemoveSuccess>;
  CollaboratorRemoveError: ResolverTypeWrapper<CollaboratorRemoveError>;
  CollaboratorRemoveInputErrors: ResolverTypeWrapper<CollaboratorRemoveInputErrors>;
  CollaboratorRemoveOtherError: CollaboratorRemoveOtherError;
  JobCollectionCreateInput: JobCollectionCreateInput;
  JobCollectionCreateResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobCollectionCreateSuccess'] | ResolversTypes['JobCollectionCreateError'];
  JobCollectionCreateSuccess: ResolverTypeWrapper<JobCollectionCreateSuccess>;
  JobCollectionCreateError: ResolverTypeWrapper<JobCollectionCreateError>;
  JobCollectionCreateInputErrors: ResolverTypeWrapper<JobCollectionCreateInputErrors>;
  NameError: NameError;
  JobCollectionCreateOtherError: JobCollectionCreateOtherError;
  JobCollectionDeleteInput: JobCollectionDeleteInput;
  JobCollectionDeleteResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobCollectionDeleteSuccess'] | ResolversTypes['JobCollectionDeleteError'];
  JobCollectionDeleteSuccess: ResolverTypeWrapper<JobCollectionDeleteSuccess>;
  JobCollectionDeleteError: ResolverTypeWrapper<JobCollectionDeleteError>;
  JobCollectionDeleteInputErrors: ResolverTypeWrapper<JobCollectionDeleteInputErrors>;
  JobCollectionDeleteOtherError: JobCollectionDeleteOtherError;
  JobCollectionChangeTitleInput: JobCollectionChangeTitleInput;
  JobCollectionChangeTitleResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobCollectionChangeTitleSuccess'] | ResolversTypes['JobCollectionChangeTitleError'];
  JobCollectionChangeTitleSuccess: ResolverTypeWrapper<JobCollectionChangeTitleSuccess>;
  JobCollectionChangeTitleError: ResolverTypeWrapper<JobCollectionChangeTitleError>;
  JobCollectionChangeTitleInputErrors: ResolverTypeWrapper<JobCollectionChangeTitleInputErrors>;
  JobCollectionChangeTitleOtherError: JobCollectionChangeTitleOtherError;
  JobCollectionChangePermsInput: JobCollectionChangePermsInput;
  JobCollectionChangePermsResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobCollectionChangePermsSuccess'] | ResolversTypes['JobCollectionChangePermsError'];
  JobCollectionChangePermsSuccess: ResolverTypeWrapper<JobCollectionChangePermsSuccess>;
  JobCollectionChangePermsError: ResolverTypeWrapper<JobCollectionChangePermsError>;
  JobCollectionChangePermsInputErrors: ResolverTypeWrapper<JobCollectionChangePermsInputErrors>;
  JobCollectionChangePermsOtherError: JobCollectionChangePermsOtherError;
  JobCollectionToggleSharingInput: JobCollectionToggleSharingInput;
  JobCollectionToggleSharingResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobCollectionToggleSharingSuccess'] | ResolversTypes['JobCollectionToggleSharingError'];
  JobCollectionToggleSharingSuccess: ResolverTypeWrapper<JobCollectionToggleSharingSuccess>;
  JobCollectionToggleSharingError: ResolverTypeWrapper<JobCollectionToggleSharingError>;
  JobCollectionToggleSharingInputErrors: ResolverTypeWrapper<JobCollectionToggleSharingInputErrors>;
  JobCollectionToggleSharingOtherError: JobCollectionToggleSharingOtherError;
  JobSaveInput: JobSaveInput;
  JobSaveResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobSaveSuccess'] | ResolversTypes['JobSaveError'];
  JobSaveSuccess: ResolverTypeWrapper<JobSaveSuccess>;
  JobCollectionMod: ResolverTypeWrapper<JobCollectionMod>;
  ModTypeEnum: ModTypeEnum;
  JobSaveError: ResolverTypeWrapper<JobSaveError>;
  JobSaveInputErrors: ResolverTypeWrapper<JobSaveInputErrors>;
  JobSaveInputError: JobSaveInputError;
  JobSaveOtherError: JobSaveOtherError;
  JobUnsaveInput: JobUnsaveInput;
  JobUnsaveResult: ResolversTypes['UserAuthenticationRequiredError'] | ResolversTypes['JobUnsaveSuccess'] | ResolversTypes['JobUnsaveError'];
  JobUnsaveSuccess: ResolverTypeWrapper<JobUnsaveSuccess>;
  JobUnsaveError: ResolverTypeWrapper<JobUnsaveError>;
  JobUnsaveInputErrors: ResolverTypeWrapper<JobUnsaveInputErrors>;
  JobUnsaveInputError: JobUnsaveInputError;
  JobUnsaveOtherError: JobUnsaveOtherError;
  JobAlertSearchQueryInput: JobAlertSearchQueryInput;
  AddJobAlertOutput: ResolversTypes['JobAlert'] | ResolversTypes['JobAlertError'];
  VerifyJobAlertOutput: ResolversTypes['VerifyJobAlertResult'] | ResolversTypes['JobAlertError'];
  VerifyJobAlertResult: ResolverTypeWrapper<VerifyJobAlertResult>;
  PauseJobAlertOutput: ResolversTypes['PauseJobAlertResult'] | ResolversTypes['JobAlertError'];
  PauseJobAlertResult: ResolverTypeWrapper<PauseJobAlertResult>;
  PauseAllJobAlertsOutput: ResolversTypes['PauseAllJobAlertsResult'] | ResolversTypes['JobAlertError'];
  PauseAllJobAlertsResult: ResolverTypeWrapper<PauseAllJobAlertsResult>;
  DeleteJobAlertOutput: ResolversTypes['DeleteJobAlertResult'] | ResolversTypes['JobAlertError'];
  DeleteJobAlertResult: ResolverTypeWrapper<DeleteJobAlertResult>;
  DeleteAllJobAlertsOutput: ResolversTypes['DeleteAllJobAlertsResult'] | ResolversTypes['JobAlertError'];
  DeleteAllJobAlertsResult: ResolverTypeWrapper<DeleteAllJobAlertsResult>;
  JobPostingInput: JobPostingInput;
  JobPostingContentInput: JobPostingContentInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  JobPostingApplyInput: JobPostingApplyInput;
  JobPostingApplyOutput: ResolversTypes['JobPostingApplySuccess'] | ResolversTypes['JobPostingError'];
  JobPostingApplySuccess: ResolverTypeWrapper<JobPostingApplySuccess>;
  JobPostingCheckoutInput: JobPostingCheckoutInput;
  JobPostingCheckoutOutput: ResolversTypes['JobPostingCheckoutSuccess'] | ResolversTypes['JobPostingError'];
  JobPostingCheckoutSuccess: ResolverTypeWrapper<JobPostingCheckoutSuccess>;
  JobPostingLogViewInput: JobPostingLogViewInput;
  DateFormatTypes: DateFormatTypes;
  JoblistcontentqaVolume: ResolverTypeWrapper<JoblistcontentqaVolume>;
  OneFieldRequiredError: OneFieldRequiredError;
  RequiredAndInvalidInputError: RequiredAndInvalidInputError;
  AddJobAlertResult: ResolverTypeWrapper<AddJobAlertResult>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  SearchQuery: SearchQuery;
  String: Scalars['String'];
  TagFilters: TagFilters;
  TagFlags: TagFlags;
  TagFlag: TagFlag;
  Boolean: Scalars['Boolean'];
  SearchSettings: SearchSettings;
  RankFeature: RankFeature;
  Float: Scalars['Float'];
  SaturationRanking: SaturationRanking;
  LogRanking: LogRanking;
  SigmoidRanking: SigmoidRanking;
  ProviderConfig: ProviderConfig;
  CountryBoost: CountryBoost;
  JobPostingConfig: JobPostingConfig;
  DecayConfig: DecayConfig;
  Int: Scalars['Int'];
  FieldBoost: FieldBoost;
  ExclusionClause: ExclusionClause;
  ProvidersFilter: ProvidersFilter;
  SearchResult: SearchResult;
  Job: Job;
  ID: Scalars['ID'];
  Location: Location;
  CompanyLogo: CompanyLogo;
  TagSources: TagSources;
  Tags: Tags;
  DateTime: Scalars['DateTime'];
  JobResult: ResolversParentTypes['Job'] | ResolversParentTypes['JobError'];
  JobError: JobError;
  BrowseCityInput: BrowseCityInput;
  BrowseRoleInput: BrowseRoleInput;
  BrowseResult: BrowseResult;
  BrowseState: BrowseState;
  BrowseCity: BrowseCity;
  BrowseRole: BrowseRole;
  BrowseRoleContent: BrowseRoleContent;
  BrowseCityContent: BrowseCityContent;
  NormalBrowseArgs: NormalBrowseArgs;
  BrowseListing: BrowseListing;
  BrowseStats: BrowseStats;
  SuggestionContext: SuggestionContext;
  SuggestionFuzziness: SuggestionFuzziness;
  JobData: JobData;
  CityData: CityData;
  TitleJobCount: TitleJobCount;
  CompanyJobCount: CompanyJobCount;
  Area1JobCount: Area1JobCount;
  LocalityJobCount: LocalityJobCount;
  PostalCodeJobCount: PostalCodeJobCount;
  CategoryJobCount: CategoryJobCount;
  Site: Site;
  Presspage: Omit<Presspage, 'pressEntries'> & { pressEntries?: Maybe<Array<Maybe<ResolversParentTypes['PressEntriesUnion']>>> };
  EntryInterface: ResolversParentTypes['Presspage'] | ResolversParentTypes['Blog'];
  User: User;
  Timestamp: Scalars['Timestamp'];
  Section: Section;
  SectionSiteSettings: SectionSiteSettings;
  EntryType: EntryType;
  Field: Field;
  RelatedToInputType: RelatedToInputType;
  ElementInterface: ResolversParentTypes['Presspage'] | ResolversParentTypes['Blog'];
  PressEntriesUnion: ResolversParentTypes['PressEntriesPressSection'] | ResolversParentTypes['PressEntriesPressEntry'];
  PressEntriesPressSection: PressEntriesPressSection;
  PressEntriesPressEntry: PressEntriesPressEntry;
  VolumeInterface: ResolversParentTypes['JoblistcontentqaVolume'];
  CropInputObject: CropInputObject;
  VolumeFolder: VolumeFolder;
  AssetFocalPoint: AssetFocalPoint;
  EntryConnection: EntryConnection;
  PageInfo: PageInfo;
  EntryEdge: EntryEdge;
  EntryDraftConnection: EntryDraftConnection;
  EntryDraftEdge: EntryDraftEdge;
  EntryDraftInfo: EntryDraftInfo;
  CategoryConnection: CategoryConnection;
  CategoryEdge: CategoryEdge;
  CategoryInterface: ResolversParentTypes['BlogCategory'];
  CategoryGroup: CategoryGroup;
  JoblistUser: JoblistUser;
  UserRole: UserRole;
  QuizData: QuizData;
  UserProgress: UserProgress;
  VerifyTokenInput: VerifyTokenInput;
  MasqueradeInput: MasqueradeInput;
  InviteFetchInput: InviteFetchInput;
  InviteFetchResult: ResolversParentTypes['InviteFetchSuccess'];
  InviteFetchSuccess: InviteFetchSuccess;
  InvitedUser: InvitedUser;
  JobCollection: JobCollection;
  JobCollectionPerms: JobCollectionPerms;
  JobCollectionItem: JobCollectionItem;
  JobCollectionCollaborator: JobCollectionCollaborator;
  InvitedBy: InvitedBy;
  JobCollectionInput: JobCollectionInput;
  JobCollectionResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserUnauthorizedError'] | ResolversParentTypes['JobCollectionSuccess'] | ResolversParentTypes['JobCollectionError'];
  UserAuthenticationRequiredError: UserAuthenticationRequiredError;
  UserUnauthorizedError: UserUnauthorizedError;
  JobCollectionSuccess: JobCollectionSuccess;
  JobCollectionError: JobCollectionError;
  JobCollectionInputErrors: JobCollectionInputErrors;
  JobCollectionListInput: JobCollectionListInput;
  JobCollectionListResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobCollectionListSuccess'] | ResolversParentTypes['JobCollectionListError'];
  JobCollectionListSuccess: JobCollectionListSuccess;
  JobCollectionListError: JobCollectionListError;
  JobCollectionListInputErrors: JobCollectionListInputErrors;
  JobCollectionGetNewURLInput: JobCollectionGetNewUrlInput;
  JobCollectionGetNewURLResult: ResolversParentTypes['JobCollectionGetNewURLSuccess'];
  JobCollectionGetNewURLSuccess: JobCollectionGetNewUrlSuccess;
  GetJobAlertsOutput: ResolversParentTypes['GetJobAlertsResult'] | ResolversParentTypes['JobAlertError'];
  GetJobAlertsResult: GetJobAlertsResult;
  JobAlert: JobAlert;
  JobAlertSearchQuery: JobAlertSearchQuery;
  JobAlertError: JobAlertError;
  JobPostingOutput: ResolversParentTypes['JobPostingSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingSuccess: JobPostingSuccess;
  JobPosting: JobPosting;
  JobPostingContent: JobPostingContent;
  JobPostingError: JobPostingError;
  JobPostingCountOutput: ResolversParentTypes['JobPostingCountSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingCountSuccess: JobPostingCountSuccess;
  JobPostingListOutput: ResolversParentTypes['JobPostingListSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingListSuccess: JobPostingListSuccess;
  JobPostingChargeInput: JobPostingChargeInput;
  JobPostingChargeOutput: ResolversParentTypes['JobPostingChargeSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingChargeSuccess: JobPostingChargeSuccess;
  JobPostingCharge: JobPostingCharge;
  JobPostingOrder: JobPostingOrder;
  VersionedEntity: VersionedEntity;
  JobPostingOrderItem: JobPostingOrderItem;
  JobPostingProductConsumption: JobPostingProductConsumption;
  FreeTrial: FreeTrial;
  JobPostingPaymentDeferral: JobPostingPaymentDeferral;
  JobPostingOrderOutput: ResolversParentTypes['JobPostingOrderSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingOrderSuccess: JobPostingOrderSuccess;
  JobPostingDecodeIdInput: JobPostingDecodeIdInput;
  JobPostingDecodeIdOutput: ResolversParentTypes['JobPostingDecodeIdSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingDecodeIdSuccess: JobPostingDecodeIdSuccess;
  JobPostingReceiptListInput: JobPostingReceiptListInput;
  JobPostingReceiptListOutput: ResolversParentTypes['JobPostingReceiptListSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingReceiptListSuccess: JobPostingReceiptListSuccess;
  JobPostingReceipt: JobPostingReceipt;
  JobPostingActiveProductInput: JobPostingActiveProductInput;
  JobPostingActiveProductOutput: ResolversParentTypes['JobPostingActiveProductSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingActiveProductSuccess: JobPostingActiveProductSuccess;
  Mutation: {};
  UserParams: UserParams;
  ImpressionJobObject: ImpressionJobObject;
  DescriptionImpressionJobObject: DescriptionImpressionJobObject;
  NavigationParams: NavigationParams;
  PageParams: PageParams;
  ScreenParams: ScreenParams;
  WindowParams: WindowParams;
  ConnectionParams: ConnectionParams;
  MutationQuery: MutationQuery;
  HeroImageAssetInput: HeroImageAssetInput;
  AuthorAvatarAssetInput: AuthorAvatarAssetInput;
  CategoriesCategoryInput: CategoriesCategoryInput;
  ArticleBodyInput: ArticleBodyInput;
  ArticleBodyNewsectionInput: ArticleBodyNewsectionInput;
  ArticleBodyHeadingInput: ArticleBodyHeadingInput;
  ArticleBodyTextInput: ArticleBodyTextInput;
  ArticleBodyPullquoteInput: ArticleBodyPullquoteInput;
  ArticleBodyImageInput: ArticleBodyImageInput;
  ImageAssetInput: ImageAssetInput;
  ArticleBodyQuoteInput: ArticleBodyQuoteInput;
  ArticleBodySearchctaInput: ArticleBodySearchctaInput;
  Blog: Omit<Blog, 'articleBody'> & { articleBody?: Maybe<Array<Maybe<ResolversParentTypes['ArticleBodyUnion']>>> };
  BlogCategory: Omit<BlogCategory, 'articleBody'> & { articleBody?: Maybe<Array<Maybe<ResolversParentTypes['ArticleBodyUnion']>>> };
  ArticleBodyUnion: ResolversParentTypes['ArticleBodyNewsection'] | ResolversParentTypes['ArticleBodyHeading'] | ResolversParentTypes['ArticleBodyText'] | ResolversParentTypes['ArticleBodyPullquote'] | ResolversParentTypes['ArticleBodyImage'] | ResolversParentTypes['ArticleBodyQuote'] | ResolversParentTypes['ArticleBodySearchcta'];
  ArticleBodyNewsection: ArticleBodyNewsection;
  ArticleBodyHeading: ArticleBodyHeading;
  ArticleBodyText: ArticleBodyText;
  RedactorFieldData: RedactorFieldData;
  OptionFieldData: OptionFieldData;
  OptionFieldDataOptions: OptionFieldDataOptions;
  ArticleBodyPullquote: ArticleBodyPullquote;
  ArticleBodyImage: ArticleBodyImage;
  ArticleBodyQuote: ArticleBodyQuote;
  ArticleBodySearchcta: ArticleBodySearchcta;
  UserSignupInput: UserSignupInput;
  UserSignupResult: ResolversParentTypes['UserAlreadyLoggedInError'] | ResolversParentTypes['UserSignupSuccess'] | ResolversParentTypes['UserSignupError'];
  UserAlreadyLoggedInError: UserAlreadyLoggedInError;
  UserSignupSuccess: UserSignupSuccess;
  UserSignupError: UserSignupError;
  UserSignupInputErrors: UserSignupInputErrors;
  UserLoginInput: UserLoginInput;
  UserLoginResult: ResolversParentTypes['UserAlreadyLoggedInError'] | ResolversParentTypes['UserLoginSuccess'] | ResolversParentTypes['UserLoginError'];
  UserLoginSuccess: UserLoginSuccess;
  UserLoginError: UserLoginError;
  UserLoginInputErrors: UserLoginInputErrors;
  UserVerifyEmailInput: UserVerifyEmailInput;
  UserVerifyEmailResult: ResolversParentTypes['UserVerifyEmailSuccess'] | ResolversParentTypes['UserVerifyEmailError'];
  UserVerifyEmailSuccess: UserVerifyEmailSuccess;
  UserVerifyEmailError: UserVerifyEmailError;
  UserVerifyEmailInputError: UserVerifyEmailInputError;
  UserResendEmailVerificationResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserResendEmailVerificationSuccess'] | ResolversParentTypes['UserResendEmailVerificationError'];
  UserResendEmailVerificationSuccess: UserResendEmailVerificationSuccess;
  UserResendEmailVerificationError: UserResendEmailVerificationError;
  UserForgotPasswordInput: UserForgotPasswordInput;
  UserForgotPasswordResult: ResolversParentTypes['UserForgotPasswordSuccess'] | ResolversParentTypes['UserForgotPasswordError'];
  UserForgotPasswordSuccess: UserForgotPasswordSuccess;
  UserForgotPasswordError: UserForgotPasswordError;
  UserForgotPasswordInputError: UserForgotPasswordInputError;
  UserResetPasswordInput: UserResetPasswordInput;
  UserResetPasswordResult: ResolversParentTypes['UserResetPasswordSuccess'] | ResolversParentTypes['UserResetPasswordError'];
  UserResetPasswordSuccess: UserResetPasswordSuccess;
  UserResetPasswordError: UserResetPasswordError;
  UserResetPasswordInputError: UserResetPasswordInputError;
  UserUpdateAccountInput: UserUpdateAccountInput;
  UserUpdateAccountResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserUpdateAccountSuccess'] | ResolversParentTypes['UserUpdateAccountError'];
  UserUpdateAccountSuccess: UserUpdateAccountSuccess;
  UserUpdateAccountError: UserUpdateAccountError;
  UserUpdateAccountInputErrors: UserUpdateAccountInputErrors;
  UserChangeEmailInput: UserChangeEmailInput;
  UserChangeEmailResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserChangeEmailSuccess'] | ResolversParentTypes['UserChangeEmailError'];
  UserChangeEmailSuccess: UserChangeEmailSuccess;
  UserChangeEmailError: UserChangeEmailError;
  UserChangeEmailInputErrors: UserChangeEmailInputErrors;
  UserChangePasswordInput: UserChangePasswordInput;
  UserChangePasswordResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserChangePasswordSuccess'] | ResolversParentTypes['UserChangePasswordError'];
  UserChangePasswordSuccess: UserChangePasswordSuccess;
  UserChangePasswordError: UserChangePasswordError;
  UserChangePasswordInputErrors: UserChangePasswordInputErrors;
  UserDeleteAccountInput: UserDeleteAccountInput;
  UserDeleteAccountResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserDeleteAccountSuccess'] | ResolversParentTypes['UserDeleteAccountError'];
  UserDeleteAccountSuccess: UserDeleteAccountSuccess;
  UserDeleteAccountError: UserDeleteAccountError;
  UserDeleteAccountInputErrors: UserDeleteAccountInputErrors;
  UserUpdateQuizDataInput: UserUpdateQuizDataInput;
  UserUpdateQuizDataResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserUpdateQuizDataSuccess'] | ResolversParentTypes['UserUpdateQuizDataError'];
  UserUpdateQuizDataSuccess: UserUpdateQuizDataSuccess;
  UserUpdateQuizDataError: UserUpdateQuizDataError;
  UserUpdateQuizDataInputErrors: UserUpdateQuizDataInputErrors;
  UserSaveStepInput: UserSaveStepInput;
  UserSaveStepResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserSaveStepSuccess'] | ResolversParentTypes['UserSaveStepError'];
  UserSaveStepSuccess: UserSaveStepSuccess;
  UserSaveStepError: UserSaveStepError;
  UserUpdateShowGamifyInput: UserUpdateShowGamifyInput;
  UserUpdateShowGamifyResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserUpdateShowGamifySuccess'] | ResolversParentTypes['UserUpdateShowGamifyError'];
  UserUpdateShowGamifySuccess: UserUpdateShowGamifySuccess;
  UserUpdateShowGamifyError: UserUpdateShowGamifyError;
  UserChangeLoggedInRealmInput: UserChangeLoggedInRealmInput;
  UserChangeLoggedInRealmResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserChangeLoggedInRealmSuccess'] | ResolversParentTypes['UserChangeLoggedInRealmError'];
  UserChangeLoggedInRealmSuccess: UserChangeLoggedInRealmSuccess;
  UserChangeLoggedInRealmError: UserChangeLoggedInRealmError;
  UserChangeLoggedInRealmInputError: UserChangeLoggedInRealmInputError;
  UserCancelPendingEmailChangeResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserCancelPendingEmailChangeSuccess'] | ResolversParentTypes['UserCancelPendingEmailChangeError'];
  UserCancelPendingEmailChangeSuccess: UserCancelPendingEmailChangeSuccess;
  UserCancelPendingEmailChangeError: UserCancelPendingEmailChangeError;
  InviteAcceptInput: InviteAcceptInput;
  InviteAcceptResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['InviteAcceptSuccess'] | ResolversParentTypes['InviteAcceptError'];
  InviteAcceptSuccess: InviteAcceptSuccess;
  InviteAcceptError: InviteAcceptError;
  InviteAcceptInputErrors: InviteAcceptInputErrors;
  InviteIgnoreInput: InviteIgnoreInput;
  InviteIgnoreResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['InviteIgnoreSuccess'] | ResolversParentTypes['InviteIgnoreError'];
  InviteIgnoreSuccess: InviteIgnoreSuccess;
  InviteIgnoreError: InviteIgnoreError;
  InviteIgnoreInputErrors: InviteIgnoreInputErrors;
  InviteRemoveInput: InviteRemoveInput;
  InviteRemoveResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['InviteRemoveSuccess'] | ResolversParentTypes['InviteRemoveError'];
  InviteRemoveSuccess: InviteRemoveSuccess;
  InviteRemoveError: InviteRemoveError;
  InviteRemoveInputErrors: InviteRemoveInputErrors;
  CollaboratorInviteInput: CollaboratorInviteInput;
  CollaboratorInviteResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['UserUnauthorizedError'] | ResolversParentTypes['CollaboratorInviteSuccess'] | ResolversParentTypes['CollaboratorInviteError'];
  CollaboratorInviteSuccess: CollaboratorInviteSuccess;
  CollaboratorInviteError: CollaboratorInviteError;
  CollaboratorInviteInputErrors: CollaboratorInviteInputErrors;
  CollaboratorRemoveInput: CollaboratorRemoveInput;
  CollaboratorRemoveResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['CollaboratorRemoveSuccess'] | ResolversParentTypes['CollaboratorRemoveError'];
  CollaboratorRemoveSuccess: CollaboratorRemoveSuccess;
  CollaboratorRemoveError: CollaboratorRemoveError;
  CollaboratorRemoveInputErrors: CollaboratorRemoveInputErrors;
  JobCollectionCreateInput: JobCollectionCreateInput;
  JobCollectionCreateResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobCollectionCreateSuccess'] | ResolversParentTypes['JobCollectionCreateError'];
  JobCollectionCreateSuccess: JobCollectionCreateSuccess;
  JobCollectionCreateError: JobCollectionCreateError;
  JobCollectionCreateInputErrors: JobCollectionCreateInputErrors;
  JobCollectionDeleteInput: JobCollectionDeleteInput;
  JobCollectionDeleteResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobCollectionDeleteSuccess'] | ResolversParentTypes['JobCollectionDeleteError'];
  JobCollectionDeleteSuccess: JobCollectionDeleteSuccess;
  JobCollectionDeleteError: JobCollectionDeleteError;
  JobCollectionDeleteInputErrors: JobCollectionDeleteInputErrors;
  JobCollectionChangeTitleInput: JobCollectionChangeTitleInput;
  JobCollectionChangeTitleResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobCollectionChangeTitleSuccess'] | ResolversParentTypes['JobCollectionChangeTitleError'];
  JobCollectionChangeTitleSuccess: JobCollectionChangeTitleSuccess;
  JobCollectionChangeTitleError: JobCollectionChangeTitleError;
  JobCollectionChangeTitleInputErrors: JobCollectionChangeTitleInputErrors;
  JobCollectionChangePermsInput: JobCollectionChangePermsInput;
  JobCollectionChangePermsResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobCollectionChangePermsSuccess'] | ResolversParentTypes['JobCollectionChangePermsError'];
  JobCollectionChangePermsSuccess: JobCollectionChangePermsSuccess;
  JobCollectionChangePermsError: JobCollectionChangePermsError;
  JobCollectionChangePermsInputErrors: JobCollectionChangePermsInputErrors;
  JobCollectionToggleSharingInput: JobCollectionToggleSharingInput;
  JobCollectionToggleSharingResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobCollectionToggleSharingSuccess'] | ResolversParentTypes['JobCollectionToggleSharingError'];
  JobCollectionToggleSharingSuccess: JobCollectionToggleSharingSuccess;
  JobCollectionToggleSharingError: JobCollectionToggleSharingError;
  JobCollectionToggleSharingInputErrors: JobCollectionToggleSharingInputErrors;
  JobSaveInput: JobSaveInput;
  JobSaveResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobSaveSuccess'] | ResolversParentTypes['JobSaveError'];
  JobSaveSuccess: JobSaveSuccess;
  JobCollectionMod: JobCollectionMod;
  JobSaveError: JobSaveError;
  JobSaveInputErrors: JobSaveInputErrors;
  JobUnsaveInput: JobUnsaveInput;
  JobUnsaveResult: ResolversParentTypes['UserAuthenticationRequiredError'] | ResolversParentTypes['JobUnsaveSuccess'] | ResolversParentTypes['JobUnsaveError'];
  JobUnsaveSuccess: JobUnsaveSuccess;
  JobUnsaveError: JobUnsaveError;
  JobUnsaveInputErrors: JobUnsaveInputErrors;
  JobAlertSearchQueryInput: JobAlertSearchQueryInput;
  AddJobAlertOutput: ResolversParentTypes['JobAlert'] | ResolversParentTypes['JobAlertError'];
  VerifyJobAlertOutput: ResolversParentTypes['VerifyJobAlertResult'] | ResolversParentTypes['JobAlertError'];
  VerifyJobAlertResult: VerifyJobAlertResult;
  PauseJobAlertOutput: ResolversParentTypes['PauseJobAlertResult'] | ResolversParentTypes['JobAlertError'];
  PauseJobAlertResult: PauseJobAlertResult;
  PauseAllJobAlertsOutput: ResolversParentTypes['PauseAllJobAlertsResult'] | ResolversParentTypes['JobAlertError'];
  PauseAllJobAlertsResult: PauseAllJobAlertsResult;
  DeleteJobAlertOutput: ResolversParentTypes['DeleteJobAlertResult'] | ResolversParentTypes['JobAlertError'];
  DeleteJobAlertResult: DeleteJobAlertResult;
  DeleteAllJobAlertsOutput: ResolversParentTypes['DeleteAllJobAlertsResult'] | ResolversParentTypes['JobAlertError'];
  DeleteAllJobAlertsResult: DeleteAllJobAlertsResult;
  JobPostingInput: JobPostingInput;
  JobPostingContentInput: JobPostingContentInput;
  Upload: Scalars['Upload'];
  JobPostingApplyInput: JobPostingApplyInput;
  JobPostingApplyOutput: ResolversParentTypes['JobPostingApplySuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingApplySuccess: JobPostingApplySuccess;
  JobPostingCheckoutInput: JobPostingCheckoutInput;
  JobPostingCheckoutOutput: ResolversParentTypes['JobPostingCheckoutSuccess'] | ResolversParentTypes['JobPostingError'];
  JobPostingCheckoutSuccess: JobPostingCheckoutSuccess;
  JobPostingLogViewInput: JobPostingLogViewInput;
  JoblistcontentqaVolume: JoblistcontentqaVolume;
  AddJobAlertResult: AddJobAlertResult;
};

export type AddJobAlertOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddJobAlertOutput'] = ResolversParentTypes['AddJobAlertOutput']> = {
  __resolveType: TypeResolveFn<'JobAlert' | 'JobAlertError', ParentType, ContextType>;
};

export type AddJobAlertResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddJobAlertResult'] = ResolversParentTypes['AddJobAlertResult']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Area1JobCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Area1JobCount'] = ResolversParentTypes['Area1JobCount']> = {
  area1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodyHeadingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodyHeading'] = ResolversParentTypes['ArticleBodyHeading']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  heading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodyImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodyImage'] = ResolversParentTypes['ArticleBodyImage']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  image?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeInterface']>>>, ParentType, ContextType, RequireFields<ArticleBodyImageImageArgs, never>>;
  caption?: Resolver<Maybe<ResolversTypes['RedactorFieldData']>, ParentType, ContextType>;
  alttext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['PositionEnum']>, ParentType, ContextType>;
  position_FieldData?: Resolver<Maybe<ResolversTypes['OptionFieldData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodyNewsectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodyNewsection'] = ResolversParentTypes['ArticleBodyNewsection']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sectionheading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodyPullquoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodyPullquote'] = ResolversParentTypes['ArticleBodyPullquote']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pullquote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['PositionEnum']>, ParentType, ContextType>;
  position_FieldData?: Resolver<Maybe<ResolversTypes['OptionFieldData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodyQuoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodyQuote'] = ResolversParentTypes['ArticleBodyQuote']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  quote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attribution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['PositionEnum']>, ParentType, ContextType>;
  position_FieldData?: Resolver<Maybe<ResolversTypes['OptionFieldData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodySearchctaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodySearchcta'] = ResolversParentTypes['ArticleBodySearchcta']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchctaurl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchctatext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodyTextResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodyText'] = ResolversParentTypes['ArticleBodyText']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['RedactorFieldData']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['PositionEnum']>, ParentType, ContextType>;
  position_FieldData?: Resolver<Maybe<ResolversTypes['OptionFieldData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArticleBodyUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBodyUnion'] = ResolversParentTypes['ArticleBodyUnion']> = {
  __resolveType: TypeResolveFn<'ArticleBodyNewsection' | 'ArticleBodyHeading' | 'ArticleBodyText' | 'ArticleBodyPullquote' | 'ArticleBodyImage' | 'ArticleBodyQuote' | 'ArticleBodySearchcta', ParentType, ContextType>;
};

export type AssetFocalPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssetFocalPoint'] = ResolversParentTypes['AssetFocalPoint']> = {
  x?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BlogResolvers<ContextType = any, ParentType extends ResolversParentTypes['Blog'] = ResolversParentTypes['Blog']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  author?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seoTitleTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  dateUpdated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Section']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EntryType']>, ParentType, ContextType>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<BlogChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  hasDescendants?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType>;
  siblings?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  elementType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  heroImage?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeInterface']>>>, ParentType, ContextType, RequireFields<BlogHeroImageArgs, never>>;
  featured?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authorAvatar?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeInterface']>>>, ParentType, ContextType, RequireFields<BlogAuthorAvatarArgs, never>>;
  subheading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogCategory']>>>, ParentType, ContextType>;
  categoriesConnection?: Resolver<Maybe<ResolversTypes['CategoryConnection']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  articleBody?: Resolver<Maybe<Array<Maybe<ResolversTypes['ArticleBodyUnion']>>>, ParentType, ContextType, RequireFields<BlogArticleBodyArgs, never>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BlogCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlogCategory'] = ResolversParentTypes['BlogCategory']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['CategoryGroup']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<BlogCategoryChildrenArgs, never>>;
  childrenConnection?: Resolver<Maybe<ResolversTypes['CategoryConnection']>, ParentType, ContextType, RequireFields<BlogCategoryChildrenConnectionArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  nextSibling?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  prev?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  prevSibling?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  heroImage?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeInterface']>>>, ParentType, ContextType, RequireFields<BlogCategoryHeroImageArgs, never>>;
  featured?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authorAvatar?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeInterface']>>>, ParentType, ContextType, RequireFields<BlogCategoryAuthorAvatarArgs, never>>;
  subheading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogCategory']>>>, ParentType, ContextType>;
  categoriesConnection?: Resolver<Maybe<ResolversTypes['CategoryConnection']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  articleBody?: Resolver<Maybe<Array<Maybe<ResolversTypes['ArticleBodyUnion']>>>, ParentType, ContextType, RequireFields<BlogCategoryArticleBodyArgs, never>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseCityResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseCity'] = ResolversParentTypes['BrowseCity']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseCityContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseCityContent'] = ResolversParentTypes['BrowseCityContent']> = {
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  facts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medianHouseholdIncome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medianMonthlyRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseListingResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseListing'] = ResolversParentTypes['BrowseListing']> = {
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseResult'] = ResolversParentTypes['BrowseResult']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<Array<ResolversTypes['BrowseState']>>, ParentType, ContextType, RequireFields<BrowseResultStateArgs, never>>;
  city?: Resolver<Maybe<Array<ResolversTypes['BrowseCity']>>, ParentType, ContextType, RequireFields<BrowseResultCityArgs, never>>;
  industry?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType, RequireFields<BrowseResultIndustryArgs, never>>;
  role?: Resolver<Maybe<Array<ResolversTypes['BrowseRole']>>, ParentType, ContextType, RequireFields<BrowseResultRoleArgs, never>>;
  roleContent?: Resolver<Maybe<ResolversTypes['BrowseRoleContent']>, ParentType, ContextType>;
  cityContent?: Resolver<Maybe<ResolversTypes['BrowseCityContent']>, ParentType, ContextType>;
  relatedRole?: Resolver<Maybe<Array<ResolversTypes['BrowseRole']>>, ParentType, ContextType, RequireFields<BrowseResultRelatedRoleArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseRole'] = ResolversParentTypes['BrowseRole']> = {
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseRoleContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseRoleContent'] = ResolversParentTypes['BrowseRoleContent']> = {
  keyword?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responsibilities?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payinfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requirements?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icons?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseState'] = ResolversParentTypes['BrowseState']> = {
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BrowseStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrowseStats'] = ResolversParentTypes['BrowseStats']> = {
  companies?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  jobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  companyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partTimeJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CategoryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryConnection'] = ResolversParentTypes['CategoryConnection']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryEdge']>>>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CategoryEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryEdge'] = ResolversParentTypes['CategoryEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  children?: Resolver<Maybe<ResolversTypes['CategoryConnection']>, ParentType, ContextType, RequireFields<CategoryEdgeChildrenArgs, never>>;
  relatedEntries?: Resolver<Maybe<ResolversTypes['EntryConnection']>, ParentType, ContextType, RequireFields<CategoryEdgeRelatedEntriesArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CategoryGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryGroup'] = ResolversParentTypes['CategoryGroup']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CategoryInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryInterface'] = ResolversParentTypes['CategoryInterface']> = {
  __resolveType: TypeResolveFn<'BlogCategory', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['CategoryGroup']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<CategoryInterfaceChildrenArgs, never>>;
  childrenConnection?: Resolver<Maybe<ResolversTypes['CategoryConnection']>, ParentType, ContextType, RequireFields<CategoryInterfaceChildrenConnectionArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  nextSibling?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  prev?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
  prevSibling?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType>;
};

export type CategoryJobCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryJobCount'] = ResolversParentTypes['CategoryJobCount']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CityDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CityData'] = ResolversParentTypes['CityData']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  population?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coli?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minimum_wage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tipped_wage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  state_uses_federal_wages?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  state_has_district_wages?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CollaboratorInviteErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorInviteError'] = ResolversParentTypes['CollaboratorInviteError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['CollaboratorInviteInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['CollaboratorInviteOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CollaboratorInviteInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorInviteInputErrors'] = ResolversParentTypes['CollaboratorInviteInputErrors']> = {
  jobCollectionId?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['EmailInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CollaboratorInviteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorInviteResult'] = ResolversParentTypes['CollaboratorInviteResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserUnauthorizedError' | 'CollaboratorInviteSuccess' | 'CollaboratorInviteError', ParentType, ContextType>;
};

export type CollaboratorInviteSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorInviteSuccess'] = ResolversParentTypes['CollaboratorInviteSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invitedUser?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CollaboratorRemoveErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorRemoveError'] = ResolversParentTypes['CollaboratorRemoveError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['CollaboratorRemoveInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['CollaboratorRemoveOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CollaboratorRemoveInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorRemoveInputErrors'] = ResolversParentTypes['CollaboratorRemoveInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CollaboratorRemoveResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorRemoveResult'] = ResolversParentTypes['CollaboratorRemoveResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'CollaboratorRemoveSuccess' | 'CollaboratorRemoveError', ParentType, ContextType>;
};

export type CollaboratorRemoveSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollaboratorRemoveSuccess'] = ResolversParentTypes['CollaboratorRemoveSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CompanyJobCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyJobCount'] = ResolversParentTypes['CompanyJobCount']> = {
  company?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CompanyLogoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyLogo'] = ResolversParentTypes['CompanyLogo']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoData?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteAllJobAlertsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteAllJobAlertsOutput'] = ResolversParentTypes['DeleteAllJobAlertsOutput']> = {
  __resolveType: TypeResolveFn<'DeleteAllJobAlertsResult' | 'JobAlertError', ParentType, ContextType>;
};

export type DeleteAllJobAlertsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteAllJobAlertsResult'] = ResolversParentTypes['DeleteAllJobAlertsResult']> = {
  alerts?: Resolver<Array<ResolversTypes['JobAlert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeleteJobAlertOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteJobAlertOutput'] = ResolversParentTypes['DeleteJobAlertOutput']> = {
  __resolveType: TypeResolveFn<'DeleteJobAlertResult' | 'JobAlertError', ParentType, ContextType>;
};

export type DeleteJobAlertResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteJobAlertResult'] = ResolversParentTypes['DeleteJobAlertResult']> = {
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ElementInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ElementInterface'] = ResolversParentTypes['ElementInterface']> = {
  __resolveType: TypeResolveFn<'Presspage' | 'Blog', ParentType, ContextType>;
  elementType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type EntryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryConnection'] = ResolversParentTypes['EntryConnection']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryEdge']>>>, ParentType, ContextType>;
  entries?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntryDraftConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryDraftConnection'] = ResolversParentTypes['EntryDraftConnection']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryDraftEdge']>>>, ParentType, ContextType>;
  entries?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntryDraftEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryDraftEdge'] = ResolversParentTypes['EntryDraftEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType>;
  draftInfo?: Resolver<Maybe<ResolversTypes['EntryDraftInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntryDraftInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryDraftInfo'] = ResolversParentTypes['EntryDraftInfo']> = {
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntryEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryEdge'] = ResolversParentTypes['EntryEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType>;
  drafts?: Resolver<Maybe<ResolversTypes['EntryDraftConnection']>, ParentType, ContextType>;
  relatedEntries?: Resolver<Maybe<ResolversTypes['EntryConnection']>, ParentType, ContextType, RequireFields<EntryEdgeRelatedEntriesArgs, never>>;
  relatedCategories?: Resolver<Maybe<ResolversTypes['CategoryConnection']>, ParentType, ContextType, RequireFields<EntryEdgeRelatedCategoriesArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntryInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryInterface'] = ResolversParentTypes['EntryInterface']> = {
  __resolveType: TypeResolveFn<'Presspage' | 'Blog', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  author?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seoTitleTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  dateUpdated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Section']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EntryType']>, ParentType, ContextType>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<EntryInterfaceChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  hasDescendants?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType>;
  siblings?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
};

export type EntryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryType'] = ResolversParentTypes['EntryType']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  handle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  graphQlTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<Array<Maybe<ResolversTypes['Field']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['Field'] = ResolversParentTypes['Field']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FreeTrialResolvers<ContextType = any, ParentType extends ResolversParentTypes['FreeTrial'] = ResolversParentTypes['FreeTrial']> = {
  isFreeTrialJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFreeTrialActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  freeTrialStart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  freeTrialEnd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validityDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ruleMetric?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GetJobAlertsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetJobAlertsOutput'] = ResolversParentTypes['GetJobAlertsOutput']> = {
  __resolveType: TypeResolveFn<'GetJobAlertsResult' | 'JobAlertError', ParentType, ContextType>;
};

export type GetJobAlertsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetJobAlertsResult'] = ResolversParentTypes['GetJobAlertsResult']> = {
  alerts?: Resolver<Array<ResolversTypes['JobAlert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteAcceptErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteAcceptError'] = ResolversParentTypes['InviteAcceptError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['InviteAcceptInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['InviteAcceptOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteAcceptInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteAcceptInputErrors'] = ResolversParentTypes['InviteAcceptInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteAcceptResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteAcceptResult'] = ResolversParentTypes['InviteAcceptResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'InviteAcceptSuccess' | 'InviteAcceptError', ParentType, ContextType>;
};

export type InviteAcceptSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteAcceptSuccess'] = ResolversParentTypes['InviteAcceptSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invitedUser?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType>;
  jobCollectionCollaborator?: Resolver<ResolversTypes['JobCollectionCollaborator'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InvitedByResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitedBy'] = ResolversParentTypes['InvitedBy']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  me?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InvitedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitedUser'] = ResolversParentTypes['InvitedUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invitedByUser?: Resolver<Maybe<ResolversTypes['JoblistUser']>, ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFetchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteFetchResult'] = ResolversParentTypes['InviteFetchResult']> = {
  __resolveType: TypeResolveFn<'InviteFetchSuccess', ParentType, ContextType>;
};

export type InviteFetchSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteFetchSuccess'] = ResolversParentTypes['InviteFetchSuccess']> = {
  invite?: Resolver<Maybe<ResolversTypes['InvitedUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteIgnoreErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteIgnoreError'] = ResolversParentTypes['InviteIgnoreError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['InviteIgnoreInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['InviteIgnoreOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteIgnoreInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteIgnoreInputErrors'] = ResolversParentTypes['InviteIgnoreInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteIgnoreResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteIgnoreResult'] = ResolversParentTypes['InviteIgnoreResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'InviteIgnoreSuccess' | 'InviteIgnoreError', ParentType, ContextType>;
};

export type InviteIgnoreSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteIgnoreSuccess'] = ResolversParentTypes['InviteIgnoreSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteRemoveErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteRemoveError'] = ResolversParentTypes['InviteRemoveError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['InviteRemoveInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['InviteRemoveOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteRemoveInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteRemoveInputErrors'] = ResolversParentTypes['InviteRemoveInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteRemoveResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteRemoveResult'] = ResolversParentTypes['InviteRemoveResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'InviteRemoveSuccess' | 'InviteRemoveError', ParentType, ContextType>;
};

export type InviteRemoveSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteRemoveSuccess'] = ResolversParentTypes['InviteRemoveSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobResolvers<ContextType = any, ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job']> = {
  internalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fingerprint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fingerprintJd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  service?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  area1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postal_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  company?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  companyLogoSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applyByEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  companyLogo?: Resolver<Maybe<ResolversTypes['CompanyLogo']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  internalUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internalApplyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile_friendly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cpc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cpc_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  earlyApplicant?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  easyApply?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['TagSources']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  explanation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posted_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expires_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  documentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isInLocalInventory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  industries?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  syndFingerprint1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  syndFingerprint2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobAlertResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobAlert'] = ResolversParentTypes['JobAlert']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobAlertStatus'], ParentType, ContextType>;
  searchQuery?: Resolver<ResolversTypes['JobAlertSearchQuery'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobAlertErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobAlertError'] = ResolversParentTypes['JobAlertError']> = {
  error?: Resolver<ResolversTypes['JobAlertErrorCode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobAlertSearchQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobAlertSearchQuery'] = ResolversParentTypes['JobAlertSearchQuery']> = {
  keyword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['JobAlertCountry'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollection'] = ResolversParentTypes['JobCollection']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['JoblistUser']>, ParentType, ContextType>;
  jobsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locationsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['JobCollectionPerms']>, ParentType, ContextType>;
  jobs?: Resolver<Array<ResolversTypes['JobCollectionItem']>, ParentType, ContextType, RequireFields<JobCollectionJobsArgs, never>>;
  collaborators?: Resolver<Maybe<Array<ResolversTypes['JobCollectionCollaborator']>>, ParentType, ContextType>;
  invitedUsers?: Resolver<Maybe<Array<ResolversTypes['InvitedUser']>>, ParentType, ContextType>;
  myInvite?: Resolver<Maybe<ResolversTypes['InvitedUser']>, ParentType, ContextType>;
  impressionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inviter?: Resolver<Maybe<ResolversTypes['JoblistUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionChangePermsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangePermsError'] = ResolversParentTypes['JobCollectionChangePermsError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobCollectionChangePermsInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobCollectionChangePermsOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionChangePermsInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangePermsInputErrors'] = ResolversParentTypes['JobCollectionChangePermsInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  editTitle?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  save?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  unsave?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  invite?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionChangePermsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangePermsResult'] = ResolversParentTypes['JobCollectionChangePermsResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobCollectionChangePermsSuccess' | 'JobCollectionChangePermsError', ParentType, ContextType>;
};

export type JobCollectionChangePermsSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangePermsSuccess'] = ResolversParentTypes['JobCollectionChangePermsSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionChangeTitleErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangeTitleError'] = ResolversParentTypes['JobCollectionChangeTitleError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobCollectionChangeTitleInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobCollectionChangeTitleOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionChangeTitleInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangeTitleInputErrors'] = ResolversParentTypes['JobCollectionChangeTitleInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionChangeTitleResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangeTitleResult'] = ResolversParentTypes['JobCollectionChangeTitleResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobCollectionChangeTitleSuccess' | 'JobCollectionChangeTitleError', ParentType, ContextType>;
};

export type JobCollectionChangeTitleSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionChangeTitleSuccess'] = ResolversParentTypes['JobCollectionChangeTitleSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionCollaboratorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionCollaborator'] = ResolversParentTypes['JobCollectionCollaborator']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  me?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invitedBy?: Resolver<Maybe<ResolversTypes['InvitedBy']>, ParentType, ContextType>;
  jobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionCreateErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionCreateError'] = ResolversParentTypes['JobCollectionCreateError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobCollectionCreateInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobCollectionCreateOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionCreateInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionCreateInputErrors'] = ResolversParentTypes['JobCollectionCreateInputErrors']> = {
  name?: Resolver<Maybe<ResolversTypes['NameError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionCreateResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionCreateResult'] = ResolversParentTypes['JobCollectionCreateResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobCollectionCreateSuccess' | 'JobCollectionCreateError', ParentType, ContextType>;
};

export type JobCollectionCreateSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionCreateSuccess'] = ResolversParentTypes['JobCollectionCreateSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionDeleteErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionDeleteError'] = ResolversParentTypes['JobCollectionDeleteError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobCollectionDeleteInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobCollectionDeleteOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionDeleteInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionDeleteInputErrors'] = ResolversParentTypes['JobCollectionDeleteInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionDeleteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionDeleteResult'] = ResolversParentTypes['JobCollectionDeleteResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobCollectionDeleteSuccess' | 'JobCollectionDeleteError', ParentType, ContextType>;
};

export type JobCollectionDeleteSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionDeleteSuccess'] = ResolversParentTypes['JobCollectionDeleteSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionError'] = ResolversParentTypes['JobCollectionError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobCollectionInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobCollectionOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionGetNewUrlResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionGetNewURLResult'] = ResolversParentTypes['JobCollectionGetNewURLResult']> = {
  __resolveType: TypeResolveFn<'JobCollectionGetNewURLSuccess', ParentType, ContextType>;
};

export type JobCollectionGetNewUrlSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionGetNewURLSuccess'] = ResolversParentTypes['JobCollectionGetNewURLSuccess']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionInputErrors'] = ResolversParentTypes['JobCollectionInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionItem'] = ResolversParentTypes['JobCollectionItem']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  addedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  job?: Resolver<ResolversTypes['Job'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionListErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionListError'] = ResolversParentTypes['JobCollectionListError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobCollectionListInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobCollectionListOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionListInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionListInputErrors'] = ResolversParentTypes['JobCollectionListInputErrors']> = {
  ownedBy?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  saveable?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  inviteStatus?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionListResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionListResult'] = ResolversParentTypes['JobCollectionListResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobCollectionListSuccess' | 'JobCollectionListError', ParentType, ContextType>;
};

export type JobCollectionListSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionListSuccess'] = ResolversParentTypes['JobCollectionListSuccess']> = {
  jobCollections?: Resolver<Array<ResolversTypes['JobCollection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionModResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionMod'] = ResolversParentTypes['JobCollectionMod']> = {
  modType?: Resolver<ResolversTypes['ModTypeEnum'], ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  jobCollectionItem?: Resolver<ResolversTypes['JobCollectionItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionPermsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionPerms'] = ResolversParentTypes['JobCollectionPerms']> = {
  editTitle?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  save?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  unsave?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enablePublicLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionResult'] = ResolversParentTypes['JobCollectionResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserUnauthorizedError' | 'JobCollectionSuccess' | 'JobCollectionError', ParentType, ContextType>;
};

export type JobCollectionSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionSuccess'] = ResolversParentTypes['JobCollectionSuccess']> = {
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionToggleSharingErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionToggleSharingError'] = ResolversParentTypes['JobCollectionToggleSharingError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobCollectionToggleSharingInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobCollectionToggleSharingOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionToggleSharingInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionToggleSharingInputErrors'] = ResolversParentTypes['JobCollectionToggleSharingInputErrors']> = {
  id?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobCollectionToggleSharingResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionToggleSharingResult'] = ResolversParentTypes['JobCollectionToggleSharingResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobCollectionToggleSharingSuccess' | 'JobCollectionToggleSharingError', ParentType, ContextType>;
};

export type JobCollectionToggleSharingSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCollectionToggleSharingSuccess'] = ResolversParentTypes['JobCollectionToggleSharingSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollection?: Resolver<ResolversTypes['JobCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobData'] = ResolversParentTypes['JobData']> = {
  low_salary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  average_salary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  high_salary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hourly_salary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobError'] = ResolversParentTypes['JobError']> = {
  error?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JoblistcontentqaVolumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JoblistcontentqaVolume'] = ResolversParentTypes['JoblistcontentqaVolume']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<JoblistcontentqaVolumeUrlArgs, never>>;
  width?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<JoblistcontentqaVolumeWidthArgs, never>>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<JoblistcontentqaVolumeHeightArgs, never>>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  folder?: Resolver<Maybe<ResolversTypes['VolumeFolder']>, ParentType, ContextType>;
  volumeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreatedTimestamp?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  dateUpdatedTimestamp?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  focalPoint?: Resolver<Maybe<ResolversTypes['AssetFocalPoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JoblistUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['JoblistUser'] = ResolversParentTypes['JoblistUser']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  shareId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isVerified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSavedJobsListPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  savedJobsListName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastSigninAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserRole']>>>, ParentType, ContextType>;
  quizData?: Resolver<Maybe<ResolversTypes['QuizData']>, ParentType, ContextType>;
  loggedInRealm?: Resolver<Maybe<ResolversTypes['UserLoggedInRealm']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['UserProgress']>, ParentType, ContextType>;
  showGamify?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pendingEmailChange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPosting'] = ResolversParentTypes['JobPosting']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSearchable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JobPostingContent'], ParentType, ContextType>;
  hadProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasProductFrom?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hasProductUntil?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  subscriptionStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  subscriptionEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isFreeTrialJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFreeTrialActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  freeTrialStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  freeTrialEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isPaid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  applications?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingActiveProductOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingActiveProductOutput'] = ResolversParentTypes['JobPostingActiveProductOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingActiveProductSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingActiveProductSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingActiveProductSuccess'] = ResolversParentTypes['JobPostingActiveProductSuccess']> = {
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasFreeTrial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  freeTrialDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paidProductDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postingPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ruleMetric?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingApplyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingApplyOutput'] = ResolversParentTypes['JobPostingApplyOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingApplySuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingApplySuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingApplySuccess'] = ResolversParentTypes['JobPostingApplySuccess']> = {
  status?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingCharge'] = ResolversParentTypes['JobPostingCharge']> = {
  grossAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  netAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['JobPostingCurrency'], ParentType, ContextType>;
  validity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingChargeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingChargeOutput'] = ResolversParentTypes['JobPostingChargeOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingChargeSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingChargeSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingChargeSuccess'] = ResolversParentTypes['JobPostingChargeSuccess']> = {
  charge?: Resolver<ResolversTypes['JobPostingCharge'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['JobPostingOrder'], ParentType, ContextType>;
  freeTrial?: Resolver<Maybe<ResolversTypes['FreeTrial']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingCheckoutOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingCheckoutOutput'] = ResolversParentTypes['JobPostingCheckoutOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingCheckoutSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingCheckoutSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingCheckoutSuccess'] = ResolversParentTypes['JobPostingCheckoutSuccess']> = {
  charge?: Resolver<ResolversTypes['JobPostingCharge'], ParentType, ContextType>;
  billingEmailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentIntentSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setupIntentSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeTrial?: Resolver<Maybe<ResolversTypes['FreeTrial']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingContent'] = ResolversParentTypes['JobPostingContent']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['JobPostingCountry'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  area1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  companyLogo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isRemote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobType?: Resolver<Maybe<ResolversTypes['JobPostingType']>, ParentType, ContextType>;
  compensation?: Resolver<Maybe<ResolversTypes['JobPostingCompensation']>, ParentType, ContextType>;
  isEntryLevel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  requiresPhysicalLabor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  applyByEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applyAtUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingCountOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingCountOutput'] = ResolversParentTypes['JobPostingCountOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingCountSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingCountSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingCountSuccess'] = ResolversParentTypes['JobPostingCountSuccess']> = {
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  open?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  closed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  draft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasActiveProduct?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingDecodeIdOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingDecodeIdOutput'] = ResolversParentTypes['JobPostingDecodeIdOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingDecodeIdSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingDecodeIdSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingDecodeIdSuccess'] = ResolversParentTypes['JobPostingDecodeIdSuccess']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingError'] = ResolversParentTypes['JobPostingError']> = {
  error?: Resolver<Maybe<ResolversTypes['JobPostingErrorCode']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingListOutput'] = ResolversParentTypes['JobPostingListOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingListSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingListSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingListSuccess'] = ResolversParentTypes['JobPostingListSuccess']> = {
  jobPostingList?: Resolver<Array<ResolversTypes['JobPosting']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingOrder'] = ResolversParentTypes['JobPostingOrder']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobPostingOrderStatus'], ParentType, ContextType>;
  coupon?: Resolver<Maybe<ResolversTypes['VersionedEntity']>, ParentType, ContextType>;
  grossAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  netAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['JobPostingOrderItem']>, ParentType, ContextType>;
  productConsumption?: Resolver<Maybe<ResolversTypes['JobPostingProductConsumption']>, ParentType, ContextType>;
  paidOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paidAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPaymentSuccessful?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  freeTrial?: Resolver<Maybe<ResolversTypes['FreeTrial']>, ParentType, ContextType>;
  paymentDeferral?: Resolver<Maybe<ResolversTypes['JobPostingPaymentDeferral']>, ParentType, ContextType>;
  validityDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postingPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chargeEntity?: Resolver<Maybe<ResolversTypes['ChargeEntity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingOrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingOrderItem'] = ResolversParentTypes['JobPostingOrderItem']> = {
  product?: Resolver<ResolversTypes['VersionedEntity'], ParentType, ContextType>;
  unitPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unitsRequested?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingOrderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingOrderOutput'] = ResolversParentTypes['JobPostingOrderOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingOrderSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingOrderSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingOrderSuccess'] = ResolversParentTypes['JobPostingOrderSuccess']> = {
  billingEmailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['JobPostingOrder'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingOutput'] = ResolversParentTypes['JobPostingOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingPaymentDeferralResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingPaymentDeferral'] = ResolversParentTypes['JobPostingPaymentDeferral']> = {
  type?: Resolver<Maybe<ResolversTypes['PaymentDeferralType']>, ParentType, ContextType>;
  chargeEarly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ruleMetric?: Resolver<Maybe<ResolversTypes['PaymentDeferralRuleMetric']>, ParentType, ContextType>;
  ruleGrouping?: Resolver<Maybe<ResolversTypes['PaymentDeferralRuleGrouping']>, ParentType, ContextType>;
  ruleLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingProductConsumptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingProductConsumption'] = ResolversParentTypes['JobPostingProductConsumption']> = {
  activeFrom?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activeUntil?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingReceiptResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingReceipt'] = ResolversParentTypes['JobPostingReceipt']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productConsumption?: Resolver<Maybe<ResolversTypes['JobPostingProductConsumption']>, ParentType, ContextType>;
  paymentCharged?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentReceived?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isFreeTrialJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFreeTrialActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  freeTrialStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeTrialEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeTrialValidity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  validity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingReceiptListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingReceiptListOutput'] = ResolversParentTypes['JobPostingReceiptListOutput']> = {
  __resolveType: TypeResolveFn<'JobPostingReceiptListSuccess' | 'JobPostingError', ParentType, ContextType>;
};

export type JobPostingReceiptListSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingReceiptListSuccess'] = ResolversParentTypes['JobPostingReceiptListSuccess']> = {
  receipts?: Resolver<Maybe<Array<ResolversTypes['JobPostingReceipt']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobPostingSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobPostingSuccess'] = ResolversParentTypes['JobPostingSuccess']> = {
  jobPosting?: Resolver<ResolversTypes['JobPosting'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobResult'] = ResolversParentTypes['JobResult']> = {
  __resolveType: TypeResolveFn<'Job' | 'JobError', ParentType, ContextType>;
};

export type JobSaveErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobSaveError'] = ResolversParentTypes['JobSaveError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobSaveInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobSaveOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobSaveInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobSaveInputErrors'] = ResolversParentTypes['JobSaveInputErrors']> = {
  provider?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  providerId?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  routing?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  jobCollectionIdsToSave?: Resolver<Maybe<ResolversTypes['JobSaveInputError']>, ParentType, ContextType>;
  jobCollectionIdsToRemove?: Resolver<Maybe<ResolversTypes['JobSaveInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobSaveResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobSaveResult'] = ResolversParentTypes['JobSaveResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobSaveSuccess' | 'JobSaveError', ParentType, ContextType>;
};

export type JobSaveSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobSaveSuccess'] = ResolversParentTypes['JobSaveSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollectionMods?: Resolver<Array<ResolversTypes['JobCollectionMod']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobUnsaveErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobUnsaveError'] = ResolversParentTypes['JobUnsaveError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['JobUnsaveInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['JobUnsaveOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobUnsaveInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobUnsaveInputErrors'] = ResolversParentTypes['JobUnsaveInputErrors']> = {
  provider?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  providerId?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  jobCollectionIdsToRemove?: Resolver<Maybe<ResolversTypes['JobUnsaveInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JobUnsaveResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobUnsaveResult'] = ResolversParentTypes['JobUnsaveResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'JobUnsaveSuccess' | 'JobUnsaveError', ParentType, ContextType>;
};

export type JobUnsaveSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobUnsaveSuccess'] = ResolversParentTypes['JobUnsaveSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCollectionMods?: Resolver<Array<ResolversTypes['JobCollectionMod']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LocalityJobCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocalityJobCount'] = ResolversParentTypes['LocalityJobCount']> = {
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lon?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  logUserInterest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLogUserInterestArgs, 'email'>>;
  logUserContactMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLogUserContactMessageArgs, 'email' | 'name' | 'message' | 'department'>>;
  logImpression?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLogImpressionArgs, 'impression_id' | 'placement_context' | 'placement_path' | 'placement_platform' | 'location' | 'keyword' | 'filters' | 'url' | 'jobs'>>;
  logDescriptionImpression?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLogDescriptionImpressionArgs, 'impression_id' | 'placement_context' | 'placement_path' | 'placement_platform' | 'location' | 'keyword' | 'filters' | 'url' | 'job' | 'page_context'>>;
  helloWorld?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  upsertBlog?: Resolver<Maybe<ResolversTypes['Blog']>, ParentType, ContextType, RequireFields<MutationUpsertBlogArgs, never>>;
  upsertUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpsertUserArgs, never>>;
  signup?: Resolver<ResolversTypes['UserSignupResult'], ParentType, ContextType, RequireFields<MutationSignupArgs, 'input'>>;
  login?: Resolver<ResolversTypes['UserLoginResult'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'input'>>;
  verifyEmail?: Resolver<ResolversTypes['UserVerifyEmailResult'], ParentType, ContextType, RequireFields<MutationVerifyEmailArgs, 'input'>>;
  resendEmailVerification?: Resolver<ResolversTypes['UserResendEmailVerificationResult'], ParentType, ContextType>;
  forgotPassword?: Resolver<ResolversTypes['UserForgotPasswordResult'], ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'input'>>;
  resetPassword?: Resolver<ResolversTypes['UserResetPasswordResult'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'input'>>;
  updateAccount?: Resolver<ResolversTypes['UserUpdateAccountResult'], ParentType, ContextType, RequireFields<MutationUpdateAccountArgs, 'input'>>;
  changeEmail?: Resolver<ResolversTypes['UserChangeEmailResult'], ParentType, ContextType, RequireFields<MutationChangeEmailArgs, 'input'>>;
  changePassword?: Resolver<ResolversTypes['UserChangePasswordResult'], ParentType, ContextType, RequireFields<MutationChangePasswordArgs, 'input'>>;
  deleteAccount?: Resolver<ResolversTypes['UserDeleteAccountResult'], ParentType, ContextType, RequireFields<MutationDeleteAccountArgs, 'input'>>;
  updateQuizData?: Resolver<ResolversTypes['UserUpdateQuizDataResult'], ParentType, ContextType, RequireFields<MutationUpdateQuizDataArgs, never>>;
  saveStep?: Resolver<ResolversTypes['UserSaveStepResult'], ParentType, ContextType, RequireFields<MutationSaveStepArgs, never>>;
  updateShowGamify?: Resolver<ResolversTypes['UserUpdateShowGamifyResult'], ParentType, ContextType, RequireFields<MutationUpdateShowGamifyArgs, never>>;
  userChangeLoggedInRealm?: Resolver<ResolversTypes['UserChangeLoggedInRealmResult'], ParentType, ContextType, RequireFields<MutationUserChangeLoggedInRealmArgs, never>>;
  userCancelPendingEmailChange?: Resolver<ResolversTypes['UserCancelPendingEmailChangeResult'], ParentType, ContextType>;
  inviteAccept?: Resolver<ResolversTypes['InviteAcceptResult'], ParentType, ContextType, RequireFields<MutationInviteAcceptArgs, 'input'>>;
  inviteIgnore?: Resolver<ResolversTypes['InviteIgnoreResult'], ParentType, ContextType, RequireFields<MutationInviteIgnoreArgs, 'input'>>;
  inviteRemove?: Resolver<ResolversTypes['InviteRemoveResult'], ParentType, ContextType, RequireFields<MutationInviteRemoveArgs, 'input'>>;
  collaboratorInvite?: Resolver<ResolversTypes['CollaboratorInviteResult'], ParentType, ContextType, RequireFields<MutationCollaboratorInviteArgs, 'input'>>;
  collaboratorRemove?: Resolver<ResolversTypes['CollaboratorRemoveResult'], ParentType, ContextType, RequireFields<MutationCollaboratorRemoveArgs, 'input'>>;
  jobCollectionCreate?: Resolver<ResolversTypes['JobCollectionCreateResult'], ParentType, ContextType, RequireFields<MutationJobCollectionCreateArgs, 'input'>>;
  jobCollectionDelete?: Resolver<ResolversTypes['JobCollectionDeleteResult'], ParentType, ContextType, RequireFields<MutationJobCollectionDeleteArgs, 'input'>>;
  jobCollectionChangeTitle?: Resolver<ResolversTypes['JobCollectionChangeTitleResult'], ParentType, ContextType, RequireFields<MutationJobCollectionChangeTitleArgs, 'input'>>;
  jobCollectionChangePerms?: Resolver<ResolversTypes['JobCollectionChangePermsResult'], ParentType, ContextType, RequireFields<MutationJobCollectionChangePermsArgs, 'input'>>;
  jobCollectionToggleSharing?: Resolver<ResolversTypes['JobCollectionToggleSharingResult'], ParentType, ContextType, RequireFields<MutationJobCollectionToggleSharingArgs, 'input'>>;
  jobSave?: Resolver<ResolversTypes['JobSaveResult'], ParentType, ContextType, RequireFields<MutationJobSaveArgs, 'input'>>;
  jobUnsave?: Resolver<ResolversTypes['JobUnsaveResult'], ParentType, ContextType, RequireFields<MutationJobUnsaveArgs, 'input'>>;
  addJobAlert?: Resolver<ResolversTypes['AddJobAlertOutput'], ParentType, ContextType, RequireFields<MutationAddJobAlertArgs, 'searchQuery'>>;
  verifyJobAlert?: Resolver<ResolversTypes['VerifyJobAlertOutput'], ParentType, ContextType, RequireFields<MutationVerifyJobAlertArgs, 'id' | 'token'>>;
  pauseJobAlert?: Resolver<ResolversTypes['PauseJobAlertOutput'], ParentType, ContextType, RequireFields<MutationPauseJobAlertArgs, 'id' | 'value'>>;
  pauseAllJobAlerts?: Resolver<ResolversTypes['PauseAllJobAlertsOutput'], ParentType, ContextType>;
  deleteJobAlert?: Resolver<ResolversTypes['DeleteJobAlertOutput'], ParentType, ContextType, RequireFields<MutationDeleteJobAlertArgs, 'id'>>;
  deleteAllJobAlerts?: Resolver<ResolversTypes['DeleteAllJobAlertsOutput'], ParentType, ContextType, RequireFields<MutationDeleteAllJobAlertsArgs, never>>;
  jobPostingCreate?: Resolver<ResolversTypes['JobPostingOutput'], ParentType, ContextType, RequireFields<MutationJobPostingCreateArgs, 'input'>>;
  jobPostingUpdate?: Resolver<ResolversTypes['JobPostingOutput'], ParentType, ContextType, RequireFields<MutationJobPostingUpdateArgs, 'id' | 'input'>>;
  jobPostingApply?: Resolver<ResolversTypes['JobPostingApplyOutput'], ParentType, ContextType, RequireFields<MutationJobPostingApplyArgs, never>>;
  jobPostingCheckout?: Resolver<ResolversTypes['JobPostingCheckoutOutput'], ParentType, ContextType, RequireFields<MutationJobPostingCheckoutArgs, 'id' | 'input'>>;
  jobPostingLogView?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationJobPostingLogViewArgs, 'input'>>;
};

export type NormalBrowseArgsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NormalBrowseArgs'] = ResolversParentTypes['NormalBrowseArgs']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OptionFieldDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['OptionFieldData'] = ResolversParentTypes['OptionFieldData']> = {
  selected?: Resolver<Maybe<ResolversTypes['OptionFieldDataOptions']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['OptionFieldDataOptions']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OptionFieldDataOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OptionFieldDataOptions'] = ResolversParentTypes['OptionFieldDataOptions']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  currentPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  first?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  last?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PauseAllJobAlertsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PauseAllJobAlertsOutput'] = ResolversParentTypes['PauseAllJobAlertsOutput']> = {
  __resolveType: TypeResolveFn<'PauseAllJobAlertsResult' | 'JobAlertError', ParentType, ContextType>;
};

export type PauseAllJobAlertsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PauseAllJobAlertsResult'] = ResolversParentTypes['PauseAllJobAlertsResult']> = {
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PauseJobAlertOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PauseJobAlertOutput'] = ResolversParentTypes['PauseJobAlertOutput']> = {
  __resolveType: TypeResolveFn<'PauseJobAlertResult' | 'JobAlertError', ParentType, ContextType>;
};

export type PauseJobAlertResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PauseJobAlertResult'] = ResolversParentTypes['PauseJobAlertResult']> = {
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PostalCodeJobCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostalCodeJobCount'] = ResolversParentTypes['PostalCodeJobCount']> = {
  postal_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PressEntriesPressEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PressEntriesPressEntry'] = ResolversParentTypes['PressEntriesPressEntry']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  entryTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeInterface']>>>, ParentType, ContextType, RequireFields<PressEntriesPressEntryImageArgs, never>>;
  entryUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PressEntriesPressSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PressEntriesPressSection'] = ResolversParentTypes['PressEntriesPressSection']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pressSectionTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PressEntriesUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PressEntriesUnion'] = ResolversParentTypes['PressEntriesUnion']> = {
  __resolveType: TypeResolveFn<'PressEntriesPressSection' | 'PressEntriesPressEntry', ParentType, ContextType>;
};

export type PresspageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Presspage'] = ResolversParentTypes['Presspage']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  author?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seoTitleTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  dateUpdated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Section']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EntryType']>, ParentType, ContextType>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<PresspageChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  hasDescendants?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType>;
  siblings?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType>;
  elementType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pressEntries?: Resolver<Maybe<Array<Maybe<ResolversTypes['PressEntriesUnion']>>>, ParentType, ContextType, RequireFields<PresspagePressEntriesArgs, never>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  searchJobs?: Resolver<ResolversTypes['SearchResult'], ParentType, ContextType, RequireFields<QuerySearchJobsArgs, 'query'>>;
  job?: Resolver<ResolversTypes['JobResult'], ParentType, ContextType, RequireFields<QueryJobArgs, 'provider' | 'providerId'>>;
  eJob?: Resolver<ResolversTypes['JobResult'], ParentType, ContextType, RequireFields<QueryEJobArgs, 'id'>>;
  jitRoutingJob?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType, RequireFields<QueryJitRoutingJobArgs, 'id' | 'country' | 'strategy'>>;
  topJob?: Resolver<ResolversTypes['JobResult'], ParentType, ContextType, RequireFields<QueryTopJobArgs, 'query'>>;
  browseJobs?: Resolver<ResolversTypes['BrowseResult'], ParentType, ContextType, RequireFields<QueryBrowseJobsArgs, never>>;
  normalizeBrowseArgs?: Resolver<ResolversTypes['NormalBrowseArgs'], ParentType, ContextType, RequireFields<QueryNormalizeBrowseArgsArgs, never>>;
  browseNearbyCities?: Resolver<Array<ResolversTypes['BrowseCity']>, ParentType, ContextType, RequireFields<QueryBrowseNearbyCitiesArgs, 'city' | 'radius'>>;
  browseNearbyStates?: Resolver<Array<ResolversTypes['BrowseState']>, ParentType, ContextType, RequireFields<QueryBrowseNearbyStatesArgs, 'state' | 'radius'>>;
  browseJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryBrowseJobCountArgs, never>>;
  browseListing?: Resolver<Array<ResolversTypes['BrowseListing']>, ParentType, ContextType, RequireFields<QueryBrowseListingArgs, 'segment' | 'limit'>>;
  browseStats?: Resolver<ResolversTypes['BrowseStats'], ParentType, ContextType, RequireFields<QueryBrowseStatsArgs, 'city' | 'numCompanies' | 'jobCountPrecision'>>;
  browseResolveCityRoleOrIndustry?: Resolver<Maybe<ResolversTypes['BrowseCity']>, ParentType, ContextType, RequireFields<QueryBrowseResolveCityRoleOrIndustryArgs, 'cityRoleOrIndustry' | 'state'>>;
  getSuggestionsForCompletion?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetSuggestionsForCompletionArgs, 'prefix'>>;
  getSuggestionsForTypeahead?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetSuggestionsForTypeaheadArgs, 'text'>>;
  browseSegmentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryBrowseSegmentCountArgs, 'segment'>>;
  fetchJobData?: Resolver<ResolversTypes['JobData'], ParentType, ContextType, RequireFields<QueryFetchJobDataArgs, 'role' | 'city' | 'state'>>;
  fetchCityData?: Resolver<Maybe<ResolversTypes['CityData']>, ParentType, ContextType, RequireFields<QueryFetchCityDataArgs, 'city' | 'state'>>;
  suggestJobTitle?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QuerySuggestJobTitleArgs, 'prefix'>>;
  hasJobs?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryHasJobsArgs, 'query'>>;
  topJobTitles?: Resolver<Array<ResolversTypes['TitleJobCount']>, ParentType, ContextType, RequireFields<QueryTopJobTitlesArgs, 'query'>>;
  topCompanies?: Resolver<Array<ResolversTypes['CompanyJobCount']>, ParentType, ContextType, RequireFields<QueryTopCompaniesArgs, 'query'>>;
  topGeoArea1?: Resolver<Array<ResolversTypes['Area1JobCount']>, ParentType, ContextType, RequireFields<QueryTopGeoArea1Args, 'query'>>;
  topGeoLocalities?: Resolver<Array<ResolversTypes['LocalityJobCount']>, ParentType, ContextType, RequireFields<QueryTopGeoLocalitiesArgs, 'query'>>;
  topGeoPostalCodes?: Resolver<Array<Maybe<ResolversTypes['PostalCodeJobCount']>>, ParentType, ContextType, RequireFields<QueryTopGeoPostalCodesArgs, 'query'>>;
  topCategories?: Resolver<Array<Maybe<ResolversTypes['CategoryJobCount']>>, ParentType, ContextType, RequireFields<QueryTopCategoriesArgs, 'query'>>;
  getCompanyLogo?: Resolver<Maybe<ResolversTypes['CompanyLogo']>, ParentType, ContextType, RequireFields<QueryGetCompanyLogoArgs, 'companyName'>>;
  helloWorld?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ping?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sites?: Resolver<Maybe<Array<Maybe<ResolversTypes['Site']>>>, ParentType, ContextType, RequireFields<QuerySitesArgs, never>>;
  pressPage?: Resolver<Maybe<ResolversTypes['Presspage']>, ParentType, ContextType, RequireFields<QueryPressPageArgs, never>>;
  entries?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<QueryEntriesArgs, never>>;
  entriesConnection?: Resolver<Maybe<ResolversTypes['EntryConnection']>, ParentType, ContextType, RequireFields<QueryEntriesConnectionArgs, never>>;
  entry?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<QueryEntryArgs, never>>;
  draft?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<QueryDraftArgs, 'draftId'>>;
  assets?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeInterface']>>>, ParentType, ContextType, RequireFields<QueryAssetsArgs, never>>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<QueryCategoriesArgs, never>>;
  category?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<QueryCategoryArgs, never>>;
  categoriesConnection?: Resolver<Maybe<ResolversTypes['CategoryConnection']>, ParentType, ContextType, RequireFields<QueryCategoriesConnectionArgs, never>>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryUsersArgs, never>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, never>>;
  sections?: Resolver<Maybe<Array<Maybe<ResolversTypes['Section']>>>, ParentType, ContextType>;
  entryCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryEntryCountArgs, never>>;
  me?: Resolver<Maybe<ResolversTypes['JoblistUser']>, ParentType, ContextType>;
  userProgress?: Resolver<Maybe<ResolversTypes['UserProgress']>, ParentType, ContextType>;
  verifyToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryVerifyTokenArgs, 'input'>>;
  masquerade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryMasqueradeArgs, 'input'>>;
  inviteFetch?: Resolver<ResolversTypes['InviteFetchResult'], ParentType, ContextType, RequireFields<QueryInviteFetchArgs, 'input'>>;
  jobCollection?: Resolver<ResolversTypes['JobCollectionResult'], ParentType, ContextType, RequireFields<QueryJobCollectionArgs, 'input'>>;
  jobCollectionList?: Resolver<ResolversTypes['JobCollectionListResult'], ParentType, ContextType, RequireFields<QueryJobCollectionListArgs, 'input'>>;
  jobCollectionGetNewURL?: Resolver<ResolversTypes['JobCollectionGetNewURLResult'], ParentType, ContextType, RequireFields<QueryJobCollectionGetNewUrlArgs, 'input'>>;
  getJobAlerts?: Resolver<ResolversTypes['GetJobAlertsOutput'], ParentType, ContextType>;
  jobPostingDraft?: Resolver<ResolversTypes['JobPostingOutput'], ParentType, ContextType, RequireFields<QueryJobPostingDraftArgs, 'id'>>;
  jobPostingFinalized?: Resolver<ResolversTypes['JobPostingOutput'], ParentType, ContextType, RequireFields<QueryJobPostingFinalizedArgs, 'id'>>;
  jobPostingCount?: Resolver<ResolversTypes['JobPostingCountOutput'], ParentType, ContextType, RequireFields<QueryJobPostingCountArgs, never>>;
  jobPostingList?: Resolver<ResolversTypes['JobPostingListOutput'], ParentType, ContextType, RequireFields<QueryJobPostingListArgs, never>>;
  jobPostingCharge?: Resolver<ResolversTypes['JobPostingChargeOutput'], ParentType, ContextType, RequireFields<QueryJobPostingChargeArgs, 'input'>>;
  jobPostingOrder?: Resolver<ResolversTypes['JobPostingOrderOutput'], ParentType, ContextType, RequireFields<QueryJobPostingOrderArgs, never>>;
  jobPostingDecodeId?: Resolver<ResolversTypes['JobPostingDecodeIdOutput'], ParentType, ContextType, RequireFields<QueryJobPostingDecodeIdArgs, 'input'>>;
  jobPostingReceiptList?: Resolver<ResolversTypes['JobPostingReceiptListOutput'], ParentType, ContextType, RequireFields<QueryJobPostingReceiptListArgs, 'input'>>;
  jobPostingActiveProduct?: Resolver<Maybe<ResolversTypes['JobPostingActiveProductOutput']>, ParentType, ContextType, RequireFields<QueryJobPostingActiveProductArgs, 'input'>>;
};

export type QuizDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizData'] = ResolversParentTypes['QuizData']> = {
  covid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  newGrad?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  remote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  somethingElse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RedactorFieldDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['RedactorFieldData'] = ResolversParentTypes['RedactorFieldData']> = {
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<RedactorFieldDataContentArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult'] = ResolversParentTypes['SearchResult']> = {
  jobs?: Resolver<Array<ResolversTypes['Job']>, ParentType, ContextType>;
  impression_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAppcast?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalLocalInventory?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  handle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  siteSettings?: Resolver<Maybe<Array<Maybe<ResolversTypes['SectionSiteSettings']>>>, ParentType, ContextType>;
  maxLevels?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasUrls?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enableVersioning?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  entryTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SectionSiteSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SectionSiteSettings'] = ResolversParentTypes['SectionSiteSettings']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  siteId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  enabledByDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasUrls?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uriFormat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  template?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Site'] = ResolversParentTypes['Site']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  baseUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasUrls?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalBaseUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tags'] = ResolversParentTypes['Tags']> = {
  jobType?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  siteLocation?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  requiresPhysicalLabor?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  providesHealthBenefits?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  compensationType?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  environment?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  entryLevel?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bCorp?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  diverse?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ecoFriendly?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagSourcesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagSources'] = ResolversParentTypes['TagSources']> = {
  regex?: Resolver<Maybe<ResolversTypes['Tags']>, ParentType, ContextType>;
  script?: Resolver<Maybe<ResolversTypes['Tags']>, ParentType, ContextType>;
  manual?: Resolver<Maybe<ResolversTypes['Tags']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type TitleJobCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TitleJobCount'] = ResolversParentTypes['TitleJobCount']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  friendlyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  admin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCurrent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  preferredLocale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  dateUpdated?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  lastLoginDate?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserAlreadyLoggedInErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAlreadyLoggedInError'] = ResolversParentTypes['UserAlreadyLoggedInError']> = {
  error?: Resolver<ResolversTypes['AlreadyLoggedInError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserAuthenticationRequiredErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAuthenticationRequiredError'] = ResolversParentTypes['UserAuthenticationRequiredError']> = {
  error?: Resolver<ResolversTypes['AuthenticationRequiredError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserCancelPendingEmailChangeErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCancelPendingEmailChangeError'] = ResolversParentTypes['UserCancelPendingEmailChangeError']> = {
  otherError?: Resolver<ResolversTypes['UserCancelPendingEmailChangeOtherError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserCancelPendingEmailChangeResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCancelPendingEmailChangeResult'] = ResolversParentTypes['UserCancelPendingEmailChangeResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserCancelPendingEmailChangeSuccess' | 'UserCancelPendingEmailChangeError', ParentType, ContextType>;
};

export type UserCancelPendingEmailChangeSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCancelPendingEmailChangeSuccess'] = ResolversParentTypes['UserCancelPendingEmailChangeSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangeEmailErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeEmailError'] = ResolversParentTypes['UserChangeEmailError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserChangeEmailInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserChangeEmailOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangeEmailInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeEmailInputErrors'] = ResolversParentTypes['UserChangeEmailInputErrors']> = {
  newEmailAddress?: Resolver<Maybe<ResolversTypes['EmailInputErrorWithExistsAndSelfCompare']>, ParentType, ContextType>;
  newEmailAddressConfirmation?: Resolver<Maybe<ResolversTypes['EmailAddressConfirmationInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangeEmailResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeEmailResult'] = ResolversParentTypes['UserChangeEmailResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserChangeEmailSuccess' | 'UserChangeEmailError', ParentType, ContextType>;
};

export type UserChangeEmailSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeEmailSuccess'] = ResolversParentTypes['UserChangeEmailSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangeLoggedInRealmErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeLoggedInRealmError'] = ResolversParentTypes['UserChangeLoggedInRealmError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserChangeLoggedInRealmInputError']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserChangeLoggedInRealmOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangeLoggedInRealmInputErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeLoggedInRealmInputError'] = ResolversParentTypes['UserChangeLoggedInRealmInputError']> = {
  loggedInRealm?: Resolver<Maybe<ResolversTypes['RequiredInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangeLoggedInRealmResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeLoggedInRealmResult'] = ResolversParentTypes['UserChangeLoggedInRealmResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserChangeLoggedInRealmSuccess' | 'UserChangeLoggedInRealmError', ParentType, ContextType>;
};

export type UserChangeLoggedInRealmSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangeLoggedInRealmSuccess'] = ResolversParentTypes['UserChangeLoggedInRealmSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangePasswordErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangePasswordError'] = ResolversParentTypes['UserChangePasswordError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserChangePasswordInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserChangePasswordOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangePasswordInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangePasswordInputErrors'] = ResolversParentTypes['UserChangePasswordInputErrors']> = {
  newPassword?: Resolver<Maybe<ResolversTypes['PasswordInputError']>, ParentType, ContextType>;
  newPasswordConfirmation?: Resolver<Maybe<ResolversTypes['PasswordConfirmationInputError']>, ParentType, ContextType>;
  currentPassword?: Resolver<Maybe<ResolversTypes['PasswordInputErrorWithValidation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserChangePasswordResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangePasswordResult'] = ResolversParentTypes['UserChangePasswordResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserChangePasswordSuccess' | 'UserChangePasswordError', ParentType, ContextType>;
};

export type UserChangePasswordSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChangePasswordSuccess'] = ResolversParentTypes['UserChangePasswordSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserDeleteAccountErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDeleteAccountError'] = ResolversParentTypes['UserDeleteAccountError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserDeleteAccountInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserDeleteAccountOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserDeleteAccountInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDeleteAccountInputErrors'] = ResolversParentTypes['UserDeleteAccountInputErrors']> = {
  emailAddress?: Resolver<Maybe<ResolversTypes['EmailInputError']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['PasswordInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserDeleteAccountResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDeleteAccountResult'] = ResolversParentTypes['UserDeleteAccountResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserDeleteAccountSuccess' | 'UserDeleteAccountError', ParentType, ContextType>;
};

export type UserDeleteAccountSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDeleteAccountSuccess'] = ResolversParentTypes['UserDeleteAccountSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserForgotPasswordErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserForgotPasswordError'] = ResolversParentTypes['UserForgotPasswordError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserForgotPasswordInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserForgotPasswordInputErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserForgotPasswordInputError'] = ResolversParentTypes['UserForgotPasswordInputError']> = {
  emailAddress?: Resolver<Maybe<ResolversTypes['EmailInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserForgotPasswordResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserForgotPasswordResult'] = ResolversParentTypes['UserForgotPasswordResult']> = {
  __resolveType: TypeResolveFn<'UserForgotPasswordSuccess' | 'UserForgotPasswordError', ParentType, ContextType>;
};

export type UserForgotPasswordSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserForgotPasswordSuccess'] = ResolversParentTypes['UserForgotPasswordSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserLoginErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLoginError'] = ResolversParentTypes['UserLoginError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserLoginInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserLoginOtherError']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserLoginInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLoginInputErrors'] = ResolversParentTypes['UserLoginInputErrors']> = {
  emailAddress?: Resolver<Maybe<ResolversTypes['EmailInputError']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['PasswordInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserLoginResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLoginResult'] = ResolversParentTypes['UserLoginResult']> = {
  __resolveType: TypeResolveFn<'UserAlreadyLoggedInError' | 'UserLoginSuccess' | 'UserLoginError', ParentType, ContextType>;
};

export type UserLoginSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLoginSuccess'] = ResolversParentTypes['UserLoginSuccess']> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserProgressResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProgress'] = ResolversParentTypes['UserProgress']> = {
  emailAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  signUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  saveJobs?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inviteFriends?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResendEmailVerificationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserResendEmailVerificationError'] = ResolversParentTypes['UserResendEmailVerificationError']> = {
  otherError?: Resolver<ResolversTypes['UserResendEmailVerificationOtherError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResendEmailVerificationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserResendEmailVerificationResult'] = ResolversParentTypes['UserResendEmailVerificationResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserResendEmailVerificationSuccess' | 'UserResendEmailVerificationError', ParentType, ContextType>;
};

export type UserResendEmailVerificationSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserResendEmailVerificationSuccess'] = ResolversParentTypes['UserResendEmailVerificationSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResetPasswordErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserResetPasswordError'] = ResolversParentTypes['UserResetPasswordError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserResetPasswordInputError']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserResetPasswordOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResetPasswordInputErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserResetPasswordInputError'] = ResolversParentTypes['UserResetPasswordInputError']> = {
  token?: Resolver<Maybe<ResolversTypes['TokenError']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['PasswordInputError']>, ParentType, ContextType>;
  passwordConfirmation?: Resolver<Maybe<ResolversTypes['PasswordConfirmationInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResetPasswordResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserResetPasswordResult'] = ResolversParentTypes['UserResetPasswordResult']> = {
  __resolveType: TypeResolveFn<'UserResetPasswordSuccess' | 'UserResetPasswordError', ParentType, ContextType>;
};

export type UserResetPasswordSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserResetPasswordSuccess'] = ResolversParentTypes['UserResetPasswordSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRole'] = ResolversParentTypes['UserRole']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserSaveStepErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSaveStepError'] = ResolversParentTypes['UserSaveStepError']> = {
  otherError?: Resolver<Maybe<ResolversTypes['UserSaveStepOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserSaveStepResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSaveStepResult'] = ResolversParentTypes['UserSaveStepResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserSaveStepSuccess' | 'UserSaveStepError', ParentType, ContextType>;
};

export type UserSaveStepSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSaveStepSuccess'] = ResolversParentTypes['UserSaveStepSuccess']> = {
  user?: Resolver<Maybe<ResolversTypes['JoblistUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserSignupErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSignupError'] = ResolversParentTypes['UserSignupError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserSignupInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserSignupOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserSignupInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSignupInputErrors'] = ResolversParentTypes['UserSignupInputErrors']> = {
  emailAddress?: Resolver<Maybe<ResolversTypes['EmailInputErrorWithExists']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['PasswordInputError']>, ParentType, ContextType>;
  passwordConfirmation?: Resolver<Maybe<ResolversTypes['PasswordConfirmationInputError']>, ParentType, ContextType>;
  tosAccepted?: Resolver<Maybe<ResolversTypes['CheckboxRequiredError']>, ParentType, ContextType>;
  emailAccepted?: Resolver<Maybe<ResolversTypes['CheckboxError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserSignupResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSignupResult'] = ResolversParentTypes['UserSignupResult']> = {
  __resolveType: TypeResolveFn<'UserAlreadyLoggedInError' | 'UserSignupSuccess' | 'UserSignupError', ParentType, ContextType>;
};

export type UserSignupSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSignupSuccess'] = ResolversParentTypes['UserSignupSuccess']> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUnauthorizedErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUnauthorizedError'] = ResolversParentTypes['UserUnauthorizedError']> = {
  unauthorizedError?: Resolver<ResolversTypes['UnauthorizedError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateAccountErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateAccountError'] = ResolversParentTypes['UserUpdateAccountError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserUpdateAccountInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserUpdateAccountOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateAccountInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateAccountInputErrors'] = ResolversParentTypes['UserUpdateAccountInputErrors']> = {
  firstName?: Resolver<Maybe<ResolversTypes['FirstNameInputError']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['LastNameInputError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateAccountResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateAccountResult'] = ResolversParentTypes['UserUpdateAccountResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserUpdateAccountSuccess' | 'UserUpdateAccountError', ParentType, ContextType>;
};

export type UserUpdateAccountSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateAccountSuccess'] = ResolversParentTypes['UserUpdateAccountSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateQuizDataErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateQuizDataError'] = ResolversParentTypes['UserUpdateQuizDataError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserUpdateQuizDataInputErrors']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserUpdateQuizDataOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateQuizDataInputErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateQuizDataInputErrors'] = ResolversParentTypes['UserUpdateQuizDataInputErrors']> = {
  covid?: Resolver<Maybe<ResolversTypes['CheckboxError']>, ParentType, ContextType>;
  newGrad?: Resolver<Maybe<ResolversTypes['CheckboxError']>, ParentType, ContextType>;
  remote?: Resolver<Maybe<ResolversTypes['CheckboxError']>, ParentType, ContextType>;
  somethingElse?: Resolver<Maybe<ResolversTypes['CheckboxError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateQuizDataResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateQuizDataResult'] = ResolversParentTypes['UserUpdateQuizDataResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserUpdateQuizDataSuccess' | 'UserUpdateQuizDataError', ParentType, ContextType>;
};

export type UserUpdateQuizDataSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateQuizDataSuccess'] = ResolversParentTypes['UserUpdateQuizDataSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateShowGamifyErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateShowGamifyError'] = ResolversParentTypes['UserUpdateShowGamifyError']> = {
  otherError?: Resolver<Maybe<ResolversTypes['UserUpdateShowGamifyOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserUpdateShowGamifyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateShowGamifyResult'] = ResolversParentTypes['UserUpdateShowGamifyResult']> = {
  __resolveType: TypeResolveFn<'UserAuthenticationRequiredError' | 'UserUpdateShowGamifySuccess' | 'UserUpdateShowGamifyError', ParentType, ContextType>;
};

export type UserUpdateShowGamifySuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUpdateShowGamifySuccess'] = ResolversParentTypes['UserUpdateShowGamifySuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['JoblistUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserVerifyEmailErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVerifyEmailError'] = ResolversParentTypes['UserVerifyEmailError']> = {
  inputErrors?: Resolver<Maybe<ResolversTypes['UserVerifyEmailInputError']>, ParentType, ContextType>;
  otherError?: Resolver<Maybe<ResolversTypes['UserVerifyEmailOtherError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserVerifyEmailInputErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVerifyEmailInputError'] = ResolversParentTypes['UserVerifyEmailInputError']> = {
  token?: Resolver<ResolversTypes['TokenError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserVerifyEmailResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVerifyEmailResult'] = ResolversParentTypes['UserVerifyEmailResult']> = {
  __resolveType: TypeResolveFn<'UserVerifyEmailSuccess' | 'UserVerifyEmailError', ParentType, ContextType>;
};

export type UserVerifyEmailSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVerifyEmailSuccess'] = ResolversParentTypes['UserVerifyEmailSuccess']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type VerifyJobAlertOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyJobAlertOutput'] = ResolversParentTypes['VerifyJobAlertOutput']> = {
  __resolveType: TypeResolveFn<'VerifyJobAlertResult' | 'JobAlertError', ParentType, ContextType>;
};

export type VerifyJobAlertResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyJobAlertResult'] = ResolversParentTypes['VerifyJobAlertResult']> = {
  alert?: Resolver<Maybe<ResolversTypes['JobAlert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type VersionedEntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionedEntity'] = ResolversParentTypes['VersionedEntity']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type VolumeFolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['VolumeFolder'] = ResolversParentTypes['VolumeFolder']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  volumeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type VolumeInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['VolumeInterface'] = ResolversParentTypes['VolumeInterface']> = {
  __resolveType: TypeResolveFn<'JoblistcontentqaVolume', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<VolumeInterfaceUrlArgs, never>>;
  width?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<VolumeInterfaceWidthArgs, never>>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<VolumeInterfaceHeightArgs, never>>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  folder?: Resolver<Maybe<ResolversTypes['VolumeFolder']>, ParentType, ContextType>;
  volumeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreatedTimestamp?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  dateUpdatedTimestamp?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  focalPoint?: Resolver<Maybe<ResolversTypes['AssetFocalPoint']>, ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AddJobAlertOutput?: AddJobAlertOutputResolvers;
  AddJobAlertResult?: AddJobAlertResultResolvers<ContextType>;
  Area1JobCount?: Area1JobCountResolvers<ContextType>;
  ArticleBodyHeading?: ArticleBodyHeadingResolvers<ContextType>;
  ArticleBodyImage?: ArticleBodyImageResolvers<ContextType>;
  ArticleBodyNewsection?: ArticleBodyNewsectionResolvers<ContextType>;
  ArticleBodyPullquote?: ArticleBodyPullquoteResolvers<ContextType>;
  ArticleBodyQuote?: ArticleBodyQuoteResolvers<ContextType>;
  ArticleBodySearchcta?: ArticleBodySearchctaResolvers<ContextType>;
  ArticleBodyText?: ArticleBodyTextResolvers<ContextType>;
  ArticleBodyUnion?: ArticleBodyUnionResolvers;
  AssetFocalPoint?: AssetFocalPointResolvers<ContextType>;
  Blog?: BlogResolvers<ContextType>;
  BlogCategory?: BlogCategoryResolvers<ContextType>;
  BrowseCity?: BrowseCityResolvers<ContextType>;
  BrowseCityContent?: BrowseCityContentResolvers<ContextType>;
  BrowseListing?: BrowseListingResolvers<ContextType>;
  BrowseResult?: BrowseResultResolvers<ContextType>;
  BrowseRole?: BrowseRoleResolvers<ContextType>;
  BrowseRoleContent?: BrowseRoleContentResolvers<ContextType>;
  BrowseState?: BrowseStateResolvers<ContextType>;
  BrowseStats?: BrowseStatsResolvers<ContextType>;
  CategoryConnection?: CategoryConnectionResolvers<ContextType>;
  CategoryEdge?: CategoryEdgeResolvers<ContextType>;
  CategoryGroup?: CategoryGroupResolvers<ContextType>;
  CategoryInterface?: CategoryInterfaceResolvers;
  CategoryJobCount?: CategoryJobCountResolvers<ContextType>;
  CityData?: CityDataResolvers<ContextType>;
  CollaboratorInviteError?: CollaboratorInviteErrorResolvers<ContextType>;
  CollaboratorInviteInputErrors?: CollaboratorInviteInputErrorsResolvers<ContextType>;
  CollaboratorInviteResult?: CollaboratorInviteResultResolvers;
  CollaboratorInviteSuccess?: CollaboratorInviteSuccessResolvers<ContextType>;
  CollaboratorRemoveError?: CollaboratorRemoveErrorResolvers<ContextType>;
  CollaboratorRemoveInputErrors?: CollaboratorRemoveInputErrorsResolvers<ContextType>;
  CollaboratorRemoveResult?: CollaboratorRemoveResultResolvers;
  CollaboratorRemoveSuccess?: CollaboratorRemoveSuccessResolvers<ContextType>;
  CompanyJobCount?: CompanyJobCountResolvers<ContextType>;
  CompanyLogo?: CompanyLogoResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeleteAllJobAlertsOutput?: DeleteAllJobAlertsOutputResolvers;
  DeleteAllJobAlertsResult?: DeleteAllJobAlertsResultResolvers<ContextType>;
  DeleteJobAlertOutput?: DeleteJobAlertOutputResolvers;
  DeleteJobAlertResult?: DeleteJobAlertResultResolvers<ContextType>;
  ElementInterface?: ElementInterfaceResolvers;
  EntryConnection?: EntryConnectionResolvers<ContextType>;
  EntryDraftConnection?: EntryDraftConnectionResolvers<ContextType>;
  EntryDraftEdge?: EntryDraftEdgeResolvers<ContextType>;
  EntryDraftInfo?: EntryDraftInfoResolvers<ContextType>;
  EntryEdge?: EntryEdgeResolvers<ContextType>;
  EntryInterface?: EntryInterfaceResolvers;
  EntryType?: EntryTypeResolvers<ContextType>;
  Field?: FieldResolvers<ContextType>;
  FreeTrial?: FreeTrialResolvers<ContextType>;
  GetJobAlertsOutput?: GetJobAlertsOutputResolvers;
  GetJobAlertsResult?: GetJobAlertsResultResolvers<ContextType>;
  InviteAcceptError?: InviteAcceptErrorResolvers<ContextType>;
  InviteAcceptInputErrors?: InviteAcceptInputErrorsResolvers<ContextType>;
  InviteAcceptResult?: InviteAcceptResultResolvers;
  InviteAcceptSuccess?: InviteAcceptSuccessResolvers<ContextType>;
  InvitedBy?: InvitedByResolvers<ContextType>;
  InvitedUser?: InvitedUserResolvers<ContextType>;
  InviteFetchResult?: InviteFetchResultResolvers;
  InviteFetchSuccess?: InviteFetchSuccessResolvers<ContextType>;
  InviteIgnoreError?: InviteIgnoreErrorResolvers<ContextType>;
  InviteIgnoreInputErrors?: InviteIgnoreInputErrorsResolvers<ContextType>;
  InviteIgnoreResult?: InviteIgnoreResultResolvers;
  InviteIgnoreSuccess?: InviteIgnoreSuccessResolvers<ContextType>;
  InviteRemoveError?: InviteRemoveErrorResolvers<ContextType>;
  InviteRemoveInputErrors?: InviteRemoveInputErrorsResolvers<ContextType>;
  InviteRemoveResult?: InviteRemoveResultResolvers;
  InviteRemoveSuccess?: InviteRemoveSuccessResolvers<ContextType>;
  Job?: JobResolvers<ContextType>;
  JobAlert?: JobAlertResolvers<ContextType>;
  JobAlertError?: JobAlertErrorResolvers<ContextType>;
  JobAlertSearchQuery?: JobAlertSearchQueryResolvers<ContextType>;
  JobCollection?: JobCollectionResolvers<ContextType>;
  JobCollectionChangePermsError?: JobCollectionChangePermsErrorResolvers<ContextType>;
  JobCollectionChangePermsInputErrors?: JobCollectionChangePermsInputErrorsResolvers<ContextType>;
  JobCollectionChangePermsResult?: JobCollectionChangePermsResultResolvers;
  JobCollectionChangePermsSuccess?: JobCollectionChangePermsSuccessResolvers<ContextType>;
  JobCollectionChangeTitleError?: JobCollectionChangeTitleErrorResolvers<ContextType>;
  JobCollectionChangeTitleInputErrors?: JobCollectionChangeTitleInputErrorsResolvers<ContextType>;
  JobCollectionChangeTitleResult?: JobCollectionChangeTitleResultResolvers;
  JobCollectionChangeTitleSuccess?: JobCollectionChangeTitleSuccessResolvers<ContextType>;
  JobCollectionCollaborator?: JobCollectionCollaboratorResolvers<ContextType>;
  JobCollectionCreateError?: JobCollectionCreateErrorResolvers<ContextType>;
  JobCollectionCreateInputErrors?: JobCollectionCreateInputErrorsResolvers<ContextType>;
  JobCollectionCreateResult?: JobCollectionCreateResultResolvers;
  JobCollectionCreateSuccess?: JobCollectionCreateSuccessResolvers<ContextType>;
  JobCollectionDeleteError?: JobCollectionDeleteErrorResolvers<ContextType>;
  JobCollectionDeleteInputErrors?: JobCollectionDeleteInputErrorsResolvers<ContextType>;
  JobCollectionDeleteResult?: JobCollectionDeleteResultResolvers;
  JobCollectionDeleteSuccess?: JobCollectionDeleteSuccessResolvers<ContextType>;
  JobCollectionError?: JobCollectionErrorResolvers<ContextType>;
  JobCollectionGetNewURLResult?: JobCollectionGetNewUrlResultResolvers;
  JobCollectionGetNewURLSuccess?: JobCollectionGetNewUrlSuccessResolvers<ContextType>;
  JobCollectionInputErrors?: JobCollectionInputErrorsResolvers<ContextType>;
  JobCollectionItem?: JobCollectionItemResolvers<ContextType>;
  JobCollectionListError?: JobCollectionListErrorResolvers<ContextType>;
  JobCollectionListInputErrors?: JobCollectionListInputErrorsResolvers<ContextType>;
  JobCollectionListResult?: JobCollectionListResultResolvers;
  JobCollectionListSuccess?: JobCollectionListSuccessResolvers<ContextType>;
  JobCollectionMod?: JobCollectionModResolvers<ContextType>;
  JobCollectionPerms?: JobCollectionPermsResolvers<ContextType>;
  JobCollectionResult?: JobCollectionResultResolvers;
  JobCollectionSuccess?: JobCollectionSuccessResolvers<ContextType>;
  JobCollectionToggleSharingError?: JobCollectionToggleSharingErrorResolvers<ContextType>;
  JobCollectionToggleSharingInputErrors?: JobCollectionToggleSharingInputErrorsResolvers<ContextType>;
  JobCollectionToggleSharingResult?: JobCollectionToggleSharingResultResolvers;
  JobCollectionToggleSharingSuccess?: JobCollectionToggleSharingSuccessResolvers<ContextType>;
  JobData?: JobDataResolvers<ContextType>;
  JobError?: JobErrorResolvers<ContextType>;
  JoblistcontentqaVolume?: JoblistcontentqaVolumeResolvers<ContextType>;
  JoblistUser?: JoblistUserResolvers<ContextType>;
  JobPosting?: JobPostingResolvers<ContextType>;
  JobPostingActiveProductOutput?: JobPostingActiveProductOutputResolvers;
  JobPostingActiveProductSuccess?: JobPostingActiveProductSuccessResolvers<ContextType>;
  JobPostingApplyOutput?: JobPostingApplyOutputResolvers;
  JobPostingApplySuccess?: JobPostingApplySuccessResolvers<ContextType>;
  JobPostingCharge?: JobPostingChargeResolvers<ContextType>;
  JobPostingChargeOutput?: JobPostingChargeOutputResolvers;
  JobPostingChargeSuccess?: JobPostingChargeSuccessResolvers<ContextType>;
  JobPostingCheckoutOutput?: JobPostingCheckoutOutputResolvers;
  JobPostingCheckoutSuccess?: JobPostingCheckoutSuccessResolvers<ContextType>;
  JobPostingContent?: JobPostingContentResolvers<ContextType>;
  JobPostingCountOutput?: JobPostingCountOutputResolvers;
  JobPostingCountSuccess?: JobPostingCountSuccessResolvers<ContextType>;
  JobPostingDecodeIdOutput?: JobPostingDecodeIdOutputResolvers;
  JobPostingDecodeIdSuccess?: JobPostingDecodeIdSuccessResolvers<ContextType>;
  JobPostingError?: JobPostingErrorResolvers<ContextType>;
  JobPostingListOutput?: JobPostingListOutputResolvers;
  JobPostingListSuccess?: JobPostingListSuccessResolvers<ContextType>;
  JobPostingOrder?: JobPostingOrderResolvers<ContextType>;
  JobPostingOrderItem?: JobPostingOrderItemResolvers<ContextType>;
  JobPostingOrderOutput?: JobPostingOrderOutputResolvers;
  JobPostingOrderSuccess?: JobPostingOrderSuccessResolvers<ContextType>;
  JobPostingOutput?: JobPostingOutputResolvers;
  JobPostingPaymentDeferral?: JobPostingPaymentDeferralResolvers<ContextType>;
  JobPostingProductConsumption?: JobPostingProductConsumptionResolvers<ContextType>;
  JobPostingReceipt?: JobPostingReceiptResolvers<ContextType>;
  JobPostingReceiptListOutput?: JobPostingReceiptListOutputResolvers;
  JobPostingReceiptListSuccess?: JobPostingReceiptListSuccessResolvers<ContextType>;
  JobPostingSuccess?: JobPostingSuccessResolvers<ContextType>;
  JobResult?: JobResultResolvers;
  JobSaveError?: JobSaveErrorResolvers<ContextType>;
  JobSaveInputErrors?: JobSaveInputErrorsResolvers<ContextType>;
  JobSaveResult?: JobSaveResultResolvers;
  JobSaveSuccess?: JobSaveSuccessResolvers<ContextType>;
  JobUnsaveError?: JobUnsaveErrorResolvers<ContextType>;
  JobUnsaveInputErrors?: JobUnsaveInputErrorsResolvers<ContextType>;
  JobUnsaveResult?: JobUnsaveResultResolvers;
  JobUnsaveSuccess?: JobUnsaveSuccessResolvers<ContextType>;
  LocalityJobCount?: LocalityJobCountResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NormalBrowseArgs?: NormalBrowseArgsResolvers<ContextType>;
  OptionFieldData?: OptionFieldDataResolvers<ContextType>;
  OptionFieldDataOptions?: OptionFieldDataOptionsResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PauseAllJobAlertsOutput?: PauseAllJobAlertsOutputResolvers;
  PauseAllJobAlertsResult?: PauseAllJobAlertsResultResolvers<ContextType>;
  PauseJobAlertOutput?: PauseJobAlertOutputResolvers;
  PauseJobAlertResult?: PauseJobAlertResultResolvers<ContextType>;
  PostalCodeJobCount?: PostalCodeJobCountResolvers<ContextType>;
  PressEntriesPressEntry?: PressEntriesPressEntryResolvers<ContextType>;
  PressEntriesPressSection?: PressEntriesPressSectionResolvers<ContextType>;
  PressEntriesUnion?: PressEntriesUnionResolvers;
  Presspage?: PresspageResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QuizData?: QuizDataResolvers<ContextType>;
  RedactorFieldData?: RedactorFieldDataResolvers<ContextType>;
  SearchResult?: SearchResultResolvers<ContextType>;
  Section?: SectionResolvers<ContextType>;
  SectionSiteSettings?: SectionSiteSettingsResolvers<ContextType>;
  Site?: SiteResolvers<ContextType>;
  Tags?: TagsResolvers<ContextType>;
  TagSources?: TagSourcesResolvers<ContextType>;
  Timestamp?: GraphQLScalarType;
  TitleJobCount?: TitleJobCountResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserAlreadyLoggedInError?: UserAlreadyLoggedInErrorResolvers<ContextType>;
  UserAuthenticationRequiredError?: UserAuthenticationRequiredErrorResolvers<ContextType>;
  UserCancelPendingEmailChangeError?: UserCancelPendingEmailChangeErrorResolvers<ContextType>;
  UserCancelPendingEmailChangeResult?: UserCancelPendingEmailChangeResultResolvers;
  UserCancelPendingEmailChangeSuccess?: UserCancelPendingEmailChangeSuccessResolvers<ContextType>;
  UserChangeEmailError?: UserChangeEmailErrorResolvers<ContextType>;
  UserChangeEmailInputErrors?: UserChangeEmailInputErrorsResolvers<ContextType>;
  UserChangeEmailResult?: UserChangeEmailResultResolvers;
  UserChangeEmailSuccess?: UserChangeEmailSuccessResolvers<ContextType>;
  UserChangeLoggedInRealmError?: UserChangeLoggedInRealmErrorResolvers<ContextType>;
  UserChangeLoggedInRealmInputError?: UserChangeLoggedInRealmInputErrorResolvers<ContextType>;
  UserChangeLoggedInRealmResult?: UserChangeLoggedInRealmResultResolvers;
  UserChangeLoggedInRealmSuccess?: UserChangeLoggedInRealmSuccessResolvers<ContextType>;
  UserChangePasswordError?: UserChangePasswordErrorResolvers<ContextType>;
  UserChangePasswordInputErrors?: UserChangePasswordInputErrorsResolvers<ContextType>;
  UserChangePasswordResult?: UserChangePasswordResultResolvers;
  UserChangePasswordSuccess?: UserChangePasswordSuccessResolvers<ContextType>;
  UserDeleteAccountError?: UserDeleteAccountErrorResolvers<ContextType>;
  UserDeleteAccountInputErrors?: UserDeleteAccountInputErrorsResolvers<ContextType>;
  UserDeleteAccountResult?: UserDeleteAccountResultResolvers;
  UserDeleteAccountSuccess?: UserDeleteAccountSuccessResolvers<ContextType>;
  UserForgotPasswordError?: UserForgotPasswordErrorResolvers<ContextType>;
  UserForgotPasswordInputError?: UserForgotPasswordInputErrorResolvers<ContextType>;
  UserForgotPasswordResult?: UserForgotPasswordResultResolvers;
  UserForgotPasswordSuccess?: UserForgotPasswordSuccessResolvers<ContextType>;
  UserLoginError?: UserLoginErrorResolvers<ContextType>;
  UserLoginInputErrors?: UserLoginInputErrorsResolvers<ContextType>;
  UserLoginResult?: UserLoginResultResolvers;
  UserLoginSuccess?: UserLoginSuccessResolvers<ContextType>;
  UserProgress?: UserProgressResolvers<ContextType>;
  UserResendEmailVerificationError?: UserResendEmailVerificationErrorResolvers<ContextType>;
  UserResendEmailVerificationResult?: UserResendEmailVerificationResultResolvers;
  UserResendEmailVerificationSuccess?: UserResendEmailVerificationSuccessResolvers<ContextType>;
  UserResetPasswordError?: UserResetPasswordErrorResolvers<ContextType>;
  UserResetPasswordInputError?: UserResetPasswordInputErrorResolvers<ContextType>;
  UserResetPasswordResult?: UserResetPasswordResultResolvers;
  UserResetPasswordSuccess?: UserResetPasswordSuccessResolvers<ContextType>;
  UserRole?: UserRoleResolvers<ContextType>;
  UserSaveStepError?: UserSaveStepErrorResolvers<ContextType>;
  UserSaveStepResult?: UserSaveStepResultResolvers;
  UserSaveStepSuccess?: UserSaveStepSuccessResolvers<ContextType>;
  UserSignupError?: UserSignupErrorResolvers<ContextType>;
  UserSignupInputErrors?: UserSignupInputErrorsResolvers<ContextType>;
  UserSignupResult?: UserSignupResultResolvers;
  UserSignupSuccess?: UserSignupSuccessResolvers<ContextType>;
  UserUnauthorizedError?: UserUnauthorizedErrorResolvers<ContextType>;
  UserUpdateAccountError?: UserUpdateAccountErrorResolvers<ContextType>;
  UserUpdateAccountInputErrors?: UserUpdateAccountInputErrorsResolvers<ContextType>;
  UserUpdateAccountResult?: UserUpdateAccountResultResolvers;
  UserUpdateAccountSuccess?: UserUpdateAccountSuccessResolvers<ContextType>;
  UserUpdateQuizDataError?: UserUpdateQuizDataErrorResolvers<ContextType>;
  UserUpdateQuizDataInputErrors?: UserUpdateQuizDataInputErrorsResolvers<ContextType>;
  UserUpdateQuizDataResult?: UserUpdateQuizDataResultResolvers;
  UserUpdateQuizDataSuccess?: UserUpdateQuizDataSuccessResolvers<ContextType>;
  UserUpdateShowGamifyError?: UserUpdateShowGamifyErrorResolvers<ContextType>;
  UserUpdateShowGamifyResult?: UserUpdateShowGamifyResultResolvers;
  UserUpdateShowGamifySuccess?: UserUpdateShowGamifySuccessResolvers<ContextType>;
  UserVerifyEmailError?: UserVerifyEmailErrorResolvers<ContextType>;
  UserVerifyEmailInputError?: UserVerifyEmailInputErrorResolvers<ContextType>;
  UserVerifyEmailResult?: UserVerifyEmailResultResolvers;
  UserVerifyEmailSuccess?: UserVerifyEmailSuccessResolvers<ContextType>;
  VerifyJobAlertOutput?: VerifyJobAlertOutputResolvers;
  VerifyJobAlertResult?: VerifyJobAlertResultResolvers<ContextType>;
  VersionedEntity?: VersionedEntityResolvers<ContextType>;
  VolumeFolder?: VolumeFolderResolvers<ContextType>;
  VolumeInterface?: VolumeInterfaceResolvers;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
