import { IJobSearchParams } from '../context/SearchParamsContext'
import env from '../constants/env'
import { SearchJobsQueryQuery, UserLoggedInRealm } from '~/generated/graphql'

declare global {
  interface Window {
    mixpanel?: {
      track: (eventName: string, incomeData: MixpanelArguments | undefined) => void
    }
  }
}
export interface MixpanelArguments {
  A?: string
  email?: string
  'Related Title'?: string
  Related?: string
  'Email Modal'?: string
  'Compare Partner'?: string
  'Compare Partner URL'?: string
  'Compare URL ID'?: string
  'User ID'?: string
  isLoggedIn?: boolean
  fieldHasData?: boolean
  fieldDataLength?: number
  'Session ID'?: string
  'Device Type'?: DeviceTypeEnum
  'Search Page Type'?: string
  'Search Keyword Type'?: string
  'Search Keyword Source'?: string
  'Search Keyword Value'?: string
  'Location Change Type'?: string
  'Available Jobs'?: number
  'Available Jobs - Appcast'?: number
  'Available Jobs - Indeed'?: number
  'Available Jobs - Local Inventory'?: number
  'Jobs on Page - Appcast'?: number
  'Jobs on Page - Indeed'?: number
  'Jobs on Page - Local Inventory'?: number
  Place?: string
  'Place ID'?: string
  'Filter - Job Titles'?: string[]
  'Filter - Companies'?: string[]
  'Filter - Locations'?: string
  'Filter - Posted Date'?: string
  'Filter - Employment Type'?: string
  'Filter - Salary'?: string
  'Job - ID'?: string
  'Job - Provider'?: string | undefined
  'Job - Search Position'?: number | undefined
  'Job - CPC'?: number | undefined
  'Job - Context'?:
    | 'searchPage'
    | 'explorePage'
    | 'descPanel'
    | 'jobPage'
    | 'ePage'
    | 'inlineActions'
  'Job Posting - Apply Type'?: 'email' | 'url'
  'Outgoing URL'?: string | undefined
  'Search Form - Error Type'?: string
  'Post Job - Provider'?: string
  'Contact - Department'?: string
  'Article Title'?: string
  'No Results - Click Type'?: string
  'A/B Test Bucket'?: string
  'Page Route Part'?: string
  'Ad Position'?: string
  'User Country'?: string
  'Exit Type'?: string
  Screen?: string
  Timeline?: string
  'No Scenario'?: string
  'Remote Scenario'?: string
  'Covid Scenario'?: string
  'Entry Level Scenario'?: string
  'Role Specificity'?: string
  'Keyword Field Type'?: string
  'Keyword Field Value'?: string
  'Location Changed'?: string
  'Radius Changed'?: string
  'Location Value'?: string
  'Radius Value'?: string
  'Job Type - Part-Time'?: string
  'Job Type - Part-Time Only'?: string
  'Job Type - Full-Time'?: string
  'Site Location - On-Site'?: string
  'Site Location - Remote'?: string
  'Site Location - Remote Only'?: string
  'Health & Physical - Physical Labor'?: string
  'Health & Physical - Physical Labor Only'?: string
  'Health & Physical - Health Benefits'?: string
  'Compensation - Hourly'?: string
  'Compensation - Hourly Only'?: string
  'Compensation - Minimum Per Hour Changed'?: string
  'Compensation - Minimum Per Hour Value'?: number
  'Compensation - Minimum Per Year Changed'?: string
  'Compensation - Minimum Per Year Value'?: number
  'Environment - Retail'?: string
  'Environment - Food Services'?: string
  'Environment - Warehouse'?: string
  'Environment - Driver'?: string
  'Environment - Hospitality'?: string
  'Environment - Other'?: string
  'Collab - Role'?: 'owner' | 'collaborator'
  'Collab - Source'?: 'saveJobModal' | 'createCollectionModal'
  'Profile - switched to realm'?: UserLoggedInRealm
  'Apply - Job Posting ID'?: string
  'Apply - Field - First Name'?: boolean
  'Apply - Field - Last Name'?: boolean
  'Apply - Field - Email'?: boolean
  'Apply - Field - Phone'?: boolean
  'Apply - Field - Terms'?: boolean
  'Apply - Field - Resume'?: boolean
  'Apply - Field - Cover Letter'?: boolean
  'Insert Type'?: 'remote'
  viewType?: 'organic' | 'paid'
}

enum DeviceTypeEnum {
  desktop = 'desktop',
  mobile = 'mobile'
}

export const Mixpanel = {
  track: (eventName: string, data: MixpanelArguments) => {
    if (env.PUBLIC_APP_ENVIRONMENT === 'local') {
      console.table && console.table({ eventName, ...data })
    }
    if (typeof window === 'object' && window.mixpanel && !env.PUBLIC_TEST_ENV) {
      window.mixpanel.track(eventName, data)
    }
  }
}

export const formatMixPanelData = (
  data: SearchJobsQueryQuery | undefined,
  searchParams: IJobSearchParams | undefined
) => {
  const searchJobs = data && data.searchJobs

  if (!searchJobs && !searchParams) {
    return {}
  }

  let formattedData: MixpanelArguments = {
    'User ID': getUserId(),
    'Session ID': getSessionId(),
    'Device Type': getDeviceType(),
    Place: searchParams && searchParams.location,
    'Place ID': searchParams && searchParams.placeid,
    'Filter - Job Titles': searchParams && searchParams.titles,
    'Filter - Companies': searchParams && searchParams.companys,
    'Filter - Locations': searchParams && searchParams.location,
    'Filter - Posted Date': searchParams && searchParams.posted
  }

  if (!searchJobs) {
    return formattedData
  }

  if (data) {
    const appcastOnPage = data.searchJobs.jobs.reduce((count: number, el: any) => {
      if (el && el.provider && el.provider.match(/appcast/i)) {
        count++
      }
      return count
    }, 0)

    return {
      ...formattedData,
      'Available Jobs': data.searchJobs.total,
      'Available Jobs - Appcast': data.searchJobs.totalAppcast,
      'Available Jobs - Local Inventory': data.searchJobs.totalLocalInventory
        ? data.searchJobs.total
        : 0,
      'Jobs on Page - Appcast': appcastOnPage,
      'Jobs on Page - Local Inventory': data.searchJobs.totalLocalInventory
    }
  }
}

function getCookie(name: string, cookie: string): string {
  const matches = cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  )
  return matches ? decodeURIComponent(matches[1]) : ''
}

export const getSessionId = (): string => {
  const SESSION_ID_NAME = 'sessionId'
  if (typeof document === 'object' && document.cookie) {
    return getCookie(SESSION_ID_NAME, document.cookie)
  } else {
    return ''
  }
}

const getUserId = (): string => {
  const USER_ID_NAME = 'firstVisitId'
  if (typeof document === 'object' && document.cookie) {
    return getCookie(USER_ID_NAME, document.cookie)
  } else {
    return ''
  }
}

const getDeviceType = (): DeviceTypeEnum => {
  if (typeof navigator === 'object') {
    const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

    return isMobile ? DeviceTypeEnum.mobile : DeviceTypeEnum.desktop
  } else {
    return DeviceTypeEnum.desktop
  }
}
