export const SortArrayOfObjects = <T extends { [key: string]: any }, K extends keyof T>(
  arrayOfObjects: T[],
  keyToSortOn: K
): T[] => {
  return arrayOfObjects.sort((a, b) => {
    if (a[keyToSortOn] < b[keyToSortOn]) {
      return -1
    }
    if (a[keyToSortOn] > b[keyToSortOn]) {
      return 1
    }
    return 0
  })
}

export const arrayOfLength = <T>(count: number, val: T): T[] => {
  const arr = []
  for (let i = 0; i < count; i++) {
    arr.push(val)
  }
  return arr
}
