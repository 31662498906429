import React, { createContext, useContext, useState } from 'react'
import Cookie from 'js-cookie'
export interface GamifyData {
  gamifyUiSettings: {
    showMainBanner: boolean
  }
  steps: {
    startYourJobSearch: boolean
    emailAlert: boolean
    inviteFriends: boolean
    saveJobs: boolean
    signUp: boolean
  }
}

type GamifyActions = {
  updateGamifySteps: (steps: Partial<GamifyData['steps']>) => void
  updateGamifyUiSettings: (steps: Partial<GamifyData['gamifyUiSettings']>) => void
}

const initialData: GamifyData = {
  gamifyUiSettings: {
    showMainBanner: true
  },
  steps: {
    startYourJobSearch: true,
    emailAlert: false,
    inviteFriends: false,
    saveJobs: false,
    signUp: false
  }
}

export const GamifyActionsContext = createContext<GamifyActions | undefined>(undefined)
export const GamifyContext = createContext(initialData)

const useGamifyData = () => {
  const context = useContext(GamifyContext)
  if (context === undefined) {
    throw new Error('useGamifyData must be used within a GamifyProvider')
  }
  return context
}

function useGamifyActions() {
  const context = useContext(GamifyActionsContext)
  if (context === undefined) {
    throw new Error('useGamifyActions must be used within a GamifyActionsContext')
  }
  return context
}

interface ProviderParams {
  value: GamifyData
}
const GamifyProvider: React.FC<ProviderParams> = ({ value, children }) => {
  const [gamifyStepsState, setGamifySteps] = useState<GamifyData['steps']>(value.steps)
  const [gamifyUiSettingsState, setGamifyUiSettings] = useState<GamifyData['gamifyUiSettings']>(
    value.gamifyUiSettings
  )

  const updateGamifySteps = (steps: Partial<GamifyData['steps']>) => {
    setGamifySteps(current => ({ ...current, ...steps }))
  }
  const updateGamifyUiSettings = (uiSettings: Partial<GamifyData['gamifyUiSettings']>) => {
    if (uiSettings.showMainBanner) {
      Cookie.remove('hideGamifyBanner')
    } else {
      Cookie.set('hideGamifyBanner', 'true', {
        expires: 365
      })
    }
    setGamifyUiSettings(current => ({ ...current, ...uiSettings }))
  }
  const gamifyState: GamifyData = {
    gamifyUiSettings: gamifyUiSettingsState,
    steps: gamifyStepsState
  }
  return (
    <GamifyContext.Provider value={gamifyState}>
      <GamifyActionsContext.Provider value={{ updateGamifySteps, updateGamifyUiSettings }}>
        {children}
      </GamifyActionsContext.Provider>
    </GamifyContext.Provider>
  )
}

export { GamifyProvider, useGamifyData, useGamifyActions }
