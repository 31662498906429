import React, { createContext, useContext } from 'react'

interface PaidViewProps {
  isPaidView: boolean
}

const initialData: PaidViewProps = {
  isPaidView: true
}

export const PaidViewContext = createContext(initialData)

const usePaidView = () => {
  return useContext(PaidViewContext)
}

interface ProviderParams {
  value: PaidViewProps
}

const PaidViewProvider: React.FC<ProviderParams> = ({ value, children }) => {
  return <PaidViewContext.Provider value={value}>{children}</PaidViewContext.Provider>
}

export { PaidViewProvider, usePaidView }
