import { UserDevice } from '../../context/UserDeviceContext'

export function getUserAgent(req: any): string | undefined {
  return req ? req.headers['user-agent'] : navigator.userAgent
}

/**
 * Currently we detect the iPhone, iPod and Android mobiles (devices that have
 * both Android and Mobile in the user agent string).
 *
 * @return {boolean} Whether the user is using a mobile device.
 */
export function isMobile(userAgent: string): boolean {
  return (
    !isTablet(userAgent) &&
    (userAgent.indexOf('iPod') !== -1 ||
      userAgent.indexOf('iPhone') !== -1 ||
      userAgent.indexOf('Android') !== -1 ||
      userAgent.indexOf('IEMobile') !== -1)
  )
}

/**
 * Currently we detect Kindle Fire, iPad, and Android tablets (devices that have
 * Android but not Mobile in the user agent string).
 *
 * @return {boolean} Whether the user is using a tablet.
 */
export function isTablet(userAgent: string): boolean {
  return (
    userAgent.indexOf('iPad') !== -1 ||
    (userAgent.indexOf('Android') !== -1 && userAgent.indexOf('Mobile') === -1) ||
    userAgent.indexOf('Silk') !== -1
  )
}

/**
 * Detect Ipad
 * @return {boolean} Whether the user is using an Ipad.
 */
export function isIpad(userAgent: string): boolean {
  return userAgent.indexOf('iPad') !== -1
}

/**
 * Detect bots
 * @returns {boolean}
 */
export function isBot(userAgent: string): boolean {
  const pattern = '(AdsBot-Google|AdsBot-Google-Mobile|AdIdxBot|BingPreview|facebookexternalhit)'
  const regex = new RegExp(pattern, 'i')
  return !!regex.test(userAgent)
}

export const detectIE = () => {
  if (typeof window === 'undefined') {
    return false
  }
  const ua = window.navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  // other browser
  return false
}

export const getUserDevice = (req: any): UserDevice => {
  const userAgent = getUserAgent(req) || ''
  return {
    userAgent,
    isIpad: isIpad(userAgent),
    isMobile: isMobile(userAgent),
    isTablet: isTablet(userAgent),
    isBot: isBot(userAgent)
  }
}
