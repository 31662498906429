import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-fetch'
import env from '../constants/env'
import introspectionQueryResultData from '../generated/introspectionQueryResultData'
import { redirect } from './redirect'
import { extractTraceContext, parseCookies } from './utils'

const {
  PUBLIC_GRAPHQL_BACKEND_ENDPOINT,
  PUBLIC_GRAPHQL_GATEWAY_ENDPOINT,
  PUBLIC_GQL_QUERY_TIMEOUT_MILLIS
} = env

function create(initialState: any, linkErrorLog: any, headers: any, ctx: any) {
  let uploadLink

  if (typeof window === 'undefined') {
    uploadLink = createUploadLink({
      credentials: 'include',
      uri: PUBLIC_GRAPHQL_BACKEND_ENDPOINT,
      headers,
      fetch
    })
  } else {
    uploadLink = createUploadLink({
      uri: PUBLIC_GRAPHQL_GATEWAY_ENDPOINT,
      credentials: 'include'
    })
  }

  const links = []

  const headersContextLink = setContext((_, { headers }) => {
    const cookies = parseCookies(ctx?.req)
    // get the authentication token from cookie if it exists
    const token = cookies.masqueradeToken || cookies.authToken
    const serverSideHeaders: Record<string, string> = {}
    if (ctx && ctx.req) {
      serverSideHeaders['x-forwarded-for'] =
        ctx.req.header('X-Forwarded-For') || ctx.req.connection.remoteAddress
      serverSideHeaders['user-agent'] = ctx.req.header('user-agent')
      const traceCtx = extractTraceContext(ctx.req.get('x-cloud-trace-context'))
      if (traceCtx) {
        serverSideHeaders['X-Cloud-Trace-Context'] =
          ctx.req.res.locals.renderAppTraceHeader || `${traceCtx.traceId}/${traceCtx.spanId};o=1`
        serverSideHeaders['x-frontend-trace-id'] = traceCtx.traceId
      }
    }
    // return the headers to the context
    return {
      headers: {
        ...headers,
        ...serverSideHeaders,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })
  links.push(headersContextLink)

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    graphQLErrors?.map(error => {
      if (error.message === 'MAXIMUM_RATE_EXCEEDED') {
        redirect({ context: ctx, target: '/limit-error', statusCode: 429 })
      }
    })

    if (linkErrorLog) {
      linkErrorLog.error({ operation, graphQLErrors, networkError })
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 10,
      max: 10,
      jitter: false
    },
    attempts: {
      max: 2,
      retryIf: error => error.statusCode === 502
    }
  })

  links.push(retryLink)

  const timeoutLink = new ApolloLinkTimeout(PUBLIC_GQL_QUERY_TIMEOUT_MILLIS)
  links.push(timeoutLink)
  links.push(errorLink)
  links.push(uploadLink)

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })

  return new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache({ fragmentMatcher }).restore(initialState || {}),
    ssrMode: !process.browser
  })
}

export const createApolloClient = (ctx: any, initialState: any = {}) => {
  const headers: Record<string, string> = {}
  let linkErrorLog = null
  if (!process.browser) {
    if (ctx && ctx.req && ctx.res) {
      linkErrorLog = ctx.res.locals.linkErrorLog
      headers['user-agent'] = ctx.req.header('user-agent')
      headers['X-Forwarded-For'] =
        ctx.req.header('X-Forwarded-For') || ctx.req.connection.remoteAddress
    }
  }

  return create(initialState, linkErrorLog, headers, ctx)
}
