/**
 * Picks keys from given object.
 */
export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const ret: any = {}
  keys.forEach(key => {
    ret[key] = obj[key]
  })
  return ret
}

/**
 * Picks keys from given object while renaming keys.
 */
export function pickWithKeyPrefix(
  obj: Record<string, any>,
  prefix: string,
  ...keys: string[]
): Record<string, any> {
  const ret: Record<string, any> = {}
  keys.forEach(key => {
    ret[prefix + key.charAt(0).toUpperCase() + key.slice(1)] = obj[key]
  })
  return ret
}

export function deepCopy(inObject: any) {
  let outObject: any, value, key

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value)
  }

  return outObject
}
