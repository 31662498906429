import Axios from 'axios'
import md5 from 'blueimp-md5'
import Cookie from 'js-cookie'
import * as Sentry from '@sentry/browser'
import { IABTest, IABTestVariant, IABTestResult, ABTestInternal } from '../../types/types'
import { parseCookies } from '../utils/cookies'
import { Mixpanel, formatMixPanelData } from '../mixpanel'
import { IJobSearchParams } from '../../context/SearchParamsContext'
import env from '../../constants/env'
import { SearchJobsQueryQuery } from '~/generated/graphql'

interface TrackingData {
  firstVisitId: string | undefined
  sessionId: string | undefined
  searchResultData?: SearchJobsQueryQuery | undefined
  searchParams?: IJobSearchParams | undefined
}

export class ABTest {
  name: string
  variants: IABTestVariant[]
  testData: IABTestVariant[] = []

  constructor(name: string, variants: IABTestVariant[]) {
    this.name = name
    this.variants = variants
    this.initTestData(variants)
  }

  initTestData(variants: IABTestVariant[]): void {
    const defaultWeight = 0.5
    let accumulativeWeight = 0.0

    const totalWeight = variants
      .map(o => {
        return isFinite(o.weight) ? o.weight : defaultWeight
      })
      .reduce((a, b) => {
        return a + b
      })

    this.testData = variants
      .map(variant => {
        return {
          weight: (isFinite(variant.weight) ? variant.weight : defaultWeight) / totalWeight,
          name: variant.name
        }
      })
      .sort((a, b) => {
        return b.weight - a.weight
      })

    this.testData.forEach(o => {
      o.weight += accumulativeWeight
      accumulativeWeight = o.weight
    })
  }

  getGroup(identifier: string): string {
    const hashedId = md5(this.name, identifier).substr(0, 8)
    const hashAsInt = parseInt('0x' + hashedId, 16)
    const maxInt = parseInt('0xffffffff', 16)
    const random = hashAsInt / maxInt

    const filtered = this.testData.filter(t => {
      return t.weight > random
    })

    if (filtered.length === 0) throw Error('Error filtering the tests')

    return filtered[0].name
  }
}

/**
 * Given a unique identifier and ABTest configuration, calculates
 * and returns an object containg test name and bucket where this
 * identifier will be assigned to.
 *
 * @export
 * @param {string} identifier
 * @param {IABTest} testConfig
 * @returns {IABTestResult} name, bucket
 */
export function calculateABTestBucket(
  identifier: string | undefined,
  testConfig: IABTest,
  overwriteBucket?: string
): IABTestResult {
  if (!testConfig)
    return {
      test_name: 'Unknown',
      bucket: 'A'
    }

  const ABTestObj = new ABTest(testConfig.name, testConfig.variants)

  let bucket = ''

  // First, use the unique identifier to bucket users into tests
  if (identifier) {
    bucket = ABTestObj.getGroup(identifier)
  }

  // If we're in a test environment, always choose control
  if (env.PUBLIC_TEST_ENV) {
    bucket = testConfig.variants[0].name
  } else {
    // If we're not in a test environment, attempt to check
    // if internal users are trying to override buckets
    if (overwriteBucket !== undefined) {
      for (let variant of testConfig.variants) {
        if (variant.name === overwriteBucket) {
          bucket = overwriteBucket
          break
        }
      }
    } else {
      // Default to control if somehow we are not in test and
      // don't have a bucket calculated using a unique identifier
      bucket = bucket || testConfig.variants[0].name
    }
  }

  return {
    test_name: testConfig.name,
    bucket: bucket
  }
}

/**
 * Sends A/B test bucketing event to PubSub to later be
 * tracked in BigQuery.
 *
 * @export
 * @param {(string | undefined)} firstVisitId
 * @param {(string | undefined)} sessionId
 * @param {string} abTestName
 * @param {string} abTestBucket
 * @param {string} [timestamp]
 */
async function trackABTestInternally(
  firstVisitId: string | undefined,
  sessionId: string | undefined,
  abTestName: string,
  abTestBucket: string
): Promise<void> {
  if (firstVisitId !== undefined && sessionId !== undefined) {
    const data = JSON.stringify({
      firstVisitId: firstVisitId,
      sessionId: sessionId,
      abTestName: abTestName,
      abTestBucket: abTestBucket
    })

    let MAX_RETRIES = 3
    for (let i = 0; i < MAX_RETRIES; i++) {
      try {
        return await Axios.post('/event-tracking/ab-test', {
          data: Buffer.from(data).toString('base64')
        })
      } catch (err) {
        Sentry.withScope(scope => {
          scope.setTag('event-tracking', 'ab-test')
          Sentry.captureException(err)
        })
        await new Promise(r => setTimeout(r, (i + 1) * 200))
      }
    }
  }
}

/**
 * Tracks A/B test bucketing events in Mixpanel and BigQuery,
 * by default. Users may overwrite the second part to skip
 * saving data in BigQuery.
 *
 * @export
 * @param {MixpanelProps} mixpanel
 * @param {*} cookies
 * @param {ABTestInternal} abTest
 * @param {string} calculatedBucket
 * @param {({
 *     firstVisitId: string | undefined,
 *     sessionId: string | undefined
 *     searchResultData: SearchJobsQueryQuery | undefined
 *     searchParams: IJobSearchParams | undefined
 *   })} [trackingData]
 * @param {number} [expires]
 * @param {boolean} [saveInBigQuery]
 */
export async function trackABTestBucketing(
  abTest: ABTestInternal,
  calculatedBucket: string,
  { firstVisitId, sessionId, searchResultData, searchParams }: TrackingData,
  expires: number = 90,
  isBot: boolean = false
): Promise<void> {
  if (env.PUBLIC_TEST_ENV || isBot === true) {
    return Promise.resolve()
  }
  const cookies = parseCookies()
  if (cookies[abTest.cookieName] === undefined) {
    // Track in MixPanel
    Mixpanel.track(abTest.mpEvtName, {
      ...formatMixPanelData(searchResultData, searchParams),
      'A/B Test Bucket': calculatedBucket
    })
    // Save in BigQuery through PubSubs
    await trackABTestInternally(firstVisitId, sessionId, abTest.shortName, calculatedBucket)
    // Set a cookie so we only do this once
    Cookie.set(abTest.cookieName, calculatedBucket, { expires: expires })
  }
}

/*
 * Does some basic validation on passed in parameters to ensure
 * it matches the A/B test naming nomenclature.
 *
 * @param {*} query Path Query
 */
export function setABTestOverwrites(query: { [key: string]: string | string[] } = {}) {
  let overwrites: { [key: string]: string } = {}
  Object.keys(query).forEach(paramKey => {
    let paramValue = Array.isArray(query[paramKey]) ? query[paramKey][0] : query[paramKey]

    if (typeof paramValue === 'string' && paramValue.startsWith(paramKey)) {
      overwrites[paramKey] = paramValue
    }
  })
  return overwrites
}
