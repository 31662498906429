import React, { createContext, useContext } from 'react'

interface CookieProps {
  [key: string]: string | undefined
}

const initialData: CookieProps = {}

export const CookieContext = createContext(initialData)

// Use useCookies this within a function component to access cookies
const useCookies = () => {
  return useContext(CookieContext)
}

interface ConsumerProps {
  children: (context: CookieProps | undefined) => React.ReactNode
}
// use this within a class component to access cookies
const CookieConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <CookieContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('CookeConsumer must be used within a CookieProvider')
        }
        return children(context)
      }}
    </CookieContext.Consumer>
  )
}

interface ProviderParams {
  value: CookieProps
}
const CookieProvider: React.FC<ProviderParams> = ({ value, children }) => {
  return <CookieContext.Provider value={value}>{children}</CookieContext.Provider>
}

export { CookieProvider, CookieConsumer, useCookies }
