import Router from 'next/router'

export const redirect = ({
  target,
  context,
  statusCode = 303,
  clientMethod = 'replace'
}: {
  target: string
  context?: any
  statusCode?: number
  clientMethod?: 'push' | 'replace'
}) => {
  if (context && context.res) {
    // server
    // 303 default: "See other"
    context.res.writeHead(statusCode, { Location: target })
    context.res.end()
  } else {
    // In the browser, we just pretend like this never even happened ;)
    Router[clientMethod](target)
  }
}
