import { CountryList_ISO3166_1, DecayFunction, JobProvider } from '../../constants/app-constants'
import { AppGlobal, Flags } from '../../types/types'

enum LocationRadius {
  Within_50Miles = 'WITHIN_50_MILES',
  Within_25Miles = 'WITHIN_25_MILES',
  Within_10Miles = 'WITHIN_10_MILES',
  Within_5Miles = 'WITHIN_5_MILES',
  Within_100Miles = 'WITHIN_100_MILES',
  Within_50Kms = 'WITHIN_50_KMS',
  Within_25Kms = 'WITHIN_25_KMS',
  Within_10Kms = 'WITHIN_10_KMS',
  Within_5Kms = 'WITHIN_5_KMS',
  Within_100Kms = 'WITHIN_100_KMS',
  AnyLocation = 'ANY_LOCATION'
}

declare const global: AppGlobal

/* Checks if a given value is not undefined. */
export function isDef(value: any): boolean {
  return value !== undefined
}

/***
 * Checks if we are running on Google App Engine.
 * See https://cloud.google.com/appengine/docs/standard/nodejs/runtime#environment_variables
 */
export function isAppEngine(): boolean {
  return (
    isDef(process.env.GOOGLE_CLOUD_PROJECT) &&
    isDef(process.env.GAE_INSTANCE) &&
    isDef(process.env.GAE_SERVICE) &&
    isDef(process.env.GAE_VERSION)
  )
}

export interface HostInfo {
  isServer: boolean
  host: string
  originalUrl: string
  protocol: string
}

export function detectHost(
  req: any = {
    headers: {
      host: ''
    },
    originalUrl: '',
    protocol: ''
  }
): HostInfo {
  const isServer = typeof window === 'undefined'
  const host = isServer ? req.headers.host : window.location.hostname
  const originalUrl = isServer ? req.originalUrl : window.location.pathname
  const protocol = isServer ? `${req.protocol}:` : window.location.protocol

  return {
    isServer,
    host,
    originalUrl,
    protocol
  }
}

export const getDefaultFlags = (): Flags => {
  return {
    homepageDescLogic: false,
    topAdPositionForCountry: {
      au: 0,
      ca: 1,
      uk: 2,
      us: 3
    },
    disableSidebarEmailSignup: true,
    disableBottomEmailSignup: true,
    disableFacebookiFrame: false,
    disableSearchAlert: true,
    enablePostAJobRedirect: true,
    redirectPostAJobToLanding: false,
    redirectEPage: false,
    customCpcPivot: 0.18,
    showPermissionToggleEditTitle: false,
    showPermissionToggleShareLink: false,
    showCollabFeature: true,
    showPostJobLink: true,
    showWorkAtJoblistLink: true,
    showPrivacyNotice: true,
    showSignup: true,
    showAdsense: true,
    showAdsenseOrganic: true,
    showCompare: true,
    showPersonaBox: true,
    showPersonaBlog: true,
    showPressPage: true,
    showReportsCategory: true,
    showNewsCategory: true,
    showCoverLetter: false,
    showSalary: true,
    showGamify: true,
    showJobPageRelatedResults: true,
    gamifySticky: false,
    paginationColor: 'black',
    pressTitleText: 'Notable Highlights',
    pressKitUrl: '/static/JoblistPresskit.zip',
    localKeywordExclusions: ['any', 'all', 'anything'],
    inviteButton: 'Invite Friends',
    activePostingProduct: 'us.posting.deferred',
    activePostingProductVersion: 3,
    renewalPostingProduct: 'us.posting.standard',
    renewalPostingProductVersion: 1,
    postingMarkets: 'United States',
    postingPrice: 60,
    postingPricePerDay: 2,
    postingSteps: 'Show your job to millions of job seekers in three quick steps',
    postingDescriptionFreeTrial:
      'Receive at least {freeTrialRuleLimit} {freeTrialRuleMetric} in the first {freeTrialDuration} days or we will automatically pause your trial before it is charged.',
    applicationComplete: {
      probability: 0,
      waitInMs: 1000
    },
    featuredCpcs: {
      local: 0.25,
      i18n: 0.18
    },
    compareModal: {
      numSplits: 8,
      splits: [
        [-1, -1],
        [0, 7],
        [-1, -1],
        [-1, -1]
      ]
    },
    compareModalV2: [
      // Compare modal settings.
      {
        label: 'Upward', // Shown in compare modal.
        link: {
          url: 'https://www.upward.careers/results.jsp',
          params: {
            // These params will be sent in URL as query string.
            CID: '4139',
            SID: '$SESSION_ID',
            location: '$LOCATION',
            job: '$KEYWORD'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'Upward',
          'Compare Partner ID': '1'
        },
        traffic: 0 // Share of traffic this provider should get.
      },
      {
        label: 'Jobs2Careers', // Shown in compare modal.
        link: {
          url: 'http://www.jobs2careers.com/search/results',
          params: {
            // These params will be sent in URL as query string.
            c1: '41',
            q: '$KEYWORD',
            l: '$LOCATION',
            email_id: '1'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'Talroo',
          'Compare Partner ID': '2'
        },
        traffic: 0 // Share of traffic this provider should get.
      },
      {
        label: 'Jobs2Careers', // Shown in compare modal.
        link: {
          url: 'http://www.jobs2careers.com/search',
          params: {
            // These params will be sent in URL as query string.
            c1: '41',
            q: '$KEYWORD',
            l: '$LOCATION'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'Talroo',
          'Compare Partner ID': '3'
        },
        traffic: 0 // Share of traffic this provider should get.
      },
      {
        label: 'Neuvoo', // Shown in compare modal.
        link: {
          url: 'https://neuvoo.com/jobs/',
          params: {
            // These params will be sent in URL as query string.
            k: '$KEYWORD',
            l: '$LOCATION',
            source: 'joblist',
            utm_source: 'dtl'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'Neuvoo',
          'Compare Partner ID': '4'
        },
        traffic: 0 // Share of traffic this provider should get.
      },
      {
        label: 'Jobs2Careers', // Shown in compare modal.
        link: {
          url: 'https://www.jobs2careers.com/results3.php',
          params: {
            // These params will be sent in URL as query string.
            c1: '67',
            q: '$KEYWORD',
            l: '$LOCATION',
            aff_id: '5966'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'Talroo',
          'Compare Partner ID': '5'
        },
        traffic: 1 // Share of traffic this provider should get.
      },
      {
        label: 'Neuvoo', // Shown in compare modal.
        link: {
          url: '/explore',
          params: {
            // These params will be sent in URL as query string.
            l: '$LOCATION',
            q: '$KEYWORD'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'internal',
          'Compare Partner ID': '6'
        },
        traffic: 0 // Share of traffic this provider should get.
      }
    ],
    compareModalUK: [
      // Compare modal settings.
      {
        label: 'CV Library', // Shown in compare modal.
        link: {
          url: 'https://www.cv-library.co.uk/search-jobs',
          params: {
            // These params will be sent in URL as query string.
            q: '$KEYWORD',
            geo: '$LOCATION',
            s: '104259'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'CV Library',
          'Compare Partner ID': '7'
        },
        traffic: 1 // Share of traffic this provider should get.
      },
      {
        label: 'CV Library', // Shown in compare modal.
        link: {
          url: 'https://www.cv-library.co.uk/register',
          params: {
            // These params will be sent in URL as query string.
            id: '104260'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'CV Library',
          'Compare Partner ID': '7'
        },
        traffic: 1 // Share of traffic this provider should get.
      },
      {
        label: 'WhatJobs', // Shown in compare modal.
        link: {
          url: 'https://uk.whatjobs.com/jobs',
          params: {
            // These params will be sent in URL as query string.
            keyword: '$KEYWORD',
            location: '$LOCATION',
            pid: '2982',
            psrc: 'textlink'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'WhatJobs',
          'Compare Partner ID': '8'
        },
        traffic: 1 // Share of traffic this provider should get.
      },
      {
        label: 'trending jobs', // Shown in compare modal.
        link: {
          url: '/explore',
          params: {
            // These params will be sent in URL as query string.
            l: '$LOCATION',
            q: 'part-time',
            tv: '3b'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'internal',
          'Compare Partner ID': '6'
        },
        traffic: 1 // Share of traffic this provider should get.
      }
    ],
    compareModalAU: [
      // Compare modal settings.
      {
        label: 'trending jobs', // Shown in compare modal.
        link: {
          url: '/explore',
          params: {
            // These params will be sent in URL as query string.
            l: '$LOCATION',
            q: 'part-time',
            tv: '3b'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'internal',
          'Compare Partner ID': '6'
        },
        traffic: 1 // Share of traffic this provider should get.
      }
    ],
    compareModalCA: [
      // Compare modal settings.
      {
        label: 'trending jobs', // Shown in compare modal.
        link: {
          url: '/explore',
          params: {
            // These params will be sent in URL as query string.
            l: '$LOCATION',
            q: 'part-time',
            tv: '3b'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'internal',
          'Compare Partner ID': '6'
        },
        traffic: 1 // Share of traffic this provider should get.
      }
    ],
    compareModalNonUS: [
      // Compare modal settings.
      {
        label: 'trending jobs', // Shown in compare modal.
        link: {
          url: '/explore',
          params: {
            // These params will be sent in URL as query string.
            l: '$LOCATION',
            q: 'part-time',
            tv: '3b'
          }
        },
        mixpanel: {
          // Mixpanel events.
          'Compare Partner': 'internal',
          'Compare Partner ID': '6'
        },
        traffic: 1 // Share of traffic this provider should get.
      }
    ],
    relatedTitles: {
      columnCountMobile: 2,
      columnCountDesktop: 2
    },
    recommendedTitlesSearch: {
      columnCountMobile: 2,
      columnCountDesktop: 2,
      compare: true
    },
    abTests: {
      'Related Jobs A/B Test': {
        name: 'Related Jobs A/B Test',
        short_name: 'tv-4',
        variants: [
          {
            name: 'tv-4a-vy0wS',
            weight: 1
          },
          {
            name: 'tv-4b-bh8YW',
            weight: 0
          }
        ]
      },
      'Landing Modal A/B Test': {
        name: 'Landing Modal A/B Test',
        short_name: 'tv-5',
        variants: [
          {
            name: 'tv-5a-QH0Tp',
            weight: 0
          },
          {
            name: 'tv-5b-ZrJ86',
            weight: 1
          },
          {
            name: 'tv-5c-lVSGt',
            weight: 0
          }
        ]
      },
      'Results Link Color A/B Test': {
        name: 'Results Link Color A/B Test',
        short_name: 'tv-6',
        variants: [
          {
            name: 'tv-6a-59dz2',
            weight: 0
          },
          {
            name: 'tv-6b-0U2cg',
            weight: 1
          }
        ]
      },
      'Require Keyword On Modal A/B Test': {
        name: 'Require Keyword On Modal A/B Test',
        short_name: 'tv-7',
        variants: [
          {
            name: 'tv-7a-BmEjH',
            weight: 0
          },
          {
            name: 'tv-7b-bPdcF',
            weight: 0
          },
          {
            name: 'tv-7c-g4bvB',
            weight: 1
          }
        ]
      },
      'Hide Job Desc A/B Test': {
        name: 'Hide Job Desc A/B Test',
        short_name: 'tv-8',
        variants: [
          {
            name: 'tv-8a-P4gPO',
            weight: 0
          },
          {
            name: 'tv-8b-ET0wW',
            weight: 1
          }
        ]
      },
      'Search Local Inventory A/B Test': {
        name: 'Search Local Inventory A/B Test',
        short_name: 'tv-9',
        variants: [
          {
            name: 'tv-9a-mk4n5',
            weight: 1
          },
          {
            name: 'tv-9b-niusW',
            weight: 0
          }
        ]
      },
      'Keyword Boost A/B Test': {
        name: 'Keyword Boost A/B Test',
        short_name: 'tv-10',
        variants: [
          {
            name: 'tv-10a-dFg2i',
            weight: 0.5
          },
          {
            name: 'tv-10b-YnIY5',
            weight: 0.5
          }
        ]
      },
      'No Keyword Boost A/B Test': {
        name: 'No Keyword Boost A/B Test',
        short_name: 'tv-11',
        variants: [
          {
            name: 'tv-11a-dFg2i',
            weight: 0.5
          },
          {
            name: 'tv-11b-YnIY5',
            weight: 0.5
          }
        ]
      },
      'Require Keyword On Modal A/B Test V2': {
        name: 'Require Keyword On Modal A/B Test V2',
        short_name: 'tv-12',
        variants: [
          {
            name: 'tv-12a-EGyMQ',
            weight: 1
          },
          {
            name: 'tv-12b-NQRxk',
            weight: 0
          }
        ]
      },
      'Search Local Inventory A/B Test V2': {
        name: 'Search Local Inventory A/B Test V2',
        short_name: 'tv-13',
        variants: [
          {
            name: 'tv-13a-lf2Vi',
            weight: 0
          },
          {
            name: 'tv-13b-7eaSR',
            weight: 1
          }
        ]
      },
      'Disable Bottom Email Modal A/B Test': {
        name: 'Disable Bottom Email Modal A/B Test',
        short_name: 'tv-14',
        variants: [
          {
            name: 'tv-14a-dRrQu',
            weight: 0
          },
          {
            name: 'tv-14b-57PEj',
            weight: 1
          }
        ]
      },
      'Search Modal Button A/B Test': {
        name: 'Search Modal Button A/B Test',
        short_name: 'tv-15',
        variants: [
          {
            name: 'tv-15a-vRwJd',
            weight: 0
          },
          {
            name: 'tv-15b-d5ZLO',
            weight: 1
          }
        ]
      },
      'Search Modal FB Headline A/B Test': {
        name: 'Search Modal FB Headline A/B Test',
        short_name: 'tv-16',
        variants: [
          {
            name: 'tv-16a-5XD5w',
            weight: 0.5
          },
          {
            name: 'tv-16b-a1gV7',
            weight: 0.5
          }
        ]
      },
      'Neuvoo CPC Floor A/B Test': {
        name: 'Neuvoo CPC Floor A/B Test',
        short_name: 'tv-17',
        variants: [
          {
            name: 'tv-17a-wtjQS',
            weight: 0.5
          },
          {
            name: 'tv-17b-LKoxV',
            weight: 0.5
          }
        ]
      },
      'Covid 19 Search Results Alert': {
        name: 'Covid 19 Search Results Alert',
        short_name: 'tv-18',
        variants: [
          {
            name: 'tv-18a-o9uff',
            weight: 0
          },
          {
            name: 'tv-18b-JCjv3',
            weight: 1
          }
        ]
      },
      'Require Keyword Test for CA': {
        name: 'Require Keyword Test for CA',
        short_name: 'tv-19',
        variants: [
          {
            name: 'tv-19a-s4KuB',
            weight: 1
          },
          {
            name: 'tv-19b-Okzaz',
            weight: 0
          }
        ]
      },
      'Search Results Alert': {
        name: 'Search Results Alert',
        short_name: 'tv-22',
        variants: [
          {
            name: 'tv-22a-9zjR4',
            weight: 0
          },
          {
            name: 'tv-22b-nDpMq',
            weight: 1
          }
        ]
      },
      'Search Alert Learning Links': {
        name: 'Search Alert Learning Links',
        short_name: 'tv-23',
        variants: [
          {
            name: 'tv-23a-Igp04',
            weight: 1
          },
          {
            name: 'tv-23b-gPwfE',
            weight: 0
          }
        ]
      },
      'Keywords Autocomplete': {
        name: 'Keywords Autocomplete',
        short_name: 'tv-24',
        variants: [
          {
            name: 'tv-24a-sdE74',
            weight: 0
          },
          {
            name: 'tv-24b-uNwaP',
            weight: 1
          }
        ]
      },
      'Onboarding Quiz v1': {
        name: 'Onboarding Quiz v1',
        short_name: 'tv-25',
        variants: [
          {
            name: 'tv-25a-8BDg2',
            weight: 1
          },
          {
            name: 'tv-25b-8BDg2',
            weight: 0
          }
        ]
      },
      'Search Facets': {
        name: 'Search Facets',
        short_name: 'tv-26',
        variants: [
          {
            name: 'tv-26a-9JxpO',
            weight: 0
          },
          {
            name: 'tv-26b-WaNie',
            weight: 1
          }
        ]
      },
      'Onboarding Quiz v2': {
        name: 'Onboarding Quiz v2',
        short_name: 'tv-27',
        variants: [
          {
            name: 'tv-27a-sffEA',
            weight: 1
          },
          {
            name: 'tv-27b-sffEA',
            weight: 0
          }
        ]
      },
      'Use Onboarding for Mobile Search Header': {
        name: 'Use Onboarding for Mobile Search Header',
        short_name: 'tv-28',
        variants: [
          {
            name: 'tv-28a-fvEdA',
            weight: 0
          },
          {
            name: 'tv-28b-fvEdA',
            weight: 1
          }
        ]
      },
      'Use Onboarding for Simple Search': {
        name: 'Use Onboarding for Simple Search',
        short_name: 'tv-29',
        variants: [
          {
            name: 'tv-29a-lqRTa',
            weight: 1
          },
          {
            name: 'tv-29b-lqRTa',
            weight: 0
          }
        ]
      },
      'Replace Desktop Filters with new Search Facets': {
        name: 'Replace Desktop Filters with new Search Facets',
        short_name: 'tv-30',
        variants: [
          {
            name: 'tv-30a-vbWEk',
            weight: 0
          },
          {
            name: 'tv-30b-vbWEk',
            weight: 1
          }
        ]
      },
      'Exclude Description From Search': {
        name: 'Exclude Description From Search',
        short_name: 'tv-31',
        variants: [
          {
            name: 'tv-31a-syqk2',
            weight: 0
          },
          {
            name: 'tv-31b-RSC98',
            weight: 1
          }
        ]
      },
      'Use Semantic Search': {
        name: 'Use Semantic Search',
        short_name: 'tv-32',
        variants: [
          {
            name: 'tv-32a-StDYw',
            weight: 0
          },
          {
            name: 'tv-32b-0Cy0g',
            weight: 1
          }
        ]
      },
      'Dev Panel': {
        name: 'Dev Panel',
        short_name: 'tv-34',
        variants: [
          {
            name: 'tv-34a-txXEk',
            weight: 1
          },
          {
            name: 'tv-34b-txXEk',
            weight: 0
          }
        ]
      },
      'Expanded Search': {
        name: 'Expanded Search',
        short_name: 'tv-35',
        variants: [
          {
            name: 'tv-35a-Y9bNE',
            weight: 0
          },
          {
            name: 'tv-35b-RyqsC',
            weight: 1
          }
        ]
      },
      'Search Modal Colors A/B Test': {
        name: 'Search Modal Colors A/B Test',
        short_name: 'tv-38',
        variants: [
          {
            name: 'tv-38a-vxxJd',
            weight: 1
          },
          {
            name: 'tv-38b-dnnLO',
            weight: 0
          },
          {
            name: 'tv-38c-angRO',
            weight: 0
          }
        ]
      },
      'CA - Search Radius A/B Test': {
        name: 'CA - Search Radius A/B Test',
        short_name: 'tv-39',
        variants: [
          {
            name: 'tv-39a-juC4V',
            weight: 0.34
          },
          {
            name: 'tv-39b-voXrh',
            weight: 0.33
          },
          {
            name: 'tv-39c-u35vl',
            weight: 0.33
          }
        ]
      },
      'No Extra Help Prompt on Mobile': {
        name: 'No Extra Help Prompt on Mobile',
        short_name: 'tv-40',
        variants: [
          {
            name: 'tv-40a-Xu3H4',
            weight: 0
          },
          {
            name: 'tv-40b-YA6jl',
            weight: 1
          }
        ]
      },
      'Compare Tick Placement': {
        name: 'Compare Tick Placement',
        short_name: 'tv-41',
        variants: [
          {
            name: 'tv-41a-TLBTF',
            weight: 0.5
          },
          {
            name: 'tv-41b-oOzk8',
            weight: 0.5
          }
        ]
      },
      'Top Ad Position For Country': {
        name: 'Top Ad Position For Country',
        short_name: 'tv-42',
        variants: [
          {
            name: 'tv-42a-zAU1U',
            weight: 1
          },
          {
            name: 'tv-42b-nUr9g',
            weight: 0
          }
        ]
      },
      'Low Results Middle Explainer': {
        name: 'Low Results Middle Explainer',
        short_name: 'tv-43',
        variants: [
          {
            name: 'tv-43a-0ZOOK',
            weight: 0
          },
          {
            name: 'tv-43b-HhIjV',
            weight: 1
          }
        ]
      },
      'Search Results Limit': {
        name: 'Search Results Limit',
        short_name: 'tv-44',
        variants: [
          {
            name: 'tv-44a-81GW0',
            weight: 0.5
          },
          {
            name: 'tv-44b-WzXvQ',
            weight: 0.5
          }
        ]
      },
      'Job Listing Icons': {
        name: 'Job Listing Icons',
        short_name: 'tv-45',
        variants: [
          {
            name: 'tv-45a-BZWHe',
            weight: 0.34
          },
          {
            name: 'tv-45b-alpqW',
            weight: 0.33
          },
          {
            name: 'tv-45c-aqzi2',
            weight: 0.33
          }
        ]
      },
      'Search CPC Boost': {
        name: 'Search CPC Boost',
        short_name: 'tv-48',
        variants: [
          {
            name: 'tv-48a-d5AQo',
            weight: 0.5
          },
          {
            name: 'tv-48b-YOqdG',
            weight: 0.5
          }
        ]
      },
      'Job Alerts Sidebar': {
        name: 'Search Rank By Posted',
        short_name: 'tv-50',
        variants: [
          {
            name: 'tv-50a-Iru4d',
            weight: 0
          },
          {
            name: 'tv-50b-NWj53',
            weight: 1
          }
        ]
      },
      'Search Places Autocomplete Test': {
        name: 'Search Places Autocomplete Test',
        short_name: 'tv-51',
        variants: [
          {
            name: 'tv-51a-1TGtN',
            weight: 0.5
          },
          {
            name: 'tv-51b-BId22',
            weight: 0.5
          }
        ]
      },
      'Gamify Feature': {
        name: 'Gamify Feature',
        short_name: 'tv-52',
        variants: [
          {
            name: 'tv-52a-G4bHE',
            weight: 0.5
          },
          {
            name: 'tv-52b-HuqsF',
            weight: 0.5
          }
        ]
      },
      'DFS Search Test': {
        name: 'DFS Search Test',
        short_name: 'tv-53',
        variants: [
          {
            name: 'tv-53a-R75o3',
            weight: 0.5
          },
          {
            name: 'tv-53b-ioTwQ',
            weight: 0.5
          }
        ]
      },
      'CPC Pivot Test': {
        name: 'CPC Pivot Test',
        short_name: 'tv-54',
        variants: [
          {
            name: 'tv-54a-ryiZb',
            weight: 0.34
          },
          {
            name: 'tv-54b-zgDzy',
            weight: 0.33
          },
          {
            name: 'tv-54c-HruTd',
            weight: 0.33
          }
        ]
      },
      'Search Pagination Strategy': {
        name: 'Search Pagination Strategy',
        short_name: 'tv-55',
        variants: [
          {
            name: 'tv-55a-wjnxJ',
            weight: 0.34
          },
          {
            name: 'tv-55b-HihiR',
            weight: 0.33
          },
          {
            name: 'tv-55c-UEfIg',
            weight: 0.33
          }
        ]
      },
      'Description Phrase Search Test': {
        name: 'Description Phrase Search Test',
        short_name: 'tv-56',
        variants: [
          {
            name: 'tv-56a-kjilp',
            weight: 100
          },
          {
            name: 'tv-56b-Jkolm',
            weight: 0
          },
          {
            name: 'tv-56c-Ndrjo',
            weight: 0
          }
        ]
      },
      'Always Boost Companies': {
        name: 'Always Boost Companies',
        short_name: 'tv-57',
        variants: [
          {
            name: 'tv-57a-wS8hU',
            weight: 0.5
          },
          {
            name: 'tv-57b-drlHs',
            weight: 0.5
          }
        ]
      },
      'Dedupe Company For Keyword Searches': {
        name: 'Dedupe Company For Keyword Searches',
        short_name: 'tv-58',
        variants: [
          {
            name: 'tv-58a-24yAe',
            weight: 0
          },
          {
            name: 'tv-58b-xugXY',
            weight: 1
          }
        ]
      },
      'Rank Providers by Arpu': {
        name: 'Rank Providers by Arpu',
        short_name: 'tv-59',
        variants: [
          {
            name: 'tv-59a-5WcmJ',
            weight: 0
          },
          {
            name: 'tv-59b-s0xsU',
            weight: 1
          }
        ]
      },
      'E Page Modal Title': {
        name: 'E Page Modal Title',
        short_name: 'tv-60',
        variants: [
          {
            name: 'tv-60a-2YchA',
            weight: 0
          },
          {
            name: 'tv-60b-u4FpU',
            weight: 1
          }
        ]
      },
      'Top Job Page': {
        name: 'Top Job Page',
        short_name: 'tv-61',
        variants: [
          {
            name: 'tv-61a-7Dghh',
            weight: 0
          },
          {
            name: 'tv-61b-uu4uu',
            weight: 1
          }
        ]
      },
      'Email Insert Feature': {
        name: 'Email Insert Feature',
        short_name: 'tv-62',
        variants: [
          {
            name: 'tv-62a-1Whhh',
            weight: 0
          },
          {
            name: 'tv-62b-hh4aa',
            weight: 1
          }
        ]
      },
      'Inline Job Actions Feature': {
        name: 'Inline Job Actions Feature',
        short_name: 'tv-63',
        variants: [
          {
            name: 'tv-63a-7Wtjh',
            weight: 0
          },
          {
            name: 'tv-63b-uN31a',
            weight: 1
          }
        ]
      },

      'JIT Routing': {
        name: 'JIT Routing',
        short_name: 'tv-64',
        variants: [
          {
            name: 'tv-64a-uqH21',
            weight: 0
          },
          {
            name: 'tv-64b-NJE02',
            weight: 1
          }
        ]
      },
      'Super Post Feature': {
        name: 'Super Post Feature',
        short_name: 'tv-65',
        variants: [
          {
            name: 'tv-65a-9Qjlr',
            weight: 0
          },
          {
            name: 'tv-65b-yN46p',
            weight: 1
          }
        ]
      },
      'Modify Search Insert Feature': {
        name: 'Modify Search Insert Feature',
        short_name: 'tv-66',
        variants: [
          {
            name: 'tv-66a-7Hjlr',
            weight: 0
          },
          {
            name: 'tv-66b-yN88p',
            weight: 1
          }
        ]
      },
      'Compare Insert Feature': {
        name: 'Email Insert Feature',
        short_name: 'tv-67',
        variants: [
          {
            name: 'tv-67a-1gghh',
            weight: 0
          },
          {
            name: 'tv-67b-he4aa',
            weight: 1
          }
        ]
      }
    },
    keywordCPCBoost: 100,
    noKeywordCPCBoost: 100,
    caNeuvooCPCFloor: {
      // in cents
      'tv-17a-wtjQS': 1,
      'tv-17b-LKoxV': 1
    },
    tags: {
      jobType: {
        source: 'script'
      },
      siteLocation: {
        source: 'script'
      },
      requiresPhysicalLabor: {
        source: 'script'
      },
      providesHealthBenefits: {
        source: 'script'
      },
      compensationType: {
        source: 'script'
      },
      environment: {
        source: 'script'
      },
      entryLevel: {
        source: 'script'
      },
      bCorp: {
        source: 'script'
      },
      diverse: {
        source: 'script'
      },
      ecoFriendly: {
        source: 'script'
      }
    },
    defaultLocationRadiuses: {
      au: LocationRadius.Within_25Kms,
      ca: LocationRadius.Within_25Kms,
      uk: LocationRadius.Within_25Kms,
      us: LocationRadius.Within_25Miles
    },
    searchFeeds: {
      ADZUNA_CPC: [],
      ADZUNA_CPC_2: [],
      APPCAST_CPA: [],
      APPCAST_CPC: [CountryList_ISO3166_1.US],
      APPCAST_LI: [],
      APPCAST_EXCHANGE_CPA_DESKTOP: [CountryList_ISO3166_1.US],
      APPCAST_EXCHANGE_CPA_MOBILE: [CountryList_ISO3166_1.US],
      INDEED: [],
      JOBG8: [],
      JOBGET: [],
      JOBLIFT: [],
      JOBLOOKUP: [],
      JOVEO: [],
      NEUVOO_ORGANIC: [
        CountryList_ISO3166_1.US,
        CountryList_ISO3166_1.AU,
        CountryList_ISO3166_1.CA,
        CountryList_ISO3166_1.UK
      ],
      NEUVOO_SPONSORED: [
        CountryList_ISO3166_1.US,
        CountryList_ISO3166_1.AU,
        CountryList_ISO3166_1.CA,
        CountryList_ISO3166_1.UK
      ],
      PANDOLOGIC_1: [],
      PANDOLOGIC_2: [],
      TALROO_1_XML: [CountryList_ISO3166_1.US],
      TALROO_DIRECT: [],
      TALROO_TIER1: [],
      TALROO_TIER2: [],
      TOTALJOBS: [],
      LINKUP_ORGANIC: [CountryList_ISO3166_1.US],
      LINKUP_SPONSORED: [CountryList_ISO3166_1.US],
      UPWARD: [],
      JOBLIST_SPONSORED: [CountryList_ISO3166_1.US],
      ZIPRECRUITER_PRIORITY: [],
      ZIPRECRUITER_STANDARD: [],
      ZIPRECRUITER_TEST10: [],
      ZIPRECRUITER_TEST30: [],
      ZIPRECRUITER_TEST10P: [],
      ZIPRECRUITER_TEST10S: [],
      ZIPRECRUITER_TEST15: []
    },
    topJobSearchFeeds: {
      ADZUNA_CPC: [],
      ADZUNA_CPC_2: [],
      APPCAST_CPA: [],
      APPCAST_CPC: [CountryList_ISO3166_1.US],
      APPCAST_LI: [],
      APPCAST_EXCHANGE_CPA_DESKTOP: [],
      APPCAST_EXCHANGE_CPA_MOBILE: [],
      JOBG8: [],
      JOBGET: [],
      NEUVOO_ORGANIC: [
        CountryList_ISO3166_1.US,
        CountryList_ISO3166_1.AU,
        CountryList_ISO3166_1.CA,
        CountryList_ISO3166_1.UK
      ],
      NEUVOO_SPONSORED: [
        CountryList_ISO3166_1.US,
        CountryList_ISO3166_1.AU,
        CountryList_ISO3166_1.CA,
        CountryList_ISO3166_1.UK
      ],
      TALROO_1_XML: [CountryList_ISO3166_1.US],
      LINKUP_ORGANIC: [CountryList_ISO3166_1.US],
      LINKUP_SPONSORED: [CountryList_ISO3166_1.US],
      UPWARD: [],
      JOBLIST_SPONSORED: [],
      ZIPRECRUITER_PRIORITY: [],
      ZIPRECRUITER_STANDARD: [],
      ZIPRECRUITER_TEST10: [],
      ZIPRECRUITER_TEST30: [],
      ZIPRECRUITER_TEST10P: [],
      ZIPRECRUITER_TEST10S: [],
      ZIPRECRUITER_TEST15: []
    },
    routableFeeds: {
      ADZUNA_CPC: [],
      ADZUNA_CPC_2: [],
      APPCAST_CPA: [],
      APPCAST_CPC: [CountryList_ISO3166_1.US],
      APPCAST_LI: [],
      APPCAST_EXCHANGE_CPA_DESKTOP: [],
      APPCAST_EXCHANGE_CPA_MOBILE: [],
      JOBG8: [],
      JOBGET: [],
      NEUVOO_ORGANIC: [
        CountryList_ISO3166_1.US,
        CountryList_ISO3166_1.AU,
        CountryList_ISO3166_1.CA,
        CountryList_ISO3166_1.UK
      ],
      NEUVOO_SPONSORED: [
        CountryList_ISO3166_1.US,
        CountryList_ISO3166_1.AU,
        CountryList_ISO3166_1.CA,
        CountryList_ISO3166_1.UK
      ],
      TALROO_1_XML: [CountryList_ISO3166_1.US],
      LINKUP_ORGANIC: [CountryList_ISO3166_1.US],
      LINKUP_SPONSORED: [CountryList_ISO3166_1.US],
      UPWARD: [],
      JOBLIST_SPONSORED: [],
      ZIPRECRUITER_PRIORITY: [],
      ZIPRECRUITER_STANDARD: [],
      ZIPRECRUITER_TEST10: [],
      ZIPRECRUITER_TEST30: [],
      ZIPRECRUITER_TEST10P: [],
      ZIPRECRUITER_TEST10S: [],
      ZIPRECRUITER_TEST15: []
    },
    expandedSearch: {
      radius: 100, // miles
      limit: 15,
      threshold: 10
    },
    searchResultsLimit: 50,
    searchRadiusBoostBuckets: {
      control: 5,
      variant: 10
    },
    searchCpcBoost: 5,
    searchCpcBoostbyCountry: {
      au: 5,
      ca: 5,
      uk: 5,
      us: 5
    },
    adzunaMobilePercentage: 0.25,
    providerConfig: [
      {
        provider: JobProvider.JoblistSponsored,
        boost: 10
      },
      {
        provider: JobProvider.AdzunaCpc,
        boost: 0.95
      }
    ],
    providerConfigTest: [
      {
        provider: JobProvider.JoblistSponsored,
        boost: 10
      }
    ],
    providerConfigArpu: [
      {
        provider: JobProvider.Talroo_1Xml,
        boost: 20,
        countryBoost: {
          us: 5,
          uk: 5,
          ca: 5,
          au: 5
        }
      }
    ],
    postingConfig: {
      clickDecay: {
        func: DecayFunction.Gauss,
        weight: 10,
        origin: '50',
        scale: '30',
        decay: 0.001,
        offset: '0'
      },
      impressionDecay: {
        func: DecayFunction.Gauss,
        weight: 10,
        origin: '50',
        scale: '30',
        decay: 0.001,
        offset: '0'
      }
    },
    companySlugToNormalized: {
      'hr block': 'H&R Block',
      lowes: "Lowe's"
    },
    remoteKeywords: ['remote', 'wfh', 'work from home'],
    phraseSearchMaxDistance: {
      'tv-56b-Jkolm': 1,
      'tv-56c-Ndrjo': 2
    },
    ePageTitleForModalTest: 'Congrats! Your job is available.',
    showEPageModalButton: true,
    showEPageJobDescription: true,
    showEPageAcceptTag: true,
    insertTopJobAtIndexes: [],
    insertEmailFormAtIndexes: [],
    showTopJobOnAllPages: true,
    showEmailInsertOnAllPages: true,
    jobResultsInserts: [
      { name: 'organicCompareInsert', index: 2, minJobCount: 20, firstPageOnly: false },
      { name: 'paidCompareInsert', index: 4, minJobCount: 20, firstPageOnly: false },

      { name: 'emailAlertInsert', index: 5, minJobCount: 20, firstPageOnly: true },
      { name: 'searchModifierInsert', index: 3, minJobCount: 10, firstPageOnly: false },
      { name: 'topJobInsert', index: 0, firstPageOnly: true },
      { name: 'topJobInsert', index: 5, firstPageOnly: true },
      { name: 'topJobInsert', index: 'last', minJobCount: 20, firstPageOnly: false }
    ],
    workAtJoblist: [
      {
        name: 'Business Operations Associate',
        link: 'https://jobs.lever.co/wilburlabs/f54b52e8-29c4-4eb8-ac34-41412e20032c'
      },
      {
        name: 'Senior Organic Growth Manager',
        link: 'https://jobs.lever.co/wilburlabs/f5c372a7-89c5-4de9-91e7-581b549987e7'
      }
    ]
  }
}

export const fetchFlags = async (): Promise<Flags> => {
  const res = await fetch(`/flags`)
  const data = await res.json()
  return data.flags || getDefaultFlags()
}

export const getServerFlags = (): Flags => {
  return global.flags || getDefaultFlags()
}

export const getFlags = async (req?: any): Promise<Flags> => {
  return req ? getServerFlags() : await fetchFlags()
}

export enum FieldType {
  STRING,
  FLOAT,
  INTEGER,
  BOOLEAN
}

export const sanitizeEventPayload = (
  bqSchema: Array<[string, FieldType]>,
  payload: Record<string, any>
) => {
  const result: Record<string, any> = {}
  for (const [fieldName, fieldType] of bqSchema) {
    const fieldValue = payload[fieldName]
    if (fieldValue === undefined || fieldValue === null) {
      result[fieldName] = null
      continue
    }
    let sanitizedFieldValue: any = null
    switch (fieldType) {
      case FieldType.BOOLEAN:
        if (typeof fieldValue === 'boolean') {
          sanitizedFieldValue = fieldValue
        }
        break
      case FieldType.FLOAT:
        if (typeof fieldValue === 'number') {
          sanitizedFieldValue = fieldValue
        }
        break
      case FieldType.INTEGER:
        if (typeof fieldValue === 'number' && Number.isInteger(fieldValue)) {
          sanitizedFieldValue = fieldValue
        }
        break
      case FieldType.STRING:
        if (typeof fieldValue === 'string') {
          sanitizedFieldValue = fieldValue
        }
        break
    }
    result[fieldName] = sanitizedFieldValue
  }
  return result
}
