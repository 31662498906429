import React, { createContext, useState } from 'react'
import { withRouter, SingletonRouter } from 'next/router'
import isEqual from 'react-fast-compare'
import { ABTestInternal, IABTestResult, IABTestFlag } from '../types/types'
import { calculateABTestBucket } from '../lib/ab-testing'

export * from '../constants/abTestConstants'

export type State = {
  [key: string]: string
}

export type Actions = {
  setState: (state: State) => void
  getTest: (test: ABTestInternal) => IABTestResult
}

interface ABProviderProps {
  state?: State
  firstVisitId: string | undefined
  flags: IABTestFlag
  router: SingletonRouter
}

const ABStateContext = createContext<State | undefined>(undefined)
const ABActionsContext = createContext<Actions | undefined>(undefined)

const ABProviderComponent: React.FC<ABProviderProps> = ({
  state: initialState,
  firstVisitId,
  flags,
  children
}) => {
  const [state, setState] = useState<State>(initialState || {})

  const getTest = (test: ABTestInternal) => {
    return calculateABTestBucket(firstVisitId, flags[test.name], state[test.shortName])
  }

  const actions = {
    setState,
    getTest
  }

  return (
    <ABStateContext.Provider value={state}>
      <ABActionsContext.Provider value={actions}>{children}</ABActionsContext.Provider>
    </ABStateContext.Provider>
  )
}

function useABState(props?: State) {
  const state = React.useContext(ABStateContext)
  const actions = React.useContext(ABActionsContext)

  if (props !== undefined) {
    const newState = {
      ...state,
      ...props
    }

    if (!isEqual(state, newState)) {
      actions?.setState(newState)
    }
  }

  if (state === undefined) {
    throw new Error('useABState must be used within a ABProvider')
  }

  return state
}

function useABActions(props?: Actions) {
  const actions = React.useContext(ABActionsContext)

  if (actions === undefined) {
    throw new Error('useABActions must be used within a ABProvider')
  }

  return actions
}

function useAB(state?: State, actions?: Actions): [State, Actions] {
  return [useABState(state), useABActions(actions)]
}

interface ConsumerProps {
  children: (context: State | undefined) => React.ReactNode
}
const ABConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <ABStateContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('ABConsumer must be used within a ABProvider')
        }
        return children(context)
      }}
    </ABStateContext.Consumer>
  )
}

const ABProvider = withRouter(ABProviderComponent)

export { ABProvider, ABConsumer, useAB, useABState, useABActions }
