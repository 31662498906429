import React from 'react'
// import Head from 'next/head'
import { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { NextPageContext } from 'next'
import { createApolloClient } from './initApollo'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

interface NextApolloAppProps {
  apolloClient: ApolloClient<NormalizedCacheObject>
  apolloState?: NormalizedCacheObject
}
/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withApollo(PageComponent: any, { ssr = true } = {}) {
  const WithApollo = ({
    apolloClient,
    apolloState,
    ...pageProps
  }: NextApolloAppProps & AppProps) => {
    const client = apolloClient || initApolloClient(null, apolloState)
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    )
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component'

    if (displayName === 'JobListApp') {
      console.warn('This withApollo HOC only works with PageComponents.')
    }

    WithApollo.displayName = `withApollo(${displayName})`
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx: NextPageContext) => {
      const { AppTree } = ctx

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      const apolloClient = ((ctx as NextPageContext &
        NextApolloAppProps).apolloClient = initApolloClient(ctx))

      // Run wrapped getInitialProps methods
      let pageProps = {}
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx)
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps
        }
        let span = null
        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import('@apollo/react-ssr')
            const fn = async () =>
              getDataFromTree(
                <AppTree
                  pageProps={{
                    ...pageProps,
                    apolloClient
                  }}
                />
              )
            // @ts-ignore
            const tracer = ctx.res?.locals.tracer
            if (tracer) {
              span = tracer.startSpan('GetDataFromTree', { parent: tracer.getCurrentSpan() })
              span.setAttributes({
                appService: process.env.GAE_SERVICE,
                appVersion: process.env.GAE_VERSION,
                appInstance: process.env.GAE_INSTANCE
              })
              await tracer.withSpan(span, fn)
            } else {
              await fn()
            }
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error)
          } finally {
            if (span) {
              span.end()
            }
          }
          // getDataFromTree does not call componentWillUnmount
          // head side effect therefore need to be cleared manually
          // Head.rewind()
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract()

      return {
        ...pageProps,
        apolloState
      }
    }
  }

  return WithApollo
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
function initApolloClient(
  ctx: any,
  initialState?: NormalizedCacheObject
): ApolloClient<NormalizedCacheObject> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(ctx, initialState)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(ctx, initialState)
  }

  return apolloClient
}
