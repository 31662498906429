import cookie from 'cookie'
import { UserParams } from '../../types/types'

export function parseCookies(req?: any): { [key: string]: string | undefined } {
  return req ? req.cookies : typeof document !== 'undefined' ? cookie.parse(document.cookie) : {}
}

export function onlyRequestCookies(req?: any): { [key: string]: string | undefined } {
  return req ? req.cookies : {}
}

export function getUserParams(req?: any): UserParams {
  const cookies = parseCookies(req)
  const { fbclid, gclid, msclkid, firstVisitId, sessionId, adChannel } = cookies
  return { fbclid, gclid, msclkid, firstVisitId, sessionId, adChannel }
}
