
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "JobResult",
        "possibleTypes": [
          {
            "name": "Job"
          },
          {
            "name": "JobError"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "EntryInterface",
        "possibleTypes": [
          {
            "name": "Presspage"
          },
          {
            "name": "Blog"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ElementInterface",
        "possibleTypes": [
          {
            "name": "Presspage"
          },
          {
            "name": "Blog"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PressEntriesUnion",
        "possibleTypes": [
          {
            "name": "PressEntriesPressSection"
          },
          {
            "name": "PressEntriesPressEntry"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "VolumeInterface",
        "possibleTypes": [
          {
            "name": "JoblistcontentqaVolume"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CategoryInterface",
        "possibleTypes": [
          {
            "name": "BlogCategory"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "InviteFetchResult",
        "possibleTypes": [
          {
            "name": "InviteFetchSuccess"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserUnauthorizedError"
          },
          {
            "name": "JobCollectionSuccess"
          },
          {
            "name": "JobCollectionError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionListResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobCollectionListSuccess"
          },
          {
            "name": "JobCollectionListError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionGetNewURLResult",
        "possibleTypes": [
          {
            "name": "JobCollectionGetNewURLSuccess"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GetJobAlertsOutput",
        "possibleTypes": [
          {
            "name": "GetJobAlertsResult"
          },
          {
            "name": "JobAlertError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingOutput",
        "possibleTypes": [
          {
            "name": "JobPostingSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingCountOutput",
        "possibleTypes": [
          {
            "name": "JobPostingCountSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingListOutput",
        "possibleTypes": [
          {
            "name": "JobPostingListSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingChargeOutput",
        "possibleTypes": [
          {
            "name": "JobPostingChargeSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingOrderOutput",
        "possibleTypes": [
          {
            "name": "JobPostingOrderSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingDecodeIdOutput",
        "possibleTypes": [
          {
            "name": "JobPostingDecodeIdSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingReceiptListOutput",
        "possibleTypes": [
          {
            "name": "JobPostingReceiptListSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingActiveProductOutput",
        "possibleTypes": [
          {
            "name": "JobPostingActiveProductSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ArticleBodyUnion",
        "possibleTypes": [
          {
            "name": "ArticleBodyNewsection"
          },
          {
            "name": "ArticleBodyHeading"
          },
          {
            "name": "ArticleBodyText"
          },
          {
            "name": "ArticleBodyPullquote"
          },
          {
            "name": "ArticleBodyImage"
          },
          {
            "name": "ArticleBodyQuote"
          },
          {
            "name": "ArticleBodySearchcta"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserSignupResult",
        "possibleTypes": [
          {
            "name": "UserAlreadyLoggedInError"
          },
          {
            "name": "UserSignupSuccess"
          },
          {
            "name": "UserSignupError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserLoginResult",
        "possibleTypes": [
          {
            "name": "UserAlreadyLoggedInError"
          },
          {
            "name": "UserLoginSuccess"
          },
          {
            "name": "UserLoginError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserVerifyEmailResult",
        "possibleTypes": [
          {
            "name": "UserVerifyEmailSuccess"
          },
          {
            "name": "UserVerifyEmailError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserResendEmailVerificationResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserResendEmailVerificationSuccess"
          },
          {
            "name": "UserResendEmailVerificationError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserForgotPasswordResult",
        "possibleTypes": [
          {
            "name": "UserForgotPasswordSuccess"
          },
          {
            "name": "UserForgotPasswordError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserResetPasswordResult",
        "possibleTypes": [
          {
            "name": "UserResetPasswordSuccess"
          },
          {
            "name": "UserResetPasswordError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserUpdateAccountResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserUpdateAccountSuccess"
          },
          {
            "name": "UserUpdateAccountError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserChangeEmailResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserChangeEmailSuccess"
          },
          {
            "name": "UserChangeEmailError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserChangePasswordResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserChangePasswordSuccess"
          },
          {
            "name": "UserChangePasswordError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserDeleteAccountResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserDeleteAccountSuccess"
          },
          {
            "name": "UserDeleteAccountError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserUpdateQuizDataResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserUpdateQuizDataSuccess"
          },
          {
            "name": "UserUpdateQuizDataError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserSaveStepResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserSaveStepSuccess"
          },
          {
            "name": "UserSaveStepError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserUpdateShowGamifyResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserUpdateShowGamifySuccess"
          },
          {
            "name": "UserUpdateShowGamifyError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserChangeLoggedInRealmResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserChangeLoggedInRealmSuccess"
          },
          {
            "name": "UserChangeLoggedInRealmError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserCancelPendingEmailChangeResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserCancelPendingEmailChangeSuccess"
          },
          {
            "name": "UserCancelPendingEmailChangeError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "InviteAcceptResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "InviteAcceptSuccess"
          },
          {
            "name": "InviteAcceptError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "InviteIgnoreResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "InviteIgnoreSuccess"
          },
          {
            "name": "InviteIgnoreError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "InviteRemoveResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "InviteRemoveSuccess"
          },
          {
            "name": "InviteRemoveError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CollaboratorInviteResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "UserUnauthorizedError"
          },
          {
            "name": "CollaboratorInviteSuccess"
          },
          {
            "name": "CollaboratorInviteError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CollaboratorRemoveResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "CollaboratorRemoveSuccess"
          },
          {
            "name": "CollaboratorRemoveError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionCreateResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobCollectionCreateSuccess"
          },
          {
            "name": "JobCollectionCreateError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionDeleteResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobCollectionDeleteSuccess"
          },
          {
            "name": "JobCollectionDeleteError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionChangeTitleResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobCollectionChangeTitleSuccess"
          },
          {
            "name": "JobCollectionChangeTitleError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionChangePermsResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobCollectionChangePermsSuccess"
          },
          {
            "name": "JobCollectionChangePermsError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobCollectionToggleSharingResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobCollectionToggleSharingSuccess"
          },
          {
            "name": "JobCollectionToggleSharingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobSaveResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobSaveSuccess"
          },
          {
            "name": "JobSaveError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobUnsaveResult",
        "possibleTypes": [
          {
            "name": "UserAuthenticationRequiredError"
          },
          {
            "name": "JobUnsaveSuccess"
          },
          {
            "name": "JobUnsaveError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "AddJobAlertOutput",
        "possibleTypes": [
          {
            "name": "JobAlert"
          },
          {
            "name": "JobAlertError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "VerifyJobAlertOutput",
        "possibleTypes": [
          {
            "name": "VerifyJobAlertResult"
          },
          {
            "name": "JobAlertError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PauseJobAlertOutput",
        "possibleTypes": [
          {
            "name": "PauseJobAlertResult"
          },
          {
            "name": "JobAlertError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PauseAllJobAlertsOutput",
        "possibleTypes": [
          {
            "name": "PauseAllJobAlertsResult"
          },
          {
            "name": "JobAlertError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DeleteJobAlertOutput",
        "possibleTypes": [
          {
            "name": "DeleteJobAlertResult"
          },
          {
            "name": "JobAlertError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DeleteAllJobAlertsOutput",
        "possibleTypes": [
          {
            "name": "DeleteAllJobAlertsResult"
          },
          {
            "name": "JobAlertError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingApplyOutput",
        "possibleTypes": [
          {
            "name": "JobPostingApplySuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JobPostingCheckoutOutput",
        "possibleTypes": [
          {
            "name": "JobPostingCheckoutSuccess"
          },
          {
            "name": "JobPostingError"
          }
        ]
      }
    ]
  }
};
      export default result;
    