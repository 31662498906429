export const formatNumberWithComma = (num: number | undefined | null) => {
  if (num === undefined || num === null) {
    return ''
  }
  let numStr = num.toString()
  const pattern = /(-?\d+)(\d{3})/
  while (pattern.test(numStr)) {
    numStr = numStr.replace(pattern, '$1,$2')
  }
  return numStr
}

/**
 * Calculates modulus of a dividend in given radix.
 * The caller has to make sure that divisor is non-zero and radix <= 36.
 */
export function modulusInRadix(dividend: string, divisor: number, radix: number): number {
  let result = 0
  const start = dividend.length - 1
  for (let i = start; i >= 0; --i) {
    result += (parseInt(dividend[i], radix) * Math.pow(radix, start - i)) % divisor
  }
  return result % divisor
}

/**
 * Creates a range of numbers in an arithmetic progression.
 *
 * Range takes 1, 2, or 3 arguments:
 * <pre>
 * range(5) is the same as range(0, 5, 1) and produces [0, 1, 2, 3, 4]
 * range(2, 5) is the same as range(2, 5, 1) and produces [2, 3, 4]
 * range(-2, -5, -1) produces [-2, -3, -4]
 * range(-2, -5, 1) produces [], since stepping by 1 wouldn't ever reach -5.
 * </pre>
 *
 * @param {number} startOrEnd The starting value of the range if an end argument
 *     is provided. Otherwise, the start value is 0, and this is the end value.
 * @param {number?} opt_end The optional end value of the range.
 * @param {number?} opt_step The step size between range values. Defaults to 1
 *     if opt_step is undefined or 0.
 * @return {number[]} An array of numbers for the requested range. May be
 *     an empty array if adding the step would not converge toward the end
 *     value.
 */
export function range(startOrEnd: number, optEnd?: number, optStep?: number): number[] {
  const array = []
  let start = 0
  let end = startOrEnd
  const step = optStep || 1
  if (optEnd !== undefined) {
    start = startOrEnd
    end = optEnd
  }

  if (step * (end - start) < 0) {
    // Sign mismatch: start + step will never reach the end value.
    return []
  }

  if (step > 0) {
    for (let i = start; i < end; i += step) {
      array.push(i)
    }
  } else {
    for (let i = start; i > end; i += step) {
      array.push(i)
    }
  }
  return array
}

/**
 * Round number to the nearest number (default: thousand) provided.
 * @param {string} n The number to round off.
 * @param {string} nearestNumber The nearest number to round off.
 * @return {string} Final number after rounding off to the nearest number.
 */
export function roundOff(n: number, nearestNumber: number = 1000): number {
  return Math.round(n / nearestNumber) * nearestNumber
}

// round(1.7777, 2); // 1.78
export function round(num: number, places: number) {
  // @ts-ignore
  return +(Math.round(num + 'e+' + places) + 'e-' + places)
}
