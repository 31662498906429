import React, { createContext, useContext } from 'react'
import { Flags } from '../types/types'
import { getDefaultFlags } from '../lib/utils/appEnv'

const initialData: Flags = getDefaultFlags()

export const FlagsContext = createContext(initialData)

// Use useFlags within a function component to access Flags
const useFlags = () => {
  return useContext(FlagsContext)
}

interface ConsumerProps {
  children: (context: Flags | undefined) => React.ReactNode
}

// use this within a class component to access Flags
const FlagsConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <FlagsContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('FlagsConsumer must be used within a FlagsProvider')
        }
        return children(context)
      }}
    </FlagsContext.Consumer>
  )
}

interface ProviderParams {
  value: Flags
}
const FlagsProvider: React.FC<ProviderParams> = ({ value, children }) => {
  return <FlagsContext.Provider value={value}>{children}</FlagsContext.Provider>
}

export { FlagsProvider, FlagsConsumer, useFlags }
