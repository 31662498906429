import { pickWithKeyPrefix } from './object'

/**
 * Common utility functions.
 * For a broad range of browser compatibility, the below code is heavily borrowed from Google Closure Library.
 */

/**
 * Split the URI into 3 parts where the [1] is the queryData without a leading
 * '?'. For example, the URI http://foo.com/bar?a=b#abc returns
 * ['http://foo.com/bar','a=b','#abc'].
 * @param {string} uri The URI to parse.
 * @return {string[]} An array representation of uri of length 3 where the
 *     middle value is the queryData without a leading '?'.
 * @private
 */
function splitQueryData(uri: string): string[] {
  // Find the query data and hash.
  let hashIndex = uri.indexOf('#')
  if (hashIndex < 0) {
    hashIndex = uri.length
  }
  let questionIndex = uri.indexOf('?')
  let queryData
  if (questionIndex < 0 || questionIndex > hashIndex) {
    questionIndex = hashIndex
    queryData = ''
  } else {
    queryData = uri.substring(questionIndex + 1, hashIndex)
  }
  return [uri.substr(0, questionIndex), queryData, uri.substr(hashIndex)]
}

/**
 * Appends key=value pairs to an array, supporting multi-valued objects.
 * @param {*} key The key prefix.
 * @param {*} value The value to serialize.
 * @param {string[]} pairs The array to which the 'key=value' strings
 *     should be appended.
 * @private
 */
function appendKeyValuePairs(key: string, value: any, pairs: string[]) {
  if (Array.isArray(value)) {
    for (const item of value) {
      // Convert to string explicitly, to short circuit the null and array
      // logic in this function -- this ensures that null and undefined get
      // written as literal 'null' and 'undefined', and arrays don't get
      // expanded out but instead encoded in the default way.
      appendKeyValuePairs(key, String(item), pairs)
    }
  } else if (value != null) {
    // Skip a top-level null or undefined entirely.
    pairs.push(
      key +
        // Check for empty string. Zero gets encoded into the url as literal
        // strings.  For empty string, skip the equal sign, to be consistent
        // with UriBuilder.java.
        (value === '' ? '' : '=' + encodeURIComponent(String(value)))
    )
  }
}

/**
 * Appends URI parameters to an existing URI.
 *
 * The variable arguments may contain alternating keys and values.  Keys are
 * assumed to be already URI encoded.  The values should not be URI-encoded,
 * and will instead be encoded by this function.
 * <pre>
 * appendParams('http://www.foo.com?existing=true',
 *     'key1', 'value1',
 *     'key2', 'value?willBeEncoded',
 *     'key3', ['valueA', 'valueB', 'valueC'],
 *     'key4', null);
 * result: 'http://www.foo.com?existing=true&' +
 *     'key1=value1&' +
 *     'key2=value%3FwillBeEncoded&' +
 *     'key3=valueA&key3=valueB&key3=valueC'
 * </pre>
 *
 * A single call to this function will not exhibit quadratic behavior in IE,
 * whereas multiple repeated calls may, although the effect is limited by
 * fact that URL's generally can't exceed 2kb.
 *
 * @param {string} uri The original URI, which may already have query data.
 * @param {...(any[]|any)} params An array or argument list conforming to QueryArray.
 * @return {string} The URI with all query parameters added.
 */
export function appendParams(uri: string, ...params: any[]) {
  const queryParams: string[] = []
  for (let i = 0; i < params.length; i += 2) {
    appendKeyValuePairs(params[i] as string, params[i + 1], queryParams)
  }
  const queryString = queryParams.join('&')
  if (!queryString) {
    return uri
  }
  const parts = splitQueryData(uri)
  parts[1] = parts[1] ? parts[1] + '&' + queryString : queryString
  return parts[0] + (parts[1] ? '?' + parts[1] : '') + parts[2]
}

/**
 * Builds a query data string from a map.
 * Currently generates "&key&" for empty args.
 *
 * @param {!Object<string, any>} map An object where keys
 *     are URI-encoded parameter keys, and the values are arbitrary types
 *     or arrays. Keys with a null value are dropped.
 * @return {string} The encoded query string, in the form 'a=1&b=2'.
 */
export function buildQueryDataFromMap(map: Record<string, any>) {
  const params: string[] = []
  for (const key in map) {
    if (map.hasOwnProperty(key)) {
      appendKeyValuePairs(key, map[key], params)
    }
  }
  return params.join('&')
}

/**
 * Appends query parameters from a map.
 *
 * @param {string} uri The original URI, which may already have query data.
 * @param {!Object<goog.uri.utils.QueryValue>} map An object where keys are
 *     URI-encoded parameter keys, and the values are arbitrary types or arrays.
 *     Keys with a null value are dropped.
 * @return {string} The new parameters.
 */
export function appendParamsFromMap(uri: string, map: Record<string, any>) {
  const queryData = buildQueryDataFromMap(map)
  const parts = splitQueryData(uri)
  parts[1] = parts[1] ? parts[1] + '&' + queryData : queryData
  return parts[0] + (parts[1] ? '?' + parts[1] : '') + parts[2]
}

interface Query {
  [key: string]: string | string[] | undefined
}
interface QueryReturn<T extends Query> {
  value: (key: keyof T) => string | undefined
  arrayValue: (key: keyof T) => string[] | undefined
}

export function queryResolver<T extends Query>(query: T): QueryReturn<T> {
  return {
    value: (key: keyof T): string | undefined => {
      const val = query[key]
      if (Array.isArray(val)) {
        return val[0]
      }
      if (typeof val === 'string') {
        return val
      }
      if (typeof val === 'undefined') {
        return val
      }
    },
    arrayValue: (key: keyof T): string[] | undefined => {
      const val = query[key]
      if (!val) {
        return undefined
      }
      const isArray = Array.isArray(val)
      if (isArray) {
        return val as string[]
      }
      return [val] as string[]
    }
  }
}

export const extractHostname = (url: string): string => {
  //find & remove protocol (http, ftp, etc.) and get hostname
  let hostname = url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0]
  //find & remove port number
  hostname = hostname.split(':')[0]
  //find & remove "?"
  hostname = hostname.split('?')[0]

  return hostname
}

export const reWriteUrlsMatchingDomQuery = (domQuery: string) => {
  if (typeof document === undefined) return
  const jobItems = document.querySelectorAll(domQuery)

  for (let i = 0; i < jobItems.length; i++) {
    const anchor = jobItems[i] as HTMLAnchorElement
    anchor.href = getUrlWithClientInfo(anchor.href)
  }
}

export const addJitRoutingParamsToJobURLInDom = (domQuery: string, jit: string) => {
  if (typeof document === undefined) return
  const jobItems = document.querySelectorAll(domQuery)

  for (let i = 0; i < jobItems.length; i++) {
    const anchor = jobItems[i] as HTMLAnchorElement
    anchor.href = appendParamsFromMap(anchor.href, { jit })
  }
}

export const addJitRoutingParamsToJobURL = (url: string, jit: string) => {
  return `${url}&jit=${jit}`
}

export function getPageParams() {
  return pickWithKeyPrefix(window.location, 'page', 'hostname', 'pathname', 'href', 'hash')
}

export function getUrlWithClientInfo(url: string): string {
  const nav = window.navigator
  const conn = (nav as any).connection
  const navigatorParams = pickWithKeyPrefix(
    nav,
    'navigation',
    'appCodeName',
    'appName',
    'appVersion',
    'cookieEnabled',
    'hardwareConcurrency',
    'language',
    'maxTouchPoints',
    'platform',
    'product',
    'productSub',
    'vendor',
    'vendorSub'
  )
  const windowParams = pickWithKeyPrefix(
    window,
    'window',
    'innerWidth',
    'innerHeight',
    'outerWidth',
    'outerHeight',
    'pageXOffset',
    'pageYOffset',
    'screenX',
    'screenY',
    'screenLeft',
    'screenTop',
    'scrollX',
    'scrollY',
    'devicePixelRatio'
  )
  const screenParams = pickWithKeyPrefix(
    window.screen,
    'screen',
    'availHeight',
    'availWidth',
    'availWidth',
    'colorDepth',
    'height',
    'width',
    'pixelDepth'
  )
  const locationParams = pickWithKeyPrefix(
    window.location,
    'page',
    'hostname',
    'pathname',
    'href',
    'hash'
  )
  const connectionParams = pickWithKeyPrefix(
    conn || {},
    'connection',
    'downlink',
    'effectiveType',
    'rtt'
  )
  const payload = {
    ...navigatorParams,
    ...windowParams,
    ...screenParams,
    ...locationParams,
    ...connectionParams
  }
  return appendParamsFromMap(url, payload)
}

export const getPageType = (asPath?: string) => {
  if (!asPath) {
    return {
      isCompanyPage: false,
      isPartnerPage: false,
      isLocationOnlyPage: false,
      isJobTitleOnlyPage: false,
      isJobTitleAndLocationPage: false,
      isStandardSearchPage: false
    }
  }
  const routeParts = asPath
    .split('/')
    .filter(part => part.charAt(0) !== '?' && part.charAt(0) !== '#')
    .filter(Boolean)

  const isCompanyPage = !!asPath.match(/\/c\//)
  const isPartnerPage = !!asPath.match(/\/e\//)
  const isLocationOnlyPage = !!asPath.match(/\/l\//)
  const isStandardSearchPage = !!asPath.match(/\/search/)

  const isJobTitleOnlyPage =
    (routeParts.indexOf('j') === 0 && routeParts.length === 2) ||
    (routeParts.indexOf('j') === 1 && routeParts.length === 3)

  const isJobTitleAndLocationPage =
    (routeParts.indexOf('j') === 0 && routeParts.length === 3) ||
    (routeParts.indexOf('j') === 1 && routeParts.length === 4)

  return {
    isCompanyPage,
    isPartnerPage,
    isLocationOnlyPage,
    isJobTitleOnlyPage,
    isJobTitleAndLocationPage,
    isStandardSearchPage
  }
}

export function unwrap<T>(x: T | T[]): T {
  return Array.isArray(x) ? x[0] : x
}
