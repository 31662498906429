import { usStatesArray } from '../../constants/usStateData'
import { Country } from '~/constants/app-constants'

export function extractCityName(location: string = ''): string {
  if (location.includes(',')) {
    return location.substr(0, location.lastIndexOf(','))
  } else if (location.includes('-')) {
    return location.replace(/-[A-Za-z]{2,3}$/, '').replace(/-/, ' ')
  } else {
    return location
  }
}

export function extractStateName(location: string = ''): string {
  const match = location.match(/(-|,|\s)([A-Za-z]{2,3})$/)

  if (match && match[2]) {
    return match[2]
  } else {
    return ''
  }
}

export function getFullStateName(val: string): string {
  const stateName =
    usStatesArray.find(
      state =>
        state.code.toLowerCase() === val.toLowerCase() ||
        state.name.toLowerCase() === val.toLowerCase()
    )?.name ||
    (val.toLowerCase() == 'uk' && 'United Kingdom')
  return stateName || ''
}

export function getStateAbbrev(val: string): string {
  const stateName = usStatesArray.find(
    state =>
      state.code.toLowerCase() === val.toLowerCase() ||
      state.name.toLowerCase() === val.toLowerCase()
  )
  return stateName ? stateName.code : ''
}

export function getLocationFieldFallback(cookies: { [key: string]: string | undefined }): string {
  const { locationFieldTracker } = cookies
  const defaultLocation = 'San Francisco, CA'

  return locationFieldTracker || defaultLocation
}

export const getCountryRestrictions = (country?: string) => {
  if (!country) {
    return [Country.DEFAULT]
  } else if (country === Country.CA) {
    return [Country.US, Country.CA]
  } else {
    return [country.toLowerCase()]
  }
}

export function getCountryFromCountryCode(countryCode: string): string {
  const countries: { [key: string]: string } = {
    us: 'the United States',
    uk: 'the United Kingdom',
    ca: 'Canada',
    au: 'Australia'
  }
  return countries[countryCode]
}
