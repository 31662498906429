import React, { createContext, useContext } from 'react'

export interface UserDevice {
  userAgent: string
  isMobile: boolean
  isTablet: boolean
  isIpad: boolean
  isBot: boolean
}

const initialData: UserDevice = {
  userAgent: '',
  isMobile: false,
  isTablet: false,
  isIpad: false,
  isBot: false
}

export const UserDeviceContext = createContext(initialData)

// Use useUserDevice within a function component to access user device info
const useUserDevice = () => {
  return useContext(UserDeviceContext)
}

interface ConsumerProps {
  children: (context: UserDevice) => React.ReactNode
}

// use this within a class component to access user device info
const UserDeviceConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <UserDeviceContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('UserDeviceConsumer must be used within a UserDeviceProvider')
        }
        return children(context)
      }}
    </UserDeviceContext.Consumer>
  )
}

interface ProviderParams {
  value: UserDevice
}
const UserDeviceProvider: React.FC<ProviderParams> = ({ value, children }) => {
  return <UserDeviceContext.Provider value={value}>{children}</UserDeviceContext.Provider>
}

export { UserDeviceProvider, UserDeviceConsumer, useUserDevice }
