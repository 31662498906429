import React, { createContext, useContext, useState } from 'react'
import { ParsedUrlQuery } from 'querystring'
import { queryResolver } from '~/lib/utils/url'
import { parseCookies } from '~/lib/utils/cookies'
import Cookie from 'js-cookie'

export type SupportedLanguages = 'en' | 'fr' | 'es'

type LanguageActions = { setLanguage: (lang: SupportedLanguages) => void }

export interface Lang {
  lang: SupportedLanguages
}

const initialData: Lang = {
  lang: 'en'
}

export const LangActionsContext = createContext<LanguageActions | undefined>(undefined)
export const LangContext = createContext(initialData)

const getLanguageFromUrl = (query: ParsedUrlQuery): string | undefined => {
  const supportedLanguages: SupportedLanguages[] = ['en', 'es', 'fr']
  const resolve = queryResolver(query)
  let lang = resolve.value('alg') as SupportedLanguages
  return supportedLanguages.includes(lang) ? lang : ''
}

const getLanguageFromCookie = (req?: any): string | undefined => {
  const supportedLanguages: SupportedLanguages[] = ['en', 'es', 'fr']
  const { siteLang = '' } = parseCookies(req)
  return supportedLanguages.includes(siteLang as SupportedLanguages) ? siteLang : ''
}

export const getLanguage = (query: ParsedUrlQuery, req?: any): SupportedLanguages | boolean => {
  const supportedLanguages: SupportedLanguages[] = ['en', 'es', 'fr']
  const lang = (getLanguageFromUrl(query) || getLanguageFromCookie(req) || '') as SupportedLanguages
  return supportedLanguages.includes(lang) ? lang : false
}

const useLang = () => {
  const context = useContext(LangContext)
  if (context === undefined) {
    throw new Error('useLang must be used within a LanguageProvider')
  }
  return context
}

function useLangActions() {
  const context = useContext(LangActionsContext)
  if (context === undefined) {
    throw new Error('useLangActions must be used within a LanguageProvider')
  }
  return context
}

interface ProviderParams {
  value: Lang
}
const LangProvider: React.FC<ProviderParams> = ({ value, children }) => {
  const [lang, setLang] = useState<SupportedLanguages>(value.lang)

  const setLanguage = (lang: SupportedLanguages) => {
    setLang(lang)
    Cookie.set('siteLang', lang)
  }

  return (
    <LangContext.Provider value={{ lang }}>
      <LangActionsContext.Provider value={{ setLanguage }}>{children}</LangActionsContext.Provider>
    </LangContext.Provider>
  )
}

export { LangProvider, useLang, useLangActions }
