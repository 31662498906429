type UsStatesArrayType = { name: string; code: string }[]

export const usStatesArray: UsStatesArrayType = [
  { name: 'Alabama', code: 'AL' },

  { name: 'Alaska', code: 'AK' },

  { name: 'Arizona', code: 'AZ' },

  { name: 'Arkansas', code: 'AR' },

  { name: 'California', code: 'CA' },

  { name: 'Colorado', code: 'CO' },

  { name: 'Connecticut', code: 'CT' },

  { name: 'Delaware', code: 'DE' },

  { name: 'Florida', code: 'FL' },

  { name: 'Georgia', code: 'GA' },

  { name: 'Hawaii', code: 'HI' },

  { name: 'Idaho', code: 'ID' },

  { name: 'Illinois', code: 'IL' },

  { name: 'Indiana', code: 'IN' },

  { name: 'Iowa', code: 'IA' },

  { name: 'Kansas', code: 'KS' },

  { name: 'Kentucky', code: 'KY' },

  { name: 'Louisiana', code: 'LA' },

  { name: 'Maine', code: 'ME' },

  { name: 'Maryland', code: 'MD' },

  { name: 'Massachusetts', code: 'MA' },

  { name: 'Michigan', code: 'MI' },

  { name: 'Minnesota', code: 'MN' },

  { name: 'Mississippi', code: 'MS' },

  { name: 'Missouri', code: 'MO' },

  { name: 'Montana', code: 'MT' },

  { name: 'Nebraska', code: 'NE' },

  { name: 'Nevada', code: 'NV' },

  { name: 'New Hampshire', code: 'NH' },

  { name: 'New Jersey', code: 'NJ' },

  { name: 'New Mexico', code: 'NM' },

  { name: 'New York', code: 'NY' },

  { name: 'North Carolina', code: 'NC' },

  { name: 'North Dakota', code: 'ND' },

  { name: 'Ohio', code: 'OH' },

  { name: 'Oklahoma', code: 'OK' },

  { name: 'Oregon', code: 'OR' },

  { name: 'Pennsylvania', code: 'PA' },

  { name: 'Rhode Island', code: 'RI' },

  { name: 'South Carolina', code: 'SC' },

  { name: 'South Dakota', code: 'SD' },

  { name: 'Tennessee', code: 'TN' },

  { name: 'Texas', code: 'TX' },

  { name: 'Utah', code: 'UT' },

  { name: 'Vermont', code: 'VT' },

  { name: 'Virginia', code: 'VA' },

  { name: 'Washington', code: 'WA' },

  { name: 'West Virginia', code: 'WV' },

  { name: 'Wisconsin', code: 'WI' },

  { name: 'Wyoming', code: 'WY' }
]

export interface UsStatesType {
  [key: string]: {
    abbrev: string
  }
}

export const usStates: UsStatesType = {
  Alabama: {
    abbrev: 'AL'
  },

  Alaska: {
    abbrev: 'AK'
  },

  Arizona: {
    abbrev: 'AZ'
  },

  Arkansas: {
    abbrev: 'AR'
  },

  California: {
    abbrev: 'CA'
  },

  Colorado: {
    abbrev: 'CO'
  },

  Connecticut: {
    abbrev: 'CT'
  },

  Delaware: {
    abbrev: 'DE'
  },

  Florida: {
    abbrev: 'FL'
  },

  Georgia: {
    abbrev: 'GA'
  },

  Hawaii: {
    abbrev: 'HI'
  },

  Idaho: {
    abbrev: 'ID'
  },

  Illinois: {
    abbrev: 'IL'
  },

  Indiana: {
    abbrev: 'IN'
  },

  Iowa: {
    abbrev: 'IA'
  },

  Kansas: {
    abbrev: 'KS'
  },

  Kentucky: {
    abbrev: 'KY'
  },

  Louisiana: {
    abbrev: 'LA'
  },

  Maine: {
    abbrev: 'ME'
  },

  Maryland: {
    abbrev: 'MD'
  },

  Massachusetts: {
    abbrev: 'MA'
  },

  Michigan: {
    abbrev: 'MI'
  },

  Minnesota: {
    abbrev: 'MN'
  },

  Mississippi: {
    abbrev: 'MS'
  },

  Missouri: {
    abbrev: 'MO'
  },

  Montana: {
    abbrev: 'MT'
  },

  Nebraska: {
    abbrev: 'NE'
  },

  Nevada: {
    abbrev: 'NV'
  },

  'New Hampshire': {
    abbrev: 'NH'
  },

  'New Jersey': {
    abbrev: 'NJ'
  },

  'New Mexico': {
    abbrev: 'NM'
  },

  'New York': {
    abbrev: 'NY'
  },

  'North Carolina': {
    abbrev: 'NC'
  },

  'North Dakota': {
    abbrev: 'ND'
  },

  Ohio: {
    abbrev: 'OH'
  },

  Oklahoma: {
    abbrev: 'OK'
  },

  Oregon: {
    abbrev: 'OR'
  },

  Pennsylvania: {
    abbrev: 'PA'
  },

  'Rhode Island': {
    abbrev: 'RI'
  },

  'South Carolina': {
    abbrev: 'SC'
  },

  'South Dakota': {
    abbrev: 'SD'
  },

  Tennessee: {
    abbrev: 'TN'
  },

  Texas: {
    abbrev: 'TX'
  },

  Utah: {
    abbrev: 'UT'
  },

  Vermont: {
    abbrev: 'VT'
  },

  Virginia: {
    abbrev: 'VA'
  },

  Washington: {
    abbrev: 'WA'
  },

  'West Virginia': {
    abbrev: 'WV'
  },

  Wisconsin: {
    abbrev: 'WI'
  },

  Wyoming: {
    abbrev: 'WY'
  }
}
