import { ABTestInternal } from '../types/types'

// Use this tool to generate a random 5 character ID
// https://randomkeygen.com/

// export const TV5ABTest: ABTestInternal = {
//   name: 'Landing Modal A/B Test',
//   shortName: 'tv-5',
//   control: 'tv-5a-QH0Tp',
//   bucket: {
//     A: 'tv-5a-QH0Tp',
//     B: 'tv-5b-ZrJ86',
//     C: 'tv-5c-lVSGt'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-5'
// }

export const TV6ABTest: ABTestInternal = {
  name: 'Results Link Color A/B Test',
  shortName: 'tv-6',
  control: 'tv-6a-59dz2',
  bucket: {
    A: 'tv-6a-59dz2',
    B: 'tv-6b-0U2cg'
  },
  mpEvtName: 'Search - A/B Test',
  cookieName: 'tv-6'
}

// export const TV7ABTest: ABTestInternal = {
//   name: 'Require Keyword On Modal A/B Test',
//   shortName: 'tv-7',
//   control: 'tv-7a-BmEjH',
//   bucket: {
//     A: 'tv-7a-BmEjH',
//     B: 'tv-7b-bPdcF',
//     C: 'tv-7c-g4bvB'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-7'
// }

// export const TV8ABTest: ABTestInternal = {
//   name: 'Hide Job Desc A/B Test',
//   shortName: 'tv-8',
//   control: 'tv-8a-P4gPO',
//   bucket: {
//     A: 'tv-8a-P4gPO',
//     B: 'tv-8b-ET0wW'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-8'
// }

// export const TV9ABTest: ABTestInternal = {
//   name: 'Search Local Inventory A/B Test',
//   shortName: 'tv-9',
//   control: 'tv-9a-mk4n5',
//   bucket: {
//     A: 'tv-9a-mk4n5',
//     B: 'tv-9b-niusW'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-9'
// }

// export const TV10ABTest: ABTestInternal = {
//   name: 'Keyword Boost A/B Test',
//   shortName: 'tv-10',
//   control: 'tv-10a-dFg2i',
//   bucket: {
//     A: 'tv-10a-dFg2i',
//     B: 'tv-10b-YnIY5'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-10'
// }

// export const TV11ABTest: ABTestInternal = {
//   name: 'No Keyword Boost A/B Test',
//   shortName: 'tv-11',
//   control: 'tv-11a-dFg2i',
//   bucket: {
//     A: 'tv-11a-dFg2i',
//     B: 'tv-11b-YnIY5'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-11'
// }

export const TV12ABTest: ABTestInternal = {
  name: 'Require Keyword On Modal A/B Test V2',
  shortName: 'tv-12',
  control: 'tv-12a-EGyMQ',
  bucket: {
    A: 'tv-12a-EGyMQ',
    B: 'tv-12b-NQRxk'
  },
  mpEvtName: 'Search - Modal - A/B Test',
  cookieName: 'tv-12'
}

export const TV13ABTest: ABTestInternal = {
  name: 'Search Local Inventory A/B Test V2',
  shortName: 'tv-13',
  control: 'tv-13a-lf2Vi',
  bucket: {
    A: 'tv-13a-lf2Vi',
    B: 'tv-13b-7eaSR'
  },
  mpEvtName: 'Search - A/B Test',
  cookieName: 'tv-13'
}

// export const TV14ABTest: ABTestInternal = {
//   name: 'Disable Bottom Email Modal A/B Test',
//   shortName: 'tv-14',
//   control: 'tv-14a-dRrQu',
//   bucket: {
//     A: 'tv-14a-dRrQu',
//     B: 'tv-14b-57PEj'
//   },
//   mpEvtName: 'Search - Email - A/B Test',
//   cookieName: 'tv-14'
// }

// export const TV15ABTest: ABTestInternal = {
//   name: 'Search Modal Button A/B Test',
//   shortName: 'tv-15',
//   control: 'tv-15a-vRwJd',
//   bucket: {
//     A: 'tv-15a-vRwJd',
//     B: 'tv-15b-d5ZLO'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-15'
// }

// export const TV16ABTest: ABTestInternal = {
//   name: 'Search Modal FB Headline A/B Test',
//   shortName: 'tv-16',
//   control: 'tv-16a-5XD5w',
//   bucket: {
//     A: 'tv-16a-5XD5w',
//     B: 'tv-16b-a1gV7'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-16'
// }

export const TV17ABTest: ABTestInternal = {
  name: 'Neuvoo CPC Floor A/B Test',
  shortName: 'tv-17',
  control: 'tv-17a-wtjQS',
  bucket: {
    A: 'tv-17a-wtjQS',
    B: 'tv-17b-LKoxV'
  },
  mpEvtName: 'Neuvoo CPC Floor - A/B Test',
  cookieName: 'tv-17'
}

// export const TV18ABTest: ABTestInternal = {
//   name: 'Covid 19 Search Results Alert',
//   shortName: 'tv-18',
//   control: 'tv-18a-o9uff',
//   bucket: {
//     A: 'tv-18a-o9uff',
//     B: 'tv-18b-JCjv3'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-18'
// }

export const TV19ABTest: ABTestInternal = {
  name: 'Require Keyword Test for CA',
  shortName: 'tv-19',
  control: 'tv-19a-o9uff',
  bucket: {
    A: 'tv-19a-s4KuB',
    B: 'tv-19b-Okzaz'
  },
  mpEvtName: 'Search - Modal - A/B Test',
  cookieName: 'tv-19'
}

// export const TV20ABTest: ABTestInternal = {
//   name: 'New Search Page Design',
//   shortName: 'tv-20',
//   control: 'tv-20a-o9gff',
//   bucket: {
//     A: 'tv-20a-o9gff',
//     B: 'tv-20b-JCgv3'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-20'
// }

// export const TV22ABTest: ABTestInternal = {
//   name: 'Search Results Alert',
//   shortName: 'tv-22',
//   control: 'tv-22a-9zjR4',
//   bucket: {
//     A: 'tv-22a-9zjR4',
//     B: 'tv-22b-nDpMq'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-22'
// }

// export const TV23ABTest: ABTestInternal = {
//   name: 'Search Alert Learning Links',
//   shortName: 'tv-23',
//   control: 'tv-23a-Igp04',
//   bucket: {
//     A: 'tv-23a-Igp04',
//     B: 'tv-23b-gPwfE'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-23'
// }

// export const TV24ABTest: ABTestInternal = {
//   name: 'Keywords Autocomplete',
//   shortName: 'tv-24',
//   control: 'tv-24a-sdE74',
//   bucket: {
//     A: 'tv-24a-sdE74',
//     B: 'tv-24b-uNwaP'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-24'
// }

// export const TV25ABTest: ABTestInternal = {
//   name: 'Onboarding Quiz v1',
//   shortName: 'tv-25',
//   control: 'tv-25a-8BDg2',
//   bucket: {
//     A: 'tv-25a-8BDg2',
//     B: 'tv-25b-8BDg2'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-25'
// }

// export const TV26ABTest: ABTestInternal = {
//   name: 'Search Facets',
//   shortName: 'tv-26',
//   control: 'tv-26a-9JxpO',
//   bucket: {
//     A: 'tv-26a-9JxpO',
//     B: 'tv-26b-WaNie'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-26'
// }

// export const TV27ABTest: ABTestInternal = {
//   name: 'Onboarding Quiz v2',
//   shortName: 'tv-27',
//   control: 'tv-27a-sffEA',
//   bucket: {
//     A: 'tv-27a-sffEA',
//     B: 'tv-27b-sffEA'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-27'
// }

// export const TV28ABTest: ABTestInternal = {
//   name: 'Use Onboarding for Mobile Search Header',
//   shortName: 'tv-28',
//   control: 'tv-28a-fvEdA',
//   bucket: {
//     A: 'tv-28a-fvEdA',
//     B: 'tv-28b-fvEdA'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-28'
// }

// export const TV29ABTest: ABTestInternal = {
//   name: 'Use Onboarding for Simple Search',
//   shortName: 'tv-29',
//   control: 'tv-29a-lqRTa',
//   bucket: {
//     A: 'tv-29a-lqRTa',
//     B: 'tv-29b-lqRTa'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-29'
// }

// export const TV30ABTest: ABTestInternal = {
//   name: 'Replace Desktop Filters with new Search Facets',
//   shortName: 'tv-30',
//   control: 'tv-30a-vbWEk',
//   bucket: {
//     A: 'tv-30a-vbWEk',
//     B: 'tv-30b-vbWEk'
//   },
//   mpEvtName: 'Search - Modal - A/B Test',
//   cookieName: 'tv-30'
// }

// export const TV31ABTest: ABTestInternal = {
//   name: 'Exclude Description From Search',
//   shortName: 'tv-31',
//   control: 'tv-31a-syqk2',
//   bucket: {
//     A: 'tv-31a-syqk2',
//     B: 'tv-31b-RSC98'
//   },
//   mpEvtName: 'Search - Exclude Description - A/B Test',
//   cookieName: 'tv-31'
// }

// export const TV32ABTest: ABTestInternal = {
//   name: 'Use Semantic Search',
//   shortName: 'tv-32',
//   control: 'tv-32a-StDYw',
//   bucket: {
//     A: 'tv-32a-StDYw',
//     B: 'tv-32b-0Cy0g'
//   },
//   mpEvtName: 'Search - Use Semantic Search - A/B Test',
//   cookieName: 'tv-32'
// }

export const TV34ABTest: ABTestInternal = {
  name: 'Dev Panel',
  shortName: 'tv-34',
  control: 'tv-34a-txXEk',
  bucket: {
    A: 'tv-34a-txXEk',
    B: 'tv-34b-txXEk'
  },
  mpEvtName: 'Dev Panel',
  cookieName: 'tv-34'
}

// export const TV35ABTest: ABTestInternal = {
//   name: 'Expanded Search',
//   shortName: 'tv-35',
//   control: 'tv-35a-Y9bNE',
//   bucket: {
//     A: 'tv-35a-Y9bNE',
//     B: 'tv-35b-RyqsC'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-35'
// }

export const TV38ABTest: ABTestInternal = {
  name: 'Search Modal Colors A/B Test',
  shortName: 'tv-38',
  control: 'tv-38a-vxxJd',
  bucket: {
    A: 'tv-38a-vxxJd',
    B: 'tv-38b-dnnLO',
    C: 'tv-38c-angRO'
  },
  mpEvtName: 'Search - Modal - A/B Test',
  cookieName: 'tv-38'
}

// export const TV39ABTest: ABTestInternal = {
//   name: 'CA - Search Radius A/B Test',
//   shortName: 'tv-39',
//   control: 'tv-39a-juC4V',
//   bucket: {
//     A: 'tv-39a-juC4V',
//     B: 'tv-39b-voXrh',
//     C: 'tv-39c-u35vl'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-39'
// }

// export const TV40ABTest: ABTestInternal = {
//   name: 'No Extra Help Prompt on Mobile',
//   shortName: 'tv-40',
//   control: 'tv-40a-Xu3H4',
//   bucket: {
//     A: 'tv-40a-Xu3H4',
//     B: 'tv-40b-YA6jl'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-40'
// }

export const TV41ABTest: ABTestInternal = {
  name: 'Compare Tick Placement',
  shortName: 'tv-41',
  control: 'tv-41a-TLBTF',
  bucket: {
    A: 'tv-41a-TLBTF',
    B: 'tv-41b-oOzk8'
  },
  mpEvtName: 'Search - Modal - A/B Test',
  cookieName: 'tv-41'
}

export const TV42ABTest: ABTestInternal = {
  name: 'Top Ad Position For Country',
  shortName: 'tv-42',
  control: 'tv-42a-zAU1U',
  bucket: {
    A: 'tv-42a-zAU1U',
    B: 'tv-42b-nUr9g'
  },
  mpEvtName: 'Search - A/B Test',
  cookieName: 'tv-42'
}

export const TV43ABTest: ABTestInternal = {
  name: 'Low Results Middle Explainer',
  shortName: 'tv-43',
  control: 'tv-43a-0ZOOK',
  bucket: {
    A: 'tv-43a-0ZOOK',
    B: 'tv-43b-HhIjV'
  },
  mpEvtName: 'Search - A/B Test',
  cookieName: 'tv-43'
}

export const TV44ABTest: ABTestInternal = {
  name: 'Search Results Limit',
  shortName: 'tv-44',
  control: 'tv-44a-81GW0',
  bucket: {
    A: 'tv-44a-81GW0',
    B: 'tv-44b-WzXvQ'
  },
  mpEvtName: 'Search - A/B Test',
  cookieName: 'tv-44'
}

// export const TV45ABTest: ABTestInternal = {
//   name: 'Job Listing Icons',
//   shortName: 'tv-45',
//   control: 'tv-45a-BZWHe',
//   bucket: {
//     A: 'tv-45a-BZWHe',
//     B: 'tv-45b-alpqW',
//     C: 'tv-45c-aqzi2'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-45'
// }

export const TV48ABTest: ABTestInternal = {
  name: 'Search CPC Boost',
  shortName: 'tv-48',
  control: 'tv-48a-d5AQo',
  bucket: {
    A: 'tv-48a-d5AQo',
    B: 'tv-48b-YOqdG'
  },
  mpEvtName: 'Search - A/B Test',
  cookieName: 'tv-48'
}

// export const TV50ABTest: ABTestInternal = {
//   name: 'Job Alerts Sidebar',
//   shortName: 'tv-50',
//   control: 'tv-50a-Iru4d',
//   bucket: {
//     A: 'tv-50a-Iru4d',
//     B: 'tv-50b-NWj53'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-50'
// }

// export const TV51ABTest: ABTestInternal = {
//   name: 'Search Places Autocomplete Test',
//   shortName: 'tv-51',
//   control: 'tv-51a-1TGtN',
//   bucket: {
//     A: 'tv-51a-1TGtN',
//     B: 'tv-51b-BId22'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-51'
// }

export const TV52ABTest: ABTestInternal = {
  name: 'Gamify Feature',
  shortName: 'tv-52',
  control: 'tv-52a-G4bHE',
  bucket: {
    A: 'tv-52a-G4bHE',
    B: 'tv-52b-HuqsF'
  },
  mpEvtName: 'Gamify Featre - A/B Test',
  cookieName: 'tv-52'
}

// export const TV53ABTest: ABTestInternal = {
//   name: 'DFS Search Test',
//   shortName: 'tv-53',
//   control: 'tv-53a-R75o3',
//   bucket: {
//     A: 'tv-53a-R75o3',
//     B: 'tv-53b-ioTwQ'
//   },
//   mpEvtName: 'DFS Search Test - A/B Test',
//   cookieName: 'tv-53'
// }

// export const TV54ABTest: ABTestInternal = {
//   name: 'Calc CPC Pivot',
//   shortName: 'tv-54',
//   control: 'tv-54a-ryiZb',
//   bucket: {
//     A: 'tv-54a-ryiZb',
//     B: 'tv-54b-zgDzy',
//     C: 'tv-54c-HruTd'
//   },
//   mpEvtName: 'Search - A/B Test',
//   cookieName: 'tv-54'
// }

// export const TV55ABTest: ABTestInternal = {
//   name: 'Search Pagination Strategy',
//   shortName: 'tv-55',
//   control: 'tv-55a-wjnxJ',
//   bucket: {
//     A: 'tv-55a-wjnxJ',
//     B: 'tv-55b-HihiR',
//     C: 'tv-55c-UEfIg'
//   },
//   mpEvtName: 'Search Pagination Strategy - A/B Test',
//   cookieName: 'tv-55'
// }

// export const TV56ABTest: ABTestInternal = {
//   name: 'Description Phrase Search Test',
//   shortName: 'tv-56',
//   control: 'tv-56a-kjilp',
//   bucket: {
//     A: 'tv-56a-kjilp',
//     B: 'tv-56b-Jkolm',
//     C: 'tv-56c-Ndrjo'
//   },
//   mpEvtName: 'Description Phrase Search Test - A/B Test',
//   cookieName: 'tv-56'
// }

// export const TV57ABTest: ABTestInternal = {
//   name: 'Always Boost Companies',
//   shortName: 'tv-57',
//   control: 'tv-57a-wS8hU',
//   bucket: {
//     A: 'tv-57a-wS8hU',
//     B: 'tv-57b-drlHs'
//   },
//   mpEvtName: 'Always Boost Companies - A/B Test',
//   cookieName: 'tv-57'
// }

// export const TV58ABTest: ABTestInternal = {
//   name: 'Dedupe Company For Keyword Searches',
//   shortName: 'tv-58',
//   control: 'tv-58a-24yAe',
//   bucket: {
//     A: 'tv-58a-24yAe',
//     B: 'tv-58b-xugXY'
//   },
//   mpEvtName: 'Dedupe Company For Keyword Searches - A/B Test',
//   cookieName: 'tv-58'
// }

// export const TV59ABTest: ABTestInternal = {
//   name: 'Rank Providers by Arpu',
//   shortName: 'tv-59',
//   control: 'tv-59a-5WcmJ',
//   bucket: {
//     A: 'tv-59a-5WcmJ',
//     B: 'tv-59b-s0xsU'
//   },
//   mpEvtName: 'Rank Providers by Arpu - A/B Test',
//   cookieName: 'tv-59'
// }

export const TV60ABTest: ABTestInternal = {
  name: 'E Page Modal Title',
  shortName: 'tv-60',
  control: 'tv-60a-2YchA',
  bucket: {
    A: 'tv-60a-2YchA',
    B: 'tv-60b-u4FpU'
  },
  mpEvtName: 'E Page Modal Title - A/B Test',
  cookieName: 'tv-60'
}

export const TV61ABTest: ABTestInternal = {
  name: 'Top Job Page',
  shortName: 'tv-61',
  control: 'tv-61a-7Dghh',
  bucket: {
    A: 'tv-61a-7Dghh',
    B: 'tv-61b-uu4uu'
  },
  mpEvtName: 'Top Job Page - A/B Test',
  cookieName: 'tv-61'
}

export const TV62ABTest: ABTestInternal = {
  name: 'Email Insert Feature',
  shortName: 'tv-62',
  control: 'tv-62a-1Whhh',
  bucket: {
    A: 'tv-62a-1Whhh',
    B: 'tv-62b-hh4aa'
  },
  mpEvtName: 'Email Insert Feature - A/B Test',
  cookieName: 'tv-62'
}

// export const TV63ABTest: ABTestInternal = {
//   name: 'Inline Job Actions Feature',
//   shortName: 'tv-63',
//   control: 'tv-63a-7Wtjh',
//   bucket: {
//     A: 'tv-63a-7Wtjh',
//     B: 'tv-63b-uN31a'
//   },
//   mpEvtName: 'Inline Job Actions Feature - A/B Test',
//   cookieName: 'tv-63'
// }

export const TV64ABTest: ABTestInternal = {
  name: 'JIT Routing',
  shortName: 'tv-64',
  control: 'tv-64a-uqH21',
  bucket: {
    A: 'tv-64a-uqH21',
    B: 'tv-64b-NJE02'
  },
  mpEvtName: 'JIT Routing - A/B Test',
  cookieName: 'tv-64'
}
// export const TV65ABTest: ABTestInternal = {
//   name: 'Super Post Feature',
//   shortName: 'tv-65',
//   control: 'tv-65a-9Qjlr',
//   bucket: {
//     A: 'tv-65a-9Qjlr',
//     B: 'tv-65b-yN46p'
//   },
//   mpEvtName: 'Super Post Feature - A/B Test',
//   cookieName: 'tv-65'
// }
export const TV66ABTest: ABTestInternal = {
  name: 'Modify Search Insert Feature',
  shortName: 'tv-66',
  control: 'tv-66a-7Hjlr',
  bucket: {
    A: 'tv-66a-7Hjlr',
    B: 'tv-66b-yN88p'
  },
  mpEvtName: 'Modify Search Insert Feature - A/B Test',
  cookieName: 'tv-66'
}

export const TV67ABTest: ABTestInternal = {
  name: 'Compare Insert Feature',
  shortName: 'tv-67',
  control: 'tv-67a-1gghh',
  bucket: {
    A: 'tv-67a-1gghh',
    B: 'tv-67b-he4aa'
  },
  mpEvtName: 'Compare Insert Feature - A/B Test',
  cookieName: 'tv-67'
}

// export const TV68ABTest: ABTestInternal = {
//   name: 'Single Search Results Container',
//   shortName: 'tv-68',
//   control: 'tv-68a-1snop',
//   bucket: {
//     A: 'tv-68a-1snop',
//     B: 'tv-68b-fasta'
//   },
//   mpEvtName: 'Single Search Results Container - A/B Test',
//   cookieName: 'tv-68'
// }
