import { decToHex } from 'hex2dec'
import { NextRouter } from 'next/router'
import { UserDevice } from '~/context/UserDeviceContext'

/**
 * Converts a string representation of a number to an array of digit values.
 *
 * More precisely, the digit values are indices into the number base, which
 * is represented as a string, which can either be user defined or one of the
 * BASE_xxx constants.
 *
 * Throws an Error if the number contains a digit not found in the base.
 *
 * @param {string} num The string to convert, most significant digit first.
 * @param {string} base Digits in the base.
 * @return {!Array<number>} Array of digit values, least significant digit
 *     first.
 * @private
 */
function stringToDigits(num: string, base: string): number[] {
  const index: Record<string, number> = {}
  for (let i = 0, n = base.length; i < n; i++) {
    index[base.charAt(i)] = i
  }
  const result = []
  for (let i = num.length - 1; i >= 0; i--) {
    const character = num.charAt(i)
    const digit = index[character]
    if (typeof digit === 'undefined') {
      throw new Error(
        'Number ' +
          num +
          ' contains a character not found in base ' +
          base +
          ', which is ' +
          character
      )
    }
    result.push(digit)
  }
  return result
}

/**
 * Converts an array representation of a number to a string.
 *
 * More precisely, the elements of the input array are indices into the base,
 * which is represented as a string, which can either be user defined or one of
 * the BASE_xxx constants.
 *
 * Throws an Error if the number contains a digit which is outside the range
 * 0 ... base.length - 1.
 *
 * @param {Array<number>} digits Array of digit values, least significant
 *     first.
 * @param {string} base Digits in the base.
 * @return {string} Number as a string, most significant digit first.
 * @private
 */
function digitsToString(digits: number[], base: string): string {
  const chars = []
  const baseSize = base.length
  for (let i = digits.length - 1; i >= 0; i--) {
    const digit = digits[i]
    if (digit >= baseSize || digit < 0) {
      throw new Error('Number ' + digits + ' contains an invalid digit: ' + digit)
    }
    chars.push(base.charAt(digit))
  }
  return chars.join('')
}

/**
 * Converts a number from one numeric base to another.
 *
 * The bases are represented as strings, which list allowed digits.  Each digit
 * should be unique.
 *
 * The number is in human-readable format, most significant digit first, and is
 * a non-negative integer.  Base designators such as $, 0x, d, b or h (at end)
 * will be interpreted as digits, so avoid them.  Leading zeros will be trimmed.
 *
 * Note: for huge bases the result may be inaccurate because of overflowing
 * 64-bit doubles used by JavaScript for integer calculus.  This may happen
 * if the product of the number of digits in the input and output bases comes
 * close to 10^16, which is VERY unlikely (100M digits in each base), but
 * may be possible in the future unicode world.  (Unicode 3.2 has less than 100K
 * characters.  However, it reserves some more, close to 1M.)
 *
 * @param {string} num The number to convert.
 * @param {string} inputBase The numeric base the number is in (all digits).
 * @param {string} outputBase Requested numeric base.
 * @return {string} The converted number.
 */
export function recodeString(num: string, inputBase: string, outputBase: string): string {
  if (outputBase === '') {
    throw new Error('Empty output base')
  }

  // Check if num is 0 (special case when we don't want to return '').
  let isZero = true
  for (let numIndex = 0, numSize = num.length; numIndex < numSize; numIndex++) {
    if (num.charAt(numIndex) !== inputBase.charAt(0)) {
      isZero = false
      break
    }
  }
  if (isZero) {
    return outputBase.charAt(0)
  }

  const numberDigits = stringToDigits(num, inputBase)

  const inputBaseSize = inputBase.length
  const outputBaseSize = outputBase.length

  // result = 0.
  const result: number[] = []

  // For all digits of number, starting with the most significant ...
  for (let i = numberDigits.length - 1; i >= 0; i--) {
    // result *= number.base.
    let carry = 0
    let remainder
    let j = 0
    for (j = 0; j < result.length; j++) {
      let digit = result[j]
      // This may overflow for huge bases.  See function comment.
      digit = digit * inputBaseSize + carry
      if (digit >= outputBaseSize) {
        remainder = digit % outputBaseSize
        carry = (digit - remainder) / outputBaseSize
        digit = remainder
      } else {
        carry = 0
      }
      result[j] = digit
    }
    while (carry) {
      remainder = carry % outputBaseSize
      result.push(remainder)
      carry = (carry - remainder) / outputBaseSize
    }

    // result += number[i].
    carry = numberDigits[i]
    j = 0
    while (carry) {
      if (j >= result.length) {
        // Extend result with a leading zero which will be overwritten below.
        result.push(0)
      }
      let digit = result[j]
      digit += carry
      if (digit >= outputBaseSize) {
        remainder = digit % outputBaseSize
        carry = (digit - remainder) / outputBaseSize
        digit = remainder
      } else {
        carry = 0
      }
      result[j] = digit
      j++
    }
  }

  return digitsToString(result, outputBase)
}

export const BASE_DECIMAL = '0123456789'
export const BASE_64_URL_SAFE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'
export const D64 = '.0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz'
export const BASE_62 = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

export function unwrapHead(val: any): any {
  return Array.isArray(val) ? val[0] : val
}

export function sanitizeEventId(num: string): string {
  return recodeString(num, D64, BASE_62)
}

export interface TraceContext {
  traceId: string
  spanId: string
  options?: number
}

export function extractTraceContext(st: string | undefined): TraceContext | null {
  if (!st) {
    return null
  }
  const matches = st.match(/^([0-9a-fA-F]{32})(?:\/([0-9]+))(?:;o=(.*))?/)
  if (
    !matches ||
    matches[1] === '00000000000000000000000000000000' ||
    matches[2] === '0000000000000000'
  ) {
    return null
  }
  return {
    traceId: matches[1],
    spanId: decToHex(matches[2], { prefix: false }).padStart(16, '0'),
    options: isNaN(Number(matches[3])) || matches[3] === '0' ? 0 : 1
  }
}

export const wait = (ms: number) => {
  let start = Date.now(),
    now = start
  while (now - start < ms) {
    now = Date.now()
  }
}

export function getImpressionTrackingData(
  router: NextRouter,
  userDevice: UserDevice,
  placement_context: string
) {
  const pageRoute = router && router.asPath ? router.asPath : ''
  const pageRoutePart = pageRoute.split('/')[1]
  const pathRegex = pageRoutePart ? pageRoutePart.match(/^(?:l|j)?/) : undefined
  const placement_path = pathRegex && pathRegex[0] ? pathRegex[0] : placement_context
  const placement_platform = userDevice.isMobile || userDevice.isTablet ? 'mobile' : 'desktop'
  return { placement_context, placement_path, placement_platform }
}
