import gql from 'graphql-tag'

export const ME_QUERY = gql`
  query Me {
    me {
      id
      shareId
      firstName
      lastName
      fullName
      emailAddress
      isVerified
      roles {
        name
      }
      isSavedJobsListPublic
      savedJobsListName
      lastSigninAt
      createdAt
      quizData {
        covid
        newGrad
        remote
        somethingElse
      }
      showGamify
      progress {
        emailAlert
        signUp
        saveJobs
        inviteFriends
      }
      loggedInRealm
      pendingEmailChange
    }
  }
`
